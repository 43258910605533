import ExternalLinks from "@/components/AdvancedEditor/plugins/LinksPlugin/ExternalLinks";
import News from "@/components/AdvancedEditor/plugins/ResearchPlugin/News";
import Questions from "@/components/AdvancedEditor/plugins/ResearchPlugin/Questions";
import Statistics from "@/components/AdvancedEditor/plugins/ResearchPlugin/Statistics";
import Wiki from "@/components/AdvancedEditor/plugins/ResearchPlugin/Wiki";
import { Tabs, TabsList, TabsTrigger } from "@/components/Elements";
import { TabsContent } from "@radix-ui/react-tabs";

export const InfoGainTabs = ({
  isHeaderNavVisible,
}: {
  isHeaderNavVisible: boolean;
}) => {
  return (
    <Tabs defaultValue="links">
      {!isHeaderNavVisible && (
        <div className="px-4 w-full">
          <TabsList className="w-full mr-4">
            <TabsTrigger value="links">Links</TabsTrigger>
            <TabsTrigger value="questions">Questions</TabsTrigger>
            <TabsTrigger value="stats">Stats</TabsTrigger>
            <TabsTrigger value="news">News</TabsTrigger>
            <TabsTrigger value="wiki">Wiki</TabsTrigger>
          </TabsList>
        </div>
      )}
      <TabsContent value="links">
        <ExternalLinks />
      </TabsContent>
      <TabsContent value="questions" className="overflow-none">
        <Questions />
      </TabsContent>
      <TabsContent value="stats">
        <Statistics />
      </TabsContent>
      <TabsContent value="news">
        <News />
      </TabsContent>
      <TabsContent value="wiki">
        <Wiki />
      </TabsContent>
    </Tabs>
  );
};
