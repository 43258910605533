import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { DialogClose, DialogPortal } from "@radix-ui/react-dialog";
import React, { useState } from "react";
import { useCancelAddOn } from "../../subscription/api/cancelAddOn";

export const ConfirmCancelAddOnDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const cancelAddOnMutation = useCancelAddOn();

  const handleCancelAddOn = () => {
    cancelAddOnMutation.mutate(undefined, {
      onSuccess: () => {
        setOpen(false);
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outlineBlur" className="w-fit" size="xs">
          Cancel add-on
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Confirm add-on cancellation</DialogTitle>
            <DialogCloseButton
              close={() => {
                setOpen(false);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4">
            Are you sure you want to cancel your add-on subscription? Add-on
            functionality will be removed from your account immediately and
            credit will be issued for the unused portion of your subscription.
            <br></br>
            <br></br>
            <strong>Warning:</strong> Any discounts applied to your account will
            be removed and will not be reapplied if you choose to re-add the
            add-on.
          </DialogDescription>
          <DialogFooter className="px-4 pb-4">
            <DialogClose asChild>
              <Button variant="outlineBlur">Cancel</Button>
            </DialogClose>
            <Button
              variant="destructiveBlur"
              onClick={handleCancelAddOn}
              isLoading={cancelAddOnMutation.isLoading}
            >
              Confirm cancellation
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
