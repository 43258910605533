import { useUpdateTemplate } from "@/features/templates/api/updateTemplate"; // Import the useUpdateTemplate hook
import { Template } from "@/features/templates/types"; // Import the Template type
import { useEditorStore } from "@/stores/editor";
import { $generateHtmlFromNodes } from "@lexical/html";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useDebounceCallback } from "@react-hook/debounce";
import { $getRoot, EditorState, LexicalEditor } from "lexical";
import isEqual from "lodash/isEqual";
import { useCallback } from "react";
import { getWordCountOfTextNode } from "../WordCountPlugin";

export function AutoSaveTemplate({
  template,
  isLinkEditMode,
}: {
  template: Template;
  isLinkEditMode: boolean;
}) {
  const updateTemplateMutation = useUpdateTemplate({});
  const { editor: editorStore, setEditor } = useEditorStore();

  const debouncedHandleOnChange = useDebounceCallback(
    (editor: LexicalEditor) => {
      if (isLinkEditMode) return;

      editor.update(() => {
        const previousHtml = template.text[0].html; // Assuming single tab for simplicity
        const newHtml = $generateHtmlFromNodes(editor, null);

        if (!isEqual(newHtml, previousHtml)) {
          const updatedTemplate = {
            ...template,
            text: [{ ...template.text[0], html: newHtml }],
          };

          updateTemplateMutation.mutate(updatedTemplate);
        }

        const root = $getRoot();
        const text = root.getTextContent();
        const numWords = getWordCountOfTextNode(text);
        setEditor((editorState) => {
          editorState.wordCount = numWords;
        });
      });
    },
    500,
    false
  );

  const handleOnChange = useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      debouncedHandleOnChange(editor);
    },
    [debouncedHandleOnChange]
  );

  return <OnChangePlugin onChange={handleOnChange} />;
}
