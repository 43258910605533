import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation } from "react-query";

type UserInvite = { role: number; email: string };
type InviteUsersPayload = { channelId: string; userEmails: UserInvite[] };

export const inviteUsersToFolder = ({
  channelId,
  userEmails,
}: InviteUsersPayload): Promise<any> => {
  return axios.post(`/inviteUsersToTeam`, { channelId, userEmails });
};

type UseInviteUsersToFolderOptions = {
  config?: MutationConfig<typeof inviteUsersToFolder>;
};

export const useInviteUsersToFolder = ({
  config,
}: UseInviteUsersToFolderOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    mutationFn: inviteUsersToFolder,
    onSuccess: (_, { channelId, userEmails }: InviteUsersPayload) => {
      queryClient.invalidateQueries("folderInfo");
      const folderInfo = queryClient.getQueryData("folderInfo");
      addNotification({
        type: "success",
        title: "Users invited",
      });
    },
    ...config,
  });
};
