import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useTemplateStore } from "@/stores/template";
import * as Sentry from "@sentry/react";
import { useMutation, useQueryClient } from "react-query";
import { Template } from "../types";

export const updateTemplate = async (template: Template): Promise<Template> => {
  const response = await axios.post("/updateTemplate", template);
  return response;
};

type UseUpdateTemplateOptions = {
  config?: MutationConfig<typeof updateTemplate>;
};

export const useUpdateTemplate = ({ config }: UseUpdateTemplateOptions) => {
  const queryClient = useQueryClient();
  const { setTemplate } = useTemplateStore();

  return useMutation({
    mutationFn: updateTemplate,
    onMutate: async (updatedTemplate: Template) => {
      await queryClient.cancelQueries(["template", updatedTemplate.hash]);
      await queryClient.cancelQueries(["templates"], { exact: false });
      await queryClient.cancelQueries(["getAiToolByHash", { exact: false }]);
      await queryClient.cancelQueries(["getAiToolById", { exact: false }]);

      if (!updatedTemplate?.text) return;

      return { previousTemplate: updatedTemplate };
    },
    onError: (error, _variables, context) => {
      Sentry.captureException(error);
    },
    onSuccess: (updatedTemplate) => {
      queryClient.setQueryData(
        ["template", updatedTemplate.hash],
        updatedTemplate
      );

      setTemplate(updatedTemplate);

      queryClient.invalidateQueries("templates");
      queryClient.invalidateQueries("getAiToolByHash");
      queryClient.invalidateQueries("getAiToolById");
    },
    ...config,
  });
};
