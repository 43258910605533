import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useSerpStore } from "@/stores/serp";
import { useQuery } from "react-query";
import { FraseDocument } from "../types";
import { Article } from "./getUrlBatch";

const breakIntoBatches = (arr, batchSize) => {
  const arrCopy = [...arr];
  const batches = [];

  while (arrCopy.length) {
    batches.push(arrCopy.splice(0, batchSize));
  }

  return batches;
};

export interface DomainAuthorityResult {
  ueid: number;
  pda: number;
}

export const getDomainAuthority = async (
  urls: string[]
): Promise<DomainAuthorityResult[]> => {
  if (urls.length === 0) {
    return [];
  }

  const batches = breakIntoBatches(urls, 10);
  const requests = batches.map((batch) =>
    axios.post("/getDomainAuthority", { urls: batch })
  );

  try {
    const responses = await Promise.all(requests);
    // flatten the results into a single array, don't need to reference .data
    return responses.flatMap((response) => response);
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching domain authority");
  }
};

type QueryFnType = typeof getDomainAuthority;

type UseProcessDomainAuthorityOptions = {
  urls: string[];
  articles: Article[];
  document: FraseDocument;
  config?: QueryConfig<QueryFnType>;
};

export const useDomainAuthority = ({
  urls,
  articles,
  document,
  config,
}: UseProcessDomainAuthorityOptions) => {
  const { serp, setSerp } = useSerpStore();
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["processDomainAuthority", document.query, urls],
    queryFn: () => getDomainAuthority(urls),
    onSuccess: (data) => {
      const results = data.map((result, index) => ({
        ...result,
        url: urls[index],
      }));
      const scoredArticles = (articles || []).map((article) => {
        const matchedResult = results.find(
          (result) => result.url === article.url
        );
        return {
          ...article,
          domainAuthority: matchedResult?.pda || 0,
          backlinks: matchedResult?.ueid || 0,
        };
      });
      setSerp(document.id, {
        ...serp[document.id],
        articles: scoredArticles || [],
        serpLoaded: true,
      });
    },
  });
};
