import "@fontsource/inter/variable.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { initMocks } from "./test/server";

initMocks();

let darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

updateMode();
darkModeMediaQuery.addEventListener("change", updateModeWithoutTransitions);
window.addEventListener("storage", updateModeWithoutTransitions);

function updateMode() {
  // Don't update the mode when printing
  if (window.matchMedia("print").matches) {
    return;
  }

  let isDarkMode;
  try {
    isDarkMode = window.localStorage.isDarkMode === "true";
  } catch (error) {
    // Fallback to system preferences if localStorage is not available
    isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  }

  if (isDarkMode) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
}

function disableTransitionsTemporarily() {
  document.documentElement.classList.add("[&_*]:!transition-none");
  window.setTimeout(() => {
    document.documentElement.classList.remove("[&_*]:!transition-none");
  }, 0);
}

function updateModeWithoutTransitions() {
  disableTransitionsTemporarily();
  updateMode();
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);
