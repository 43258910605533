import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../../lib/auth";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

type UpdateUserInformationPayload = {
  name: string;
  geography: string;
  domain: string;
};

export const updateUserInformation = async (
  userInfo: UpdateUserInformationPayload
): Promise<any> => {
  return axios.post("/updateUserInformation", userInfo);
};

export const useUpdateUserInformation = () => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();
  const { refetchUser } = useAuth();

  return useMutation(updateUserInformation, {
    onSuccess: () => {
      addNotification({
        type: "success",
        title: "Profile saved!",
        message: "Your profile information has been updated.",
      });
      queryClient.refetchQueries("user");
      refetchUser();
    },
  });
};
