import {
  $createListItemNode,
  $createListNode,
  $isListItemNode,
  $isListNode,
  ListItemNode,
  ListNode,
} from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import {
  $createParagraphNode,
  $isParagraphNode,
  ElementNode,
  LexicalNode,
} from "lexical";

// Regular expressions for matching numbered and bullet lists
// Only match if not preceded by # (heading)
const NUMBER_LIST_REG_EXP = /^(?!#)(?:\s)*1[.)]\s/;
const BULLET_LIST_REG_EXP = /^(?!#)(?:\s)*[-*+]\s/;

export const CustomListTransformer = {
  dependencies: [ListNode, ListItemNode],
  export: (node: LexicalNode) => {
    if (!$isListNode(node)) {
      return null;
    }

    const output = [];
    const children = node.getChildren();
    let index = 1;

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if ($isListItemNode(child)) {
        if (node.getListType() === "number") {
          output.push(`${index}. ${child.getTextContent()}`);
          index++;
        } else {
          output.push(`- ${child.getTextContent()}`);
        }
      }
    }

    return output.join("\n");
  },
  regExp: NUMBER_LIST_REG_EXP,
  replace: (parentNode: ElementNode, _1: string, match: Array<string>) => {
    // Skip if parent is a heading
    if ($isHeadingNode(parentNode)) {
      return;
    }

    // Create list if needed
    let listNode;
    if ($isParagraphNode(parentNode)) {
      if ($isListNode(parentNode.getPreviousSibling())) {
        listNode = parentNode.getPreviousSibling();
      } else {
        listNode = $createListNode("number");
        parentNode.replace(listNode);
      }
    } else if ($isListItemNode(parentNode)) {
      listNode = parentNode.getParent();
    }

    // Create list item
    const listItemNode = $createListItemNode();
    if ($isParagraphNode(parentNode)) {
      const children = parentNode.getChildren();
      for (let i = 0; i < children.length; i++) {
        listItemNode.append(children[i]);
      }
    }
    listNode.append(listItemNode);
  },
  type: "element",
};
