import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import { Comment } from "./getComments";

type CommentPayload = {
  comment: string;
  doc_hash: string;
  reference_text: string;
  section_id: string;
};

export const createComment = (payload: CommentPayload): Promise<Comment> => {
  return axios.post(`/saveCommentForDoc`, payload);
};

type UseSaveCommentOptions = {
  config?: MutationConfig<typeof createComment>;
};

export const useCreateComment = ({ config }: UseSaveCommentOptions) => {
  return useMutation({
    ...config,
    mutationFn: createComment,
  });
};
