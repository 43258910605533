import { axios } from "../../../lib/axios";

export type LoginCredentialsDTO = {
  username: string;
  password: string;
  googleLogin: string;
};

export type LoginResponse = {
  accessToken: string;
  refreshToken: string;
};

export const loginWithEmailAndPassword = async (
  data: LoginCredentialsDTO
): Promise<LoginResponse> => {
  return await axios.post("/api/login", data);
};
