import { axios } from "@/lib/axios";

type GetGptInstructionsPayload = {
  title: string;
  topics: string;
  focus_heading: string;
  outline: Array<{ heading: string; heading_type: string }>;
  lang: string;
  urls: string[];
  docHash: string;
};

export const getGptInstructions = ({
  title,
  topics,
  focus_heading,
  outline,
  lang,
  urls,
  docHash,
  cancelToken,
}: GetGptInstructionsPayload & { cancelToken?: any }): Promise<any> => {
  const promise = axios.post(
    `/gpt_instructions`,
    {
      title,
      topics,
      focus_heading,
      outline,
      lang,
      urls,
      docHash,
    },
    {
      cancelToken,
    }
  );

  promise.cancel = () => {
    cancelToken?.cancel("Query was cancelled by React Query");
  };

  return promise;
};
