import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

export const getInvoicePdf = async (id: string) => {
  const data = await axios.post("/stripe/getInvoicePDF", { id });
  return data;
};

export const useGetInvoicePdf = ({ invoiceId }: { invoiceId: string }) => {
  return useQuery(
    ["getInvoicePdf", invoiceId],
    () => getInvoicePdf(invoiceId),
    {
      enabled: !!invoiceId, // The query will not execute until the invoiceId is provided
    }
  );
};
