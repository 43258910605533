import { Button, DropdownDialogItem } from "@/components/Elements";
import { useDeleteTemplate } from "@/features/templates/api/deleteTemplate";
import { TrashIcon } from "@heroicons/react/24/outline";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { TbLayoutGrid } from "react-icons/tb";
import { useDeleteDocument } from "../api/deleteDocument";

type DeleteEntityProps = {
  entityTitles: string[];
  entityIds: string[];
  entityType: "document" | "template";
  triggerButton?: React.ReactNode;
  onClose?: () => void;
  onSelect?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
};

const DeleteEntityDialog = ({
  entityTitles,
  entityIds,
  entityType,
  onClose,
  onSelect,
  onOpenChange,
  ...itemProps
}: DeleteEntityProps) => {
  const deleteDocumentMutation = useDeleteDocument({});
  const deleteTemplateMutation = useDeleteTemplate({});

  const entityPlural =
    entityTitles && entityTitles.length > 0 ? `${entityType}s` : entityType;
  const uppercaseEntityPlural =
    entityPlural && entityPlural.length > 0
      ? entityPlural[0].toUpperCase() + entityPlural.slice(1)
      : entityType[0].toUpperCase() + entityType.slice(1);
  const body = (
    <>
      Are you sure you want to delete the following {entityPlural}?
      <ul className="list-disc list-inside space-y-2 my-2">
        {entityTitles.map((title) => (
          <li
            key={title}
            className="flex items-center list-none font-semibold text-zinc-900 dark:text-white dark:bg-zinc-700"
          >
            {entityType === "document" ? (
              <DocumentIcon className="h-3 w-3 mr-2" />
            ) : (
              <TbLayoutGrid className="h-3 w-3 mr-2 fill-zinc-500" />
            )}
            {title}
          </li>
        ))}
      </ul>
      Your {entityPlural} will be archived for 30 days prior to permanent
      deletion.
    </>
  );
  const confirmButton = (
    <Button
      isLoading={
        entityType === "document"
          ? deleteDocumentMutation.isLoading
          : deleteTemplateMutation.isLoading
      }
      variant="destructiveBlur"
      className="bg-red-600"
      onClick={async () => {
        const mutation =
          entityType === "document"
            ? deleteDocumentMutation
            : deleteTemplateMutation;

        await Promise.all(
          entityIds.map((id) =>
            mutation.mutateAsync(
              entityType === "document"
                ? { documentId: id }
                : { templateId: id }
            )
          )
        )
          .then(() => {
            onClose && onClose();
          })
          .catch(() => {});
      }}
    >
      Delete
    </Button>
  );

  return (
    <DropdownDialogItem
      icon={<TrashIcon />}
      label={`Delete ${entityType}`}
      title={`Delete ${uppercaseEntityPlural}`}
      body={body}
      close={onClose}
      confirmButton={confirmButton}
      onSelect={onSelect}
      onOpenChange={onOpenChange}
      className="text-red-600 dark:text-red-400"
      {...itemProps}
    />
  );
};

export const DeleteEntity = DeleteEntityDialog;
