import { TbSearch } from "react-icons/tb";

export const getSearchQueryFilters = () => {
  return [
    {
      value: "query",
      label: "Search Query",
      icon: <TbSearch strokeWidth={2.5} />,
      parent: "query",
      type: "input",
    },
  ];
};
