import frase_bot from "@/assets/frase_bot_seo_analytics.png";

export const FraseResearchBot = () => {
  return (
    <img
      src={frase_bot}
      alt="Frase Research Bot"
      className="rounded-xl w-12 h-12 border-2 start mb-4 dark:border-zinc-700"
    />
  );
};
