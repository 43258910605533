import { Article, Asset, Cluster } from "@/features/documents/api/getUrlBatch";
import { Topic } from "@/features/documents/utils/serp";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type Query = {
  dateCreated: string;
  description: string;
  index: number;
  title: string;
  url: string;
};

type QueryKey = string;

type Queries = {
  [key in QueryKey]: Query[];
};

type DocumentKey = number;

export type Link = {
  anchor: string;
  url: string;
  classed_text: string;
  raw_text: string;
};

export type DomainMap = {
  [key: string]: {
    items: {
      [key: string]: boolean;
    };
    internal_links: Link[];
    external_links: Link[];
  };
};

export type Serp = {
  [key in DocumentKey]: {
    peopleAlsoAsk: string[];
    queries: Queries;
    results: Query[];
    urls: string[];
    allArticles: Article[];
    articles: Article[];
    clusters: Cluster[];
    headings: Asset[];
    topics: Topic[];
    questions: {
      serp: Asset[];
      people_also_ask: Asset[];
      quora: Asset[];
      reddit: Asset[];
    };
    domainMap: DomainMap;
    serpLoaded: boolean;
    averageWordCount: number;
    averageHeaderCount: number;
    averageImageCount: number;
    averageLinkCount: number;
  };
};

type SerpStore = {
  serp: Serp;
  setSerp: (docId: DocumentKey, serp: Serp[DocumentKey]) => void;
  resetSerp: () => void;
};

export const useSerpStore = create(
  devtools(
    immer<SerpStore>((set) => ({
      serp: {},
      setSerp: (docId: DocumentKey, serp: Serp[DocumentKey]) =>
        set((state) => {
          state.serp[docId] = serp;
        }),
      resetSerp: () =>
        set(() => ({
          serp: {},
        })),
    })),
    { name: "serp", store: "serp" }
  )
);
