import { getTierByPlanName, isLegacyPlan } from "./tiers";

export const getSearchQueryCredits = (plan: string): string | number => {
  // For empty or invalid plans, return 0
  if (!plan) return 0;

  // Get the tier information
  const tier = getTierByPlanName(plan);
  if (!tier) return 0;

  // Return the search query limit
  return tier.searchQueries;
};
