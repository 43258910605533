export const isQuestion = (str, strict) => {
  if (!str || typeof str !== "string" || str.length === 0 || str.length > 200) {
    return false;
  }

  str = removeAccents(str.trim().toLowerCase());

  if (str.split(" ").length < 4) {
    return false;
  }

  if (strict === true) {
    return _startsWithQuestionWord(str) && _endsWithQuestionMark(str);
  }

  return true;
};

/**
 * Count the occurrences of a substring within a string.
 * @param {string} string - The input string.
 * @param {string} subString - The substring to count.
 * @returns {number} - The number of occurrences of the substring within the string.
 */
export const occurrences = (string: string, subString: string): number => {
  string = removeAccents(string);
  subString = removeAccents(subString);
  let matches = [];

  try {
    var regex = new RegExp("\\b" + subString + "\\b", "gi");
  } catch (err) {
    return matches.length;
  }

  matches = string.match(regex) || [];
  if (matches) {
    return matches.length;
  } else {
    return 0;
  }
};

/**
 * Remove accents from a string.
 * @param {string} str - The input string with accents.
 * @returns {string} - The modified string without accents.
 */
function removeAccents(str: string): string {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const _startsWithQuestionWord = (str) => {
  const questionWords = [
    "what",
    "which",
    "where",
    "how",
    "why",
    "when",
    "who",
    "can",
    "should",
    "would",
    "is",
    "will",
    "are",
    "do",
    "does",
    "que",
    "como",
    "por que",
    "quien",
    "donde",
    "cuando",
    "quoi",
    "comment",
    "pourquoi",
    "qui",
    "ou",
    "quand",
    "cosa",
    "come",
    "perché",
    "chi",
    "dove",
    "quando",
    "was",
    "wie",
    "warum",
    "wer",
    "wo",
    "wann",
    "o que",
    "como",
    "por que",
    "quem",
    "onde",
    "quando",
    "wat",
    "hoe",
    "waarom",
    "wie",
    "waar",
    "wanneer",
  ];

  const firstWord = str.split(" ")[0];
  return questionWords.includes(firstWord);
};

const _endsWithQuestionMark = (str) => {
  return str[str.length - 1] === "?";
};
