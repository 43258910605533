import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  Switch,
  Tag,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray } from "react-hook-form";
import * as z from "zod";

import { useUser } from "@/features/auth";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useGetChannelsForUser } from "@/features/documents/api/channels";
import { useCreateDocument } from "@/features/documents/api/createDocument";
import { useDocumentCount } from "@/features/documents/api/getDocumentCount";
import { getPlanNickname } from "@/features/subscription/utils/getPlanNickname";
import { useNotificationStore } from "@/stores/notifications";
import { useDialogStore } from "@/stores/upgrade";
import { cn } from "@/utils/style";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { ArrowLeft, Check, Plus, Target } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TbChevronDown,
  TbCircle,
  TbCircleCheck,
  TbInfoCircleFilled,
  TbPlus,
  TbSparkles,
  TbTrash,
} from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import { useTrackEvent } from "../../../features/analytics/api/trackUser";
import { FraseDocument } from "../../../features/documents";
import SearchQueryCredits from "../../../features/subscription/components/SearchQueryCredits";
import { countryOptions, languageOptions } from "./newDocumentOptions";

const newDocumentFormSchema = z.object({
  queries: z
    .array(
      z.object({
        value: z.string({
          required_error: "Please enter a search query",
        }),
      })
    )
    .refine(
      (queries) => {
        const totalWords = queries.reduce(
          (acc, query) => acc + query.value.split(" ").length,
          0
        );
        return totalWords <= 32;
      },
      {
        message:
          "The combined number of words in all queries must be 32 or less",
      }
    ),
  importUrl: z.string().url().optional().or(z.literal("")),
  country: z.string(),
  language: z.string(),
  folder: z.string(),
  searchType: z.string().optional(),
});

type NewDocumentStore = {
  open: boolean;
  setOpen: (value: boolean) => void;
  workflow: string;
  setWorkflow: (value: string) => void;
  shouldPrepopulate: boolean;
  setShouldPrepopulate: (value: boolean) => void;
};

export const useNewDocumentStore = create<NewDocumentStore>((set) => ({
  open: false,
  setOpen: (value: boolean) => set({ open: value }),
  workflow: "new",
  setWorkflow: (value: string) => set({ workflow: value }),
  shouldPrepopulate: false,
  setShouldPrepopulate: (value: boolean) => set({ shouldPrepopulate: value }),
}));

type NewDocumentFormValues = z.infer<typeof newDocumentFormSchema>;

export const NewDocumentButtonAction = React.forwardRef(
  (
    {
      className,
      buttonVariant = "primaryBlur",
      text = "New document",
      size = "2xs",
      startIcon,
    },
    ref
  ) => {
    const { data: subscriptionData, isLoading: isLoadingSubscription } =
      useSubscription({});
    const isSubscriptionInactive = subscriptionData?.status === "inactive";
    const documentsCountQuery = useDocumentCount({
      config: {
        enabled: isSubscriptionInactive,
      },
    });

    const { setOpen, setWorkflow } = useNewDocumentStore();
    const isLoading = isLoadingSubscription;

    return (
      <Button
        ref={ref}
        variant={buttonVariant}
        size={size}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => {
          setOpen(true);
          setWorkflow("new");
        }}
        className={cn(
          "w-full flex-nowrap whitespace-nowrap px-[9.5px]",
          className
        )}
        textClassName="w-full text-left ml-[3px]"
        startIcon={
          startIcon || (
            <TbPlus
              strokeWidth={2.5}
              style={{
                height: "1rem",
                width: "1rem",
              }}
            />
          )
        }
        tooltipContent="Create new document"
      >
        {text}
      </Button>
    );
  }
);

type DefaultFormValues = Partial<NewDocumentFormValues>;

export const NewDocumentButton = ({
  className,
  buttonVariant,
  disableKeyEvent = false,
  trigger,
  defaultValues: propDefaultValues,
  onDocumentCreated,
  skipWorkflowSelection = false,
}: {
  className?: string;
  buttonVariant?: string;
  disableKeyEvent?: boolean;
  trigger?: React.ReactNode;
  defaultValues?: DefaultFormValues;
  onDocumentCreated?: (document: any) => Promise<void>;
  skipWorkflowSelection?: boolean;
}) => {
  const { data: userDetails } = useUser();
  const geography = useMemo(() => {
    try {
      return userDetails?.geography ? JSON.parse(userDetails.geography) : {};
    } catch {
      return {};
    }
  }, [userDetails?.geography]);

  const {
    open: storeOpen,
    setOpen: setStoreOpen,
    workflow,
    setWorkflow,
    shouldPrepopulate,
    setShouldPrepopulate,
  } = useNewDocumentStore();
  const [isOpen, setIsOpen] = useState(false);
  const [currentDefaultValues, setCurrentDefaultValues] = useState<
    DefaultFormValues | undefined
  >(propDefaultValues);
  const [docType, setDocType] = useState<string>("new");
  const [tempDocType, setTempDocType] = useState<string>("seo");
  const [showImportUrlField, setShowImportUrlField] = useState(false);
  const { addNotification } = useNotificationStore();
  const trackEvent = useTrackEvent();
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const isAddOnActive = subscriptionData?.add_on;
  const isSubscriptionInactive = subscriptionData?.status === "inactive";
  const planNickname = getPlanNickname(
    subscriptionData?.plan ||
      subscriptionData?.subscriptionItems?.data[0].plan.name ||
      "freeTrial"
  );

  const handleOpenChange = (newOpenState: boolean) => {
    setIsOpen(newOpenState);
    setStoreOpen(newOpenState);
  };

  const handleDocTypeChange = (type: string) => {
    setTempDocType(type);
  };

  const handleTriggerClick = () => {
    handleOpenChange(true);
    if (skipWorkflowSelection) {
      setWorkflow("seo");
      setDocType("seo");
      setTempDocType("seo");
    } else {
      setWorkflow("new");
    }
  };

  useEffect(() => {
    setCurrentDefaultValues(propDefaultValues);
  }, [propDefaultValues]);

  const resetForm = () => {
    setWorkflow("new");
    setDocType("new");
    setTempDocType("seo");
    setShowImportUrlField(currentDefaultValues?.importUrl ? true : false);
  };

  const channelsQuery = useGetChannelsForUser({});
  let folderOptions =
    channelsQuery.data?.map((channel) => ({
      label: channel?.name,
      value: channel?.id,
    })) || [];
  const newFolderOption = {
    value: "New Folder",
    label: "New Folder",
    onClick: (_value: any, data: { original: FraseDocument }) => {
      createFolderMutation.mutateAsync({
        name: _value,
      });
    },
    icon: <Plus className="w-3 h-3 mr-2" />,
    type: "input",
  };
  folderOptions.unshift(newFolderOption);
  const createDocumentMutation = useCreateDocument();
  const { data: user } = useUser();
  const navigate = useNavigate();

  const getDefaultValues = useCallback(
    (): Partial<NewDocumentFormValues> => ({
      queries: currentDefaultValues?.queries || [{ value: "" }],
      importUrl: currentDefaultValues?.importUrl || "",
      country: currentDefaultValues?.country || geography?.code || "us",
      language: currentDefaultValues?.language || geography?.lang || "en",
      folder: currentDefaultValues?.folder || "",
      searchType: currentDefaultValues?.searchType || "",
    }),
    [currentDefaultValues, geography]
  );

  const form = useForm<NewDocumentFormValues>({
    resolver: zodResolver(newDocumentFormSchema),
    defaultValues: getDefaultValues(),
    mode: "onChange",
  });

  useEffect(() => {
    if (userDetails?.geography) {
      form.reset(getDefaultValues());
    }
  }, [userDetails?.geography, getDefaultValues]);

  const { fields, append, remove } = useFieldArray({
    name: "queries",
    control: form.control,
  });

  const handleTrackNewDocumentEvent = (query: string) => {
    trackEvent.mutate({
      event: "new_document",
      properties: JSON.stringify({
        type: tempDocType,
        query,
      }),
    });
  };

  // Watch the queries field to dynamically validate the word count
  const queries = form.watch("queries");
  const totalWords = queries.reduce(
    (acc, query) => acc + query.value.split(" ").length,
    0
  );
  const isWordLimitExceeded = totalWords > 32;

  async function onSubmit(data: NewDocumentFormValues) {
    const res = await createDocumentMutation
      .mutateAsync({
        channel_name: channelsQuery.data?.find(
          (channel) => channel?.id.toString() === data.folder.toString()
        )?.name,
        doc_owner: user.id,
        doc_owner_name: user.fullName,
        doc_status: 1,
        metadata: {
          name: data.queries.map((query) => query.value).join(","),
          code: data.country,
          display_code: countryOptions.find(
            (country) => country.value === data.country
          )?.label,
          lang_code: data.language,
          lang_display: languageOptions.find(
            (language) => language.value === data.language
          )?.label,
          url: data.importUrl || null,
        },
        org_id: user.orgId,
        query: data.queries.map((query) => query.value).join(","),
        teamId: parseInt(data.folder),
        template: null,
        text: [
          {
            html: "",
            title: data.queries.map((query) => query.value).join(","),
            name: "Tab 1",
          },
        ],
        title: "",
      })
      .catch(() => {
        handleOpenChange(false);
      });

    if (onDocumentCreated) {
      await onDocumentCreated(res);
    }
    handleTrackNewDocumentEvent(
      data.queries.map((query) => query.value).join(",")
    );
    navigate(`/app/documents/${res?.hash}`, {
      state: { importUrl: data.importUrl },
    });
    form.reset(getDefaultValues());
    resetForm();
    handleOpenChange(false);
  }

  async function handleCreateBlankDocument() {
    const userGeography = JSON.parse(user.geography || "{}");

    const res = await createDocumentMutation.mutateAsync({
      doc_owner: user.id,
      doc_owner_name: user.fullName,
      doc_status: 1,
      metadata: {
        name: "Untitled",
        code: userGeography.code || "us",
        display_code: userGeography.display_code || "United States",
        lang_code: userGeography.lang || "en",
        lang_display:
          languageOptions.find(
            (language) => language.value === userGeography.lang
          )?.label || "English",
        url: null,
      },
      org_id: user.orgId,
      query: "",
      template: null,
      text: [
        {
          html: "",
          title: "Untitled",
          name: "Tab 1",
        },
      ],
      title: "Untitled",
    });
    handleTrackNewDocumentEvent("");
    navigate(`/app/documents/${res.hash}`);
    form.reset(getDefaultValues());
    resetForm();
    handleOpenChange(false);
  }

  const renderDocumentTypeSelection = () => {
    return (
      <>
        <DialogHeader className="pb-0 flex items-center justify-between flex-row">
          <DialogTitle>Create a new document</DialogTitle>
          <DialogCloseButton close={() => handleOpenChange(false)} />
        </DialogHeader>
        <DialogDescription className="px-4 overflow-hidden">
          <RadioGroup className="flex-col w-full">
            <div
              onClick={() => handleDocTypeChange("seo")}
              className={cn(
                "transition-all w-full flex items-center justify-between space-x-3 space-y-0 p-4 rounded-md text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none dark:bg-zinc-900",
                tempDocType === "seo"
                  ? " ring-emerald-600 ring-2 dark:ring-emerald-600"
                  : "bg-white"
              )}
            >
              <div className="flex flex-row items-center space-x-2">
                <div className="w-12 h-12 min-w-12 min-h-12 bg-zinc-100 dark:bg-zinc-800 rounded-md flex items-center justify-center border border-zinc-200 dark:border-zinc-700 shrink-0">
                  <Target className="w-6 h-6" />
                </div>
                <div className="flex flex-col flex-wrap">
                  <span className="font-bold ml-2">SEO Workflow</span>
                  <span className="text-sm text-zinc-500 ml-2">
                    Analyze search results for your target query and optimize
                    your content for SEO.
                  </span>
                </div>
              </div>
              {tempDocType === "seo" ? (
                <TbCircleCheck className="flex-shrink-0 w-5 h-5 stroke-emerald-600" />
              ) : (
                <TbCircle className="flex-shrink-0 w-5 h-5" />
              )}
            </div>
            <div
              onClick={() => handleDocTypeChange("blank")}
              className={cn(
                "transition-all w-full flex items-center justify-between space-x-3 space-y-0 p-4 rounded-md text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none dark:bg-zinc-900",
                tempDocType === "blank"
                  ? "ring-emerald-600 ring-2 dark:ring-emerald-600"
                  : "bg-white"
              )}
            >
              <div className="flex flex-row items-center space-x-2">
                <div className="w-12 h-12 min-w-12 min-h-12 bg-zinc-100 dark:bg-zinc-800 rounded-md flex items-center justify-center border border-zinc-200 dark:border-zinc-700 shrink-0">
                  <DocumentIcon className="w-6 h-6 shrink-0" />
                </div>
                <div className="flex flex-col flex-wrap">
                  <span className="font-bold ml-2 text-sm">Blank Document</span>
                  <span className="text-sm text-zinc-500 ml-2">
                    Begin with a blank slate, allowing you to write on your own
                    or using AI tools. You can optimize for SEO later.
                  </span>
                </div>
              </div>
              {tempDocType === "blank" ? (
                <TbCircleCheck className="flex-shrink-0 w-5 h-5 stroke-emerald-600" />
              ) : (
                <TbCircle className="flex-shrink-0 w-5 h-5" />
              )}
            </div>
            <div
              onClick={() => handleDocTypeChange("wizard")}
              className={cn(
                "transition-all w-full flex items-center justify-between space-x-3 space-y-0 p-4 rounded-md text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none dark:bg-zinc-900",
                tempDocType === "wizard"
                  ? "ring-emerald-600 ring-2 dark:ring-emerald-600"
                  : "bg-white"
              )}
            >
              <div className="flex flex-row items-center space-x-2">
                <div className="w-12 h-12 min-w-12 min-h-12 bg-zinc-100 dark:bg-zinc-800 rounded-md flex items-center justify-center border border-zinc-200 dark:border-zinc-700 shrink-0">
                  <TbSparkles className="w-6 h-6 shrink-0" />
                </div>
                <div className="flex flex-col flex-wrap">
                  <span className="flex items-center font-bold ml-2 text-sm">
                    AI Article Wizard
                    {!isSubscriptionInactive && (
                      <Tag
                        variant="small"
                        className="ml-2 h-[20px]"
                        color="emerald"
                        textClassName="font-medium text-2xs"
                      >
                        New
                      </Tag>
                    )}
                  </span>

                  <span className="text-sm text-zinc-500 ml-2">
                    Step-by-step process to generate full articles. Use SERP
                    context, or provide your own information.
                  </span>
                </div>
              </div>
              {tempDocType === "wizard" ? (
                <TbCircleCheck className="flex-shrink-0 w-5 h-5 stroke-emerald-600" />
              ) : (
                <TbCircle className="flex-shrink-0 w-5 h-5" />
              )}
            </div>
          </RadioGroup>
        </DialogDescription>
        <DialogFooter className="flex justify-between p-4 pt-0">
          <Button
            variant="outlineBlur"
            onClick={() => {
              setDocType(null);
              setShowImportUrlField(false);
              handleOpenChange(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primaryBlur"
            onClick={() => {
              if (tempDocType === "wizard" && !isAddOnActive) {
                useDialogStore
                  .getState()
                  .openDialog("aiArticleUpgrade", "test");
                handleOpenChange(false);
              } else if (tempDocType === "wizard") {
                navigate("/app/wizard");
                handleOpenChange(false);
              } else if (tempDocType === "blank") {
                handleCreateBlankDocument();
                handleOpenChange(false);
              }
              setShouldPrepopulate(false);
              setWorkflow(tempDocType);
              setShowImportUrlField(
                currentDefaultValues?.importUrl ? true : false
              );
            }}
            disabled={!tempDocType}
          >
            Continue
          </Button>
        </DialogFooter>
      </>
    );
  };

  const renderSeoDocumentForm = () => {
    return (
      <div className="p-4">
        <DialogTitle className="flex items-center justify-between pb-8">
          <div className="flex items-center">
            {!skipWorkflowSelection && (
              <Button
                variant="buttonIcon"
                buttonIcon={<ArrowLeft />}
                onClick={() => {
                  setDocType("new");
                  setTempDocType("new");
                  setWorkflow("new");
                }}
                className="mr-2"
              ></Button>
            )}
            New document
          </div>
          <DialogCloseButton close={() => handleOpenChange(false)} />
        </DialogTitle>
        <TooltipProvider>
          <DialogDescription>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <div className={cn("flex flex-col space-y-2 mb-4")}>
                  <TargetSearchQueryField
                    form={form}
                    fields={fields}
                    append={append}
                    remove={remove}
                    planNickname={planNickname}
                    isWordLimitExceeded={isWordLimitExceeded}
                  />
                </div>
                <div className="flex items-center w-full space-x-4 mb-4">
                  <CountryField form={form} />
                  <LanguageField form={form} />
                </div>
                {showImportUrlField && (
                  <div className="flex items-center w-full mb-4">
                    <ImportUrlField form={form} />
                  </div>
                )}
                <div className="flex items-center text-end justify-between">
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={showImportUrlField}
                      onCheckedChange={(checked) =>
                        setShowImportUrlField(checked)
                      }
                      size="sm"
                    />
                    <span className="text-xs font-medium text-zinc-400 dark:text-zinc-600 mr-2">
                      Import content from URL
                    </span>
                  </div>
                  <Button
                    type="submit"
                    disabled={
                      !form
                        .getValues()
                        .queries?.every(({ value }) => value.trim() !== "") ||
                      isWordLimitExceeded
                    }
                    isLoading={createDocumentMutation.isLoading}
                  >
                    Create document
                  </Button>
                </div>
              </form>
            </Form>
          </DialogDescription>
        </TooltipProvider>
      </div>
    );
  };

  const handleClose = () => {
    handleOpenChange(false);
    resetForm();
  };

  useEffect(() => {
    form.reset(getDefaultValues());
    resetForm();
  }, [isOpen, getDefaultValues, form]);

  return (
    <Dialog onOpenChange={handleOpenChange} open={isOpen}>
      <DialogTrigger asChild>
        {trigger ? (
          <span className="w-full" onClick={handleTriggerClick}>
            {trigger}
          </span>
        ) : (
          <NewDocumentButtonAction
            className={className}
            buttonVariant={buttonVariant}
          />
        )}
      </DialogTrigger>
      <DialogContent
        className={cn(
          workflow === "wizard" &&
            "py-4 w-screen h-[calc(100vh-1.5rem)] max-w-[1280px] max-h-[720px] rounded-md m-4 overflow-hidden dark:shadow-lg dark:bg-zinc-800"
        )}
        variant={workflow === "wizard" ? "fullScreen" : "fitContent"}
        preventCloseOnEscape={workflow === "wizard"}
        onPointerDownOutside={(e) => {
          if (workflow === "wizard") {
            e.preventDefault();
          }
        }}
      >
        {workflow === "new" &&
          !skipWorkflowSelection &&
          renderDocumentTypeSelection()}
        {(workflow === "seo" || skipWorkflowSelection) &&
          renderSeoDocumentForm()}
      </DialogContent>
    </Dialog>
  );
};

function TooltipIcon() {
  return (
    <TooltipTrigger autoFocus={false}>
      <button onClick={(e) => e.preventDefault()}>
        <TbInfoCircleFilled className="h-3.5 w-3.5 text-zinc-300 dark:text-zinc-700 ml-2 hover:text-zinc-600 hover:dark:text-zinc-400" />
      </button>
    </TooltipTrigger>
  );
}

export const TargetSearchQueryField = ({
  form,
  fields,
  append,
  remove,
  planNickname,
  isWordLimitExceeded,
}) => {
  const maxQueries = planNickname === "Solo" ? 1 : 5;

  return (
    <div>
      {fields.map((field, index) => (
        <FormField
          control={form.control}
          key={field.id}
          name={`queries.${index}.value`}
          render={({ field }) => (
            <FormItem>
              <FormLabel
                className={cn(
                  index !== 0 ? "sr-only" : "",
                  "flex items-center text-zinc-600 dark:text-zinc-200 w-full justify-between"
                )}
              >
                <div className="flex items-center">
                  Search Query
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipIcon></TooltipIcon>
                      <TooltipContent side="right">
                        <p>
                          The search query you want to optimize for. The more
                          specific, the better.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <SearchQueryCredits />
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder={`Enter a search query target (long-tail keywords work best)`}
                  className="mb-2"
                  autoFocus
                  firstIcon={
                    index !== 0 && fields.length < maxQueries ? (
                      <TbPlus />
                    ) : null
                  }
                  firstButtonAction={() => {
                    if (index !== 0 && fields.length < maxQueries) {
                      append({ value: "" });
                    }
                  }}
                  firstButtonTooltip={
                    index !== 0 && fields.length < maxQueries
                      ? `Add another search query.`
                      : null
                  }
                  showFirstButtonOnHover={false}
                  showSecondButtonOnHover={false}
                  secondIcon={
                    index === 0 ? (
                      fields.length < maxQueries ? (
                        <TbPlus />
                      ) : null
                    ) : (
                      <TbTrash />
                    )
                  }
                  secondButtonAction={() => {
                    if (index === 0) {
                      if (fields.length < maxQueries) {
                        append({ value: "" });
                      }
                    } else {
                      remove(index);
                    }
                  }}
                  secondButtonTooltip={
                    index === 0
                      ? fields.length < maxQueries
                        ? `Add another search query.`
                        : null
                      : "Remove this search query"
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />
      ))}
      <FormMessage>
        {isWordLimitExceeded &&
          "The combined number of words in all queries must be 32 or less"}
      </FormMessage>
    </div>
  );
};

export const ImportUrlField = ({ form }) => {
  return (
    <FormField
      control={form.control}
      name="importUrl"
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel className="flex items-center text-zinc-600 dark:text-zinc-200">
            Import URL
            <Tooltip>
              <TooltipIcon></TooltipIcon>
              <TooltipContent side="right">
                <p>
                  Import content from a URL to analyze and optimize existing
                  content.
                </p>
              </TooltipContent>
            </Tooltip>
          </FormLabel>
          <FormControl>
            <Input
              {...field}
              placeholder="Enter URL (optional)"
              className="mb-2"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const CountryField = ({ form }) => (
  <FormField
    control={form.control}
    name="country"
    render={({ field }) => (
      <FormItem className="flex flex-col w-full">
        <FormLabel className="flex items-center text-zinc-600 dark:text-zinc-200">
          Country
        </FormLabel>
        <Popover modal>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant="outlineBlur"
                size="sm"
                className="w-full dark:bg-zinc-900 justify-between"
                textClassName="text-xs font-normal truncate justify-between w-full text-start"
                role="combobox"
                endIcon={<TbChevronDown />}
              >
                {field.value
                  ? countryOptions.find(
                      (country) => country.value === field.value
                    )?.label
                  : "Select country"}
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContent className="z-[55] w-[250px]">
            <Command className="max-h-80">
              <CommandInput placeholder="Search country..." />
              <ScrollArea className="overflow-y-auto">
                <CommandEmpty>No country found.</CommandEmpty>
                <CommandGroup>
                  {countryOptions.map((country) => (
                    <CommandItem
                      value={country.label}
                      key={country.value}
                      onSelect={() => {
                        form.setValue("country", country.value, {
                          shouldDirty: true,
                        });
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          country.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {country.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </ScrollArea>
            </Command>
          </PopoverContent>
        </Popover>
        <FormMessage />
      </FormItem>
    )}
  />
);

export const LanguageField = ({ form }) => (
  <FormField
    control={form.control}
    name="language"
    render={({ field }) => (
      <FormItem className="flex flex-col w-full">
        <FormLabel className="flex items-center text-zinc-600 dark:text-zinc-200">
          Language
        </FormLabel>
        <Popover>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant="outlineBlur"
                size="sm"
                className="w-full dark:bg-zinc-900 justify-between"
                textClassName="text-xs font-normal truncate justify-between w-full text-start"
                role="combobox"
                endIcon={<TbChevronDown />}
              >
                {field.value
                  ? languageOptions.find(
                      (language) => language.value === field.value
                    )?.label
                  : "Select language"}
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContent className="z-[55] w-[250px]">
            <Command className="max-h-80">
              <CommandInput placeholder="Search language..." />
              <ScrollArea className="overflow-y-auto">
                <CommandEmpty>No language found.</CommandEmpty>
                <CommandGroup>
                  {languageOptions.map((language) => (
                    <CommandItem
                      value={language.label}
                      key={language.value}
                      onSelect={() => {
                        form.setValue("language", language.value, {
                          shouldDirty: true,
                        });
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          language.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {language.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </ScrollArea>
            </Command>
          </PopoverContent>
        </Popover>
        <FormMessage />
      </FormItem>
    )}
  />
);
