import { create } from "zustand";

type EditorConflictDialogType = "editorConflictOnSave" | "editorConflictOnLoad";

type EditorConflictDialogStore = {
  dialogType: EditorConflictDialogType;
  lastEditorFullName: string;
  lastEditedTime: number;
  openDialog: (
    type: EditorConflictDialogType,
    fullName: string,
    time: number
  ) => void;

  closeDialog: () => void;
};

export const useEditorConflictDialogStore = create<EditorConflictDialogStore>(
  (set) => ({
    dialogType: null,
    openDialog: (type, fullName, time) =>
      set({
        dialogType: type,
        lastEditorFullName: fullName,
        lastEditedTime: time,
      }),
    closeDialog: () => set({ dialogType: null }),
    setLastEditorFullName: (fullName) => set({ lastEditorFullName: fullName }),
    setLastEditedTime: (time) => set({ lastEditedTime: time }),
  })
);
