import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

export const getUsersForOrg = () => {
  return axios.post("/getUsersForOrg", {});
};

export const useGetUsersForOrg = (config) => {
  return useQuery("usersForOrg", getUsersForOrg, config);
};
