import frase_bot from "@/assets/frase_bot.png";

export const FraseBot = () => {
  return (
    <img
      src={frase_bot}
      alt="Frase Genius"
      className="rounded-xl w-12 h-12 border-2 start mb-4"
    />
  );
};
