import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";

type ResetPasswordWithKeyPayload = {
  resetPasswordToken: string;
  password: string;
};

export const resetPasswordWithKey = ({
  resetPasswordToken,
  password,
}: ResetPasswordWithKeyPayload): Promise<any> => {
  const params = new URLSearchParams();
  params.append("password", password);

  return axios.post(`/resetpassword?_key=${resetPasswordToken}`, params);
};

export const useResetPasswordWithKey = () => {
  return useMutation(resetPasswordWithKey);
};
