import { useMutation } from "react-query";

import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";

import { useIdentifyUser } from "@/features/analytics/api/identifyUser";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { FraseDocument } from "../types";
import { getDocumentCount } from "./getDocumentCount";

export const createDocument = (data: FraseDocument): Promise<FraseDocument> => {
  return axios.post(`/createDocument`, data);
};

type UseCreateDocumentOptions = {
  config?: MutationConfig<typeof createDocument>;
  notifyOnSuccess?: boolean;
};

export const useCreateDocument = ({
  config,
  notifyOnSuccess = true,
}: UseCreateDocumentOptions = {}) => {
  const { addNotification } = useNotificationStore();
  const { mutate: identifyUser } = useIdentifyUser();
  const mutationKey = ["createDocument"];

  return useMutation(mutationKey, {
    onSuccess: async (newDocument: FraseDocument) => {
      queryClient.invalidateQueries("documents", { exact: false });
      queryClient.invalidateQueries("documentCount", { exact: false });

      if (!notifyOnSuccess) return;

      addNotification({
        type: "success",
        title: "Document created",
        message: (
          <span className="flex items-center">
            <DocumentIcon className="w-3.5 h-3.5 fill-zinc-500" />
            <span className="ml-2 text-xs">
              {newDocument.metadata?.name || newDocument.query}
            </span>
          </span>
        ),
      });

      const documentCount = await getDocumentCount();
      identifyUser(
        JSON.stringify({
          document_count: documentCount.total_doc_count,
          document_count_current_month: documentCount.cur_month_doc_count,
          first_document_date:
            documentCount.total_doc_count === 1
              ? new Date().setUTCHours(0, 0, 0, 0)
              : undefined,
          last_document_date: new Date().setUTCHours(0, 0, 0, 0),
        })
      );
    },
    ...config,
    mutationFn: createDocument,
  });
};
