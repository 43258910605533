import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

type GetGptOutlinePayload = {
  title: string;
  topics: string;
  lang: string;
  serp: Array<{
    title: string;
    url: string;
    snippet: string;
    author: string;
    date_created: string;
    image: string;
  }>;
  docHash: string;
};

export const getGptOutline = ({
  title,
  topics,
  lang,
  serp,
  docHash,
}: GetGptOutlinePayload): Promise<any> => {
  return axios.post(`/gpt_outline`, {
    title,
    topics,
    lang,
    serp,
    docHash,
  });
};

type UseGetGptOutlineOptions = {
  title: string;
  topics: string;
  lang: string;
  serp: Array<{
    title: string;
    url: string;
    snippet: string;
    author: string;
    date_created: string;
    image: string;
  }>;
  docHash: string;
  config?: any;
};

export const useGetGptOutline = ({
  title,
  topics,
  lang,
  serp,
  docHash,
  config,
}: UseGetGptOutlineOptions) => {
  return useQuery({
    ...config,
    queryKey: ["gptOutline", { title, topics, lang, serp, docHash }],
    queryFn: () => getGptOutline({ title, topics, lang, serp, docHash }),
  });
};
