import { cn } from "@/utils/style";
import { motion } from "framer-motion";
import { ComponentProps } from "react";
import { Spinner } from "../Spinner";

export function WizardStep({
  step,
  label,
  currentStep,
  isLoading,
  isValid,
}: {
  step: number;
  label?: string;
  currentStep: number;
  isLoading?: boolean;
  isValid?: boolean;
}) {
  let status =
    currentStep === step
      ? "active"
      : currentStep < step
      ? "inactive"
      : "complete";

  if (isLoading) {
    return <Spinner size="sm" />;
  }

  return (
    <motion.div animate={status} className="flex">
      <motion.div
        variants={{
          active: {
            scale: 1,
            transition: {
              delay: 0,
              duration: 0.2,
            },
          },
          complete: {
            scale: 1.25,
          },
          inactive: {
            scale: 0,
          },
        }}
        transition={{
          duration: 0.6,
          delay: 0.2,
          type: "tween",
          ease: "circOut",
        }}
        className="rounded-full bg-zinc-200"
      />

      <motion.div
        initial={false}
        variants={{
          inactive: {
            backgroundColor: "#e4e4e7", // neutral
            borderColor: "transparent", // neutral-200
            color: "#e4e4e7", // neutral-400
            borderWidth: 2,
            height: 16,
            width: 16,
          },
          active: {
            backgroundColor: "#059669",
            borderColor: "#bbf7d0", // blue-500
            color: "#059669", // blue-500
            borderWidth: 4,
            height: 16,
            width: 16,
          },
          complete: {
            backgroundColor: "#059669", // blue-500
            borderColor: "#34d399", // blue-500
            color: "#059669", // blue-500
            borderWidth: 2,
            height: 16,
            width: 16,
          },
        }}
        transition={{ duration: 0.2 }}
        className={cn(
          "flex items-center justify-center rounded-full border-2",
          isValid && "bg-emerald-600"
        )}
      >
        <div className="flex items-center">
          {status === "complete" && (
            <CheckIcon className="h-3.5 w-3.5 ml-[1px] text-white flex-shrink-0" />
          )}
        </div>
      </motion.div>
    </motion.div>
  );
}

function CheckIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <motion.path
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{
          delay: 0.2,
          type: "tween",
          ease: "easeOut",
          duration: 0.3,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 13l4 4L19 7"
      />
    </svg>
  );
}
