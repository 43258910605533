import { Checkbox } from "@/components/Elements";
import { cn } from "@/utils/style";

export const SelectCell = {
  Header: ({ table }) => (
    <div
      className="h-8 w-8 flex items-center justify-center group ml-2"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={cn(
          table.getIsSomeRowsSelected() &&
            "bg-emerald-600 dark:bg-emerald-600 hover:dark:bg-emerald-600"
        )}
        checked={table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(value)}
        aria-label="Select all"
      />
    </div>
  ),
  Cell: ({ row }) => (
    <div
      className="h-8 w-8 flex items-center justify-center group ml-2"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        row.toggleSelected();
      }}
    >
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={cn(
          row.getIsSelected() &&
            "bg-emerald-600 dark:bg-emerald-600 hover:dark:bg-emerald-600"
        )}
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    </div>
  ),
};
