import { axios, CancelToken } from "@/lib/axios";
import { useQuery } from "react-query";

type GptChatStreamPayload = {
  assistant_seed: string | null;
  previous_messages: Array<{ role: string; content: string }>;
  max_tokens: number;
  stop_at_newline: boolean;
};

let cancelTokenSource: CancelToken | null = null;

export function parseStreamedJson(
  response: string,
  buffer: string
): { objects: any[]; buffer: string } {
  buffer += response; // Append the new response to the buffer
  let objects = [];
  // Pattern to detect the end of one JSON object and the start of another
  let jsonRegex = /}\s*{/g;
  let parts = buffer.split(jsonRegex);
  let lastIndex = 0;

  parts.forEach((part, index) => {
    // If it's not the last part, append '}' to complete the JSON object
    if (index < parts.length - 1) {
      part += "}";
    }
    // If it's not the first part, prepend '{' since it was removed by split
    if (index > 0) {
      part = "{" + part;
    }
    if (isCompleteJson(part)) {
      try {
        objects.push(JSON.parse(part));
        lastIndex += part.length;
        if (index < parts.length - 1) {
          // Add back the length of the removed '{' for all parts except the first
          lastIndex += 1;
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // If parsing fails, reset lastIndex to avoid losing data
        lastIndex = 0;
        objects = [];
        buffer = "";
      }
    }
  });

  // Update the buffer to contain only the unparsed portion
  buffer = buffer.substring(lastIndex);

  return { objects, buffer };
}

function isCompleteJson(str: string): boolean {
  try {
    JSON.parse(str);
    return true; // No error means JSON is complete
  } catch (error) {
    return false; // Error means JSON is incomplete
  }
}

export const getGptChatStream = async ({
  assistant_seed,
  previous_messages,
  max_tokens,
  stop_at_newline,
  onDataReceived,
  onDownloadComplete,
}: GptChatStreamPayload & {
  onDataReceived: (data: string, shouldInsertParagraph: boolean) => void;
  onDownloadComplete: () => void;
}) => {
  let lastResponseLength = 0;
  let buffer = ""; // Initialize outside of the onDownloadProgress handler
  let accumulatedText = ""; // Initialize a variable to accumulate text

  if (cancelTokenSource) {
    cancelTokenSource.cancel("Cancelling previous request");
  }
  cancelTokenSource = CancelToken.source();

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: "/gpt_streaming",
      data: {
        assistant_seed,
        previous_messages,
        max_tokens,
        stop_at_newline,
      },
      onDownloadProgress: (progressEvent: any) => {
        const status = progressEvent.target?.status;

        if (status === 400) {
          reject(new Error("400"));
          return;
        }

        if (status === 498) {
          return;
        }

        if (status === 524) {
          reject(new Error("524"));
          return;
        }

        const currentResponse = progressEvent.target?.responseText || "";
        const newResponse = currentResponse.slice(lastResponseLength);
        let result = ""; // Declare result here to ensure it is defined

        if (newResponse) {
          try {
            const { objects, buffer: newBuffer } = parseStreamedJson(
              newResponse,
              buffer
            );
            buffer = newBuffer; // Update the buffer with the remaining incomplete JSON

            for (const parsedObject of objects) {
              const { choices } = parsedObject;
              if (choices && choices.length > 0) {
                const { delta } = choices[0];
                if (delta && delta.content) {
                  accumulatedText += delta.content; // Accumulate the content
                  let lastNewlineIndex = accumulatedText.lastIndexOf("\n");
                  while (lastNewlineIndex !== -1) {
                    // Send the text including the newline
                    onDataReceived(
                      accumulatedText.substring(0, lastNewlineIndex + 1),
                      true
                    );
                    // Keep the remaining text after the last newline
                    accumulatedText = accumulatedText.substring(
                      lastNewlineIndex + 1
                    );
                    // Check if there are more newlines
                    lastNewlineIndex = accumulatedText.lastIndexOf("\n");
                  }
                }
              }
            }
          } catch (error) {
            console.error("Error parsing streamed JSON:", error);
          }
        }

        lastResponseLength = currentResponse.length;
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response: string) => {
        if (cancelTokenSource?.token.reason) {
          return;
        }

        // Check if there's any accumulated text without a newline at the end of the request
        if (accumulatedText.length > 0) {
          onDataReceived(accumulatedText, false);
          accumulatedText = ""; // Clear the accumulated text after it's sent
        }

        onDownloadComplete();
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const cancelGptChatStream = () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Operation cancelled by the user.");
  }
};

type UseGetGptChatStreamOptions = {
  assistant_seed: string | null;
  previous_messages: Array<{ role: string; content: string }>;
  max_tokens: number;
  config?: any;
  stop_at_newline: boolean;
  onDataReceived: (data: string, shouldInsertParagraph: boolean) => void;
  onDownloadComplete: () => void;
};

export const useGetGptChatStream = ({
  assistant_seed,
  previous_messages,
  max_tokens,
  stop_at_newline,
  config,
  onDataReceived,
  onDownloadComplete,
}: UseGetGptChatStreamOptions) => {
  return useQuery({
    ...config,
    queryKey: ["gptChatStream", previous_messages],
    queryFn: () =>
      getGptChatStream({
        assistant_seed,
        previous_messages,
        max_tokens,
        stop_at_newline,
        onDataReceived,
        onDownloadComplete,
      }),
  });
};
