import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
export interface QuoraSearchQuery {
  query: string;
  country?: string;
  count: number;
}

export interface QuoraSearchResult {
  question: string;
  url: string;
}

type QueryKey = string;

export type QuoraSearchResults = {
  [key in QueryKey]: QuoraSearchResult[];
};

export const getQuoraSearchResults = async ({
  queries,
}: {
  queries: QuoraSearchQuery[];
}): Promise<QuoraSearchResults> => {
  const requests = queries.map((query) => {
    let quoraQuery = `${query.query}`;
    const lang = query.country; // Assuming 'country' is used for language here

    const geo = lang ? `${lang}` : undefined;
    const payload = [{ query: quoraQuery, count: "50", type: "web" }];

    return axios
      .post("/getSearchResults", {
        queries: payload,
      })
      .then((response) => response);
  });

  try {
    const responses = await Promise.all(requests);
    return responses.reduce((acc, response, index) => {
      const list = [];
      const resp = response[Object.keys(response)[0]];
      if (resp && resp.length > 0) {
        resp.forEach((value) => {
          let q = value.title;
          if (q.includes("...")) {
            value.url = decodeURI(value.url);
            q = value.url.split("/").pop().split("-").join(" ");
          }
          if (q.includes("- Quora")) {
            q = q.split("- Quora")[0];
          }
          const domain = new URL(value.url).hostname;
          list.push({
            question: q,
            url: value.url,
            domain,
            source: "Quora",
          });
        });
      }

      return list;
    }, {} as QuoraSearchResults);
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching Quora search results");
  }
};

type QueryFnType = typeof getQuoraSearchResults;

type UseQuoraSearchResultsOptions = {
  queries: QuoraSearchQuery[];
  config?: QueryConfig<QueryFnType>;
};

export const useQuoraSearchResults = ({
  queries,
  config,
}: UseQuoraSearchResultsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["quora", JSON.stringify(queries)],
    queryFn: () => getQuoraSearchResults({ queries }),
  });
};
