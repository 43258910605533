import * as React from "react";

import { Link, Logo } from "@/components/Elements";
import { Head } from "@/components/Head";

type LayoutProps = {
  children: React.ReactNode;
  title: string;
  showLogo?: boolean;
};

export const Layout = ({ children, title, showLogo = true }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 dark:bg-hero-gradient">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {showLogo && (
            <div className="flex justify-center">
              <Link className="flex items-center text-white" to="/">
                <Logo renderText={false} size="xl" />
              </Link>
            </div>
          )}

          <h2 className="flex font-medium text-2xl justify-center mt-4 text-zinc-900 dark:text-white">
            {title}
          </h2>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="py-8 px-4 sm:px-10">{children}</div>
        </div>
      </div>
    </>
  );
};
