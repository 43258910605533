import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

type GetGptArticlePayload = {
  id: number;
};

export const getGptArticle = ({ id }: GetGptArticlePayload): Promise<any> => {
  return axios.post(`/fetchGPTArticle`, {
    id,
  });
};

type UseGetGptArticleOptions = {
  id: number;
  config?: any;
};

export const useGetGptArticle = ({ id, config }: UseGetGptArticleOptions) => {
  return useQuery({
    ...config,
    queryKey: ["fetchGptArticle", { id }],
    queryFn: () => getGptArticle({ id }),
  });
};
