import { create } from "zustand";

type AiWizardDialogType = "continueGeneratingAiArticle";

type AiWizardDialogStore = {
  isOpen: boolean;
  dialogType: AiWizardDialogType;
  openDialog: (type: AiWizardDialogType) => void;
  closeDialog: () => void;
};

export const useAiWizardDialogStore = create<AiWizardDialogStore>((set) => ({
  dialogType: null,
  isOpen: false,
  openDialog: (type) =>
    set({
      dialogType: type,
      isOpen: true,
    }),
  closeDialog: () => set({ dialogType: null, isOpen: false }),
}));
