import { Route, Routes } from "react-router-dom";

import { SeoAnalytics } from "./SeoAnalytics";

export const SeoAnalyticsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<SeoAnalytics />} />
    </Routes>
  );
};
