import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { DialogClose, DialogPortal } from "@radix-ui/react-dialog";
import React from "react";
import { useUnarchiveDocument } from "../api/unarchiveDocument";

type UnarchiveDocumentProps = {
  documentTitles: string[];
  documentIds: number[];
  table: any; // Replace with the correct type for your table
};

const ButtonUnarchiveDocumentDialog = ({
  documentTitles,
  documentIds,
  table,
  ...props
}: UnarchiveDocumentProps) => {
  const unarchiveDocumentMutation = useUnarchiveDocument({});
  const [open, setOpen] = React.useState(false);
  const { addNotification } = useNotificationStore();

  const documentPlural = documentTitles.length > 1 ? "documents" : "document";
  const uppercaseDocumentPlural =
    documentPlural[0].toUpperCase() + documentPlural.slice(1);
  const body = (
    <>
      Are you sure you want to unarchive the following {documentPlural}?
      <ul className="list-disc list-inside space-y-2 my-2 max-h-40 overflow-y-scroll">
        {documentTitles.map((title) => (
          <li
            key={title}
            className="flex items-center list-none font-semibold text-zinc-900 dark:text-white dark:bg-zinc-700"
          >
            <DocumentIcon className="h-3 w-3 mr-2" />
            {title}
          </li>
        ))}
      </ul>
    </>
  );
  const confirmButton = (
    <Button
      isLoading={unarchiveDocumentMutation.isLoading}
      variant="primaryBlur"
      onClick={async () => {
        await Promise.all(
          documentIds.map((id) =>
            unarchiveDocumentMutation.mutateAsync({ documentId: id })
          )
        )
          .then(() => {})
          .catch(() => {});
        setOpen(false);
        table.toggleAllRowsSelected(false);
      }}
    >
      Unarchive
    </Button>
  );

  return (
    <Dialog
      onOpenChange={(open) => {
        setOpen(open);
      }}
      open={open}
      modal={false}
    >
      <DialogTrigger asChild>
        <Button
          variant="primaryBlur"
          onClick={() => setOpen(true)}
          className="h-7 ml-2"
          textClassName="font-normal text-xs"
          {...props}
        >
          Unarchive {uppercaseDocumentPlural}
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>{`Unarchive ${uppercaseDocumentPlural}`}</DialogTitle>
            <DialogCloseButton
              close={() => {
                setOpen(false);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4">{body}</DialogDescription>
          <DialogFooter className="px-4 pb-4">
            <DialogClose>
              <Button
                variant="outlineBlur"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </DialogClose>
            {confirmButton}
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export const UnarchiveDocumentButton = ButtonUnarchiveDocumentDialog;
