import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

export const usePausePlan = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(
    (data: { pause_interval: number }) => {
      return axios.post("/stripe/pauseSubscription", data);
    },
    {
      onSuccess: () => {
        addNotification({
          type: "success",
          title: "Subscription paused",
          message:
            "Your subscription has been paused for one month, it will automatically resume after that.",
        });
        queryClient.invalidateQueries("subscription");
      },
    }
  );
};
