import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Combobox,
} from "@/components/Elements";
import { User } from "@/features/users";
import { cn, usernameToColor } from "@/utils/style";
import React from "react";

export const UserHeader = () => {
  return <p className="ml-2 text-xs">User</p>;
};

export const UserCell = ({
  document,
  users,
  showName,
  showAvatar,
}: {
  document: Document;
  users?: User[];
  showName?: boolean;
  showAvatar?: boolean;
}) => {
  const [comboboxOpen, setComboboxOpen] = React.useState(false);

  return (
    <Combobox
      data={document}
      options={users}
      value={document.doc_owner_name}
      showEndIcon={false}
      enableSearch
      searchPlaceholder="users"
      className="h-7 text-zinc-600 dark:text-zinc-400 text-2xs"
      onOpenChange={(isOpen) => setComboboxOpen(isOpen)}
      trigger={
        <div className="flex items-center">
          {showAvatar && (
            <Avatar className="w-5 h-5">
              <AvatarImage src="" />
              <AvatarFallback
                className={cn(
                  "text-zinc-50 text-4xs",
                  usernameToColor(document?.doc_owner_name)
                )}
              >
                {document?.doc_owner_name
                  ?.split(" ")
                  .map((name) => name[0])
                  .slice(0, 2)
                  .join("")}
              </AvatarFallback>
            </Avatar>
          )}
          {showName && (
            <span
              className={cn(
                showAvatar && "ml-2",
                "text-zinc-600 dark:text-zinc-400 text-2xs truncate"
              )}
              style={{
                maxWidth: "160px",
              }}
            >
              {document.doc_owner_name}
            </span>
          )}
        </div>
      }
    />
  );
};
