import dayjs from "dayjs";
import { TbCircleArrowUpFilled, TbInfoCircleFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/Elements";
import { cn } from "../../../utils/style";
import { useSubscription } from "../../auth/api/getSubscription";
import { useGetSearchQueriesForUser } from "../api/getSearchQueriesForUser";
import { getPlanNickname } from "../utils/getPlanNickname";
import { getSearchQueryCredits } from "../utils/getSearchQueryCredits";

const SearchQueryCredits = ({
  containerClassName,
}: {
  containerClassName?: string;
}) => {
  const { data: subscriptionData } = useSubscription({});
  const planNickname = getPlanNickname(subscriptionData?.plan || "Free");
  const { data: searchQueriesData } = useGetSearchQueriesForUser({
    config: {
      enabled: planNickname !== "Free" && planNickname !== "Team",
    },
  });
  const navigate = useNavigate();

  const searchQueryLimit = getSearchQueryCredits(
    subscriptionData?.plan || ""
  ) as string | number;
  const usedSearchQueries = searchQueriesData?.current_month?.length || 0;
  const renewalDate = dayjs().add(1, "month").startOf("month").format("MMM DD");

  const hasReachedLimit =
    typeof searchQueryLimit === "number" &&
    usedSearchQueries >= searchQueryLimit;

  const handleNavigateToPlans = () => {
    navigate("/app/settings/plans");
  };

  if (planNickname === "Team" || planNickname === "Free") {
    return null;
  }

  return (
    <div
      className={cn(
        "flex items-center justify-between text-xs flex-wrap",
        hasReachedLimit ? "text-red-600/50 font-medium" : "text-zinc-400",
        containerClassName
      )}
    >
      <div className="flex items-center justify-between w-full space-x-4">
        {hasReachedLimit ? (
          <Button
            variant="aiBlur"
            onClick={handleNavigateToPlans}
            size="2xs"
            startIcon={<TbCircleArrowUpFilled />}
            textClassName="text-zinc-500 hover:text-zinc-600 dark:text-zinc-400 dark:hover:text-zinc-5"
          >
            Upgrade Plan
          </Button>
        ) : (
          <div></div>
        )}
        <div className="flex items-center">
          <div className="flex items-center space-x-1">
            <span
              className={cn(
                hasReachedLimit
                  ? "text-red-600/50 font-medium"
                  : "text-zinc-400"
              )}
            >
              {usedSearchQueries}
            </span>
            <span>
              /{" "}
              {typeof searchQueryLimit === "number"
                ? searchQueryLimit
                : searchQueryLimit}{" "}
              this month
            </span>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger autoFocus={false}>
                <button onClick={(e) => e.preventDefault()}>
                  <TbInfoCircleFilled className="h-3.5 w-3.5 text-zinc-300 dark:text-zinc-700 ml-2 hover:text-zinc-600 hover:dark:text-zinc-400" />
                </button>
              </TooltipTrigger>
              <TooltipContent side="top">
                <p className="text-xs">
                  SEO Document credits reset on the first day of the next month
                  ({renewalDate}).
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
};

export default SearchQueryCredits;
