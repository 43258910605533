export const workflowOptions = [
  {
    title: "Create new content",
    value: "new",
    id: "new",
    description: "Create a new document from scratch",
  },
  {
    title: "Optimize existing content",
    value: "optimize",
    id: "optimize",
    description: "Use existing content to create a new document",
  },
];

// Combobox options for Country, Language, and Folder
export const countryOptions = [
  {
    label: "Afghanistan",
    value: "af",
    lang: "fa",
  },
  {
    label: "Albania",
    value: "al",
    lang: "sq",
  },
  {
    label: "Algeria",
    value: "dz",
    lang: "ar",
  },
  {
    label: "American Samoa",
    value: "as",
    lang: "en",
  },
  {
    label: "Andorra",
    value: "ad",
    lang: "ca",
  },
  {
    label: "Angola",
    value: "ao",
    lang: "pt",
  },
  {
    label: "Anguilla",
    value: "ai",
    lang: "en",
  },
  {
    label: "Antarctica",
    value: "aq",
    lang: "en",
  },
  {
    label: "Antigua and Barbuda",
    value: "ag",
    lang: "en",
  },
  {
    label: "Argentina",
    value: "ar",
    lang: "es",
  },
  {
    label: "Armenia",
    value: "am",
    lang: "hy",
  },
  {
    label: "Aruba",
    value: "aw",
    lang: "nl",
  },
  {
    label: "Australia",
    value: "au",
    lang: "en",
  },
  {
    label: "Austria",
    value: "at",
    lang: "de",
  },
  {
    label: "Azerbaijan",
    value: "az",
    lang: "az",
  },
  {
    label: "Bahamas",
    value: "bs",
    lang: "en",
  },
  {
    label: "Bahrain",
    value: "bh",
    lang: "ar",
  },
  {
    label: "Bangladesh",
    value: "bd",
    lang: "bn",
  },
  {
    label: "Barbados",
    value: "bb",
    lang: "en",
  },
  {
    label: "Belarus",
    value: "by",
    lang: "ru",
  },
  {
    label: "Belgium",
    value: "be",
    lang: "fr",
  },
  {
    label: "Belize",
    value: "bz",
    lang: "en",
  },
  {
    label: "Benin",
    value: "bj",
    lang: "fr",
  },
  {
    label: "Bermuda",
    value: "bm",
    lang: "en",
  },
  {
    label: "Bhutan",
    value: "bt",
    lang: "dz",
  },
  {
    label: "Bolivia",
    value: "bo",
    lang: "es",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "ba",
    lang: "bs",
  },
  {
    label: "Botswana",
    value: "bw",
    lang: "en",
  },
  {
    label: "Bouvet Island",
    value: "bv",
    lang: "no",
  },
  {
    label: "Brazil",
    value: "br",
    lang: "pt",
  },
  {
    label: "British Indian Ocean Territory",
    value: "io",
    lang: "en",
  },
  {
    label: "Brunei Darussalam",
    value: "bn",
    lang: "ms",
  },
  {
    label: "Bulgaria",
    value: "bg",
    lang: "bg",
  },
  {
    label: "Burkina Faso",
    value: "bf",
    lang: "fr",
  },
  {
    label: "Burundi",
    value: "bi",
    lang: "fr",
  },
  {
    label: "Cambodia",
    value: "kh",
    lang: "km",
  },
  {
    label: "Cameroon",
    value: "cm",
    lang: "fr",
  },
  {
    label: "Canada",
    value: "ca",
    lang: "en",
  },
  {
    label: "Cape Verde",
    value: "cv",
    lang: "pt",
  },
  {
    label: "Cayman Islands",
    value: "ky",
    lang: "en",
  },
  {
    label: "Central African Republic",
    value: "cf",
  },
  {
    label: "Chad",
    value: "td",
    lang: "fr",
  },
  {
    label: "Chile",
    value: "cl",
    lang: "es",
  },
  {
    label: "China",
    value: "cn",
    lang: "zh-CN",
  },
  {
    label: "Christmas Island",
    value: "cx",
    lang: "en",
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "cc",
    lang: "en",
  },
  {
    label: "Colombia",
    value: "co",
    lang: "es",
  },
  {
    label: "Comoros",
    value: "km",
  },
  {
    label: "Congo",
    value: "cg",
  },
  {
    label: "Congo, the Democratic Republic of the",
    value: "cd",
  },
  {
    label: "Cook Islands",
    value: "ck",
    lang: "en",
  },
  {
    label: "Costa Rica",
    value: "cr",
    lang: "es",
  },
  {
    label: "Cote D'ivoire",
    value: "ci",
    lang: "fr",
  },
  {
    label: "Croatia",
    value: "hr",
    lang: "hr",
  },
  {
    label: "Cuba",
    value: "cu",
    lang: "es",
  },
  {
    label: "Cyprus",
    value: "cy",
    lang: "el",
  },
  {
    label: "Czech Republic",
    value: "cz",
    lang: "cs",
  },
  {
    label: "Denmark",
    value: "dk",
    lang: "da",
  },
  {
    label: "Djibouti",
    value: "dj",
    lang: "fr",
  },
  {
    label: "Dominica",
    value: "dm",
    lang: "en",
  },
  {
    label: "Dominican Republic",
    value: "do",
    lang: "es",
  },
  {
    label: "Ecuador",
    value: "ec",
    lang: "es",
  },
  {
    label: "Egypt",
    value: "eg",
    lang: "ar",
  },
  {
    label: "El Salvador",
    value: "sv",
    lang: "es",
  },
  {
    label: "Equatorial Guinea",
    value: "gq",
    lang: "es",
  },
  {
    label: "Eritrea",
    value: "er",
    lang: "ti",
  },
  {
    label: "Estonia",
    value: "ee",
    lang: "et",
  },
  {
    label: "Ethiopia",
    value: "et",
    lang: "am",
  },
  {
    label: "Falkland Islands (Malvinas)",
    value: "fk",
  },
  {
    label: "Faroe Islands",
    value: "fo",
    lang: "da",
  },
  {
    label: "Fiji",
    value: "fj",
    lang: "en",
  },
  {
    label: "Finland",
    value: "fi",
    lang: "fi",
  },
  {
    label: "France",
    value: "fr",
    lang: "fr",
  },
  {
    label: "French Guiana",
    value: "gf",
    lang: "fr",
  },
  {
    label: "French Polynesia",
    value: "pf",
    lang: "fr",
  },
  {
    label: "French Southern Territories",
    value: "tf",
    lang: "fr",
  },
  {
    label: "Gabon",
    value: "ga",
    lang: "fr",
  },
  {
    label: "Gambia",
    value: "gm",
    lang: "fr",
  },
  {
    label: "Georgia",
    value: "ge",
    lang: "ka",
  },
  {
    label: "Germany",
    value: "de",
    lang: "de",
  },
  {
    label: "Ghana",
    value: "gh",
    lang: "en",
  },
  {
    label: "Gibraltar",
    value: "gi",
    lang: "en",
  },
  {
    label: "Greece",
    value: "gr",
    lang: "el",
  },
  {
    label: "Greenland",
    value: "gl",
    lang: "da",
  },
  {
    label: "Grenada",
    value: "gd",
    lang: "en",
  },
  {
    label: "Guadeloupe",
    value: "gp",
    lang: "fr",
  },
  {
    label: "Guam",
    value: "gu",
    lang: "en",
  },
  {
    label: "Guatemala",
    value: "gt",
    lang: "es",
  },
  {
    label: "Guinea",
    value: "gn",
    lang: "fr",
  },
  {
    label: "Guinea-Bissau",
    value: "gw",
  },
  {
    label: "Guyana",
    value: "gy",
    lang: "en",
  },
  {
    label: "Haiti",
    value: "ht",
    lang: "fr",
  },
  {
    label: "Heard Island and Mcdonald Islands",
    value: "hm",
    lang: "en",
  },
  {
    label: "Honduras",
    value: "hn",
    lang: "es",
  },
  {
    label: "Hong Kong",
    value: "hk",
  },
  {
    label: "Hungary",
    value: "hu",
    lang: "hu",
  },
  {
    label: "Iceland",
    value: "is",
    lang: "is",
  },
  {
    label: "India",
    value: "in",
    lang: "hi",
  },
  {
    label: "Indonesia",
    value: "id",
    lang: "id",
  },
  {
    label: "Iran, Islamic Republic of",
    value: "ir",
  },
  {
    label: "Iraq",
    value: "iq",
    lang: "ar",
  },
  {
    label: "Ireland",
    value: "ie",
    lang: "en",
  },
  {
    label: "Israel",
    value: "il",
    lang: "he",
  },
  {
    label: "Italy",
    value: "it",
    lang: "it",
  },
  {
    label: "Jamaica",
    value: "jm",
    lang: "en",
  },
  {
    label: "Japan",
    value: "jp",
    lang: "ja",
  },
  {
    label: "Jordan",
    value: "jo",
    lang: "ar",
  },
  {
    label: "Kazakhstan",
    value: "kz",
    lang: "ru",
  },
  {
    label: "Kenya",
    value: "ke",
    lang: "en",
  },
  {
    label: "Kiribati",
    value: "ki",
    lang: "en",
  },
  {
    label: "Korea, Democratic People's Republic of",
    value: "kp",
    lang: "ko",
  },
  {
    label: "Korea, Republic of",
    value: "kr",
    lang: "ko",
  },
  {
    label: "Kuwait",
    value: "kw",
    lang: "ar",
  },
  {
    label: "Kyrgyzstan",
    value: "kg",
    lang: "ru",
  },
  {
    label: "Lao People's Democratic Republic",
    value: "la",
  },
  {
    label: "Latvia",
    value: "lv",
    lang: "lv",
  },
  {
    label: "Lebanon",
    value: "lb",
    lang: "ar",
  },
  {
    label: "Lesotho",
    value: "ls",
    lang: "en",
  },
  {
    label: "Liberia",
    value: "lr",
    lang: "en",
  },
  {
    label: "Libyan Arab Jamahiriya",
    value: "ly",
  },
  {
    label: "Liechtenstein",
    value: "li",
    lang: "de",
  },
  {
    label: "Lithuania",
    value: "lt",
    lang: "lt",
  },
  {
    label: "Luxembourg",
    value: "lu",
    lang: "fr",
  },
  {
    label: "Macao",
    value: "mo",
  },
  {
    label: "Macedonia, the Former Yugosalv Republic of",
    value: "mk",
  },
  {
    label: "Madagascar",
    value: "mg",
    lang: "fr",
  },
  {
    label: "Malawi",
    value: "mw",
    lang: "en",
  },
  {
    label: "Malaysia",
    value: "my",
    lang: "ms",
  },
  {
    label: "Maldives",
    value: "mv",
    lang: "dv",
  },
  {
    label: "Mali",
    value: "ml",
    lang: "fr",
  },
  {
    label: "Malta",
    value: "mt",
    lang: "en",
  },
  {
    label: "Marshall Islands",
    value: "mh",
    lang: "en",
  },
  {
    label: "Martinique",
    value: "mq",
    lang: "fr",
  },
  {
    label: "Mauritania",
    value: "mr",
    lang: "fr",
  },
  {
    label: "Mauritius",
    value: "mu",
    lang: "fr",
  },
  {
    label: "Mayotte",
    value: "yt",
    lang: "fr",
  },
  {
    label: "Mexico",
    value: "mx",
    lang: "es",
  },
  {
    label: "Micronesia, Federated States of",
    value: "fm",
  },
  {
    label: "Moldova, Republic of",
    value: "md",
  },
  {
    label: "Monaco",
    value: "mc",
    lang: "fr",
  },
  {
    label: "Mongolia",
    value: "mn",
    lang: "mn",
  },
  {
    label: "Montserrat",
    value: "ms",
    lang: "en",
  },
  {
    label: "Morocco",
    value: "ma",
    lang: "fr",
  },
  {
    label: "Mozambique",
    value: "mz",
    lang: "pt",
  },
  {
    label: "Myanmar",
    value: "mm",
    lang: "my",
  },
  {
    label: "Namibia",
    value: "na",
    lang: "en",
  },
  {
    label: "Nauru",
    value: "nr",
    lang: "en",
  },
  {
    label: "Nepal",
    value: "np",
    lang: "ne",
  },
  {
    label: "Netherlands",
    value: "nl",
    lang: "nl",
  },
  {
    label: "Netherlands Antilles",
    value: "an",
    lang: "nl",
  },
  {
    label: "New Caledonia",
    value: "nc",
    lang: "fr",
  },
  {
    label: "New Zealand",
    value: "nz",
    lang: "en",
  },
  {
    label: "Nicaragua",
    value: "ni",
    lang: "es",
  },
  {
    label: "Niger",
    value: "ne",
    lang: "fr",
  },
  {
    label: "Nigeria",
    value: "ng",
    lang: "en",
  },
  {
    label: "Niue",
    value: "nu",
    lang: "en",
  },
  {
    label: "Norfolk Island",
    value: "nf",
    lang: "en",
  },
  {
    label: "Northern Mariana Islands",
    value: "mp",
    lang: "en",
  },
  {
    label: "Norway",
    value: "no",
    lang: "no",
  },
  {
    label: "Oman",
    value: "om",
    lang: "ar",
  },
  {
    label: "Pakistan",
    value: "pk",
    lang: "en",
  },
  {
    label: "Palau",
    value: "pw",
    lang: "en",
  },
  {
    label: "Palestinian Territory, Occupied",
    value: "ps",
  },
  {
    label: "Panama",
    value: "pa",
    lang: "es",
  },
  {
    label: "Papua New Guinea",
    value: "pg",
    lang: "en",
  },
  {
    label: "Paraguay",
    value: "py",
    lang: "es",
  },
  {
    label: "Peru",
    value: "pe",
    lang: "es",
  },
  {
    label: "Philippines",
    value: "ph",
    lang: "en",
  },
  {
    label: "Pitcairn",
    value: "pn",
    lang: "en",
  },
  {
    label: "Poland",
    value: "pl",
    lang: "pl",
  },
  {
    label: "Portugal",
    value: "pt",
    lang: "pt",
  },
  {
    label: "Puerto Rico",
    value: "pr",
    lang: "es",
  },
  {
    label: "Qatar",
    value: "qa",
    lang: "ar",
  },
  {
    label: "Reunion",
    value: "re",
    lang: "fr",
  },
  {
    label: "Romania",
    value: "ro",
    lang: "ro",
  },
  {
    label: "Russian Federation",
    value: "ru",
    lang: "ru",
  },
  {
    label: "Rwanda",
    value: "rw",
    lang: "fr",
  },
  {
    label: "Saint Helena",
    value: "sh",
    lang: "en",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "kn",
    lang: "en",
  },
  {
    label: "Saint Lucia",
    value: "lc",
    lang: "en",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "pm",
    lang: "fr",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "vc",
    lang: "en",
  },
  {
    label: "Samoa",
    value: "ws",
    lang: "en",
  },
  {
    label: "San Marino",
    value: "sm",
    lang: "it",
  },
  {
    label: "Sao Tome and Principe",
    value: "st",
  },
  {
    label: "Saudi Arabia",
    value: "sa",
    lang: "ar",
  },
  {
    label: "Senegal",
    value: "sn",
    lang: "fr",
  },
  {
    label: "Serbia and Montenegro",
    value: "cs",
  },
  {
    label: "Seychelles",
    value: "sc",
    lang: "fr",
  },
  {
    label: "Sierra Leone",
    value: "sl",
    lang: "en",
  },
  {
    label: "Singapore",
    value: "sg",
    lang: "en",
  },
  {
    label: "Slovakia",
    value: "sk",
    lang: "sk",
  },
  {
    label: "Slovenia",
    value: "si",
    lang: "sl",
  },
  {
    label: "Solomon Islands",
    value: "sb",
    lang: "en",
  },
  {
    label: "Somalia",
    value: "so",
    lang: "so",
  },
  {
    label: "South Africa",
    value: "za",
    lang: "en",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "gs",
    lang: "en",
  },
  {
    label: "Spain",
    value: "es",
    lang: "es",
  },
  {
    label: "Sri Lanka",
    value: "lk",
    lang: "si",
  },
  {
    label: "Sudan",
    value: "sd",
    lang: "ar",
  },
  {
    label: "Suriname",
    value: "sr",
    lang: "nl",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "sj",
    lang: "no",
  },
  {
    label: "Swaziland",
    value: "sz",
  },
  {
    label: "Sweden",
    value: "se",
    lang: "sv",
  },
  {
    label: "Switzerland",
    value: "ch",
    lang: "fr",
  },
  {
    label: "Syrian Arab Republic",
    value: "sy",
  },
  {
    label: "Taiwan, Province of China",
    value: "tw",
  },
  {
    label: "Tajikistan",
    value: "tj",
    lang: "ru",
  },
  {
    label: "Tanzania, United Republic of",
    value: "tz",
  },
  {
    label: "Thailand",
    value: "th",
    lang: "th",
  },
  {
    label: "Timor-Leste",
    value: "tl",
    lang: "pt",
  },
  {
    label: "Togo",
    value: "tg",
    lang: "fr",
  },
  {
    label: "Tokelau",
    value: "tk",
    lang: "en",
  },
  {
    label: "Tonga",
    value: "to",
    lang: "en",
  },
  {
    label: "Trinidad and Tobago",
    value: "tt",
    lang: "en",
  },
  {
    label: "Tunisia",
    value: "tn",
    lang: "ar",
  },
  {
    label: "Turkey",
    value: "tr",
    lang: "tr",
  },
  {
    label: "Turkmenistan",
    value: "tm",
    lang: "tk",
  },
  {
    label: "Turks and Caicos Islands",
    value: "tc",
    lang: "en",
  },
  {
    label: "Tuvalu",
    value: "tv",
    lang: "en",
  },
  {
    label: "Uganda",
    value: "ug",
    lang: "en",
  },
  {
    label: "Ukraine",
    value: "ua",
    lang: "uk",
  },
  {
    label: "United Arab Emirates",
    value: "ae",
    lang: "ar",
  },
  {
    label: "United Kingdom",
    value: "uk",
    bing_value: "gb",
    lang: "en",
  },
  {
    label: "United States",
    value: "us",
    lang: "en",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "um",
    lang: "en",
  },
  {
    label: "Uruguay",
    value: "uy",
    lang: "es",
  },
  {
    label: "Uzbekistan",
    value: "uz",
    lang: "uz",
  },
  {
    label: "Vanuatu",
    value: "vu",
    lang: "en",
  },
  {
    label: "Venezuela",
    value: "ve",
    lang: "es",
  },
  {
    label: "Vietnam",
    value: "vn",
    lang: "vi",
  },
  {
    label: "Virgin Islands, British",
    value: "vg",
  },
  {
    label: "Virgin Islands, U.S.",
    value: "vi",
  },
  {
    label: "Wallis and Futuna",
    value: "wf",
    lang: "fr",
  },
  {
    label: "Western Sahara",
    value: "eh",
    lang: "ar",
  },
  {
    label: "Yemen",
    value: "ye",
    lang: "ar",
  },
  {
    label: "Zambia",
    value: "zm",
    lang: "en",
  },
  {
    label: "Zimbabwe",
    value: "zw",
    lang: "en",
  },
];

export const languageOptions = [
  { label: "English", value: "en", icon: null },
  { label: "Spanish", value: "es", icon: null },
  { label: "French", value: "fr", icon: null },
  { label: "German", value: "de", icon: null },
  { label: "Italian", value: "it", icon: null },
  { label: "Dutch", value: "nl", icon: null },
  { label: "Portuguese", value: "pt", icon: null },
  { label: "Danish", value: "da", icon: null },
  { label: "Norwegian", value: "no", icon: null },
  { label: "Finnish", value: "fi", icon: null },
  { label: "Swedish", value: "sv", icon: null },
  { label: "Romanian", value: "ro", icon: null },
  { label: "Polish", value: "pl", icon: null },
  { label: "Russian", value: "ru", icon: null },
  { label: "Hindi", value: "hi", icon: null },
  { label: "Indonesian", value: "id", icon: null },
  { label: "Malay", value: "ms", icon: null },
  { label: "Thai", value: "th", icon: null },
  { label: "Japanese", value: "ja", icon: null },
  { label: "Korean", value: "ko", icon: null },
];

export const folderOptions = [
  { label: "Folder 1", value: "folder1", icon: null },
  { label: "Folder 2", value: "folder2", icon: null },
  // Add more folders here...
];
