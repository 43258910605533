import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Textarea,
} from "@/components/Elements";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { DialogClose, DialogPortal } from "@radix-ui/react-dialog";
import { marked } from "marked";
import React, { useState } from "react";
import {
  TbCheck,
  TbSparkles,
  TbThumbDownFilled,
  TbThumbUpFilled,
} from "react-icons/tb";
import PlaygroundEditorTheme from "../../../components/AdvancedEditor/themes/PlaygroundEditorTheme";
import { useDocumentStore } from "../../../stores/document";
import { useEditorStore } from "../../../stores/editor";
import { useUpdateDocument } from "../../documents/api/updateDocument";

import { ExtendedTextNode } from "@/components/AdvancedEditor/plugins/ExtendedTextNodePlugin/ExtendedTextNodePlugin";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  TextNode,
} from "lexical";
import { useEffect } from "react";
import PlaygroundNodes from "../../../components/AdvancedEditor/nodes/PlaygroundNodes";
import { PLAYGROUND_TRANSFORMERS } from "../../../components/AdvancedEditor/plugins/MarkdownTransformers";
import { cn } from "../../../utils/style";
import { useRateGptArticle } from "../api/ rateGptArticle";
import { convertJsonToMarkdown } from "../utils/aiArticle";

export default function PrepopulatePreviewPlugin({ html }: { html: string }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();

      const paragraphNode = $createParagraphNode();
      const textNode = $createTextNode(html);
      paragraphNode.append(textNode);
      $getRoot().append(paragraphNode);

      $convertFromMarkdownString(
        $getRoot().getTextContent(),
        PLAYGROUND_TRANSFORMERS,
        $getRoot()
      );
    });
  }, []);

  return null;
}

export function PreviewEditor({
  html,
  className,
}: {
  html: string;
  className?: string;
}) {
  return (
    <div
      className={cn("w-[750px] max-w-[750px] h-full mx-auto mt-12", className)}
    >
      <LexicalComposer
        initialConfig={{
          editable: false,
          namespace: "Preview",
          onError: (error: Error) => {
            throw error;
          },
          nodes: [
            ...PlaygroundNodes,
            ExtendedTextNode,
            {
              replace: TextNode,
              with: (node: TextNode) => new ExtendedTextNode(node.__text),
            },
          ],
          theme: PlaygroundEditorTheme,
        }}
      >
        <PlainTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <PrepopulatePreviewPlugin html={html} />
      </LexicalComposer>
      <div className="h-80"></div>
    </div>
  );
}

export const AiDocumentPreviewDialog = ({
  aiArticleMetadata,
  aiArticleMarkdown,
  ...props
}) => {
  const { document: fraseDocument } = useDocumentStore();
  const { editor, setEditor } = useEditorStore();
  const [open, setOpen] = React.useState(false);
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });
  const rateGptArticleMutation = useRateGptArticle();
  const [rating, setRating] = useState("");
  const [feedback, setFeedback] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const markdownWithTitle = convertJsonToMarkdown(
    aiArticleMarkdown || [],
    false
  );
  const markdown = convertJsonToMarkdown(aiArticleMarkdown || [], true);
  const htmlString = marked.parse(markdown || "");

  const body = <PreviewEditor html={markdownWithTitle} />;
  const confirmButton = (
    <Button
      variant="primaryBlur"
      isLoading={updateDocumentMutation.isLoading}
      onClick={() => {
        handleSaveToDocument();
      }}
      className="h-fit"
    >
      Save to document
    </Button>
  );

  const handleSaveToDocument = React.useCallback(() => {
    const newTab = {
      name: "AI Article",
      html: htmlString,
      title: aiArticleMetadata?.title || "",
    };

    const updatedDocument = {
      ...fraseDocument,
      text: [...fraseDocument.text, newTab],
      metadata: {
        ...fraseDocument.metadata,
        ai_article: undefined,
        ai_article_started: false,
      },
    };

    updateDocumentMutation.mutateAsync(updatedDocument).then(() => {
      setEditor((editorState) => {
        editorState.activeTabIndex = updatedDocument.text.length - 1;
      });
      setOpen(false);
    });
  }, [fraseDocument, aiArticleMetadata, updateDocumentMutation]);

  const handleRateArticle = () => {
    if (rating === "positive") {
      rateGptArticleMutation.mutate(
        {
          article_id: aiArticleMetadata?.id,
          rating,
          feedback: "",
        },
        {
          onSuccess: () => {
            setFeedbackSubmitted(true);
          },
        }
      );
    } else {
      rateGptArticleMutation.mutate(
        {
          article_id: aiArticleMetadata?.id,
          rating,
          feedback,
        },
        {
          onSuccess: () => {
            setFeedbackSubmitted(true);
          },
        }
      );
    }
  };

  return (
    <Dialog
      onOpenChange={(open) => {
        setOpen(open);
      }}
      open={open}
    >
      <DialogTrigger asChild>
        <Button
          variant="aiBlur"
          onClick={() => {
            setOpen(true);
          }}
          startIcon={<TbSparkles />}
          {...props}
        >
          Review AI article
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent
          className="py-4 w-screen h-[calc(100vh-2rem)] rounded-md m-4 overflow-hidden"
          variant="fullScreen"
        >
          <DialogHeader className="p-0 px-4 pb-4 flex justify-between border-b dark:border-b-zinc-800">
            <div className="flex flex-col space-y-2">
              <DialogTitle className="flex items-center">
                {`Review AI Article`}
              </DialogTitle>
              <p className="flex items-center text-sm mt-4 font-normal text-zinc-600 dark:text-zinc-400">
                This is a preview of the article generated by our AI. You can
                review it and save it to your document.
              </p>
            </div>
            <DialogCloseButton
              className="self-start"
              close={() => {
                setOpen(false);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4 pb-40 scroll-pb-40 ">
            {body}
            <div className="space-y-2 flex-col bg-zinc-100 dark:bg-zinc-800 p-2 px-4 ring-1 ring-zinc-200 dark:ring-zinc-600 rounded-md w-[260px] absolute right-8 bottom-28 backdrop-blur-sm">
              <p className="text-sm text-zinc-800 dark:text-zinc-200 text-center font-medium">
                How would you rate the quality of the article?
              </p>
              <div className="flex space-x-2 items-center justify-center">
                {[
                  { value: "positive", icon: <TbThumbUpFilled /> },
                  { value: "negative", icon: <TbThumbDownFilled /> },
                ].map(({ value, icon }) => (
                  <Button
                    key={value}
                    onClick={() => setRating(value)}
                    className={cn(rating === value && "bg-emerald-600/20")}
                    textClassName={cn(
                      rating === value ? "text-emerald-600" : ""
                    )}
                    variant="buttonIcon"
                    disabled={feedbackSubmitted}
                  >
                    {icon}
                  </Button>
                ))}
              </div>
              {rating === "negative" && (
                <div>
                  <Textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    className="mt-1 block w-full h-28 max-h-28"
                    placeholder="Please provide feedback for the generated article..."
                    disabled={feedbackSubmitted}
                  />
                  <div className="flex justify-center mt-2">
                    <Button
                      variant="outlineBlur"
                      onClick={handleRateArticle}
                      size="xs"
                      isLoading={rateGptArticleMutation.isLoading}
                      disabled={!feedback || feedbackSubmitted}
                      startIcon={feedbackSubmitted && <TbCheck />}
                    >
                      {feedbackSubmitted
                        ? "Feedback submitted"
                        : "Submit feedback"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </DialogDescription>
          <DialogFooter className="px-4 pt-4 border-t dark:border-t-zinc-800 w-full">
            <div className="mt-4 flex justify-between items-end space-x-2">
              <DialogClose asChild>
                <Button variant="outlineBlur" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
              </DialogClose>
              {confirmButton}
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
