import { SolidCircleProgress } from "@/components/Elements/Progress/SolidCircleProgress";
import {
  filterClusters,
  filterTopics,
  scoreSERP,
  scoreTopics,
} from "@/features/documents/utils/topics";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { useSerpStore } from "@/stores/serp";
import { useTopicsStore } from "@/stores/topics";
import { cn } from "@/utils/style";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { HeadingNode } from "@lexical/rich-text";
import {
  $getRoot,
  $isElementNode,
  $nodesOfType,
  ElementNode,
  LexicalEditor,
  LexicalNode,
} from "lexical";
import { debounce } from "lodash";
import { useMemo } from "react";

export function $getAllNodes(
  root: ElementNode,
  getChildren: boolean
): Array<LexicalNode> {
  const nodes = [];
  let child: LexicalNode | null = root.getFirstChild();
  while (child !== null) {
    nodes.push(child);

    if ($isElementNode(child) && getChildren) {
      const subChildrenNodes = $getAllNodes(child, getChildren);
      nodes.push(...subChildrenNodes);
    }
    child = child.getNextSibling();
  }
  return nodes;
}

export const useEditorHeaderNodes = (editor: LexicalEditor) => {
  let headerTexts: string[] = [];
  editor.getEditorState().read(() => {
    const headerNodes = $nodesOfType(HeadingNode);
    headerTexts = headerNodes.map((node) => node.getTextContent());
  });
  return headerTexts;
};

export const useEditorTextNodes = (editor: LexicalEditor) => {
  let textContent: string[] = [];
  editor.getEditorState().read(() => {
    const textNodes = $getAllNodes($getRoot(), false);
    textContent = textNodes.map((node) => node.getTextContent());
  });
  return textContent;
};

export default function TopicScorePlugin(): JSX.Element {
  const { document: fraseDocument } = useDocumentStore();
  const { serp, setSerp } = useSerpStore();
  const [editor] = useLexicalComposerContext();
  const { editor: editorStore } = useEditorStore();
  const { topics: topicsStore, setTopics } = useTopicsStore();
  const { topics, articles, clusters, serpLoaded } = serp[fraseDocument.id] || {
    topics: [],
    articles: [],
    clusters: [],
    serpLoaded: false,
  };
  const { score, selectedType, selectedStatus } = topicsStore[
    fraseDocument.id
  ] || {
    score: 0,
    selectedType: "longTail",
    selectedStatus: "all",
  };
  const { title, activeTabIndex } = editorStore;
  const validArticles = (articles || [])?.filter((article) => article.isValid);

  const calculateTopicScore = () => {
    if (
      serpLoaded &&
      topics &&
      topics.length > 0 &&
      validArticles &&
      validArticles.length > 0 &&
      clusters &&
      clusters.length > 0
    ) {
      let scoredArticles = scoreSERP(topics, validArticles, fraseDocument);

      const headerNodesFromEditor = useEditorHeaderNodes(editor);
      const textNodesFromEditor = useEditorTextNodes(editor);
      const scoredTopicsWithEditor = scoreTopics(
        scoredArticles,
        topics,
        clusters,
        fraseDocument,
        selectedType,
        headerNodesFromEditor,
        textNodesFromEditor,
        title
      );

      const processedTopics = filterTopics(
        scoredTopicsWithEditor.topics,
        selectedType || "longTail",
        selectedStatus || "all",
        fraseDocument
      );

      const processedClusters = filterClusters(
        scoredTopicsWithEditor.clusters,
        selectedStatus,
        fraseDocument
      );
      setTopics(fraseDocument.id, {
        ...topicsStore[fraseDocument.id],
        topics: processedTopics,
        clusters: processedClusters,
        score: scoredTopicsWithEditor.score,
        avgScore: scoredTopicsWithEditor.avg_score,
        topScore: scoredTopicsWithEditor.top_score,
        selectedType: selectedType,
        selectedStatus: selectedStatus,
      });
      setSerp(fraseDocument.id, {
        ...serp[fraseDocument.id],
        articles: scoredArticles || [],
      });
    }
  };

  const debouncedCalculateTopicScore = debounce(
    () => {
      calculateTopicScore();
    },
    400,
    { leading: false, trailing: true }
  );

  useMemo(() => {
    calculateTopicScore();
  }, [fraseDocument, selectedType, selectedStatus, serpLoaded, activeTabIndex]);

  useMemo(() => {
    debouncedCalculateTopicScore();
  }, [editorStore.wordCount]);

  if (!serpLoaded) {
    return <></>;
  }

  return (
    <div
      className={cn(
        "transition-all ease-in absolute mt-4 mr-4 top-42 right-0 text-zinc-800 dark:text-zinc-200 z-50 h-10 w-12 print:hidden",
        (serpLoaded && !editorStore.optimizeViewVisible) ||
          !editorStore.optimizeScrollIsAtTop
          ? "opacity-100"
          : "opacity-0"
      )}
    >
      <SolidCircleProgress
        progress={score}
        textClassName="text-2xs"
        visible={
          (serpLoaded && !editorStore.optimizeViewVisible) ||
          !editorStore.optimizeScrollIsAtTop
        }
      />
    </div>
  );
}
