import {
  ArticleMetadata,
  Input,
  PasteAssetButton,
} from "@/components/Elements";
import { Skeleton } from "@/components/Elements/Skeleton";
import {
  useHandlePasteCitation,
  useHandlePasteContent,
} from "@/features/documents/utils/pasting";
import {
  getWikipediaSearchResults,
  WikipediaSearchQuery,
} from "@/features/serp/api/getWikipediaSearchResults";

import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { useEffect, useRef, useState } from "react";
import { VirtualizedList } from "./Questions";

const stripHtmlTags = (htmlString) => {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || "";
};

function Wiki() {
  const { document } = useDocumentStore();
  const { serp } = useSerpStore();
  const docId = document.id;
  const { topics } = serp[docId] || { topics: [] };
  const [filter, setFilter] = useState("");
  const [wikiResults, setWikiResults] = useState([]);
  const [activeWiki, setActiveWiki] = useState(null);
  const [activeWikiText, setActiveWikiText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handlePasteContent = useHandlePasteContent();
  const handlePasteCitation = useHandlePasteCitation();
  const parentRef = useRef();

  useEffect(() => {
    if (!activeWiki) {
      fetchWikipediaArticles();
    }
  }, [activeWiki]);

  const fetchWikipediaArticles = async () => {
    setIsLoading(true);
    try {
      // Limit to the first 20 topics
      const limitedTopics = topics.slice(0, 20);
      const queries: WikipediaSearchQuery[] = limitedTopics.map((topic) => ({
        query: topic.entity,
        lang: "en",
      }));
      const results = await getWikipediaSearchResults({ queries });
      const uniqueResults = Object.values(results).flat();

      // Limit to the first 25 wiki results
      setWikiResults(uniqueResults.slice(0, 25));
    } catch (error) {
      console.error("Error fetching Wikipedia articles:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const openWiki = async (title) => {
    setActiveWiki(title);
    try {
      const response = await fetch(
        `https://en.wikipedia.org/w/api.php?action=parse&page=${title}&format=json&origin=*`
      );
      const data = await response.json();
      setActiveWikiText(data.parse.text["*"]);
    } catch (error) {
      console.error("Error fetching full Wikipedia article:", error);
    }
  };

  const renderLoadingSkeleton = () =>
    Array.from({ length: 10 }).map((_, i) => (
      <div
        key={i}
        className="group mt-2 p-2 cursor-pointer relative items-center rounded-md bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 hover:bg-white"
      >
        <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
        <Skeleton className="w-full h-4" />
      </div>
    ));

  const filteredWikiResults = wikiResults.filter(
    (item) =>
      item.title.toLowerCase().includes(filter.toLowerCase()) ||
      item.snippet.includes(filter.toLowerCase())
  );

  const renderWikiItem = (item, index) => {
    return (
      <div
        key={index}
        className="flex flex-col mb-2 group/asset-text p-2 cursor-pointer relative rounded-md ring-1 ring-inset ring-zinc-900/7.5 dark:ring-white/10 hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5"
      >
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full space-y-1 pl-2">
            <ArticleMetadata
              url={`https://en.wikipedia.org/wiki/${item.title}`}
              urlHost={getSourceUrl(
                `https://en.wikipedia.org/wiki/${item.title}`
              )}
            />
            <div className="flex flex-col justify-between">
              <p className="transition-all text-xs font-medium text-zinc-900 dark:text-white">
                {item.title}
              </p>
              <p
                className="text-xs text-gray-500"
                dangerouslySetInnerHTML={{ __html: item.snippet }}
              />
            </div>
          </div>
          <div className="flex items-center ml-2">
            <PasteAssetButton
              handlePasteContent={handlePasteContent}
              handlePasteCitation={handlePasteCitation}
              headerText={stripHtmlTags(item.snippet)}
              url={`https://en.wikipedia.org/wiki/${item.title}`}
              className="invisible group-hover/asset-text:visible"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-center justify-between px-4 my-2">
        <Input
          value={filter}
          onChange={(value) => setFilter(value)}
          placeholder="Filter by keyword..."
          className="text-xs"
        />
      </div>
      <ul className="space-y-2 pl-4 pr-1">
        {isLoading ? (
          <div className="pr-3">{renderLoadingSkeleton()}</div>
        ) : (
          <VirtualizedList
            items={filteredWikiResults}
            parentRef={parentRef}
            renderItem={renderWikiItem}
          />
        )}
      </ul>
      {activeWiki && (
        <div className="wiki-full-article">
          <div dangerouslySetInnerHTML={{ __html: activeWikiText }} />
        </div>
      )}
    </div>
  );
}

function getSourceUrl(url) {
  // Implement the logic to extract the domain from the URL
  return new URL(url).hostname;
}

export default Wiki;
