// src/features/settings/api/inviteUser.ts
import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";

interface InviteUserPayload {
  userEmails: Array<{ email: string; role: number }>;
}

const inviteUsersToOrg = async (payload: InviteUserPayload) => {
  const { data } = await axios.post("/inviteUsersToOrg", payload);
  return data;
};

export const useInviteUsersToOrg = () => {
  const queryClient = useQueryClient();

  return useMutation(inviteUsersToOrg, {
    onSuccess: () => {
      queryClient.invalidateQueries("usersForOrg");
      queryClient.invalidateQueries("pendingUsers");
    },
  });
};
