import { TemplateEditorContainer } from "@/components/AdvancedEditor/TemplateEditorContainer";
import { Spinner } from "@/components/Elements";
import { ContentLayout } from "@/components/Layout";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { TbAlertCircleFilled } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { useTemplate } from "../api/getTemplate";

export const Template = () => {
  const params = useParams<{ templateHash: string }>();
  const templateHash = params.templateHash;
  const templateQuery = useTemplate({ templateHash: templateHash! });
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const isSubscriptionCancelled = subscriptionData?.status === "canceled";

  if (templateQuery.isLoading || isLoadingSubscription) {
    return (
      <div className="w-full h-[25%] flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!templateQuery.data && templateQuery.isSuccess) {
    return (
      <ContentLayout>
        <div className="flex h-full w-full items-center justify-center">
          <div className="text-center">
            <div className="flex items-center space-x-2 justify-center">
              <TbAlertCircleFilled className=" h-4 w-4 text-zinc-800 dark:text-zinc-200" />
              <p className="text-md font-medium text-zinc-800 dark:text-zinc-200">
                Template not found
              </p>
            </div>
            <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
              There is no template with the identifier{" "}
              <code className="font-mono text-zinc-800 dark:text-zinc-200">
                {templateHash}
              </code>
              .
            </p>
          </div>
        </div>
      </ContentLayout>
    );
  }

  if (!templateQuery.data) {
    return null;
  }

  return (
    <ContentLayout>
      <TemplateEditorContainer
        templateMetadata={templateQuery.data}
        isReadOnly={isSubscriptionCancelled}
      />
    </ContentLayout>
  );
};
