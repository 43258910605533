import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

type GetGptArticleIdPayload = {
  query: string;
  title: string;
  topics: string;
  tone: string;
  global_instructions: string;
  outline: Array<{
    id: number;
    heading: string;
    heading_type: string;
    parent: number;
    instructions: string;
  }>;
  lang: string;
  doc_hash: string;
  country: string;
};

export const getGptArticleId = ({
  query,
  title,
  topics,
  tone,
  global_instructions,
  outline,
  lang,
  doc_hash,
  country,
}: GetGptArticleIdPayload): Promise<any> => {
  return axios.post(`/gpt_article`, {
    query,
    title,
    topics,
    tone,
    global_instructions,
    outline,
    lang,
    doc_hash,
    country,
  });
};

type UseGetGptArticleIdOptions = {
  query: string;
  title: string;
  topics: string;
  tone: string;
  global_instructions: string;
  outline: Array<{
    id: number;
    heading: string;
    parent: number;
    heading_type: string;
    instructions: string;
  }>;
  lang: string;
  doc_hash: string;
  config?: any;
  country: string;
};

export const useGetGptArticleId = ({
  query,
  title,
  topics,
  tone,
  global_instructions,
  outline,
  lang,
  doc_hash,
  config,
  country,
}: UseGetGptArticleIdOptions) => {
  return useQuery({
    ...config,
    queryKey: [
      "gptArticleId",
      {
        query,
        title,
        topics,
        tone,
        global_instructions,
        outline,
        lang,
        doc_hash,
        country,
      },
    ],
    queryFn: () =>
      getGptArticleId({
        query,
        title,
        topics,
        tone,
        global_instructions,
        outline,
        lang,
        doc_hash,
        country,
      }),
  });
};
