import { Button, Input } from "@/components/Elements";
import _ from "lodash";
import { useState } from "react";
import { TbPlus, TbX } from "react-icons/tb";
import { cn } from "../../../utils/style";

export const TopicTag = ({ children, onRemove, onAdd, showRemove = true }) => {
  return (
    <span
      className={cn(
        "inline-flex h-7 items-center gap-x-0.5 rounded-md bg-zinc-50 dark:bg-zinc-800 pl-2 py-1 text-xs font-medium text-zinc-900 dark:text-white ring-1 ring-inset ring-zinc-500/10 dark:ring-zinc-800/10 first:ml-0 mr-2 mb-2 cursor-pointer",
        onRemove
          ? "bg-emerald-600/10 hover:bg-emerald-700/20 dark:bg-emerald-400/20 dark:hover:bg-emerald-400/30"
          : "bg-zinc-50 hover:bg-zinc-100 dark:bg-zinc-800 dark:hover:bg-zinc-700"
      )}
      onClick={onRemove ? onRemove : onAdd}
    >
      <span
        className={cn(
          "truncate text-zinc-900 dark:text-white",
          !showRemove && "pr-2"
        )}
        style={{
          maxWidth: "400px",
        }}
      >
        {_.startCase(_.toLower(children))}
      </span>
      {showRemove && (
        <Button
          variant="tagIcon"
          buttonIcon={onRemove ? <TbX /> : <TbPlus />}
          onClick={onRemove ? onRemove : onAdd}
        />
      )}
    </span>
  );
};

export const TopicInputTag = ({ onAdd }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [input, setInput] = useState("");

  const handleInputChange = (value) => {
    setInput(value);
  };

  const handleAdd = () => {
    onAdd({
      entity: input,
    });
    setInput("");
  };

  if (!inputVisible) {
    return (
      <Button
        variant="outlineBlur"
        buttonIcon={<TbPlus />}
        onClick={() => setInputVisible(true)}
        className="mr-2"
        size="2xs"
      >
        Add topic
      </Button>
    );
  }

  return (
    <div className="inline-flex mr-1 mb-2">
      <Input
        type="text"
        value={input}
        onChange={handleInputChange}
        className="h-7 border-transparent mb-2 w-48"
        containerClassName="mr-1"
        placeholder="Enter custom topic..."
        autoFocus
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleAdd();
            setInputVisible(false);
          }
        }}
        onBlur={() => setInputVisible(false)}
      />
    </div>
  );
};
