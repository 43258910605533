"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { cn } from "@/utils/style";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = ({ delay = 500, ...props }) => (
  <TooltipPrimitive.Root {...props} delayDuration={delay} />
);
Tooltip.displayName = TooltipPrimitive.Tooltip.displayName;

const TooltipTrigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TooltipPrimitive.Trigger
    asChild
    ref={ref}
    className={cn("focus:ring-none focus:outline-none", className)}
    {...props}
  />
));

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 8, ...props }, ref) => (
  <TooltipPrimitive.Portal>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "z-[57] shadow-glowLg dark:shadow-lg overflow-hidden font-normal px-2 py-1 text-2xs text-zinc-900 dark:text-white rounded-md backdrop-blur-lg bg-white/80 dark:bg-zinc-900/50 ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none focus:ring-none focus:-ring-transparent",
        className
      )}
      {...props}
    />
  </TooltipPrimitive.Portal>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };
