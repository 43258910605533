import { ExtendedTextNode } from "@/components/AdvancedEditor/plugins/ExtendedTextNodePlugin/ExtendedTextNodePlugin";
import { useUser } from "@/features/auth";
import { $generateNodesFromDOM } from "@lexical/html";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import * as Sentry from "@sentry/react";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
  TextNode,
} from "lexical";
import { useEffect, useState } from "react";
import { TbLayoutGrid, TbSearch } from "react-icons/tb";
import TurndownService from "turndown";
import { Template } from "..";
import PlaygroundNodes from "../../../components/AdvancedEditor/nodes/PlaygroundNodes";
import { PLAYGROUND_TRANSFORMERS } from "../../../components/AdvancedEditor/plugins/MarkdownTransformers";
import PlaygroundEditorTheme from "../../../components/AdvancedEditor/themes/PlaygroundEditorTheme";
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
} from "../../../components/Elements";
import { useNotificationStore } from "../../../stores/notifications";
import { cn } from "../../../utils/style";
import { useGlobalTemplates } from "../api/getGlobalTemplates";
import { useTemplatesForOrg } from "../api/getTemplates";
import { NewTemplateButton } from "./NewTemplateButton";

export const PrepoulateContentPreviewPlugin = ({ html }: { html: string }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      if (html) {
        const parser = new DOMParser();
        const dom = parser.parseFromString(html || "", "text/html");

        let nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();
        root.select();
        root.clear();

        if (nodes.length === 0) {
          nodes.push($createParagraphNode());
        }

        $insertNodes(nodes);
      } else {
        const root = $getRoot();
        root.select();
        root.clear();
        const paragraphNode = $createParagraphNode();
        paragraphNode.append(
          $createTextNode(
            "No results found for the selected section. Please try another section."
          )
        );
        root.append(paragraphNode);
      }
    });
  }, [html, editor]);

  return null;
};

function ContentPreviewEditor({
  html,
  title,
  selectedTemplate,
  setOpen,
  showAuthor = true,
}: {
  html: string;
  title?: string;
  selectedTemplate: Template | null;
  setOpen: (open: boolean) => void;
  showAuthor?: boolean;
}) {
  return (
    <div
      className={cn("space-y-4 flex flex-col flex-1 pt-4 px-4 overflow-hidden")}
    >
      <div className="flex justify-between">
        <div className="flex flex-col space-y-0.5">
          <p className="w-full text-xl font-bold text-zinc-800 dark:text-zinc-200">
            {title}
          </p>
          {showAuthor && (
            <p className="text-sm text-zinc-500">
              by {selectedTemplate?.template_author || "Frase"}
            </p>
          )}
        </div>
        <DialogCloseButton close={() => setOpen(false)} />
      </div>

      <div className="flex flex-col space-y-2 flex-1 overflow-hidden">
        <div className="border-zinc-100 dark:border-zinc-800 border rounded-md p-3 flex-1 overflow-y-scroll">
          <LexicalComposer
            initialConfig={{
              editable: false,
              namespace: "Preview",
              onError: (error: Error) => {
                Sentry.captureException(error);
              },
              nodes: [
                ...PlaygroundNodes,
                ExtendedTextNode,
                {
                  replace: TextNode,
                  with: (node: TextNode) => new ExtendedTextNode(node.__text),
                },
              ],
              theme: PlaygroundEditorTheme,
            }}
          >
            <PlainTextPlugin
              contentEditable={
                <ContentEditable className="h-full w-full max-w-[750px] flex-1 mx-auto min-w-0 mb-40" />
              }
              placeholder={null}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <PrepoulateContentPreviewPlugin html={html} />
          </LexicalComposer>
        </div>
      </div>
    </div>
  );
}

export const ImportTemplateButton = ({
  open,
  setOpen,
  trigger,
  setShowPlaceholders,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  trigger?: React.ReactNode;
  setShowPlaceholders: (showPlaceholders: boolean) => void;
}) => {
  const [filter, setFilter] = useState("");
  const [editor] = useLexicalComposerContext();
  const [isInsertingToEditor, setIsInsertingToEditor] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewHtml, setPreviewHtml] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>();
  const [filteredUserTemplates, setFilteredUserTemplates] = useState<
    Template[]
  >([]);
  const [filteredGlobalTemplates, setFilteredGlobalTemplates] = useState<
    Template[]
  >([]);

  const { addNotification } = useNotificationStore();
  const { data: user, isLoading: isUserLoading } = useUser();

  const { data: userTemplates, isLoading: isLoadingTemplates } =
    useTemplatesForOrg({
      org_id: user?.orgId.toString() || "",
    });

  const { data: globalTemplates, isLoading: isLoadingGlobalTemplates } =
    useGlobalTemplates({});

  const handleInsertToEditor = async () => {
    setIsInsertingToEditor(true);

    editor.update(() => {
      const dom = new DOMParser().parseFromString(previewHtml, "text/html");
      let nodes = $generateNodesFromDOM(editor, dom);
      const root = $getRoot();
      root.select();
      root.clear();

      try {
        $insertNodes(nodes);
      } catch (error) {
        const markdown = new TurndownService().turndown(previewHtml);
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(markdown);
        paragraphNode.append(textNode);
        $insertNodes([paragraphNode]);

        $convertFromMarkdownString(
          root.getTextContent(),
          PLAYGROUND_TRANSFORMERS,
          root
        );
      }
      root.selectStart();
    });

    setShowPlaceholders(false);
    setIsInsertingToEditor(false);
    addNotification({
      type: "success",
      title: `Imported template`,
      message: (
        <span className="flex items-center">
          <TbLayoutGrid className="w-3.5 h-3.5 fill-zinc-500 text-zinc-500" />
          <span className="ml-2 text-xs">{previewTitle || "Untitled"}</span>
        </span>
      ),
    });
    setOpen(false);
  };

  const filterTemplates = (templates: Template[], filter: string) => {
    return templates.filter(
      (template) =>
        template.text?.[0]?.title &&
        template.text[0].title.toLowerCase().includes(filter.toLowerCase())
    );
  };

  useEffect(() => {
    const filteredTemplates = filterTemplates(userTemplates || [], filter);
    setFilteredUserTemplates(filteredTemplates);
  }, [userTemplates, filter]);

  useEffect(() => {
    const filteredTemplates = filterTemplates(globalTemplates || [], filter);
    setFilteredGlobalTemplates(filteredTemplates);
  }, [globalTemplates, filter]);

  const showNoResults =
    !isLoadingTemplates &&
    !isLoadingGlobalTemplates &&
    filteredUserTemplates.length === 0 &&
    filteredGlobalTemplates.length === 0;

  const handleTemplateSelection = (template: Template) => {
    setPreviewHtml(template.text?.[0]?.html || "");
    setPreviewTitle(template.text?.[0]?.title || "Untitled");
    setSelectedTemplate(template);
  };

  useEffect(() => {
    if (!isLoadingTemplates && !isLoadingGlobalTemplates) {
      let firstTemplate = userTemplates?.[0] || globalTemplates?.[0] || {};
      setPreviewHtml(firstTemplate.text?.[0]?.html || "");
      setPreviewTitle(firstTemplate.text?.[0]?.title || "Untitled");
      setSelectedTemplate(firstTemplate);
    }
  }, [
    isLoadingTemplates,
    userTemplates,
    isLoadingGlobalTemplates,
    globalTemplates,
  ]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <DialogTrigger asChild>
        {trigger || (
          <Button variant="outlineBlur" size="xs" className="h-7">
            Import template
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className="h-[90vh] max-w-[1250px] mx-12"
        variant="templates"
        containerClassName="p-0"
        style={{
          padding: "0",
        }}
      >
        <div className="flex h-full w-full">
          <div className="flex flex-col w-80 rounded-bl-md rounded-tl-md bg-zinc-50 dark:bg-zinc-800 border-r dark:border-r-zinc-700">
            <div className="p-4 space-y-2">
              <div className="flex items-center">
                <TbLayoutGrid className="w-3.5 h-3.5 mr-2 text-zinc-500 fill-zinc-500" />
                <DialogTitle className="text-sm text-zinc-700 dark:text-white">
                  Templates
                </DialogTitle>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Input
                  value={filter}
                  onChange={(value) => setFilter(value)}
                  placeholder="Search templates"
                  startIcon={<TbSearch />}
                  className="w-full"
                  autoFocus={false}
                />
                <NewTemplateButton
                  buttonVariant="outlineBlur"
                  className="h-8"
                />
              </div>
            </div>

            <div className="h-full overflow-auto pb-4">
              <div className="flex flex-col space-y-2">
                {!isLoadingTemplates && filteredUserTemplates.length > 0 && (
                  <>
                    <Label className="px-4 text-[12px] font-semibold text-zinc-500">
                      Your templates
                    </Label>
                    <div className="flex flex-col space-y-0.5 px-4 overflow-auto">
                      {filteredUserTemplates.map((template) => (
                        <Button
                          key={template.id}
                          variant="text"
                          size="sm"
                          className={cn(
                            "w-full text-left hover:bg-zinc-200/50",
                            selectedTemplate?.id === template.id &&
                              "bg-zinc-200/50 dark:bg-zinc-700/50"
                          )}
                          textClassName={cn(
                            "w-full text-zinc-500 dark:text-zinc-500 hover:text-zinc-800 dark:hover:text-white text-sm",
                            selectedTemplate?.id === template.id &&
                              "text-zinc-800 dark:text-white "
                          )}
                          onClick={() => handleTemplateSelection(template)}
                          autoFocus={
                            selectedTemplate?.id === template.id ? true : false
                          }
                        >
                          {template.text?.[0]?.title || "Untitled"}
                        </Button>
                      ))}
                    </div>
                  </>
                )}
                {!isLoadingGlobalTemplates &&
                  filteredGlobalTemplates.length > 0 && (
                    <>
                      <Label className="px-4 text-[12px] font-semibold text-zinc-500">
                        Frase templates
                      </Label>
                      <div className="flex flex-col space-y-0.5 px-4 overflow-auto">
                        {filteredGlobalTemplates.map((template) => (
                          <Button
                            key={template.id}
                            variant="text"
                            size="sm"
                            className={cn(
                              "w-full text-left hover:bg-zinc-200/50",
                              selectedTemplate?.id === template.id &&
                                "bg-zinc-200/50 dark:bg-zinc-700/50"
                            )}
                            textClassName={cn(
                              "w-full text-zinc-500 dark:text-zinc-500 hover:text-zinc-800 dark:hover:text-white text-sm",
                              selectedTemplate?.id === template.id &&
                                "text-zinc-800 dark:text-white "
                            )}
                            autoFocus={
                              selectedTemplate?.id === template.id
                                ? true
                                : false
                            }
                            onClick={() => handleTemplateSelection(template)}
                          >
                            {template.text?.[0]?.title || "Untitled"}
                          </Button>
                        ))}
                      </div>
                    </>
                  )}
                {showNoResults && (
                  <p className="px-4 text-zinc-500 text-xs">
                    No templates found
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 ml-4 space-y-4 flex flex-col">
            <ContentPreviewEditor
              html={previewHtml}
              title={previewTitle}
              selectedTemplate={selectedTemplate}
              setOpen={setOpen}
            />

            <DialogFooter className="px-4 pb-4 text-center">
              <Button
                type="submit"
                variant="primaryBlur"
                disabled={isInsertingToEditor}
                onClick={() => {
                  handleInsertToEditor();
                }}
                isLoading={isInsertingToEditor}
              >
                Import template
              </Button>
            </DialogFooter>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
