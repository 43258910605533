import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import { DialogClose, DialogPortal } from "@radix-ui/react-dialog";
import React from "react";
import { Checkbox, Input } from "../../../components/Elements";
import { useDeleteUserAccount } from "../../auth/api/deleteUser";
import { useDeletePlan } from "../../subscription/api/deletePlan";

const DeleteAccountDialog = ({ userEmail, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const { addNotification } = useNotificationStore();
  const { mutate: deletePlan, isLoading } = useDeletePlan();
  const { mutate: deleteUserAccount, isLoading: isDeletingAccount } =
    useDeleteUserAccount();
  const [emailInput, setEmailInput] = React.useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);

  const isDeleteButtonEnabled = emailInput === userEmail && isCheckboxChecked;

  const body = (
    <>
      Are you sure you want to delete your account? This action cannot be
      undone.
    </>
  );

  const handleOpenChange = (open) => {
    setOpen(open);
    if (open) {
      // Reset the state when the dialog is opened
      setEmailInput("");
      setIsCheckboxChecked(false);
    }
  };

  const confirmButton = (
    <Button
      isLoading={isLoading || isDeletingAccount}
      variant="destructiveBlur"
      className="bg-red-600"
      disabled={!isDeleteButtonEnabled} // Disable button unless conditions are met
      onClick={() => {
        deletePlan();
        deleteUserAccount();
        setOpen(false);
      }}
    >
      Delete Account
    </Button>
  );

  return (
    <Dialog onOpenChange={handleOpenChange} open={open} modal={true}>
      <DialogTrigger asChild>
        <Button
          variant="outlineBlur"
          textClassName="text-red-500"
          onClick={() => setOpen(true)}
          {...props}
          size="xs"
        >
          Delete Account
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Delete Account</DialogTitle>
            <DialogCloseButton
              close={() => {
                setOpen(false);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4">
            {body}
            <div className="mt-4">
              <span>
                Enter email address
                <code className="ml-1 text-xs bg-zinc-100 p-0.5 px-1 rounded-md dark:bg-zinc-700">
                  {userEmail}
                </code>
              </span>
              <Input
                type="email"
                placeholder="Enter your email address"
                value={emailInput}
                onChange={(value) => setEmailInput(value)}
                className="w-full px-4 py-2 border rounded-md"
              />
              <label className="flex align-top mt-4">
                <Checkbox
                  checked={isCheckboxChecked}
                  onCheckedChange={(checked) => setIsCheckboxChecked(checked)}
                  className="mr-2 mt-1.5"
                />
                <span className="text-xs flex-nowrap ml-1">
                  I acknowledge I understand that all of the account data will
                  be be deleted and want to proceed.
                </span>
              </label>
            </div>
          </DialogDescription>
          <DialogFooter className="px-4 pb-4">
            <DialogClose asChild>
              <Button
                variant="outlineBlur"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </DialogClose>
            {confirmButton}
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export const DeleteAccountButton = DeleteAccountDialog;
