import { axios } from "@/lib/axios";
import { useMutation } from "react-query";

interface UpdateAiToolVotePayload {
  template_hash: string;
  field_name: string;
}

const updateAiToolVote = async (
  payload: UpdateAiToolVotePayload
): Promise<number> => {
  const response = await axios.post<number>(`/updateTemplateMetrics`, payload);
  return response.data;
};

export const useUpdateAiToolVote = () => {
  return useMutation<number, Error, UpdateAiToolVotePayload>(updateAiToolVote);
};
