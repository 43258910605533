import { Button } from "@/components/Elements";
import { useHandlePasteContent } from "@/features/documents/utils/pasting";
import { TbCircleOff } from "react-icons/tb";
import { useUpdateDocument } from "../../../../features/documents/api/updateDocument";
import { useDocumentStore } from "../../../../stores/document";

export const BlacklistCard = ({ topic, item }) => {
  const handlePasteContent = useHandlePasteContent();
  const { document } = useDocumentStore();
  const updateDocumentMutation = useUpdateDocument({ notifyOnSuccess: false });

  const handleUpdateDocumentBlacklist = () => {
    const topicBlacklist = document.metadata.blacklist_topics;
    delete topicBlacklist[topic];

    const updatedDocument = {
      ...document,
      metadata: {
        ...document.metadata,
        blacklist_topics: {
          ...topicBlacklist,
        },
      },
    };

    updateDocumentMutation.mutateAsync(updatedDocument);
  };

  return (
    <div className="group p-2 py-2.5 cursor-pointer relative items-center rounded-md bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 hover:bg-white">
      <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
      <div className="flex justify-between">
        <div className="flex">
          <p className="text-xs self-center font-medium dark:text-zinc-50 pl-2">
            {topic}
          </p>
        </div>
        <div className="flex items-center">
          <Button
            variant="buttonIcon"
            className="mr-1"
            buttonIcon={<TbCircleOff />}
            tooltipContent={`Remove from blacklist`}
            onClick={(event) => {
              event.stopPropagation();
              handleUpdateDocumentBlacklist();
            }}
          />
        </div>
      </div>
    </div>
  );
};
