import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

type GptGeneration = {
  explicit: boolean;
  generation: string;
  finish_reason: string;
};

export type GptTemplateResponse = {
  explicit: boolean;
  gpt_generations: GptGeneration[];
  input_chars: number;
  input_words: number;
  output_words: number;
  translated_chars: number;
  output_chars: number;
};

type GetGptTemplatePayload = {
  template_hash: string;
  prompt_map: Record<string, string>;
  num_return_sequences: number;
  lang: string;
};

export const getGptTemplate = ({
  template_hash,
  prompt_map,
  lang,
  num_return_sequences,
}: GetGptTemplatePayload): Promise<GptTemplateResponse> => {
  return axios.post<GptTemplateResponse>(`/gpt_template`, {
    template_hash,
    prompt_map,
    lang,
    num_return_sequences,
  });
};

type UseGetGptTemplateOptions = {
  template_hash: string;
  prompt_map: Record<string, string>;
  lang: string;
  num_return_sequences: number;
  config?: any;
};

export const useGetGptTemplate = ({
  template_hash,
  prompt_map,
  lang,
  num_return_sequences,
  config,
}: UseGetGptTemplateOptions) => {
  return useQuery<GptTemplateResponse>({
    ...config,
    queryKey: [
      "gptTemplate",
      { template_hash, prompt_map, lang, num_return_sequences },
    ],
    queryFn: () =>
      getGptTemplate({
        template_hash,
        prompt_map,
        lang,
        num_return_sequences,
      }),
  });
};
