import { TbCircleCheckFilled } from "react-icons/tb";
import { AiDocumentPreviewDialog } from "./AiDocumentPreviewDialog";

export const WizardSuccessMessage = ({
  title,
  aiArticleMetadata,
  aiArticleMarkdown,
  onNavigateToPreview = () => {},
  renderNavigationButton = true,
}: {
  title?: string;
  aiArticleMetadata?: any;
  aiArticleMarkdown?: object;
  onNavigateToPreview?: () => void;
  renderNavigationButton?: boolean;
}) => {
  return (
    <div className="flex flex-col items-center space-y-4 w-full h-full">
      <div className="flex items-center space-x-2 mt-20">
        <TbCircleCheckFilled className="text-emerald-600 dark:text-emerald-400" />
        <p className="text-zinc-600 font-medium dark:text-zinc-300">
          Success - your article has been generated!
        </p>
      </div>
      <div className="flex flex-col border-l border-r border-t w-1/2 overflow-hidden max-w-[700px] rounded-tl-lg rounded-tr-lg relative dark:border-zinc-700 py-8">
        {aiArticleMetadata.title && (
          <p className="font-semibold text-zinc-900 text-xl dark:text-zinc-400 px-8 pb-8">
            {aiArticleMetadata.title}
          </p>
        )}
        <div className="h-4 mb-2 ml-16 mx-8 bg-emerald-600/10 rounded-lg dark:bg-emerald-400/10" />
        <div className="h-4 mb-2 mx-8 bg-emerald-600/10 rounded-lg dark:bg-emerald-400/10" />
        <div className="h-4 mb-2 mx-8 bg-emerald-600/10 rounded-lg dark:bg-emerald-400/10" />
        <div className="h-4 mb-2 mx-8 bg-emerald-600/10 rounded-lg dark:bg-emerald-400/10" />
        <div className="h-4 mb-2 mx-8 bg-emerald-600/10 rounded-lg dark:bg-emerald-400/10" />
        <div className="h-4 mb-2 mr-16 mx-8 bg-emerald-600/10 rounded-lg dark:bg-emerald-400/10" />
        <div
          className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent
        dark:from-zinc-900 dark:to-transparent"
        ></div>{" "}
      </div>
      {renderNavigationButton && (
        <AiDocumentPreviewDialog
          aiArticleMetadata={aiArticleMetadata}
          aiArticleMarkdown={aiArticleMarkdown}
          onNavigateToPreview={onNavigateToPreview}
        />
      )}
    </div>
  );
};
