import { useMutation } from "react-query";

import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";

import { TbSparkles } from "react-icons/tb";
import { Template } from "../types";

export const createAiTool = (data: Partial<Template>): Promise<Template> => {
  return axios.post(`/createTemplate`, data);
};

type UseCreateAiToolOptions = {
  config?: MutationConfig<typeof createAiTool>;
  notifyOnSuccess?: boolean;
};

export const useCreateAiTool = ({
  config,
  notifyOnSuccess = true,
}: UseCreateAiToolOptions = {}) => {
  const { addNotification } = useNotificationStore();

  const mutationKey = ["createAiTool"];

  return useMutation(mutationKey, {
    onMutate: async (newTool) => {
      await queryClient.cancelQueries("aiTools");

      const previousTools =
        queryClient.getQueryData<Template[]>("aiTools") || [];
      queryClient.setQueryData<Template[]>("aiTools", (oldQueryData) => {
        return [...(Array.isArray(oldQueryData) ? oldQueryData : []), newTool];
      });

      return { previousTools };
    },
    onSuccess: (newTool: Template) => {
      queryClient.invalidateQueries("aiTools");

      if (!notifyOnSuccess) return;

      addNotification({
        type: "success",
        title: "AI Tool created",
        message: (
          <span className="flex items-center">
            <TbSparkles className="w-3.5 h-3.5 fill-zinc-500" />
            <span className="ml-2 text-xs">
              {newTool.text[0].title || "Untitled"}
            </span>
          </span>
        ),
      });
    },
    ...config,
    mutationFn: createAiTool,
  });
};
