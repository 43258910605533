import { ArticleMetadata, Checkbox } from "@/components/Elements";
import { cn } from "@/utils/style";
import { useEffect, useState } from "react";

interface ArticleCardProps {
  title: string;
  description: string;
  assets: Array<SerpAssetProps>;
  url: string;
  rank: number;
  wordCount: number;
  links: number;
  domainAuthority: number;
  className?: string;
  onAssetClick?: (actionType: string) => void;
  selectionEnabled?: boolean;
  selectedAssets?: Record<string, any>;
  menuEnabled?: boolean;
  isCustomizing?: boolean;
  handleUpdateBlacklist?: (url: string, isSelected: boolean) => void;
  isResultSelected?: boolean;
  isCustomImport?: boolean;
  isProcessed?: boolean;
}

interface SerpAssetProps {
  header: string;
  html: Array<string>;
  header_tag: string;
  text: Array<string>;
}

const SearchResultCardHeader = ({ title }) => (
  <h3 className="mt-1 text-sm font-medium text-zinc-900 dark:text-zinc-50">
    {title}
  </h3>
);

const SearchResultCardDescription = ({ description }) => (
  <p className="mt-1 text-2xs text-zinc-600 dark:text-zinc-400 mr-4">
    {description}
  </p>
);

export const SearchResultCard = ({
  title,
  description,
  assets,
  url,
  rank,
  wordCount,
  links,
  domainAuthority,
  className,
  isCustomizing,
  handleUpdateBlacklist,
  isResultSelected,
  isCustomImport,
  isProcessed,
}: ArticleCardProps) => {
  const urlHost = url ? new URL(url).hostname.replace("www.", "") : "";
  const [isSelected, setIsSelected] = useState(isResultSelected);

  useEffect(() => {
    setIsSelected(isResultSelected);
  }, [isResultSelected]);

  return (
    <li
      className={cn(
        "relative flex w-full first-of-type:mt-2 group border rounded-md shadow-sm mb-2 dark:border-zinc-700 dark:text-white",
        className
      )}
    >
      <div className="relative flex flex-col py-3 pl-4 w-full">
        <div className="flex items-center">
          {isCustomizing && (
            <Checkbox
              className="mr-2"
              checked={isSelected}
              onCheckedChange={() => {
                setIsSelected(!isSelected);
                handleUpdateBlacklist && handleUpdateBlacklist(url, isSelected);
              }}
            />
          )}
          <ArticleMetadata
            isCustomImport={isCustomImport}
            url={url}
            urlHost={urlHost}
            rank={rank}
            wordCount={wordCount}
            links={links}
            domainAuthority={domainAuthority}
            isProcessed={isProcessed}
          />
        </div>
        <SearchResultCardHeader title={title} />
        <SearchResultCardDescription description={description} />
      </div>
    </li>
  );
};
