import { useMutation, useQueryClient } from "react-query";

import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";

export const deleteDocument = ({ documentId }: { documentId: string }) => {
  return axios.post(`/archiveDocument`, {
    doc_id: documentId,
  });
};

type UseDeleteDocumentOptions = {
  config?: MutationConfig<typeof deleteDocument>;
  notifyOnSuccess?: boolean;
};

export const useDeleteDocument = ({
  config,
  notifyOnSuccess = true,
}: UseDeleteDocumentOptions) => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteDocument,
    onMutate: async ({ documentId }: { documentId: string }) => {
      await queryClient.cancelQueries(["documents"], { exact: false });

      if (!documentId) return;

      return { documentId };
    },
    onError: () => {
      addNotification({
        type: "error",
        title: "Failed to delete document",
        message: "Please contact support for help with this issue.",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("documents", { exact: false });

      if (!notifyOnSuccess) return;

      addNotification({
        type: "success",
        title: "Document deleted",
        message: "Document has been successfully deleted.",
      });
    },
    ...config,
  });
};
