import { useEditorStore } from "@/stores/editor";
import { LinkNode } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { HeadingNode } from "@lexical/rich-text";
import { $nodesOfType, ParagraphNode } from "lexical";
import { useMemo } from "react";
import { ImageNode } from "../../nodes/ImageNode";

const debounce = (
  func: { ({ editorState }: { editorState: any }): void; apply?: any },
  wait: number | undefined
) => {
  let timeout: string | number | NodeJS.Timeout | undefined;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export default function OnChangePlugin() {
  const [editor] = useLexicalComposerContext();
  const { setEditor } = useEditorStore();
  const debounceWaitTime = 500;
  const updateEditorState = useMemo(
    () =>
      debounce(({ editorState }) => {
        const headerNodes: string[] = [];
        const textNodes: string[] = [];
        const linkNodes: string[] = [];
        const imageNodes: string[] = [];

        editorState.read(() => {
          // Get heading nodes
          const headingNodes = $nodesOfType(HeadingNode);
          for (const node of headingNodes) {
            headerNodes.push(node.getTextContent());
          }

          // Get link nodes
          const links = $nodesOfType(LinkNode);
          for (const link of links) {
            linkNodes.push(link.getURL());
          }

          // Get image nodes
          const images = $nodesOfType(ImageNode);
          for (const image of images) {
            imageNodes.push(image.getSrc());
          }

          // Get text content
          const paragraphNodes = $nodesOfType(ParagraphNode);
          for (const paragraph of paragraphNodes) {
            textNodes.push(paragraph.getTextContent());
          }
        });

        setEditor((editorState) => {
          editorState.headerNodes = headerNodes;
          editorState.textNodes = textNodes;
          editorState.linkNodes = linkNodes;
          editorState.imageNodes = imageNodes;
        });
      }, debounceWaitTime),
    [setEditor]
  );

  editor.registerUpdateListener(updateEditorState);

  return null;
}
