import { useEffect, useState } from "react";
import { useSidebarNavigationStore } from "../../../components/Layout/SidebarToggle";
import { useDocumentStore } from "../../../stores/document";
import { useUpdateDocument } from "../../documents/api/updateDocument";
import { useGetGptArticle } from "../api/getGptArticle";
import { WizardFailMessage } from "./WizardFailMessage";
import { WizardGeneratingMessage } from "./WizardGeneratingMessage";
import { WizardSuccessMessage } from "./WizardSuccessMessage";

const useAiArticle = () => {
  const { document: fraseDocument } = useDocumentStore();
  return fraseDocument.metadata?.ai_article;
};

export const useIsAiArticle = () => {
  const aiArticle = useAiArticle();
  return Boolean(aiArticle);
};

export const useIsAiArticleAccepted = () => {
  const aiArticle = useAiArticle();
  return aiArticle?.generated === true && aiArticle?.accepted === true;
};

export const useIsAiArticleGenerated = () => {
  const aiArticle = useAiArticle();
  return aiArticle?.generated === true;
};

const useGptArticleQuery = (shouldFetch: Boolean) => {
  const { document: fraseDocument } = useDocumentStore();

  return useGetGptArticle({
    id: fraseDocument.metadata?.ai_article?.id,
    config: {
      enabled: shouldFetch,
    },
  });
};

const usePollGptArticle = () => {
  const isAiArticleGenerated = useIsAiArticleGenerated();
  const getGptArticleQuery = useGptArticleQuery(!isAiArticleGenerated);
  const [isGeneratingAiArticle, setIsGeneratingAiDraft] = useState(false);
  const [isAiArticleFailed, setIsAiArticleFailed] = useState(false);
  const { isSidebarOpen, closeSidebar, openSidebar } =
    useSidebarNavigationStore();

  const { handleUpdateAiArticleGenerated } = useUpdateAiArticle();

  useEffect(() => {
    const interval = setInterval(() => {
      if (isSidebarOpen || isAiArticleGenerated) {
        closeSidebar();
      }

      if (
        isGeneratingAiArticle &&
        getGptArticleQuery.data &&
        (getGptArticleQuery.data.status === "in-progress" ||
          getGptArticleQuery.data.status === "submitted")
      ) {
        getGptArticleQuery.refetch();
      }

      if (
        getGptArticleQuery.data &&
        getGptArticleQuery.data.status === "failed"
      ) {
        setIsAiArticleFailed(true);
        setIsGeneratingAiDraft(false);
      }

      if (
        getGptArticleQuery.data &&
        getGptArticleQuery.data.status === "completed" &&
        !isAiArticleGenerated
      ) {
        handleUpdateAiArticleGenerated(getGptArticleQuery.data.article);
        setIsGeneratingAiDraft(false);
        clearInterval(interval);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [isGeneratingAiArticle, getGptArticleQuery, isAiArticleGenerated]);

  return {
    isGeneratingAiArticle,
    setIsGeneratingAiDraft,
    isAiArticleFailed,
    progress: getGptArticleQuery.data?.progress,
    articleInput: getGptArticleQuery.data?.article_input,
  };
};

export const useUpdateAiArticle = () => {
  const { document: fraseDocument, setDocument } = useDocumentStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });

  const handleUpdateAiArticleGenerated = (articleData) => {
    const updatedDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        ai_article: {
          ...fraseDocument.metadata.ai_article,
          generated: true,
          accepted: false,
        },
      },
    };

    updateDocumentMutation.mutateAsync(updatedDocument);
  };

  return { handleUpdateAiArticleGenerated };
};

export const AiArticleLoader = () => {
  const { document: fraseDocument } = useDocumentStore();
  const {
    isGeneratingAiArticle,
    setIsGeneratingAiDraft,
    isAiArticleFailed,
    progress,
    articleInput,
  } = usePollGptArticle();
  const { closeSidebar, isSidebarOpen } = useSidebarNavigationStore();

  if (isSidebarOpen) {
    closeSidebar();
  }

  const isAiArticle = useIsAiArticle();
  const isAiArticleGenerated = useIsAiArticleGenerated();
  const isAiArticleAccepted = useIsAiArticleAccepted();
  const getGptArticleQuery = useGptArticleQuery(
    isAiArticleGenerated && !isAiArticleAccepted
  );
  const aiArticle = fraseDocument.metadata?.ai_article;

  useEffect(() => {
    if (isAiArticle) {
      if (isAiArticleGenerated) {
        setIsGeneratingAiDraft(false);
      } else {
        setIsGeneratingAiDraft(true);
      }
    }
  }, [fraseDocument.metadata, isAiArticle, isAiArticleGenerated]);

  if (isAiArticleFailed) {
    return <WizardFailMessage title={fraseDocument.metadata.name} />;
  }

  if (isGeneratingAiArticle && !isAiArticleGenerated) {
    return (
      <WizardGeneratingMessage
        fraseDocument={fraseDocument}
        title={articleInput?.title || fraseDocument.metadata.name}
        renderNavigationButton={false}
        progress={progress}
      />
    );
  }

  if (isAiArticleGenerated) {
    return (
      <WizardSuccessMessage
        title={fraseDocument.metadata.name}
        aiArticleMetadata={aiArticle}
        aiArticleMarkdown={getGptArticleQuery.data?.article}
      />
    );
  }

  return null;
};
