import { axios } from "@/lib/axios";
import { useQuery, UseQueryOptions } from "react-query";

export interface TemplateOutput {
  id: string;
  user_hash: string;
  template_hash: string;
  generation: string;
  prompt_map: {
    topic: string;
  };
  unix_timestamp: number;
}

const getAiToolOutputs = async (
  template_hash: string
): Promise<TemplateOutput[]> => {
  const response = await axios.post<TemplateOutput[]>(
    `/retrieveTemplateOutputs`,
    {
      template_hash: template_hash,
    }
  );

  //@ts-ignore
  return response;
};

type QueryFnType = typeof getAiToolOutputs;

interface UseGetAiToolOutputsOptions {
  template_hash: string;
  config?: UseQueryOptions<TemplateOutput[]>;
}

export const useGetAiToolOutputs = ({
  template_hash,
  config,
}: UseGetAiToolOutputsOptions) => {
  return useQuery<TemplateOutput[]>({
    ...config,
    queryKey: ["getAiToolOutputs", template_hash],
    queryFn: () => getAiToolOutputs(template_hash),
  });
};
