import { FraseDocument } from "@/features/documents";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import { useEditorStore } from "@/stores/editor";
import { $generateHtmlFromNodes } from "@lexical/html";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useDebounceCallback } from "@react-hook/debounce";
import { $getRoot, EditorState, type LexicalEditor } from "lexical";
import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import { getWordCountOfTextNode } from "../WordCountPlugin";

export let debouncedHandleOnChange: (editor: LexicalEditor) => void;

export function AutoSaveDocument({
  fraseDocument,
  isLinkEditMode,
}: {
  fraseDocument: FraseDocument;
  isLinkEditMode: boolean;
}) {
  const updateDocumentMutation = useUpdateDocument({ notifyOnSuccess: false });
  const { editor: editorStore, setEditor } = useEditorStore();
  const { activeTabIndex } = editorStore;

  debouncedHandleOnChange = useDebounceCallback(
    (editor: LexicalEditor) => {
      if (isLinkEditMode) return;

      editor.update(() => {
        const previousHtml = fraseDocument.text[activeTabIndex].html;
        const newHtml = $generateHtmlFromNodes(editor, null);

        if (!isEqual(newHtml, previousHtml)) {
          const updatedDocument = {
            ...fraseDocument,
            metadata: {
              ...fraseDocument.metadata,
              active_tab_index: activeTabIndex,
            },
            text: fraseDocument.text.map((text, index) =>
              index === activeTabIndex ? { ...text, html: newHtml } : text
            ),
          };

          updateDocumentMutation.mutate(updatedDocument);
        }

        const root = $getRoot();
        const text = root.getTextContent();
        const numWords = getWordCountOfTextNode(text);
        setEditor((editorState) => {
          editorState.wordCount = numWords;
        });
      });
    },
    500,
    false
  );

  const handleOnChange = useCallback(
    (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => {
      debouncedHandleOnChange(editor);
    },
    [debouncedHandleOnChange]
  );

  return useMemo(
    () => <OnChangePlugin onChange={handleOnChange} />,
    [handleOnChange]
  );
}
