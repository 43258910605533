import { Login } from "@/features/auth/routes/Login";
import { Register } from "@/features/auth/routes/Register";
import { ResetPassword } from "@/features/auth/routes/ResetPassword";
import { DocumentPreview } from "@/features/documents/routes/DocumentPreview";
import { Navigate, useLocation } from "react-router-dom";

interface NavigateWithQueryProps {
  to: string;
}

export function NavigateWithQuery({ to }: NavigateWithQueryProps) {
  let location = useLocation();
  return <Navigate to={to + location.search} replace />;
}

export const publicRoutes = [
  { path: "/register", element: <Register /> },
  { path: "/login", element: <Login /> },
  { path: "/resetpassword", element: <ResetPassword /> },
  {
    path: "/auth/register",
    element: <NavigateWithQuery to="/register" />,
  },
  {
    path: "/app/resetpassword",
    element: <NavigateWithQuery to="/resetpassword" />,
  },
  {
    path: "/app/documents/preview/:documentHash",
    element: <DocumentPreview />,
  },
  { path: "/auth/login", element: <NavigateWithQuery to="/login" /> },
  { path: "*", element: <NavigateWithQuery to="/login" /> },
];
