import { tiers } from "./tiers";

export const getBasePrice = (
  planNickname: string,
  isAppsumoUser: boolean,
  pricingPeriod: string
) => {
  if (isAppsumoUser) {
    return 0;
  }

  return (
    tiers.find((t) => t.name === planNickname)?.[pricingPeriod]?.price || 0
  );
};
