import { FraseDocument } from "@/features/documents";
import { cn } from "@/utils/style";
import {
  CalendarDaysIcon,
  CalendarIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { add, format } from "date-fns";
import React, { useState } from "react";
import { TbX } from "react-icons/tb";
import { Button } from "../Button";
import { Calendar } from "../Calendar";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandShortcut,
} from "../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";

export const AddTargetDate = () => {
  return (
    <span className="inline-flex items-center rounded-full text-2xs text-zinc-400 dark:text-zinc-600 hover:text-zinc-900 dark:hover:text-white">
      <PlusIcon className="mr-2 h-3 w-3" />
      Date
    </span>
  );
};

export const TargetDateHeader = () => {
  return <p className="ml-2 text-xs">Deadline</p>;
};

export const TargetDateCell = ({
  document,
  onFieldUpdate,
}: {
  document: FraseDocument;
  onFieldUpdate: (
    updates: { field: string; value: any }[],
    data: FraseDocument | FraseDocument[]
  ) => void;
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleDateChange = (date: Date | null) => {
    const updates = [{ field: "doc_target_date", value: date }];
    onFieldUpdate(updates, document);
  };

  const relativeDateOptions = [
    {
      icon: <CalendarIcon />,
      label: "Today",
      value: new Date(),
      onClick: () => {
        handleDateChange(new Date());
      },
    },
    {
      icon: <CalendarIcon />,
      label: "Tomorrow",
      value: add(new Date(), { days: 1 }),
      onClick: () => {
        handleDateChange(add(new Date(), { days: 1 }));
      },
    },
    {
      icon: <CalendarIcon />,
      label: "Next week",
      value: add(new Date(), { weeks: 1 }),
      onClick: () => {
        handleDateChange(add(new Date(), { weeks: 1 }));
      },
    },
    {
      icon: <CalendarIcon />,
      label: "In two weeks",
      value: add(new Date(), { weeks: 2 }),
      onClick: () => {
        handleDateChange(add(new Date(), { weeks: 2 }));
      },
    },
    {
      icon: <CalendarDaysIcon />,
      label: "Custom...",
      value: "custom",
      onClick: () => {
        setShowCalendar(true);
      },
    },
  ];

  const removeDateOption = {
    icon: <TbX />,
    label: "Remove date",
    value: "remove",
    onClick: () => {
      handleDateChange(null);
    },
  };

  if (document.doc_target_date) {
    relativeDateOptions.unshift(removeDateOption);
  }

  return (
    <Popover
      open={popoverOpen}
      onOpenChange={(open) => {
        setShowCalendar(false);
        setPopoverOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant={"buttonNode"}
          className="px-2 py-1"
          textClassName="text-2xs truncate font-normal text-zinc-600 dark:text-zinc-400"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setPopoverOpen(!popoverOpen);
          }}
        >
          {document.doc_target_date ? (
            <span
              className={cn(
                "flex items-center",
                new Date(document.doc_target_date) < new Date() &&
                  "text-red-500 dark:text-red-400 font-medium"
              )}
            >
              {format(new Date(document.doc_target_date), "MMM d, yyyy")}
            </span>
          ) : (
            <AddTargetDate />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onBlur={() => {
          if (!showCalendar) {
            setPopoverOpen(false);
          }
        }}
      >
        {!showCalendar && (
          <Command>
            <CommandGroup
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {relativeDateOptions.map((option, optionIndex) => (
                <CommandItem
                  key={optionIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onSelect={() => {
                    option.onClick();
                    if (option.value !== "custom") {
                      setPopoverOpen(false);
                    }
                  }}
                  className={cn(
                    "w-48",
                    option.value === "remove" &&
                      "text-red-600 dark:text-red-400"
                  )}
                >
                  {React.cloneElement(option.icon, {
                    className: cn(
                      "mr-2 h-4 w-4",
                      option.value === document.doc_target_date &&
                        "text-zinc-500 dark:text-zinc-400"
                    ),
                  })}
                  {option.label}
                  <CommandShortcut>
                    {option.value instanceof Date &&
                      format(option.value as Date, "MMM d")}
                  </CommandShortcut>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        )}
        {showCalendar && (
          <Calendar
            mode="single"
            selected={new Date()}
            onSelect={handleDateChange}
            onDayClick={(
              day,
              { selected, disabled, selectable, dayStart, dayEnd },
              e
            ) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            initialFocus
          />
        )}
      </PopoverContent>
    </Popover>
  );
};
