import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { useRestoreSubscription } from "@/features/subscription/api/restorePlan";
import dayjs from "dayjs";
import { useState } from "react";

export const SubscriptionPausedOverlay: React.FC<{ renewalDate?: string }> = ({
  renewalDate,
}) => {
  const [open, setOpen] = useState(true);
  const { mutate: restoreSubscription, isLoading: isRestoring } =
    useRestoreSubscription();

  const close = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          close();
        }
      }}
    >
      <DialogContent className="p-0">
        <div className="h-full w-full">
          <div className="flex flex-col justify-center pt-4">
            <DialogCloseButton
              className={`absolute top-4 right-4`}
              close={close}
            />
          </div>
          <div>
            <DialogHeader className="pb-8 flex items-center space-x-4 pl-6">
              <DialogTitle className="text-2xl text-zinc-700">
                Subscription paused
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="px-6 pb-8">
              <div className="flex flex-col space-y-4">
                <p className="text-sm font-medium text-zinc-700 dark:text-white">
                  Your subscription is currently paused. During this period, you
                  may only access settings.
                </p>
                {renewalDate && (
                  <p className="mt-4 text-sm text-zinc-600 dark:text-zinc-200">
                    Your subscription will automatically renew on{" "}
                    <span className="font-medium">
                      {dayjs(renewalDate).format("MMM DD, YYYY")}
                    </span>
                    .
                  </p>
                )}
              </div>
            </DialogDescription>
            <DialogFooter className="px-6 pb-4">
              <Button
                variant="outlineBlur"
                onClick={() => restoreSubscription()}
                size="xs"
                disabled={isRestoring}
                isLoading={isRestoring}
                className="w-fit"
              >
                Restore Subscription
              </Button>
            </DialogFooter>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
