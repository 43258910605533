import { cn } from "@/utils/style";

interface StatusBadgeProps {
  children: React.ReactNode;
  color?: string;
  className?: string;
}

const colorMap: { [key: string]: string } = {
  "#64748b": "#1e293b", // slate-500 to slate-900
  "#bbf7d0": "#047857", // green-200 to green-900
  "#bae6fd": "#0369a1", // sky-200 to sky-900
  "#e4e4e7": "#27272a", // zinc-300 to zinc-900
  "#fecaca": "#991b1b", // red-200 to red-700
  "#fef08a": "#713f12", // yellow-200 to yellow-900
  "#e0e7ff": "#312e811", // indigo-100 to indigo-900
};

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  children,
  color,
  className,
}) => {
  return (
    <span
      className={cn(
        "inline-flex items-center rounded-md px-1 text-2xs font-medium",
        "transition-colors duration-200",
        className
      )}
      style={{
        backgroundColor: color,
        color: color ? colorMap[color] : undefined,
      }}
    >
      {typeof children === "string"
        ? children[0].toUpperCase() + children.slice(1)
        : children}
    </span>
  );
};
