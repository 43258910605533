import { useUser } from "@/features/auth";
import { useState } from "react";

import { DocumentTableSkeleton } from "@/components/Elements/Skeleton/DocumentTableSkeleton";
import { TemplateTable } from "@/components/Elements/TemplateTable";
import { useTemplatesForOrg } from "../api/getTemplates";
import { getNameFilters } from "./NameFilters";

const useFilterSettings = ({}) => [...getNameFilters()];

export const TemplatesList = ({ getArchived }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { data: user, isLoading: isUserLoading } = useUser();

  const templatesQuery = useTemplatesForOrg({
    org_id: user?.orgId.toString() || "",
  });

  if (isUserLoading || templatesQuery.isLoading)
    return (
      <DocumentTableSkeleton rows={50} columns={6} className="w-full h-full" />
    );

  const filters = useFilterSettings({ usersQuery: templatesQuery });

  return renderTemplateTable({
    templatesQuery,
    filters,
    selectedFilters,
    setSelectedFilters,
    getArchived,
  });
};

const renderTemplateTable = ({
  templatesQuery,
  filters,
  selectedFilters,
  setSelectedFilters,
  getArchived,
}) => {
  return (
    <TemplateTable
      templates={templatesQuery.data || []}
      templatesCount={templatesQuery.data.length || 0}
      filters={filters}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      getArchived={getArchived}
      isTemplatesLoading={templatesQuery.isLoading}
    />
  );
};
