"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";
import { cn } from "../../../utils/style";

// Define a mapping of sizes to height, width, and thumb dimensions
const sizeVariants = {
  xs: { height: "h-3", width: "w-5", thumbSize: "h-2 w-2" },
  sm: { height: "h-4", width: "w-7", thumbSize: "h-3 w-3" },
  md: { height: "h-5", width: "w-8", thumbSize: "h-4 w-4" },
  lg: { height: "h-6", width: "w-9", thumbSize: "h-5 w-5" },
  // Add more sizes as needed
};

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    size?: keyof typeof sizeVariants;
  }
>(({ className, size = "md", ...props }, ref) => {
  // Get the height, width, and thumb size classes based on the size prop
  const { height, width, thumbSize } = sizeVariants[size];

  return (
    <SwitchPrimitives.Root
      className={cn(
        `peer inline-flex ${height} ${width} shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50`,
        {
          "bg-zinc-200": !props.checked,
          "bg-emerald-600/80": props.checked,
          "dark:bg-zinc-700": !props.checked && props.dark,
          "dark:bg-emerald-700/80": props.checked && props.dark,
        },
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          `pointer-events-none block ${thumbSize} rounded-full bg-white dark:bg-zinc-800 shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-full data-[state=unchecked]:translate-x-0`
        )}
      />
    </SwitchPrimitives.Root>
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
