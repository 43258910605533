import { tiers } from "./tiers";

export const getPricingPeriod = (planStripeName: string) => {
  for (const tier of tiers) {
    if (
      tier.monthly.stripeName === planStripeName ||
      tier.monthly.lifetimePlan === planStripeName
    ) {
      return "monthly";
    } else if (
      tier.yearly.stripeName === planStripeName ||
      tier.yearly.lifetimePlan === planStripeName
    ) {
      return "yearly";
    }
  }
  return null; // or a default value if needed
};
