import { axios } from "../../../lib/axios";
import { UserResponse } from "../types";

export type RegisterCredentialsDTO = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  signUpSource?: string;
  timezoneOffset?: number;
  mobileRegistration?: string;
  googleRegistration?: string;
};

export const registerWithEmailAndPassword = (
  data: RegisterCredentialsDTO
): Promise<UserResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      // Construct the query parameters
      const queryParams = new URLSearchParams({
        email: data.email,
        password: data.password,
        name: `${data.firstName} ${data.lastName}`,
        signUpSource: data.signUpSource || "",
        timezoneOffset: data.timezoneOffset?.toString() || "",
        mobile_registration: data.mobileRegistration || "",
        googleRegistration: data.googleRegistration || "",
      }).toString();

      // Register the user with query parameters
      const response = await axios.post(`/register?${queryParams}`);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
