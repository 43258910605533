export const WizardHeader = ({
  query,
  articles,
  isLoadingSerp,
  currentStep,
}: {
  query: string;
  articles: any;
  isLoadingSerp: boolean;
  currentStep: number;
}) => (
  <div className="flex items-center w-full">
    {query.length > 0 && articles?.length > 0 && (
      <div className="flex items-center text-zinc-500 dark:text-zinc-600 text-xs px-4">
        <p className="border-r px-2 bg-zinc-100 border rounded-l-md py-0.5 dark:bg-zinc-800 dark:text-zinc-300 dark:border-zinc-700  whitespace-nowrap">
          Search Query
        </p>
        <p className="font-medium px-2 border-r border-t border-b rounded-r-md py-0.5 text-zinc-800 dark:text-zinc-200 dark:border-zinc-700 whitespace-nowrap">
          {query}
        </p>
      </div>
    )}
  </div>
);
