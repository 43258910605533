import { DocumentIcon } from "@heroicons/react/24/solid";

export const getNameFilters = () => {
  return [
    {
      value: "metadata_name",
      label: "Name",
      icon: <DocumentIcon />,
      parent: "metadata_name",
      type: "input",
    },
  ];
};
