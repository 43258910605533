import { create } from "zustand";

import { Button } from "@/components/Elements";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

export const useSidebarNavigationStore = create((set) => ({
  isSidebarOpen: false,
  openSidebar: () => set({ isSidebarOpen: true }),
  closeSidebar: () => set({ isSidebarOpen: false }),
  toggleSidebar: () =>
    set((state) => ({ isSidebarOpen: !state.isSidebarOpen })),
}));

export const useMobileSidebarNavigationStore = create((set) => ({
  isMobileSidebarOpen: false,
  openMobileSidebar: () => set({ isMobileSidebarOpen: true }),
  closeMobileSidebar: () => set({ isMobileSidebarOpen: false }),
  toggleMobileSidebar: () =>
    set((state) => ({ isMobileSidebarOpen: !state.isMobileSidebarOpen })),
}));

export function SidebarToggle() {
  let { toggleSidebar, isSidebarOpen } = useSidebarNavigationStore();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isSidebarOpen) {
      queryClient.cancelQueries(["googleSearchResults"], { exact: false });
      queryClient.cancelQueries(["processUrlBatch"], { exact: false });
      queryClient.invalidateQueries(["googleSearchResults"], { exact: false });
      queryClient.invalidateQueries(["processUrlBatch"], { exact: false });
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    function onKeyDown(event) {
      if (
        event.target.tagName === "INPUT" ||
        event.target.tagName === "TEXTAREA"
      ) {
        return;
      }
      if (event.target.isContentEditable) {
        return;
      }

      if (event.key === "]") {
        event.preventDefault();
        toggleSidebar();
      }
    }

    if (!isSidebarOpen) {
      (async () => {
        await queryClient.cancelQueries(["googleSearchResults"], {
          exact: false,
        });
        await queryClient.cancelQueries(["processUrlBatch"], { exact: false });
        queryClient.removeQueries(["googleSearchResults"], { exact: false });
        queryClient.removeQueries(["processUrlBatch"], { exact: false });
      })();
    }

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [isSidebarOpen, toggleSidebar, queryClient]);

  return (
    <Button
      variant="buttonIcon"
      aria-label="Toggle navigation"
      tooltipContent={
        isSidebarOpen ? "Close document tools" : "Open document tools"
      }
      className="ml-4 flex-shrink-0"
      tooltipModifierKey="]"
      onClick={() => {
        toggleSidebar();
      }}
      buttonIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"
            stroke="currentColor"
          ></path>
          <path d="M15 4l0 16" stroke="currentColor"></path>
        </svg>
      }
      size="lg"
    />
  );
}
