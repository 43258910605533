import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";
import { ExtractFnReturnType, QueryConfig } from "../../../lib/react-query";

export interface SearchQueriesData {
  current_month: any[];
  previous_month: any[];
}

export const getSearchQueriesForUser = (): Promise<SearchQueriesData> => {
  return axios.post("/getSearchQueriesForUser", {});
};

type QueryFnType = typeof getSearchQueriesForUser;

export const useGetSearchQueriesForUser = ({
  config,
}: {
  config?: QueryConfig<QueryFnType>;
} = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["searchQueriesForUser"],
    queryFn: () => getSearchQueriesForUser(),
  });
};
