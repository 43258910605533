import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { MegaphoneIcon } from "@heroicons/react/24/solid";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";
import { CommandItem } from "../../../components/Elements";

export const ShareFeedbackButton = ({
  triggerButton,
}: {
  triggerButton?: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  const loadFeedbackForm = () => {
    if (window.hbspt) {
      // Load HubSpot form
      window.hbspt.forms.create({
        region: "na1",
        portalId: "6359064",
        formId: "0e151cf4-ed9b-4f9e-802e-891bff353988",
        target: "#feedback-form",
      });
    } else {
      // If hbspt is not available, load the HubSpot script
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "6359064",
            formId: "0e151cf4-ed9b-4f9e-802e-891bff353988",
            target: "#feedback-form",
          });
        }
      });
    }
  };

  const dialogTriggerButton = triggerButton || (
    <CommandItem
      className="w-full"
      onSelect={() => {
        setOpen(true);
        loadFeedbackForm();
      }}
    >
      <MegaphoneIcon className="w-3 h-3 mr-2.5" />
      Share Feedback
    </CommandItem>
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{dialogTriggerButton}</DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Share feedback</DialogTitle>
            <DialogCloseButton close={() => setOpen(false)} />
          </DialogHeader>
          <DialogDescription className="px-4">
            <p className="text-sm text-zinc-600 dark:text-zinc-400 mb-4">
              We look forward to receiving your feedback as we continue to build
              Frase!
            </p>
            <div id="feedback-form"></div>
          </DialogDescription>
          <DialogFooter className="px-4"></DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
