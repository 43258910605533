import { Button, DialogModal } from "@/components/Elements";
import { getTopicHeatmapForSERP } from "@/features/documents/utils/topics";
import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { useTopicsStore } from "@/stores/topics";

import { useEffect, useState } from "react";
import TopicHeatmapTable from "./HeatmapTable";

const TopicHeatmap = ({ panelWidth }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { document } = useDocumentStore();
  const { serp } = useSerpStore();
  const { topics: topicsStore } = useTopicsStore();
  const { topics: userTopics } = topicsStore[document.id] || {
    topics: [],
  };
  const { topics, articles, clusters } = serp[document.id] || {
    articles: [],
    clusters: [],
    topics: [],
  };
  const [filteredSeries, setFilteredSeries] = useState([]);
  const [heatmapArticles, setHeatmapArticles] = useState([]);

  useEffect(() => {
    const { series, articles: heatmapArticles } = getTopicHeatmapForSERP(
      articles,
      topics,
      userTopics
    );
    const filteredSeries = series.filter((s) => {
      return s.long_tail;
    });
    setFilteredSeries(filteredSeries);
    setHeatmapArticles(heatmapArticles);
  }, [articles, clusters]);

  return (
    <div className="h-full">
      <Button
        variant="outlineBlur"
        size="2xs"
        // align to bottom of the container
        className="self-end"
        onClick={() => {
          setDialogOpen(true);
        }}
        tooltipContent="Open in full screen"
      >
        Explore Topics
      </Button>
      <DialogModal
        open={dialogOpen}
        setOpen={setDialogOpen}
        panelWidth={panelWidth}
        title="Explore Topics"
        variant="fullScreen"
        className="h-full w-full"
        contentClassName="px-0"
      >
        <TopicHeatmapTable columns={heatmapArticles} rows={filteredSeries} />
      </DialogModal>
    </div>
  );
};

export default TopicHeatmap;
