import { useMutation } from "react-query";

import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";

import { FolderIcon } from "@heroicons/react/24/solid";
import { Folder } from "../types";

export const deleteFolder = (id: string): Promise<void> => {
  return axios.post(`/deleteTeam`, { teamId: id });
};

type UseDeleteFolderOptions = {
  config?: MutationConfig<typeof deleteFolder>;
};

export const useDeleteFolder = ({ config }: UseDeleteFolderOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (folderId) => {
      await queryClient.cancelQueries("channels");

      const previousFolders = queryClient.getQueryData<Folder[]>("channels");

      const folderToDelete = previousFolders?.find(
        (folder) => folder.id === folderId
      );

      queryClient.setQueryData(
        "channels",
        previousFolders?.filter((folder) => folder.id !== folderId)
      );

      return { previousFolders, folderToDelete };
    },
    onError: (_, __, context: any) => {
      if (context?.previousFolders) {
        queryClient.setQueryData("channels", context.previousFolders);
      }
    },
    onSuccess: (_, __, context: any) => {
      queryClient.invalidateQueries("channels");

      const folderId = context.folderToDelete.id;
      const folderName = context.folderToDelete.name;

      addNotification({
        type: "success",
        title: "Folder deleted",
        message: (
          <span className="flex items-center">
            <FolderIcon className="w-3.5 h-3.5 fill-zinc-500" />
            <span className="ml-2 text-xs">{folderName}</span>
          </span>
        ),
      });
      if (location.pathname.includes(`${folderId}`)) {
        window.location.href = "/";
      }
    },
    ...config,
    mutationFn: deleteFolder,
  });
};
