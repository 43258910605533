import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

import { useDocumentStore } from "@/stores/document";
import { FraseDocument } from "../types";

export const getDocumentByHash = ({
  documentHash,
}: {
  documentHash: string;
}): Promise<FraseDocument> => {
  return axios.post(`/getDocumentByHash`, {
    doc_hash: documentHash,
  });
};

type QueryFnType = typeof getDocumentByHash;

type UseDocumentOptions = {
  documentHash: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetDocumentByHash = ({
  documentHash,
  config,
}: UseDocumentOptions) => {
  const { setDocument } = useDocumentStore();

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["document", documentHash],
    queryFn: () => getDocumentByHash({ documentHash }),
    onSuccess: (document) => {
      setDocument(document);
    },
  });
};
