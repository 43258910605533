import { Template } from "@/features/templates";
import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";

export const getAiToolByHash = ({
  hash,
}: {
  hash: string;
}): Promise<Template> => {
  return axios.post(`/getTemplateForHash`, {
    hash: hash,
  });
};

type QueryFnType = typeof getAiToolByHash;

type UseAiToolOptions = {
  hash: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetAiToolByHash = ({ hash, config }: UseAiToolOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["getAiToolByHash", hash],
    queryFn: () => getAiToolByHash({ hash }),
  });
};
