import { GoogleSearchResult } from '../api/getGoogleSearchResults';

export const cleanUrl = (url: string) => {
  if (typeof url !== 'string') {
    console.error('cleanUrl: url parameter is not a string:', url);
    return '';
  }

  // Remove query parameters
  url = url.split('?')[0];

  // Remove protocol (http or https)
  url = url.replace(/(http|https):\/\//, '');

  // Remove 'www.' prefix
  url = url.replace(/www\./, '');

  // Remove trailing slash
  url = url.replace(/\/$/, '');

  return url;
};

export const getHostname = (url) => {
  // Remove protocol (http or https) and www prefix from url
  url = cleanUrl(url);

  try {
    return new URL(`http://${url}`).hostname;
  } catch (e) {
    return url;
  }
};

export const dedupeUrls = (results: GoogleSearchResult) => {
  const dedupedResults = results.filter((result, index, self) => {
    return self.findIndex((r) => r.url === result.url) === index;
  });
  return dedupedResults;
};
