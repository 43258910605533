import logo_dark from "@/assets/logo_color_dark.svg";
import logo_light from "@/assets/logo_color_light.svg";
import { cn } from "@/utils/style";

const sizes = {
  sm: "h-6 w-auto",
  md: "h-8 w-auto",
  lg: "h-10 w-auto",
  xl: "h-12 w-auto",
};

type LogoProps = {
  renderText: boolean;
  renderLogo?: boolean;
  size?: keyof typeof sizes;
  className?: string;
  imgClassName?: string;
  textClassName?: string;
  url?: string;
};

export function Logo(props: LogoProps) {
  const content = (
    <>
      {props.renderLogo && (
        <>
          <img
            className={cn(
              "w-auto dark:hidden",
              props.size && sizes[props.size],
              props.imgClassName
            )}
            src={logo_dark}
            alt="Workflow"
          />
          <img
            className={cn(
              "w-auto hidden dark:block",
              props.size && sizes[props.size],
              props.imgClassName
            )}
            src={logo_light}
            alt="Workflow"
          />
        </>
      )}
      {props.renderText && (
        <span
          className={cn(
            "text-2xl text-zinc-700 dark:text-white font-semibold ml-2",
            props.textClassName
          )}
        >
          frase
        </span>
      )}
    </>
  );

  return props.url ? (
    <a
      className={cn(
        "flex flex-shrink-0 items-center justify-center text-zinc-700 dark:text-white",
        props.className
      )}
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {content}
    </a>
  ) : (
    <div
      className={cn(
        "flex flex-shrink-0 items-center justify-center text-zinc-700 dark:text-white",
        props.className
      )}
    >
      {content}
    </div>
  );
}

Logo.defaultProps = {
  renderText: true,
  renderLogo: true,
  size: "md" as keyof typeof sizes,
};
