import clsx from "clsx";
import { UseFormRegisterReturn } from "react-hook-form";

import { Input } from "../Elements/Input";
import { FieldWrapper, FieldWrapperPassThroughProps } from "./FieldWrapper";

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: "text" | "email" | "password";
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = "text",
    id,
    label,
    className,
    registration,
    error,
    name,
    required,
    placeholder,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <Input
        id={id}
        name={name}
        type={type}
        autoComplete={type}
        required={required}
        placeholder={placeholder}
        className={clsx(
          "block h-8 w-full items-center gap-2 rounded-full bg-white pl-4 pr-3 text-sm text-zinc-500 dark:text-zinc-900 font-normal border-zinc-900/10 hover:border-zinc-900/20 focus:border-zinc-900/20 focus:outline-none focus:ring-zinc-900/10",
          className
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
