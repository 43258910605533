import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { TrashIcon } from "@heroicons/react/24/outline";
import { DialogClose, DialogPortal } from "@radix-ui/react-dialog";
import React, { useState } from "react";
import { useRemovePendingUser } from "../../teams/api/removePendingUser";
import { useRemoveUser } from "../../teams/api/removeUser";

interface ConfirmRemoveUserDialogProps {
  userName: string;
  userEmail?: string;
  userId?: number;
  orgId?: number;
  isPending: boolean;
}

const ConfirmRemoveUserDialog: React.FC<ConfirmRemoveUserDialogProps> = ({
  userName,
  userEmail,
  userId,
  orgId,
  isPending,
}) => {
  const [open, setOpen] = useState(false);
  const removePendingUserMutation = useRemovePendingUser();
  const removeUserMutation = useRemoveUser();
  const handleRemove = async () => {
    if (isPending && userId && orgId) {
      await removePendingUserMutation.mutateAsync({ userId, orgId });
    } else if (userEmail) {
      await removeUserMutation.mutateAsync({ userEmail });
    }
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="buttonIcon" buttonIcon={<TrashIcon />} />
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Remove user</DialogTitle>
            <DialogCloseButton
              close={() => {
                setOpen(false);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4">
            Are you sure you want to remove{" "}
            <span className="font-medium">{userName}?</span>
            <br />
            <br />
            <strong>Warning:</strong> All documents associated with this user
            will be archived under your account for 30 days.
          </DialogDescription>
          <DialogFooter className="px-4 pb-4">
            <DialogClose asChild>
              <Button variant="outlineBlur">Cancel</Button>
            </DialogClose>
            <Button
              variant="destructiveBlur"
              onClick={handleRemove}
              isLoading={
                removePendingUserMutation.isLoading ||
                removeUserMutation.isLoading
              }
            >
              Remove
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default ConfirmRemoveUserDialog;
