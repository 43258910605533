import { User } from "@/features/users";
import { axios } from "@/lib/axios";
import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import { AuthUser } from "../types";

export const getUser = async (): Promise<AuthUser> => {
  const data = await axios.get("/api/testJwtToken");
  return data;
};

export const useUser = () => {
  return useQuery("user", getUser, {
    onSuccess: (data: User) => {
      Sentry.setUser({
        id: data.id.toString(),
        username: data.username,
        email: data.username,
      });
    },
  });
};
