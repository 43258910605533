import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { processRedditResults } from "../utils/questions";

export interface RedditSearchQuery {
  query: string;
  count: number;
}

export interface RedditSearchResult {
  author: string;
  created_utc: number;
  title: string;
  url: string;
}

type QueryKey = string;

export type RedditSearchResults = {
  [key in QueryKey]: RedditSearchResult[];
};

export const getRedditSearchResults = async ({
  queries,
}: {
  queries: RedditSearchQuery[];
}): Promise<RedditSearchResults> => {
  const requests = queries.map((query) => {
    const url = new URL("https://www.reddit.com/search.json");
    url.searchParams.append("q", query.query);
    url.searchParams.append("limit", query.count.toString());
    return fetch(url.toString()).then((response) => response.json());
  });

  try {
    const responses = await Promise.all(requests);
    return responses.reduce((acc, response, index) => {
      if (response.data && response.data.children) {
        // Use processRedditResults to process the response
        acc[queries[index].query] = processRedditResults(
          response.data.children,
          queries[index].query
        );
      }
      return acc;
    }, {} as RedditSearchResults);
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching reddit search results");
  }
};

type QueryFnType = typeof getRedditSearchResults;

type UseRedditSearchResultsOptions = {
  query?: string;
  count?: number;
  config?: QueryConfig<QueryFnType>;
};

export const useRedditSearchResults = ({
  queries,
  config,
}: UseRedditSearchResultsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["serp", JSON.stringify(queries)],
    queryFn: () => getRedditSearchResults({ queries }),
  });
};
