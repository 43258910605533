import { TbExclamationCircle, TbReload } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Elements";

export const WizardFailMessage = ({ title }: { title?: string }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center space-y-4">
      <div className="flex items-center space-x-2 pt-16">
        <TbExclamationCircle className="text-red-600" />
        <p className="text-zinc-600 font-medium dark:text-zinc-300">
          Sorry, there was an issue generating your article. Please try again.
        </p>
      </div>
      <div className="flex flex-col border-l border-r border-t h-30 w-1/3 rounded-t overflow-hidden relative dark:border-zinc-700">
        {title && (
          <p className="font-semibold text-zinc-600 dark:text-zinc-400 p-4">
            {title}
          </p>
        )}
        <div className="h-4 mb-2 ml-8 mx-4 bg-red-600/10 rounded-md dark:bg-red-400/10" />
        <div className="h-4 mb-2 mx-4 bg-red-600/10 rounded-md dark:bg-red-400/10" />
        <div className="h-4 mb-2 mx-4 bg-red-600/10 rounded-md dark:bg-red-400/10" />
        <div className="h-4 mb-2 mr-16 mx-4 bg-red-600/10 rounded-md dark:bg-red-400/10" />
        <div
          className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent
        dark:from-zinc-900 dark:to-transparent"
        ></div>
      </div>
      <Button
        variant="primaryBlur"
        startIcon={<TbReload />}
        onClick={() => {
          navigate("/app/wizard", {
            state: { shouldPrepopulate: true },
          });
        }}
      >
        Try again
      </Button>
    </div>
  );
};
