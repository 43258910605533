import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation } from "react-query";

type RemoveUserPayload = { teamId: string; username: string };

export const removeUserFromFolder = ({
  teamId,
  username,
}: RemoveUserPayload): Promise<any> => {
  return axios.post(`/removeUserFromTeam`, { teamId, username });
};

type UseRemoveUserFromFolderOptions = {
  config?: MutationConfig<typeof removeUserFromFolder>;
};

export const useRemoveUserFromFolder = ({
  config,
}: UseRemoveUserFromFolderOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    mutationFn: removeUserFromFolder,
    onSuccess: () => {
      queryClient.invalidateQueries("folderInfo");
      addNotification({
        type: "success",
        title: "User Removed",
      });
    },
    ...config,
  });
};
