import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Elements/Select";
import { useSeoAnalyticsStore } from "@/features/seo-analytics/store";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { TbWorld } from "react-icons/tb";
import * as z from "zod";

interface SelectSiteDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

const formSchema = z.object({
  site: z.string().nonempty({ message: "Please select a site" }),
});

const SelectSiteDialog: React.FC<SelectSiteDialogProps> = ({
  open,
  onClose,
  onCancel,
}) => {
  const { sites, setAuthorizedSite } = useSeoAnalyticsStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      site: "",
    },
  });

  const handleSiteSelection = async (values: z.infer<typeof formSchema>) => {
    await setAuthorizedSite(values.site);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogContent className="sm:max-w-[425px] p-0">
        <DialogHeader className="px-6 pt-6 pb-0 flex items-center justify-between">
          <DialogTitle className="text-xl font-semibold text-zinc-900 dark:text-zinc-100 flex items-center">
            <TbWorld className="w-5 h-5 mr-2 text-emerald-600" />
            Select a Site
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 pt-2 text-sm text-zinc-600 dark:text-zinc-400">
          Choose the site you want to authorize for SEO Analytics.
        </DialogDescription>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSiteSelection)}
            className="space-y-4 px-6 pb-6"
          >
            <FormField
              control={form.control}
              name="site"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    Available Sites
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full border-zinc-300 dark:border-zinc-700 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100">
                        <SelectValue placeholder="Select a site" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="bg-white dark:bg-zinc-800">
                      {sites.map((site) => (
                        <SelectItem
                          key={site}
                          value={site}
                          className="text-zinc-900 dark:text-zinc-100"
                        >
                          {site}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <DialogFooter className="pt-4 pb-6 flex items-center justify-end space-x-2">
              <Button
                type="button"
                onClick={() => {
                  onCancel();
                  onClose();
                }}
                variant="outlineBlur"
                className="text-zinc-600 dark:text-zinc-400"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primaryBlur"
                className="bg-emerald-600 text-white"
              >
                Confirm Selection
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default SelectSiteDialog;
