import { FraseDocument } from "..";

export const getFraseDocumentTitle = (
  fraseDocument: FraseDocument,
  activeTabIndex: number
): string => {
  if (
    !fraseDocument ||
    !fraseDocument.text ||
    !Array.isArray(fraseDocument.text)
  ) {
    return "";
  }

  if (activeTabIndex < 0 || activeTabIndex >= fraseDocument.text.length) {
    return "";
  }

  const documentTitle = fraseDocument.text[activeTabIndex]?.title || "";

  return documentTitle;
};
