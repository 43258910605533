import { TbAlertCircle, TbCheck, TbCircleFilled } from "react-icons/tb";
import { Button, Spinner } from "..";
import { cn } from "../../../utils/style";
import { HeaderTag, headerMarginMap, valueColorMap } from "./OutlineAsset";

export const InstructionAsset = ({
  loading,
  heading,
  onClick,
  className,
  completed,
  showInstructions,
}: {
  loading: boolean;
  heading: {
    header: string;
    url: string;
    user_header_tag: string;
    instruction: string; // JSON array of strings
  };
  onClick: () => void;
  className?: string;
  completed: boolean;
  showInstructions: boolean;
}) => {
  const instructions = JSON.parse(heading.instruction || "[]");

  return (
    <div
      className={cn(
        `flex flex-col items-start space-y-2 group mt-2 px-2 py-2 text-2xs bg-white rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5`,
        headerMarginMap[
          heading.user_header_tag as keyof typeof headerMarginMap
        ],
        loading ? "cursor-not-allowed pointer-events-none" : "cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <HeaderTag
            headerType={heading.user_header_tag}
            color={
              valueColorMap[
                heading.user_header_tag as keyof typeof valueColorMap
              ]
            }
          />
          <span
            className={cn(
              "dark:text-zinc-50 ml-4 self-center font-normal text-xs"
            )}
          >
            {heading.header}
          </span>
        </div>
        {loading ? (
          <Spinner
            size="sm"
            className="flex-shrink-0 text-current ml-auto mr-2"
          />
        ) : completed ? (
          <Button
            variant="buttonIcon"
            size="md"
            tooltipContent="Instructions completed"
            buttonIcon={<TbCheck />}
          ></Button>
        ) : (
          <Button
            variant="buttonIcon"
            size="md"
            className=" text-zinc-400"
            tooltipContent="Instructions not completed"
            buttonIcon={<TbAlertCircle />}
          ></Button>
        )}
      </div>
      {showInstructions && instructions.length > 0 && (
        <div className="flex flex-col w-full space-y-1">
          {instructions.map((instruction: string, index: number) => (
            <div className="flex items-start w-full pl-7 space-x-4">
              <TbCircleFilled className="h-1.5 w-1.5 text-zinc-600 dark:text-zinc-600 mt-2.5" />
              <div
                key={index}
                className="text-xs text-gray-700 dark:text-gray-300 w-full text-left"
              >
                {instruction}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
