// src/features/documents/api/getDocumentCount.ts
import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";
import { ExtractFnReturnType, QueryConfig } from "../../../lib/react-query";

interface DocumentCount {
  total_doc_count: number;
  cur_month_doc_count: number;
}

export const getDocumentCount = (): Promise<DocumentCount> => {
  return axios.post("/getDocumentCount", {});
};

type QueryFnType = typeof getDocumentCount;

type UseDocumentCountOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useDocumentCount = ({ config }: UseDocumentCountOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["documentCount"],
    queryFn: getDocumentCount,
  });
};
