import { useUser } from "@/features/auth";
import { User } from "@/features/users";
import { useIsDarkMode } from "@/utils/isDarkMode";
import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";
import { canTrackUser } from "../utils/canTrackUser";
import { getIdentifyTraits } from "../utils/traits";

type TrackPagePayload = {
  pageName: string;
  properties: string;
  user: User;
  verified?: boolean;
  isDarkMode: boolean;
};

export const trackPage = ({
  pageName,
  properties,
  user,
  verified,
  isDarkMode,
}: TrackPagePayload): Promise<any> => {
  if (!canTrackUser(user?.username)) {
    return Promise.resolve();
  }

  if (verified === false) {
    return Promise.resolve();
  }

  if (!pageName) {
    return Promise.resolve();
  }

  if (!user?.id) {
    return Promise.resolve();
  }
  const traits = getIdentifyTraits(user);

  let propertiesObj;
  try {
    propertiesObj = { ...JSON.parse(properties || "{}"), email: traits.email };
  } catch (error) {
    propertiesObj = { ...traits };
  }
  propertiesObj.beta = true;
  propertiesObj.dark_mode = isDarkMode;
  const updatedProperties = JSON.stringify(propertiesObj);

  const payload = {
    name: pageName,
    properties: updatedProperties,
    userId: user.id.toString(),
  };

  return axios.post("/trackPage", payload);
};

export const useTrackPage = () => {
  const { data: user } = useUser();
  const isDarkMode = useIsDarkMode();

  return useMutation((payload: TrackPagePayload) => {
    const properties = JSON.stringify({
      ...(payload.properties && JSON.parse(payload.properties)),
      email: user?.username,
    });

    return trackPage({
      ...payload,
      properties,
      user: user as User,
      verified: user?.verified,
      isDarkMode,
    });
  });
};
