import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/Elements";
import { Input } from "@/components/Elements/Input";
import { Skeleton } from "@/components/Elements/Skeleton";
import { Navigation } from "@/components/Layout/MobileNavigation";
import { ShareMenu } from "@/features/editor/components/ShareMenu";
import { useDeleteTemplate } from "@/features/templates/api/deleteTemplate";
import { useUpdateTemplate } from "@/features/templates/api/updateTemplate";
import { cn } from "@/utils/style";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import {
  TbBookmark,
  TbBookmarkFilled,
  TbCheck,
  TbChevronRight,
  TbDots,
  TbEdit,
  TbPencil,
  TbTrashFilled,
  TbX,
} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteBookmarkedTemplate } from "../api/deleteAiToolBookmark";
import { useUpdateAiToolBookmark } from "../api/updateAiToolBookmark";
import { DraftBadge, ReadyBadge } from "./AiToolsGrid";

dayjs.extend(relativeTime);

const ToolMenu = ({
  aiTool,
  aiToolId,
  setIsEditing,
}: {
  aiTool: any;
  aiToolId: string;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<string | null>(
    aiTool?.metadata?.template_tags || null
  );
  const deleteTemplateMutation = useDeleteTemplate({});
  const updateTemplate = useUpdateTemplate({});
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTag(aiTool?.metadata?.template_tags || null);
  }, [aiTool]);

  const handleDelete = async () => {
    await deleteTemplateMutation
      .mutateAsync({ templateId: aiToolId })
      .then(() => {
        navigate("/app/ai-tools");
      });
  };

  const handleTagSelect = (tag: string) => {
    const newTag = selectedTag === tag ? null : tag;
    setSelectedTag(newTag);
    updateTemplate.mutate({
      ...aiTool,
      metadata: {
        ...aiTool.metadata,
        template_tags: newTag,
      },
    });
  };

  const tags = [
    "Advertising",
    "Blog",
    "Copywriting",
    "Email",
    "Rewrite",
    "SEO",
    "Social Media",
  ];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="buttonIcon"
            size="sm"
            buttonIcon={<TbDots />}
            tooltipContent="Tool options"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setIsEditing(true)}>
            <TbPencil className="mr-2" />
            <span>Rename tool</span>
          </DropdownMenuItem>
          {/*<DropdownMenuSub>
            <DropdownMenuSubTrigger className="DropdownMenuSubTrigger">
              <MdLabel className="mr-2" />
              Select tag
              <TbChevronRight className="ml-8" />
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent className="DropdownMenuSubContent">
                {tags.map((tag) => (
                  <DropdownMenuCheckboxItem
                    key={tag}
                    checked={selectedTag === tag}
                    onCheckedChange={() => handleTagSelect(tag)}
                  >
                    <DropdownMenuItemIndicator>
                      <CheckIcon />
                    </DropdownMenuItemIndicator>
                    {tag}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
                </DropdownMenuSub>*/}
          <DropdownMenuSeparator />
          <DialogTrigger asChild>
            <DropdownMenuItem className="text-red-500 dark:text-red-500">
              <TbTrashFilled className="mr-2" />
              <span>Delete tool</span>
            </DropdownMenuItem>
          </DialogTrigger>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent className="flex flex-col">
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitle>Delete tool</DialogTitle>
          <DialogCloseButton close={() => setOpen(false)} />
        </DialogHeader>
        <DialogDescription className="px-4">
          This action cannot be undone. Are you sure you want to permanently
          delete this tool?
        </DialogDescription>
        <DialogFooter className="px-4 pb-4">
          <Button
            variant="outlineBlur"
            type="button"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="destructiveBlur"
            type="button"
            onClick={handleDelete}
            isLoading={deleteTemplateMutation.isLoading}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

function ToolName({
  title,
  isEditing,
  newTitle,
  setNewTitle,
  handleSave,
  handleCancel,
  isLoading,
  setIsEditing,
  isOwner,
}: {
  title: string;
  isEditing: boolean;
  newTitle: string;
  setNewTitle: (title: string) => void;
  handleSave: () => void;
  handleCancel: () => void;
  isLoading: boolean;
  setIsEditing: (isEditing: boolean) => void;
  isOwner: boolean;
}) {
  if (isLoading) {
    return <Skeleton className="h-6 w-32" />;
  }

  return isEditing ? (
    <div className="flex items-center w-full space-x-2 min-w-0">
      <Input
        value={newTitle}
        onChange={(value) => setNewTitle(value)}
        onBlur={handleSave}
        autoFocus
        className="flex-grow min-w-0"
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSave();
          if (e.key === "Escape") handleCancel();
        }}
      />
      <Button
        size="sm"
        variant="buttonNodeIcon"
        onClick={handleSave}
        buttonIcon={<TbCheck />}
        className="ml-2 text-emerald-600 flex-shrink-0"
        disabled={newTitle.trim() === ""}
      ></Button>
      <Button
        size="sm"
        variant="buttonNodeIcon"
        onClick={handleCancel}
        buttonIcon={<TbX />}
        className="ml-2 text-red-500 flex-shrink-0"
      ></Button>
    </div>
  ) : (
    <div className="flex items-center  min-w-0 w-fit">
      <span
        className={`text-sm font-medium dark:text-white truncate pr-2 ${
          isOwner
            ? "hover:bg-zinc-100 cursor-text px-1 rounded-md hover:dark:bg-zinc-800"
            : ""
        }`}
        onClick={() => isOwner && setIsEditing(true)}
      >
        {title}
      </span>
    </div>
  );
}

export function AiToolHeader({
  showNavigation = true,
  lastSaved,
  aiToolHash,
  aiToolId,
  aiTool,
  isBookmarked: initialIsBookmarked,
  isOwner,
  handleBookmark,
  isLoading,
  isEditor,
  isEditMode,
  isPublished = false,
}: {
  showNavigation?: boolean;
  lastSaved?: string;
  aiToolHash?: string;
  aiToolId?: string;
  isBookmarked?: boolean;
  isOwner?: boolean;
  handleBookmark?: () => void;
  isLoading?: boolean;
  isEditor?: boolean;
  isEditMode?: boolean;
  aiTool?: any;
  isPublished?: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(initialIsBookmarked);
  const updateBookmark = useUpdateAiToolBookmark();
  const updateTemplate = useUpdateTemplate({});

  const [newTitle, setNewTitle] = useState(aiTool?.text?.[0]?.title || "");

  useEffect(() => {
    setNewTitle(aiTool?.text?.[0]?.title || "");
  }, [aiTool]);

  const handleSave = () => {
    if (newTitle.trim() === "") {
      setNewTitle(aiTool?.text?.[0]?.title || "");
      setIsEditing(false);
      return;
    }
    updateTemplate.mutate({
      ...aiTool,
      text: [{ ...aiTool?.text?.[0], title: newTitle }],
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setNewTitle(aiTool?.text?.[0]?.title || "");
    setIsEditing(false);
  };

  const handleBookmarkClick = () => {
    setIsBookmarked(!isBookmarked);
    if (isBookmarked) {
      deleteBookmarkedTemplate(aiToolHash!).catch(() => {
        setIsBookmarked(true);
      });
    } else {
      updateBookmark.mutate(aiToolHash!, {
        onError: () => {
          setIsBookmarked(false);
        },
      });
    }
    handleBookmark?.();
  };

  const handleShare = () => {
    const shareUrl = `${window.location.origin}/app/ai-tools/${aiToolId}`;
    navigator.clipboard.writeText(shareUrl);
    alert("Link copied to clipboard!");
  };

  return (
    <div className="flex flex-col h-fit bg-white dark:bg-zinc-900">
      <div className="flex justify-between items-center px-6 h-14 w-full border-b dark:border-zinc-700">
        <div className="flex items-center w-full">
          {showNavigation && <Navigation />}
          <div className="flex items-center space-x-1 flex-shrink-0">
            <Button
              variant="textNode"
              size="sm"
              onClick={() => navigate("/app/ai-tools")}
              className="text-zinc-900 dark:text-white"
              tooltipContent="Back to AI Tools"
            >
              AI Tools
            </Button>
            <TbChevronRight className="text-zinc-900 h-3 w-3 dark:text-zinc-400" />
          </div>

          <div className="flex items-center flex-grow min-w-0 ml-1.5">
            <ToolName
              title={aiTool?.text?.[0]?.title || ""}
              isEditing={isEditing}
              newTitle={newTitle || ""}
              setNewTitle={setNewTitle}
              handleSave={handleSave}
              handleCancel={handleCancel}
              isLoading={isLoading!}
              setIsEditing={setIsEditing}
              isOwner={false}
            />
            {isEditMode && (isPublished ? <ReadyBadge /> : <DraftBadge />)}
          </div>

          {!isEditing && (
            <div className="flex items-center space-x-2 flex-shrink-0">
              {!isEditMode && (
                <>
                  <ShareMenu type="AI Tool" />
                  <Button
                    variant="buttonNodeIcon"
                    size="sm"
                    onClick={handleBookmarkClick}
                    buttonIcon={
                      isBookmarked ? <TbBookmarkFilled /> : <TbBookmark />
                    }
                    className={cn(
                      "flex-shrink-0",
                      isBookmarked
                        ? "text-emerald-600 fill-emerald-600 bg-emerald-600 visible"
                        : "text-zinc-500 hover:text-zinc-900"
                    )}
                    tooltipContent={
                      isBookmarked ? "Remove bookmark" : "Bookmark tool"
                    }
                  ></Button>
                </>
              )}
              {!location.pathname.includes("new") && isOwner && (
                <Button
                  variant="buttonNodeIcon"
                  size="sm"
                  onClick={() => {
                    navigate(`/app/ai-tools/new/${aiToolId}`);
                  }}
                  className="flex-shrink-0 text-zinc-500 hover:text-zinc-900"
                  tooltipContent="Edit tool"
                  buttonIcon={<TbEdit />}
                ></Button>
              )}
              {isOwner && isEditMode && (
                <ToolMenu
                  aiToolId={aiToolId!}
                  setIsEditing={setIsEditing}
                  aiTool={aiTool}
                />
              )}

              {lastSaved && isEditMode && (
                <span className="text-xs text-zinc-500 dark:text-zinc-400 pl-2">
                  Last saved {dayjs(aiTool.updated_dt).fromNow()}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
