import { Button, Input } from "@/components/Elements";
import { useCreateFolder } from "@/features/documents/api/createFolder"; // Import the hook
import { cn } from "@/utils/style";
import { FolderIcon, PlusIcon } from "@heroicons/react/24/solid";

import { useState } from "react";

export const FolderInput = ({ className, containerClassName }) => {
  const [showInput, setShowInput] = useState(false);
  const [folderName, setFolderName] = useState("");
  const createFolderMutation = useCreateFolder({});

  const handleFolderUpdate = () => {
    if (folderName !== "") {
      createFolderMutation.mutate({ name: folderName });
      setShowInput(false);
      setFolderName(""); // Reset the folder name after creation
    }
  };

  if (showInput) {
    return (
      <Input
        onChange={(value) => setFolderName(value)}
        value={folderName}
        onBlur={() => setShowInput(false)}
        startIcon={
          <FolderIcon className="w-3 h-3 text-zinc-600 dark:text-zinc-400 ml-1" />
        }
        className={cn("h-8 text-2xs ring-emerald-600 ring-1", className)}
        containerClassName={containerClassName}
        autoFocus
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>): void => {
          if (event.key === "Escape") {
            setShowInput(false);
          }
          if (event.key === "Enter") {
            handleFolderUpdate();
          }
        }}
      />
    );
  } else {
    return (
      <Button
        variant="text"
        size="2xs"
        className="flex w-full hover:bg-zinc-900/5 dark:hover:bg-white/5 h-8"
        textClassName="w-full ml-[2px] text-left text-zinc-500"
        onClick={() => setShowInput(true)}
        startIcon={
          <PlusIcon
            className="h-3 w-3 ml-[1px]"
            style={{
              strokeWidth: 2,
            }}
          />
        }
      >
        New Folder
      </Button>
    );
  }
};
