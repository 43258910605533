import { Button, Checkbox, Textarea } from "@/components/Elements";
import { CustomizeResultsCard } from "@/components/Elements/Card/CustomizeResultsCard";
import { useUrlBatch } from "@/features/documents/api/getUrlBatch";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import {
  getArticleCounts,
  getHeadings,
  getQuestions,
  validateArticles,
} from "@/features/documents/utils/research";
import { processBrief } from "@/features/documents/utils/serp";
import { useDocumentStore } from "@/stores/document";
import { useNotificationStore } from "@/stores/notifications";
import { useSerpStore } from "@/stores/serp";
import { cn } from "@/utils/style";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../Elements";
import { LogoSpinner } from "../../../Elements/Spinner/LogoSpinner";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";
import { sanitizeUrl, validateUrl } from "../../utils/url";

export const ImportUrlButton = ({ open, setOpen, setCustomUrls, trigger }) => {
  const [importUrls, setImportUrls] = useState("");
  const [sanitizedImportUrls, setSanitizedImportUrls] = useState(
    [] as string[]
  );
  const [isProcessingUrl, setIsProcessingUrl] = useState(false);
  const { addNotification } = useNotificationStore();
  const { document: fraseDocument } = useDocumentStore();
  const { serp, setSerp } = useSerpStore();
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const { urls, results, articles, allArticles } = serp[fraseDocument.id] || {
    urls: [],
    results: [],
    articles: [],
    allArticles: [],
  };
  const validArticles = React.useMemo(() => {
    return (articles || [])
      .filter((article) => article.isValid)
      .sort((a, b) => a.index - b.index);
  }, [articles]);
  const blacklist = fraseDocument.metadata?.blacklist || {};

  const urlBatchQuery = useUrlBatch({
    urls: [
      ...new Set([
        ...sanitizedImportUrls,
        ...validArticles.map((article) => article.url),
      ]),
    ],
    document: fraseDocument,
    shouldSetSerpProcessed: true,
    config: {
      enabled: triggerRefetch,
    },
  });

  const handleSubmit = async () => {
    setIsProcessingUrl(true);
    const processedUrls = importUrls
      .split(",")
      .map((url) => sanitizeUrl(url.trim()));
    let valid = true;
    let invalidUrls = [];
    let existingUrls = [];

    processedUrls.forEach((url) => {
      if (validArticles.some((article) => article.url === url)) {
        existingUrls.push(url);
      } else if (!validateUrl(url)) {
        valid = false;
        invalidUrls.push(url);
      }
    });

    if (existingUrls.length > 0) {
      addNotification({
        title: "URL already imported",
        message: `The following URLs have already been processed: ${existingUrls.join(
          ", "
        )}.`,
        type: "info",
      });
      setIsProcessingUrl(false);
      return;
    }

    if (!valid) {
      addNotification({
        title: "Invalid URL",
        message: `The following URLs are invalid: ${invalidUrls.join(
          ", "
        )}. The supported protocols are https and http.`,
        type: "error",
      });
      setIsProcessingUrl(false);
      return;
    }

    setSanitizedImportUrls(processedUrls);
    setTriggerRefetch(true);
  };

  useEffect(() => {
    if (urlBatchQuery.isSuccess && triggerRefetch) {
      const { items, cluster_info } = urlBatchQuery.data;
      let searchResults = [...serp[fraseDocument.id].allArticles] as object[];
      let importedResults = [] as object[];

      items &&
        items.forEach((item) => {
          const existsInSearchResults = searchResults.some(
            (result) => result.url === item.url
          );
          const existsInImportedResults = importedResults.some(
            (result) => result.url === item.url
          );

          if (!existsInSearchResults && !existsInImportedResults) {
            importedResults.push({
              title: item.title,
              url: item.url,
              description: item.description,
              isValid: true,
              isCustomImport: true,
              index: -1, // Use negative index to ensure it appears at the top
              importOrder: Date.now(), // Use timestamp for import order
              dateCreated: new Date().toISOString(),
              content: item.content,
              metadata: item.metadata,
              serp_data: item.serp_data,
              processed: true,
              lastImported: true, // Mark this as the last imported URL
            });
          }
        });

      // Sort importedResults to ensure newest imports are first
      importedResults.sort((a, b) => b.importOrder - a.importOrder);

      // Reset lastImported flag for all existing results
      searchResults = searchResults.map((result) => ({
        ...result,
        lastImported: false,
      }));

      // Preserve existing search results and add new imports
      const combinedResults = [...importedResults, ...searchResults];

      // Filter out blacklisted items
      const filteredResults = combinedResults.filter((result) => {
        return !Object.keys(blacklist).includes(result.url);
      });

      const articles = validateArticles(items, filteredResults);
      const { wordCount, headerCount, linkCount, imageCount } =
        getArticleCounts(articles);
      const headings = getHeadings(articles);
      const serpQuestions = getQuestions(articles);
      const { domain_map, topics } = processBrief(
        items,
        cluster_info,
        fraseDocument.query,
        fraseDocument.metadata?.lang_code
      );

      setSerp(fraseDocument.id, {
        ...serp[fraseDocument.id],
        results: filteredResults,
        allArticles: combinedResults, // Store all results including blacklisted ones
        articles: articles || [],
        clusters: cluster_info,
        headings: headings,
        topics: topics,
        domainMap: domain_map,
        questions: {
          ...serp[fraseDocument.id].questions,
          serp: serpQuestions,
        },
        averageWordCount: wordCount,
        averageHeaderCount: headerCount,
        averageImageCount: imageCount,
        averageLinkCount: linkCount,
        urls: combinedResults.map((article) => article.url),
        serpLoaded: true,
      });
      setIsProcessingUrl(false);
      setCustomUrls(sanitizedImportUrls);
      setImportUrls("");
      setSanitizedImportUrls([]);
      setOpen(false);
      setTriggerRefetch(false);
    }
  }, [urlBatchQuery.isSuccess]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {trigger || (
          <Button variant="outlineBlur" size="xs" className="h-7">
            Import URL
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        {isProcessingUrl ? (
          <div className="flex items-center justify-center py-16 pb-24">
            <LogoSpinner
              variant="md"
              loadingText="Importing URL..."
            ></LogoSpinner>
          </div>
        ) : (
          <>
            <DialogHeader className="pb-0 flex items-center justify-between">
              <DialogTitle>Import URL</DialogTitle>
              <DialogCloseButton
                close={() => {
                  setOpen(false);
                }}
              />
            </DialogHeader>
            <DialogDescription className="overflow-y-hidden px-4">
              <Textarea
                value={importUrls}
                onChange={(e) => setImportUrls(e.target.value)}
                placeholder="https://www.example1.com"
                className="h-24 resize-none"
                onClick={(e) => e.stopPropagation()}
              />
            </DialogDescription>
            <DialogFooter className="px-4 pb-4 text-center flex-col">
              <Button
                type="submit"
                variant="primaryBlur"
                disabled={importUrls.length === 0}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const ControlButtons = ({
  saveDisabled,
  selectedArticlesLength,
  validArticlesLength,
  handleSelectAll,
  handleSelectNone,
  handleSaveBlacklist,
  setCustomUrls,
  isSaving,
}) => {
  const [allResultsSelected, setAllResultsSelected] = useState(true);
  const [importUrlDialogOpen, setImportUrlDialogOpen] = useState(false);

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center w-full justify-between">
        <div className="flex items-center pl-1.5">
          <div className="flex-col pt-2.5">
            <span className="font-semibold text-zinc-800 dark:text-zinc-200 pl-2">
              {selectedArticlesLength}
            </span>
            <span className="ml-1 text-xs text-zinc-500 whitespace-nowrap mr-4">
              of {validArticlesLength} results selected
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <ImportUrlButton
            setCustomUrls={setCustomUrls}
            open={importUrlDialogOpen}
            setOpen={setImportUrlDialogOpen}
          />
          <Button
            variant="primaryBlur"
            size="xs"
            className="h-7"
            onClick={() => {
              handleSaveBlacklist();
            }}
            isLoading={isSaving}
            disabled={saveDisabled}
            tooltipContent={
              saveDisabled
                ? "Please select at least five search results"
                : undefined
            }
          >
            Save
          </Button>
        </div>
      </div>
      <div className="flex items-center border-t border-zinc-900/5 dark:border-zinc-800 pt-2 pb-1 mt-2">
        <Checkbox
          className={cn(
            "ml-4 mr-3",
            selectedArticlesLength === validArticlesLength &&
              "bg-emerald-600 dark:bg-emerald-600 hover:dark:bg-emerald-600"
          )}
          checked={allResultsSelected}
          onCheckedChange={(checked) => {
            if (checked) {
              handleSelectAll();
            } else {
              handleSelectNone();
            }
            setAllResultsSelected(checked);
          }}
          aria-label="Select all results"
        />
        <div className="flex-col">
          <span className="ml-1 text-xs text-zinc-500 whitespace-nowrap">
            Page title
          </span>
        </div>
      </div>
    </div>
  );
};

export default function CustomizeResults({
  setCustomizeResultsVisible,
  setIsLoadingSerp,
  handleStartProcessingSerp,
}: {
  setCustomizeResultsVisible: (value: boolean) => void;
  setIsLoadingSerp: (value: boolean) => void;
  handleStartProcessingSerp: () => void;
}) {
  const { document: fraseDocument } = useDocumentStore();
  const { serp, setSerp } = useSerpStore();
  const docId = fraseDocument.id;
  const currentSerp = serp[docId] || {
    results: [],
    articles: [],
    allArticles: [],
    serpLoaded: false,
  };
  const { setHeaderNavigation, onBack } = useHeaderNavigation();
  const blacklist = fraseDocument.metadata?.blacklist || {};
  const custom_imports = fraseDocument.metadata?.custom_imports || {};
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });
  const { addNotification } = useNotificationStore();
  const [tempBlacklist, setTempBlacklist] = useState(blacklist);
  const [customUrls, setCustomUrls] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [lastImportedUrl, setLastImportedUrl] = useState<string | null>(null);

  const handleSetCustomUrls = (urls: string[]) => {
    setCustomUrls(urls);
    if (urls.length > 0) {
      setLastImportedUrl(urls[urls.length - 1]);
    }
  };

  const validArticles = React.useMemo(() => {
    return (currentSerp.articles || [])
      .filter((article) => article.isValid)
      .sort((a, b) => a.index - b.index);
  }, [currentSerp.articles]);

  const allArticles = React.useMemo(() => {
    const customImportKey = `${fraseDocument.query}:${fraseDocument.metadata.code}:${fraseDocument.metadata.lang_code}`;

    // Get existing custom imports
    const existingCustomImports = custom_imports[customImportKey] || [];

    // Get valid articles from current SERP results
    const validSerpArticles = (currentSerp.allArticles || []).filter(
      (article) => article.isValid
    );

    // Create a Map to deduplicate by URL
    const articleMap = new Map();

    // Add SERP articles first
    validSerpArticles.forEach((article) => {
      articleMap.set(article.url, {
        ...article,
        importOrder: 0, // Default order for SERP results
        isLastImported: article.url === lastImportedUrl,
      });
    });

    // Add custom imports with timestamps as order
    existingCustomImports.forEach((article) => {
      if (!articleMap.has(article.url)) {
        articleMap.set(article.url, {
          ...article,
          importOrder: new Date(article.dateCreated).getTime() || Date.now(),
          isLastImported: article.url === lastImportedUrl,
        });
      }
    });

    // Convert back to array and sort
    const uniqueArticles = Array.from(articleMap.values());
    return uniqueArticles.sort((a, b) => {
      // Last imported URL always comes first
      if (a.isLastImported) return -1;
      if (b.isLastImported) return 1;

      // Then sort by custom import (custom imports first)
      if (a.isCustomImport && !b.isCustomImport) return -1;
      if (!a.isCustomImport && b.isCustomImport) return 1;

      // For custom imports, sort by importOrder (higher/newer timestamp first)
      if (a.isCustomImport && b.isCustomImport) {
        return b.importOrder - a.importOrder;
      }

      // For non-custom imports, maintain original order
      return a.index - b.index;
    });
  }, [
    currentSerp.allArticles,
    custom_imports,
    fraseDocument.query,
    fraseDocument.metadata.code,
    fraseDocument.metadata.lang_code,
    lastImportedUrl,
  ]);

  const selectedArticlesCount = React.useMemo(() => {
    const selectedCount = allArticles.filter(
      (article) => !tempBlacklist[article.url]
    ).length;
    return selectedCount;
  }, [allArticles, tempBlacklist]);

  useEffect(() => {
    setHeaderNavigation({
      title: "Customize search results",
      onBack: () => {
        handleNavigateBack();
      },
    });
  }, [setCustomizeResultsVisible]);

  // Call the useUrlBatch hook at the top level of your component
  const urlBatchQuery = useUrlBatch({
    urls:
      allArticles
        .map((article) => article.url)
        .filter((url) => !tempBlacklist[url]) || [],
    document: fraseDocument,
    shouldSetSerpProcessed: true,
    config: {
      enabled: false,
    },
  });

  const handleNavigateBack = () => {
    setCustomizeResultsVisible(false);
    onBack();
  };

  const handleSaveBlacklist = async () => {
    setIsSaving(true);
    try {
      // Create updated document with new blacklist and custom imports
      const serpKey = `${fraseDocument.query}:${fraseDocument.metadata.code}:${fraseDocument.metadata.lang_code}`;
      const customImports = fraseDocument.metadata?.custom_imports || {};

      let newDocument = {
        ...fraseDocument,
        metadata: {
          ...fraseDocument.metadata,
          blacklist: tempBlacklist,
          custom_imports: {
            ...customImports,
            [serpKey]: [
              ...(customImports[serpKey] || []),
              ...allArticles
                .filter((article) => customUrls.includes(article.url))
                .map((article) => ({
                  title: article.title,
                  url: article.url,
                  description: article.description,
                  isValid: true,
                  isCustomImport: true,
                  index: -1,
                  importOrder: Date.now(),
                  dateCreated: new Date().toISOString(),
                  content: article.content,
                  metadata: article.metadata,
                  serp_data: article.serp_data,
                  processed: true,
                  lastImported: true,
                })),
            ],
          },
        },
      };

      // Filter articles using allArticles as source of truth while preserving existing article data
      const filteredArticles = allArticles
        .filter((article) => !tempBlacklist[article.url])
        .map((article) => {
          // Find existing article to preserve all its data
          const currentArticle = serp[fraseDocument.id].articles?.find(
            (a) => a.url === article.url
          );

          if (currentArticle) {
            // If article exists, preserve all its data and just update the new fields
            return {
              ...currentArticle,
              title: article.title,
              description: article.description,
              isCustomImport: article.isCustomImport,
              lastImported: article.lastImported,
              importOrder: article.importOrder,
            };
          }

          // For new articles, use the full article object
          return article;
        });

      // Update SERP state with filtered articles
      await setSerp(fraseDocument.id, {
        ...serp[fraseDocument.id],
        articles: filteredArticles,
      });

      // Save the document and navigate back
      await updateDocumentMutation.mutateAsync(newDocument);

      // Start processing SERP
      await handleStartProcessingSerp();

      onBack();
    } catch (error) {
      addNotification({
        title: "Error saving changes",
        message: "Please try again",
        type: "error",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateBlacklist = (url, isSelected) => {
    let newBlacklist = {};
    if (isSelected) {
      newBlacklist = {
        ...tempBlacklist,
      };

      delete newBlacklist[url];
    } else {
      newBlacklist = {
        ...tempBlacklist,
        [url]: true,
      };
    }
    setTempBlacklist(newBlacklist);
  };

  const handleSelectAll = () => {
    setTempBlacklist({});
  };

  const handleSelectNone = () => {
    const newBlacklist = {};

    allArticles.forEach((article) => {
      newBlacklist[article.url] = true;
    });

    setTempBlacklist(newBlacklist);
  };

  return (
    <ul className="flex flex-col pr-1 divide-y divide-zinc-900/5 dark:divide-zinc-800 last:border-b last:border-zinc-900/5 dark:last:border-zinc-800">
      <div className="flex justify-between items-center pt-0.5">
        <ControlButtons
          selectedArticlesLength={selectedArticlesCount}
          validArticlesLength={allArticles.length}
          handleSaveBlacklist={handleSaveBlacklist}
          saveDisabled={selectedArticlesCount < 5 && customUrls.length === 0}
          handleSelectAll={handleSelectAll}
          handleSelectNone={handleSelectNone}
          setCustomUrls={handleSetCustomUrls}
          isSaving={isSaving}
        />
      </div>

      {allArticles.map((article, index) => {
        return (
          <CustomizeResultsCard
            blacklist={tempBlacklist}
            key={article.url}
            title={article.title}
            url={article.url}
            isCustomImport={article.isCustomImport || false}
            domainAuthority={article.domainAuthority}
            handleUpdateBlacklist={handleUpdateBlacklist}
          />
        );
      })}
    </ul>
  );
}
