import asset_group_dark from "@/assets/asset_group_dark.svg";
import asset_group_light from "@/assets/asset_group_light.svg";
import { Button, Input } from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import SearchQueryCredits from "../../../../features/subscription/components/SearchQueryCredits";
import SearchQuery from "./SearchQuery";
import "./SerpPlaceholder.css";

const RESEARCH_TITLE = "Research";
const RESEARCH_INSTRUCTION =
  "Process the top 20 Google search results for the following search query:";

export const LoadSpinner = ({ searchResults, loadingText }) => (
  <div className="flex items-center justify-center w-full h-[25%]">
    <LogoSpinner
      variant="md"
      searchResults={searchResults}
      loadingText={loadingText}
    />
  </div>
);

export const ResearchView = ({
  updateSerp,
  fraseDocument,
  searchQuery,
  setSearchQuery,
  settingsVisible,
  isReadOnly,
}) => (
  <div className="flex-col w-full h-full p-16 relative">
    <FadeImg />
    <InputView
      isReadOnly={isReadOnly}
      settingsVisible={settingsVisible}
      updateSerp={updateSerp}
      fraseDocument={fraseDocument}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  </div>
);

export const FadeImg = () => (
  <>
    <div className="fade-img-dark hidden dark:block">
      <img src={asset_group_dark} alt="Research" className="fade-img-dark" />
    </div>

    <div className="fade-img-light dark:hidden">
      <img src={asset_group_light} alt="Research" className="fade-img-light" />
    </div>
  </>
);

export const InputView = ({
  updateSerp,
  fraseDocument,
  searchQuery,
  setSearchQuery,
  settingsVisible,
  isReadOnly,
}) => (
  <div className="flex-col items-center px-2 w-full">
    <h1 className="font-medium text-2xl mt-4 text-zinc-900 dark:text-white text-center">
      {RESEARCH_TITLE}
    </h1>
    <Instructions />
    <SearchQuery
      isReadOnly={isReadOnly}
      containerClassName="mt-4 w-full"
      fraseDocument={fraseDocument}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      setProcessResults={updateSerp}
      settingsVisible={settingsVisible}
    />
    <SearchQueryCredits />
    <ActionButton
      updateSerp={updateSerp}
      document={document}
      searchQuery={searchQuery}
    />
  </div>
);

export const Instructions = () => (
  <div className="mt-2 text-sm text-zinc-500 flex-wrap text-center">
    <p>{RESEARCH_INSTRUCTION}</p>
  </div>
);

export const InputField = ({
  document,
  setSearchQuery,
  searchQuery,
  updateSerp,
}) => (
  <Input
    className="font-medium text-zinc-900 dark:text-white mt-3 overflow-ellipsis"
    value={searchQuery}
    placeholder="Enter search query"
    onChange={(value) => setSearchQuery(value)}
    onKeyDown={(event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        updateSerp();
      }
    }}
  />
);

export const ActionButton = ({ updateSerp, document, searchQuery = "" }) => {
  const trackEvent = useTrackEvent();

  const handleTrackProcessSerp = () => {
    trackEvent.mutate({
      event: "process_serp",
      properties: JSON.stringify({
        query: document.query || searchQuery || "",
      }),
    });
  };

  return (
    <p className="text-center w-full">
      <Button
        size="md"
        className="mt-4 mx-auto"
        variant="primary"
        endIcon={<ArrowRightIcon />}
        onClick={() => {
          updateSerp();
          handleTrackProcessSerp();
        }}
        disabled={searchQuery.length === 0}
      >
        Start
      </Button>
    </p>
  );
};

export const SerpPlaceholder = ({
  isLoadingSerp,
  handleStartProcessingSerp,
  fraseDocument,
  searchQuery,
  setSearchQuery,
  results,
  settingsVisible,
  isReadOnly,
}) => {
  if (isLoadingSerp) {
    return <LoadSpinner searchResults={results} />;
  }

  return (
    <ResearchView
      isReadOnly={isReadOnly}
      settingsVisible={settingsVisible}
      updateSerp={handleStartProcessingSerp}
      fraseDocument={fraseDocument}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};
