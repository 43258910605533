import { Document } from "@/features/documents";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type DocumentStore = {
  document: Document;
  setDocument: (document: Document) => void;
};

export const defaultDocument: Document = {
  id: 0,
  name: "",
  created_by: 0,
  query: "",
  text: [
    {
      html: "",
      title: "",
      name: "",
    },
  ],
  metadata: {
    blacklist: {},
    code: "",
    display_code: "",
    lang_code: "",
    lang_display: "",
    serp_loaded: false,
    outline: "",
    last_editor_name: "",
    url: null,
    word_count: 0,
    ai_keywords: "",
    ai_context: "",
    target_domain: "",
    name: "",
    ai_article_started: false,
    global_instructions: "",
  },
  lastEdited: 0,
  dateCreated: 0,
  hash: "",
  teamId: 0,
  doc_status: 0,
  assignment_status: 0,
  assignment_status_desc: "",
  org_id: 0,
  doc_owner: 0,
  doc_owner_name: "",
  doc_owner_list: null,
  doc_status_date: 0,
  doc_status_desc: "",
  doc_type: 0,
  doc_type_desc: null,
  doc_target_date: null,
  template: null,
  googleDocID: null,
  wpDocID: null,
  archived: false,
  archived_dt: null,
  assigned_writer: null,
  admin: false,
  questions: [],
};

export const useDocumentStore = create(
  devtools<DocumentStore>(
    (set) => ({
      document: defaultDocument,
      setDocument: (document) => {
        set(() => ({ document }));
      },
    }),
    { name: "document", store: "document" }
  )
);
