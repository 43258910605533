import { ContentLayout } from "@/components/Layout";
import { DocumentsList } from "@/features/documents/components/DocumentsList";
import { useParams } from "react-router-dom";

export const Folder = () => {
  const { folderId } = useParams();

  return (
    <ContentLayout>
      <DocumentsList
        folderId={folderId ? parseInt(folderId) : null}
        getArchived={folderId === "archive"}
      />
    </ContentLayout>
  );
};
