import { axios } from "@/lib/axios";
import { useMutation } from "react-query";

interface UpdateAiToolRatingPayload {
  id: string;
  rating: number;
}

interface UpdateAiToolRatingResponse {
  id: string;
  user_hash: string;
  template_hash: string;
  generation: string;
  prompt_map: {
    question: string;
  };
  unix_timestamp: number;
  creativity: number;
  rating: number;
}

const updateAiToolRating = async (
  payload: UpdateAiToolRatingPayload
): Promise<UpdateAiToolRatingResponse> => {
  const response = await axios.post<UpdateAiToolRatingResponse>(
    `/updateTemplateRating`,
    payload
  );
  return response.data;
};

export const useUpdateAiToolRating = () => {
  return useMutation<
    UpdateAiToolRatingResponse,
    Error,
    UpdateAiToolRatingPayload
  >(updateAiToolRating);
};
