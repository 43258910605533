import { Button } from "@/components/Elements";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/Elements/Tabs";
import { useSeoAnalyticsStore } from "@/features/seo-analytics/store";
import React from "react";
import ClustersTable from "./components/ClustersTable";
import PagesTable from "./components/PagesTable";
import QueriesTable from "./components/QueriesTable";

const AnalyticsTabs: React.FC = () => {
  const { activeView, setActiveView, download, downloading } =
    useSeoAnalyticsStore();

  return (
    <Tabs defaultValue={activeView} className="w-full">
      <TabsList className="mb-2 mx-4">
        <TabsTrigger
          value="pages"
          className="text-zinc-800 dark:text-zinc-200"
          onClick={() => setActiveView("pages")}
        >
          Pages
        </TabsTrigger>
        <TabsTrigger
          value="queries"
          className="text-zinc-800 dark:text-zinc-200"
          onClick={() => setActiveView("queries")}
        >
          Queries
        </TabsTrigger>
        <TabsTrigger
          value="clusters"
          className="text-zinc-800 dark:text-zinc-200"
          onClick={() => setActiveView("clusters")}
        >
          Clusters
        </TabsTrigger>
      </TabsList>
      <Button
        variant="outline"
        size="2xs"
        onClick={download}
        isLoading={downloading}
      >
        Download Report
      </Button>
      <TabsContent value="queries">
        <QueriesTable />
      </TabsContent>
      <TabsContent value="pages">
        <PagesTable />
      </TabsContent>
      <TabsContent value="clusters">
        <ClustersTable />
      </TabsContent>
    </Tabs>
  );
};

export default AnalyticsTabs;
