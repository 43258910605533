import { Button, Input } from "@/components/Elements";
import { NavLink } from "@/components/Layout/Navigation";
import { useUpdateFolder } from "@/features/documents/api/updateFolder";
import { cn } from "@/utils/style";
import { FolderIcon } from "@heroicons/react/24/solid";
import { MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { FolderDropdownMenu } from "./FolderDropdownMenu";

export const FolderItem = ({ id, title, link, folderActive, hideDropdown }) => {
  const [showInput, setShowInput] = useState(false);
  const [folderName, setFolderName] = useState(title);
  const updateFolder = useUpdateFolder();

  const handleFolderUpdate = () => {
    if (folderName !== "") {
      updateFolder.mutate({
        id: id,
        name: folderName,
        description: "",
      });
      setShowInput(false);
    }
  };

  if (showInput) {
    return (
      <Input
        onChange={(value) => setFolderName(value)}
        value={folderName}
        onBlur={() => setShowInput(false)}
        startIcon={
          <FolderIcon className="w-3 h-3 text-zinc-600 dark:text-zinc-400 ml-1" />
        }
        className={cn("h-8 text-2xs ring-emerald-600 ring-1")}
        autoFocus
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>): void => {
          if (event.key === "Escape") {
            setShowInput(false);
          }
          if (event.key === "Enter") {
            handleFolderUpdate();
          }
        }}
      />
    );
  }

  return (
    <div
      className={cn(
        "flex items-center rounded-md group truncate",
        folderActive
          ? "bg-zinc-900/5 dark:bg-zinc-700 text-zinc-900 dark:text-white"
          : "text-zinc-700 hover:text-zinc-900 dark:text-white dark:hover:text-white hover:bg-zinc-900/5 dark:hover:bg-white/5"
      )}
    >
      <link.logo className="w-3 h-3 flex-shrink-0 ml-3" />

      <NavLink
        href={link.href}
        active={folderActive}
        className="text-2xs overflow-ellipsis whitespace-nowrap overflow-hidden group-hover:w-full group-hover:text-zinc-900 dark:group-hover:text-white mr-3"
      >
        {title}
      </NavLink>

      {!hideDropdown && (
        <FolderDropdownMenu
          id={id}
          title={title}
          onRename={() => setShowInput(true)}
          triggerComponent={
            <Button
              variant="floatingIcon"
              className="flex-shrink-0 mr-2 group-hover:block group-hover:bg-transparent group-hover:stroke-zinc-900 hidden"
              buttonIcon={<MoreHorizontal className="w-3 h-3" />}
            />
          }
        />
      )}
    </div>
  );
};
