import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

export const getInvoices = async () => {
  const data = await axios.post("/stripe/getInvoicesForUser");
  return data;
};

export const useGetInvoices = () => {
  return useQuery("invoices", getInvoices);
};
