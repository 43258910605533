import {
  Button,
  DropdownDialogItem,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/Elements";
import React from "react";
import { TbDots, TbTrash } from "react-icons/tb";

interface WizardOutlineMenuProps {
  selectedAssets: any[];
  setSelectedAssets: (assets: any[]) => void;
  handleUpdateDocument: (assets: any[]) => void;
  onClose?: () => void;
  onSelect?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
}

export const WizardOutlineMenu: React.FC<WizardOutlineMenuProps> = ({
  selectedAssets,
  setSelectedAssets,
  handleUpdateDocument,
  onSelect,
  onOpenChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [hasOpenDialog, setHasOpenDialog] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const dropdownTriggerRef = React.useRef<null | HTMLButtonElement>(null);

  const handleDialogItemSelect = () => {
    dropdownTriggerRef.current?.focus();
  };

  const handleDialogItemOpenChange = (open: boolean) => {
    setHasOpenDialog(open);
    if (!open) {
      setDropdownOpen(false);
    }
  };

  const handleClearHeadings = () => {
    setIsDeleting(true);
    setSelectedAssets((prevAssets) => {
      handleUpdateDocument([])
        .then(() => {
          setIsDeleting(false);
          handleDialogItemOpenChange(false);
        })
        .catch(() => {
          setIsDeleting(false);
          setSelectedAssets(prevAssets);
        });
      return [];
    });
  };

  const handleClearInstructions = () => {
    setIsDeleting(true);
    setSelectedAssets((prevAssets) => {
      const updatedAssets = prevAssets.map((asset) => ({
        ...asset,
        instruction: "",
      }));

      handleUpdateDocument(updatedAssets)
        .then(() => {
          setIsDeleting(false);
          handleDialogItemOpenChange(false);
        })
        .catch(() => {
          setIsDeleting(false);
          setSelectedAssets(prevAssets);
        });

      return updatedAssets;
    });
  };

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="buttonIcon"
          size="sm"
          className="ml-1.5"
          ref={dropdownTriggerRef}
        >
          <TbDots />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent hidden={hasOpenDialog}>
        <DropdownMenuGroup onClick={(e) => e.stopPropagation()}>
          <DropdownDialogItem
            icon={<TbTrash />}
            label="Clear headings"
            title="Clear Headings"
            body={
              <>
                Are you sure you want to clear all headings from the outline?
                This action cannot be undone.
              </>
            }
            close={() => {
              handleDialogItemOpenChange(false);
            }}
            confirmButton={
              <Button
                variant="destructiveBlur"
                onClick={handleClearHeadings}
                isLoading={isDeleting}
              >
                Clear
              </Button>
            }
            onSelect={onSelect || handleDialogItemSelect}
            onOpenChange={onOpenChange || handleDialogItemOpenChange}
          />
          <DropdownDialogItem
            icon={<TbTrash />}
            label="Clear instructions"
            title="Clear Instructions"
            body={
              <>
                Are you sure you want to clear all instructions from the
                outline? This action cannot be undone.
              </>
            }
            close={() => {
              handleDialogItemOpenChange(false);
            }}
            confirmButton={
              <Button
                variant="destructiveBlur"
                onClick={handleClearInstructions}
                isLoading={isDeleting}
              >
                Clear
              </Button>
            }
            onSelect={onSelect || handleDialogItemSelect}
            onOpenChange={onOpenChange || handleDialogItemOpenChange}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
