import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

const getChannelsForUser = async () => {
  const data = await axios.post("/getTeamsForUser");
  return data ? data.sort((a, b) => a.name.localeCompare(b.name)) : [];
};

const createChannel = async (name, description, image) => {
  const data = await axios.post("/createTeam", {
    name,
    description,
    image,
  });
  return data.team;
};

const updateChannel = async (channel) => {
  const data = await axios.post("/editTeamText", {
    team_id: channel?.id,
    team_name: channel?.name,
    team_description: channel?.description,
  });

  return data;
};

const deleteChannel = async (channel) => {
  const data = await axios.post("/deleteTeam", { teamId: channel?.id });
  return data;
};

export const useGetChannelsForUser = (config) => {
  return useQuery("channels", getChannelsForUser, {
    ...config,
  });
};

export const useCreateChannel = (name, description, image, config) => {
  return useQuery(
    ["createChannel", { name, description, image }],
    () => createChannel(name, description, image),
    config
  );
};

export const useUpdateChannel = (channel, config) => {
  return useQuery(
    ["updateChannel", channel],
    () => updateChannel(channel),
    config
  );
};

export const useDeleteChannel = (channel, config) => {
  return useQuery(
    ["deleteChannel", channel],
    () => deleteChannel(channel),
    config
  );
};
