import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

interface PlanUpdateRequest {
  plans: Array<{ plan: string; quantity?: number }>;
  coupon?: string;
}

const updateCustomerSubscription = async (requestData: PlanUpdateRequest) => {
  const { data } = await axios.post(
    "/stripe/update_customer_subscription",
    requestData
  );
  return data;
};

export const useUpdatePlan = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(updateCustomerSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("upcomingInvoice");
      queryClient.invalidateQueries("subscription");

      addNotification({
        type: "success",
        title: "Plan updated",
        message: "Your subscription plan has been successfully updated.",
      });
    },
  });
};
