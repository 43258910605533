import {
  CompletedStatusIcon,
  OptimizingStatusIcon,
  PublishedStatusIcon,
  ResearchingStatusIcon,
  WritingStatusIcon,
} from "@/components/Elements/TableFilter/StatusIcons";
import { TbCircleDashed } from "react-icons/tb";

export const getStatusFilters = () => {
  return [
    {
      value: "doc_status_desc",
      label: "Status",
      children: [
        {
          value: 1,
          label: "Researching",
          icon: <ResearchingStatusIcon />,
          parent: "doc_status_desc",
        },
        {
          value: 2,
          label: "Writing",
          icon: <WritingStatusIcon />,
          parent: "doc_status_desc",
        },
        {
          value: 3,
          label: "Optimizing",
          icon: <OptimizingStatusIcon />,
          parent: "doc_status_desc",
        },
        {
          value: 4,
          label: "Completed",
          icon: <CompletedStatusIcon />,
          parent: "doc_status_desc",
        },
        {
          value: 5,
          label: "Published",
          icon: <PublishedStatusIcon />,
          parent: "doc_status_desc",
        },
      ],
      icon: <TbCircleDashed />,
    },
  ];
};
