import { axios } from "@/lib/axios";
import { useMutation } from "react-query";

interface UpdateAiToolConfigPayload {
  id: number;
  created_by: number;
  created_dt: number;
  updated_dt: number;
  text: {
    html: string;
    title: string;
    name: string;
  }[];
  metadata: {
    template_prompt: string;
    template_tags: string;
    template_creativity: string;
  };
  hash: string;
  type: string;
  org_id: number;
  user_id: number | null;
  template_hash: string | null;
  bookmark_count: number | null;
  upvote_count: number | null;
  downvote_count: number | null;
  hit_count: number | null;
  template_author: string;
  email: string;
  template_author_email: string | null;
  template_author_hash: string | null;
}

interface UpdateAiToolConfigResponse {
  id: number;
  created_by: number;
  created_dt: number;
  updated_dt: number;
  text: {
    html: string;
    title: string;
    name: string;
  }[];
  metadata: {
    template_prompt: string;
    template_tags: string;
    template_creativity: string;
  };
  hash: string;
  type: string;
  org_id: number;
  user_id: number | null;
  template_hash: string | null;
  bookmark_count: number | null;
  upvote_count: number | null;
  downvote_count: number | null;
  hit_count: number | null;
  template_author: string;
  email: string;
  template_author_email: string | null;
  template_author_hash: string | null;
}

const updateAiToolConfig = async (
  payload: UpdateAiToolConfigPayload
): Promise<UpdateAiToolConfigResponse> => {
  const response = await axios.post<UpdateAiToolConfigResponse>(
    `/updateTemplate`,
    payload
  );
  return response;
};

export const useUpdateAiToolConfig = () => {
  return useMutation<
    UpdateAiToolConfigResponse,
    Error,
    UpdateAiToolConfigPayload
  >(updateAiToolConfig);
};
