import { Avatar, AvatarFallback, AvatarImage } from "@/components/Elements";
import { cn, usernameToColor } from "@/utils/style";
import { UserCircleIcon } from "@heroicons/react/24/solid";

const userAvatar = (user) => (
  <Avatar className="w-5 h-5 mr-2">
    <AvatarImage src="" />
    <AvatarFallback
      className={cn("text-zinc-50 text-4xs", usernameToColor(user.fullName))}
    >
      {user.fullName
        .split(" ")
        .map((name) => name[0])
        .slice(0, 2)
        .join("")}
    </AvatarFallback>
  </Avatar>
);

export const getUserFilters = (usersQuery) => {
  return [
    {
      value: "doc_owner_name",
      label: "User",
      children: usersQuery.data?.map((user) => ({
        value: user.id,
        label: user.fullName,
        icon: userAvatar(user),
        parent: "doc_owner_name",
      })),
      icon: <UserCircleIcon />,
    },
  ];
};
