import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Logo,
} from "@/components/Elements";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const PlanLimitDialog = ({ onClose, title, text }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const close = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose(close);
        }
      }}
    >
      <DialogPortal>
        <DialogContent className="p-0">
          <div className="h-full w-full">
            <div className="flex flex-col justify-center pt-4">
              <DialogCloseButton
                className={`absolute top-4 right-4`}
                close={() => {
                  onClose(close);
                }}
              />
            </div>
            <div>
              <DialogHeader className="pb-4 flex items-center space-x-4 pl-6">
                <Logo renderText={false} />
                <DialogTitle className="text-[22px] text-zinc-800">
                  {title}
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="px-6 pb-6">
                <p className="mt-4 text-sm text-zinc-600 dark:text-zinc-200">
                  {text || "You have reached the limit of your current plan."}
                </p>
              </DialogDescription>

              <DialogFooter className="px-6 py-4 flex items-center justify-end bg-white dark:bg-zinc-800 rounded-bl-md rounded-br-md">
                <div className="flex items-center space-x-2">
                  <Button
                    variant="primaryBlur"
                    size="sm"
                    className="bg-emerald-100"
                    onClick={() => {
                      navigate("/app/settings/plans");
                      onClose(close);
                    }}
                  >
                    View plans
                  </Button>
                </div>
              </DialogFooter>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
