import { cn } from "@/utils/style";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TbAlertCircleFilled } from "react-icons/tb";
import { Link } from "react-router-dom";

import { useUpdateTemplate } from "@/features/templates/api/updateTemplate";
import { useTemplateStore } from "@/stores/template";
import { ShareMenu } from "../../features/editor/components/ShareMenu";
import ToolbarPlugin from "../AdvancedEditor/plugins/ToolbarPlugin";
import { Navigation } from "./MobileNavigation";

const DateFormat = (date) => {
  const lastEdited = dayjs(date);
  if (dayjs().diff(lastEdited, "year") >= 1) {
    return `Edited ${lastEdited.fromNow()}`;
  } else if (dayjs().diff(lastEdited, "day") >= 1) {
    return `Edited ${lastEdited.format("MMM D")}`;
  } else {
    return `Edited ${lastEdited.fromNow()}`;
  }
};

export const TemplateHeader = ({
  renderOptions = true,
  readOnly = false,
  renderToolbar = true,
  setIsLinkEditMode = () => {},
}: {
  renderOptions?: boolean;
  readOnly?: boolean;
  renderToolbar?: boolean;
  setIsLinkEditMode?: (value: boolean) => void;
}) => {
  const { template } = useTemplateStore();
  const updateTemplateMutation = useUpdateTemplate({});
  const [lastEditedTime, setLastEditedTime] = useState(template.updated_dt);

  useEffect(() => {
    setLastEditedTime(template.updated_dt);
  }, [template.updated_dt]);

  const handleTitleUpdate = (e, title, doc) => {
    const newTitle = e.currentTarget.innerText.trim();
    if (newTitle === title || newTitle === "") return;

    const updatedTemplate = {
      ...doc,
      text: doc.text.map((text, index) =>
        index === 0 ? { ...text, name: newTitle } : text
      ),
    };
    updateTemplateMutation.mutateAsync(updatedTemplate);
  };

  return (
    <div className="@container">
      <div
        className={cn(
          "flex flex-col px-4 pt-4 justify-between max-w-full w-full dark:shadow-md bg-white dark:bg-zinc-900 align-middle border-b border-b-zinc-200 dark:border-b-zinc-800 print:hidden overflow-hidden"
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center w-full space-x-2 pl-3">
            <Navigation />
            <p
              contentEditable={!readOnly}
              suppressContentEditableWarning={true}
              className="transition-all text-sm font-medium text-zinc-900 dark:text-white focus:rounded-sm focus:outline-[1.5px] focus:outline-emerald-600 dark:focus:rounded-sm dark:focus:outline-[2px] dark:focus:outline-emerald-600 dark:ring-none dark:border-none dark:ring-0 dark:ring-offset-0 dark:focus:ring-offset-zinc-900 dark:focus:ring-emerald-600 dark:focus:ring-opacity-20 dark:focus:ring-offset-opacity-20 dark:shadow-none ring-none border-none bg-transparent focus:outline-none truncate max-w-[200px] mr-4"
              onBlur={(e) =>
                handleTitleUpdate(
                  e,
                  template.text && template.text.length > 0
                    ? template.text[0].name
                    : "Untitled",
                  template
                )
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleTitleUpdate(
                    e,
                    template.text && template.text.length > 0
                      ? template.text[0].name
                      : "Untitled",
                    template
                  );
                  e.currentTarget.blur();
                }
              }}
              onInput={(e) => {
                if (e.currentTarget.innerText.length > 20) {
                  e.preventDefault();
                }
              }}
            >
              {template.text && template.text.length > 0
                ? template.text[0].name
                : "Untitled"}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-2xs text-zinc-400 flex-shrink truncate font-medium">
              {DateFormat(lastEditedTime)}
            </span>
            {renderOptions && (
              <>
                <ShareMenu type="template" />
              </>
            )}
          </div>
          {readOnly && (
            <div className="flex items-center space-x-2">
              <TbAlertCircleFilled className="h-3.5 w-3.5 text-amber-500" />
              <p className="text-xs text-zinc-600 dark:text-zinc-400">
                This document is read only.{" "}
                <Link to="/app/settings/plans" className="underline">
                  Resubscribe
                </Link>{" "}
                to enable editing.
              </p>
            </div>
          )}
        </div>
        {!readOnly && renderToolbar && (
          <div className="flex-grow">
            <ToolbarPlugin
              isCommentingEnabled={false}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          </div>
        )}
      </div>
    </div>
  );
};
