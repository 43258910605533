import { Button } from "@/components/Elements";
import { useEffect } from "react";
import { create } from "zustand";

export const useNavigationStore = create((set) => ({
  isNavOpen: true,
  openNav: () => set({ isNavOpen: true }),
  closeNav: () => set({ isNavOpen: false }),
  toggleNav: () => set((state) => ({ isNavOpen: !state.isNavOpen })),
  isPanelOpen: false,
  openPanel: () => set({ isPanelOpen: true }),
  closePanel: () => set({ isPanelOpen: false }),
}));

export function Navigation() {
  let { toggleNav, isNavOpen, openPanel, isPanelOpen, closeNav, closePanel } =
    useNavigationStore();

  useEffect(() => {
    function onKeyDown(event) {
      if (
        event.target.tagName === "INPUT" ||
        event.target.tagName === "TEXTAREA"
      ) {
        return;
      }
      if (event.target.isContentEditable) {
        return;
      }

      if (event.key === "[") {
        event.preventDefault();
        toggleNav();
      }
    }

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [openPanel, closePanel]);

  return (
    <Button
      variant="buttonIcon"
      aria-label="Toggle navigation"
      onClick={() => {
        toggleNav();
        closePanel();
      }}
      buttonIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"
            stroke="currentColor"
          ></path>
          <path d="M9 4l0 16" stroke="currentColor"></path>
        </svg>
      }
      size="lg"
      onMouseEnter={() => {
        !isNavOpen && openPanel();
      }}
      className="flex-shrink-0 mt-0.5 mr-4"
      tooltipContent={isNavOpen ? "Close sidebar" : "Open sidebar"}
      tooltipModifierKey="["
    ></Button>
  );
}
