import { User } from "@/features/users";

export interface Traits {
  email: string;
  firstname: string;
  lastname: string;
  verified: boolean;
  frase_id: string;
  frase_hash: string;
  browser_timezone?: string;
  org_admin?: boolean;
  sign_up_date?: number;
  day_count_since_sign_up?: number;
  last_login_date: number;
  user_persona?: string;
  company_size?: string;
  number_of_potential_seats?: string;
  keyword_research_tool?: string;
  f_gclid?: string;
  f_utm_campaign?: string;
  f_utm_term?: string;
  affiliate_link?: string;
  f_free_tool?: string;
  google_docs?: boolean;
  wordpress?: boolean;
  org_id?: string;
  free_trial?: number;
  stripe_customer_id?: string;
  successful_trial_payment?: boolean;
  subscription_status?: string;
  subscription_length?: number;
  subscription_plan?: string;
  subscription_start_date?: number;
  subscription_end_date?: number;
  trial_start_date?: number;
  trial_end_date?: number;
  subscription_addon?: boolean;
  appsumo?: boolean;
  document_count?: number;
  document_count_current_month?: number;
  first_document_date?: number;
  last_document_date?: number;
  crawl_count?: number;
  last_crawl_date?: number;
  assistant_count?: number;
  last_assistant_date?: number;
  assistant_50_live_logs?: boolean;
  assistant_200_live_logs?: boolean;
  assistant_400_live_logs?: boolean;
  assistant_sandbox?: boolean;
  google_search_console?: boolean;
  churn_reason?: string;
  beta?: boolean;
}

function setSignupCookies(user: User) {
  user.f_gclid = getCookie("f_gclid");
  user.f_utm_campaign = getCookie("f_utm_campaign");
  user.f_utm_term = getCookie("f_utm_term");
  user.f_free_tool = getCookie("f_free_tool");
  if (typeof window.fpr !== "undefined") {
    window.fpr("referral", { email: user.username });
    if (
      typeof window.FPROM !== "undefined" &&
      window.FPROM.data &&
      window.FPROM.data.cookie_ref_id
    ) {
      user.affiliate_link = window.FPROM.data.cookie_ref_id;
    }
  }
}

export function getIdentifyTraits(user: User): Traits {
  setSignupCookies(user);

  let traits: Traits = {
    email: user.username,
    firstname: user.fullName?.split(" ")[0],
    lastname: user.fullName?.split(" ")[1],
    verified: user.verified,
    frase_id: user.id.toString(),
    frase_hash: user.hash,
    last_login_date: new Date().setUTCHours(0, 0, 0, 0),
    beta: true,
    f_gclid: user.f_gclid,
    f_utm_campaign: user.f_utm_campaign,
    f_utm_term: user.f_utm_term,
    f_free_tool: user.f_free_tool,
    affiliate_link: user.affiliate_link,
  };

  if (Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    traits.browser_timezone = timezone;
  }

  if (user.org_role && user.org_role === 1) {
    traits.org_admin = true;
  }

  if (user && user.createdDate) {
    let date = new Date(user.createdDate);
    date.setUTCHours(0, 0, 0, 0);
    traits.sign_up_date = date;
  }

  return traits;
}

function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
}
