import { useGetGptTitle } from "@/features/ai/api/getGptTitle";
import { useCallback, useEffect, useRef, useState } from "react";
import { TbCheck, TbSparkles } from "react-icons/tb";
import { useDebounce } from "use-debounce";
import { Button, Input } from "../../../components/Elements";
import { useDocumentStore } from "../../../stores/document";
import { cn } from "../../../utils/style";
import { useUpdateDocument } from "../../documents/api/updateDocument";

export const TitleStep = ({
  setTitle,
  title,
  query,
  processedArticles: articles,
  selectedLanguage,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedTitles, setGeneratedTitles] = useState([]);
  const [generateTitle, setGenerateTitle] = useState(false);
  const titleInputRef = useRef(null);
  const [selectedTitle] = useDebounce(title, 300);
  const { document: fraseDocument } = useDocumentStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });

  const generateTitleQuery = useGetGptTitle({
    query,
    lang: selectedLanguage,
    serp: articles.map((article) => {
      return {
        title: article.title,
        url: article.url,
        snippet: article.clean_text?.slice(0, 50) + "...",
        author: article.author,
        date_created: article.dateCreated,
        image: (article.images?.length > 0 && article.images[0]) || "",
      };
    }),
    docHash: fraseDocument.hash,
    config: {
      enabled: generateTitle,
    },
  });

  const updateDocument = useCallback(
    async (newTitle) => {
      const updatedDocument = {
        ...fraseDocument,
        metadata: {
          ...fraseDocument.metadata,
          title: newTitle,
        },
      };
      updateDocumentMutation.mutateAsync(updatedDocument);
    },
    [updateDocumentMutation]
  );

  useEffect(() => {
    if (selectedTitle !== fraseDocument.metadata.title) {
      updateDocument(selectedTitle);
    }
  }, [selectedTitle]);

  useEffect(() => {
    if (generateTitleQuery.isSuccess) {
      setGeneratedTitles(generateTitleQuery.data?.titles);
      setIsGenerating(false);
    }

    if (generateTitleQuery.isLoading) {
      setIsGenerating(true);
    }
  }, [generateTitleQuery.isSuccess, generateTitleQuery.isLoading]);

  // Adjust the onChange handler to use the debounced function
  const handleTitleChange = (value: string) => {
    setTitle(value);
  };

  return (
    <div
      className="flex-col items-center justify-between p-4 w-1/2 max-w-[700px] mx-auto"
      ref={titleInputRef}
    >
      <h2 className="text-xl font-semibold text-zinc-800 dark:text-zinc-100">
        Title
      </h2>
      <p className="text-sm mt-4 font-normal text-zinc-600 dark:text-zinc-300">
        Write an eye-catching title that will increase click-through rates on
        search engines. Frase will use your title to generate relevant headings
        for your outline.
      </p>
      <Input
        type="text"
        value={title}
        onChange={(value) => handleTitleChange(value)}
        placeholder="Enter your title"
        className={cn(
          "mt-4",
          title.length > 0 &&
            "ring-emerald-600 disabled:opacity-100 dark:ring-emerald-600/60"
        )}
        autoFocus
        disabled={isGenerating}
        secondIcon={
          title.length > 0 && (
            <TbCheck
              className="flex-shrink-0"
              style={{
                strokeWidth: 3,
              }}
            />
          )
        }
        secondButtonClassName={cn(
          title.length > 0 && "visible text-emerald-900 dark:text-emerald-400"
        )}
      />
      {generatedTitles.length === 0 ? (
        <Button
          variant="aiBlur"
          onClick={() => {
            setGenerateTitle(true);
          }}
          isLoading={isGenerating}
          className="mt-4"
          startIcon={<TbSparkles />}
        >
          {isGenerating ? "Generating" : "Generate"} titles with AI
        </Button>
      ) : (
        <div className="mt-4">
          <h2 className="flex items-center text-sm font-medium text-zinc-800 dark:text-zinc-200">
            <TbSparkles className="mr-1" />
            Generated titles
          </h2>
          <p className="text-sm font-normal text-zinc-600 dark:text-zinc-300">
            Select a title to use for your article.
          </p>

          <div className="mt-4 flex flex-col space-y-2">
            {generatedTitles.map((generatedTitle) => {
              return (
                <Button
                  variant="outline"
                  className="w-fit"
                  onClick={() => {
                    setTitle(generatedTitle);
                    titleInputRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  {generatedTitle}
                </Button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
