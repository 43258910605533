import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import { useSeoAnalyticsStore } from "@/features/seo-analytics/store";
import React from "react";
import { TbAlertTriangle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

interface UpgradeToContinueDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

const UpgradeToContinueDialog: React.FC<UpgradeToContinueDialogProps> = ({
  open,
  onClose,
  onCancel,
}) => {
  const navigate = useNavigate();
  const { authorizedSites } = useSeoAnalyticsStore();

  return (
    <Dialog open={open}>
      <DialogContent className="sm:max-w-[425px] p-0 bg-white dark:bg-zinc-900 border border-zinc-200 dark:border-zinc-700">
        <DialogHeader className="px-6 pt-6 pb-0 flex items-center justify-between">
          <DialogTitle className="text-xl font-semibold text-zinc-900 dark:text-zinc-100 flex items-center">
            <TbAlertTriangle className="w-5 h-5 mr-2 text-yellow-500" />
            Upgrade Required
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 pt-2 text-sm text-zinc-600 dark:text-zinc-400">
          To use SEO analytics with this current site, you need to upgrade your
          plan. You already have {authorizedSites[0]} selected as your primary
          domain for SEO analytics.
        </DialogDescription>
        <div className="px-6 py-4">
          <Table className="w-full mb-4">
            <TableHeader className="bg-zinc-50 dark:bg-zinc-800">
              <TableRow>
                <TableHead className="w-1/2 py-1 px-4 text-left text-xs font-semibold text-zinc-900 dark:text-zinc-100">
                  Plan
                </TableHead>
                <TableHead className="w-1/2 py-1 px-4 text-right text-xs font-semibold text-zinc-900 dark:text-zinc-100">
                  Domains
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow className="border-b border-zinc-200 dark:border-zinc-700">
                <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                  Free / Solo
                </TableCell>
                <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                  0
                </TableCell>
              </TableRow>
              <TableRow className="border-b border-zinc-200 dark:border-zinc-700">
                <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                  Basic
                </TableCell>
                <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                  1
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                  Team
                </TableCell>
                <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                  Unlimited
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <DialogFooter className="px-6 pb-6 flex items-center justify-end space-x-2">
          <Button
            type="button"
            onClick={() => {
              onClose();
              onCancel();
            }}
            variant="outlineBlur"
            className="text-zinc-600 dark:text-zinc-400 border-zinc-300 dark:border-zinc-600 hover:bg-zinc-100 dark:hover:bg-zinc-800"
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="primaryBlur"
            className="bg-emerald-600 text-white hover:bg-emerald-700 dark:bg-emerald-500 dark:hover:bg-emerald-600"
            onClick={() => navigate("/app/settings/plans")}
          >
            Upgrade Plan
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeToContinueDialog;
