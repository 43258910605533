import ToggleGroup from "@/components/Elements/ToggleGroup/ToggleGroup";

export function AiToolPreviewHeader({
  activeOutputTab,
  setActiveOutputTab,
  outputTotal,
}: {
  activeOutputTab: string;
  setActiveOutputTab: (tab: string) => void;
  outputTotal: number;
}) {
  return (
    <div className="flex flex-col h-fit bg-white dark:bg-zinc-900 w-full">
      <div className="flex justify-between items-center px-6 h-14 w-full border-b dark:border-zinc-700">
        <div className="flex items-center">
          <div className="flex items-center space-x-4">
            <ToggleGroup
              options={[
                { value: "input", label: "Input" },
                {
                  value: "output",
                  label: "Output",
                  total: outputTotal,
                },
              ]}
              selectedValue={activeOutputTab}
              setSelectedValue={setActiveOutputTab}
            ></ToggleGroup>
          </div>
        </div>
      </div>
    </div>
  );
}
