import { ConfirmPlanChangeDialog } from "@/features/settings/components/ConfirmPlanChangeDialog";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDialogStore } from "../../../stores/upgrade";
import { AiArticleLimitDialog } from "./AiArticleLimitDialog";
import { CommentingUpgradeDialog } from "./CommentingUpgradeDialog";
import { FreeTrialLimitDialog } from "./FreeTrialLimitDialog";
import { PlanLimitDialog } from "./PlanLimitDialog";
import { UpgradeProAddonDialog } from "./UpgradeProAddonDialog";
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY as string);

export const UpgradeDialogs = () => {
  const { dialogType, eventDisplayName, closeDialog } = useDialogStore();

  return (
    <>
      {dialogType === "freeTrialLimit" && (
        <FreeTrialLimitDialog
          onClose={closeDialog}
          title="Upgrade your plan"
          text={eventDisplayName}
          isFreeTrial={true}
        />
      )}
      {dialogType === "planLimit" && (
        <PlanLimitDialog
          onClose={closeDialog}
          title="Upgrade your plan"
          text={eventDisplayName}
          isFreeTrial={false}
        />
      )}
      {dialogType === "aiArticleLimit" && (
        <AiArticleLimitDialog
          onClose={closeDialog}
          title="AI article generation limit reached"
          text={eventDisplayName}
          isFreeTrial={false}
        />
      )}
      {dialogType === "aiArticleUpgrade" && (
        <UpgradeProAddonDialog onClose={closeDialog} />
      )}
      {dialogType === "aiArticleConfirmUpgrade" && (
        <Elements stripe={stripePromise}>
          <ConfirmPlanChangeDialog
            title={"Upgrade to Pro add-on"}
            actionType="upgrade"
            triggerType="dialog"
            onClose={closeDialog}
            upgradeProAddOn={true}
          />
        </Elements>
      )}
      {dialogType === "commentingUpgrade" && (
        <CommentingUpgradeDialog onClose={closeDialog} />
      )}
    </>
  );
};
