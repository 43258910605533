import { ReactNode } from "react";
import { cn } from "../../../utils/style";

export function ShortcutTag({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <kbd
      className={cn(
        "ml-2 px-1.5 inline-flex justify-center items-center capitalize text-xs p-1 rounded h-5 whitespace-nowrap bg-zinc-100 dark:bg-zinc-700 font-sans ring-1 ring-zinc-300 dark:ring-zinc-700 dark:text-zinc-300",
        className
      )}
    >
      {children}
    </kbd>
  );
}
