import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";

export const AiArticleLimitDialog = ({ onClose, title, text }) => {
  const [open, setOpen] = useState(true);

  const close = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose(close);
        }
      }}
    >
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>{title}</DialogTitle>
            <DialogCloseButton
              close={() => {
                onClose(close);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4">
            <p className="text-sm text-zinc-600 dark:text-zinc-400">
              {text || "You have reached the limit of your current plan."}
            </p>
          </DialogDescription>
          <DialogFooter></DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
