import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Progress,
  Tag,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { Copy } from "lucide-react";
import { useState } from "react";
import { TbChevronRight } from "react-icons/tb";
import { cn } from "../../../../utils/style";
import { TopicCard } from "./TopicCard";

export const ClusterCard = ({
  topic,
  count,
  total,
  setSelectedTopic,
  item,
  color,
  handlePasteContent,
}) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  return (
    <Collapsible open={isPreviewOpen} onOpenChange={setIsPreviewOpen}>
      <CollapsibleTrigger className="w-full group p-2 py-2.5 cursor-pointer relative items-center rounded-md bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 hover:bg-white">
        <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span>
                    <Tag
                      variant="medium"
                      className="left-3 py-1"
                      color={color}
                      textClassName="font-bold"
                    >
                      {`${count} / ${total}`}
                    </Tag>
                  </span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>
                    You referenced <b>{topic}</b> {count} times.
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
            <p className="text-xs self-center font-medium dark:text-zinc-50 pl-2">
              {topic}
            </p>
          </div>
          <div className="flex items-center">
            <Button
              variant="buttonIcon"
              className="mr-1 invisible group-hover:visible"
              buttonIcon={<Copy />}
              tooltipContent={`Paste to editor`}
              onClick={(event) => {
                handlePasteContent(topic);
                event.stopPropagation();
              }}
            />
            <Button
              variant="buttonIcon"
              className={cn(
                "mr-1 invisible group-hover:visible",
                isPreviewOpen && "transform rotate-90 visible"
              )}
              buttonIcon={<TbChevronRight />}
              tooltipContent={`${
                isPreviewOpen ? "Hide" : "Show"
              } cluster topics`}
              onClick={(event) => {
                setIsPreviewOpen(!isPreviewOpen);
                event.stopPropagation();
              }}
            />
          </div>
        </div>
        <Progress
          progress={(count / total) * 10}
          className={`mt-2`}
          stripColor={color}
        />
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="space-y-2 pl-8 mt-2">
          {item.cluster_entities &&
            item.cluster_entities.map((item) => (
              <TopicCard
                item={item}
                setSelectedTopic={setSelectedTopic}
                key={item.entity} // Use item.entity instead of index as key
                count={item.user_count}
                total={item.frequency}
                topic={item.entity}
                color={item.bg_color}
              />
            ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
