import { FraseDocument } from "@/features/documents";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import { Template } from "@/features/templates";
import { useUpdateTemplate } from "@/features/templates/api/updateTemplate";
import { useEditorStore } from "@/stores/editor";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getRoot } from "lexical";
import { forwardRef, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

type Props = Readonly<{
  "data-test-id"?: string;
  label: string;
  onChange: (val: string) => void;
  placeholder?: string;
  content: FraseDocument | Template;
  setShowPlaceholders: (show: boolean) => void;
  showPlaceholders: boolean;
  setShowLinePlaceholder: (show: boolean) => void;
  type: "document" | "template";
  readOnly: boolean;
}>;

export const TitleInput = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      content,
      placeholder = "Document title",
      setShowPlaceholders,
      showPlaceholders,
      setShowLinePlaceholder,
      readOnly,
      type,
    },
    ref
  ) => {
    const updateContentMutation =
      type === "document"
        ? useUpdateDocument({ notifyOnSuccess: false })
        : useUpdateTemplate({});
    const [editor] = useLexicalComposerContext();
    const { editor: editorStore, setEditor } = useEditorStore();
    const activeTabIndex = type === "document" ? editorStore.activeTabIndex : 0;
    const [title, setTitle] = useState(
      content.text && content.text[activeTabIndex]
        ? content.text[activeTabIndex].title
        : ""
    );
    const [debouncedTitle] = useDebounce(title, 1000);
    const handleTitleChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      setTitle(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter") {
        setShowLinePlaceholder(true);
        setShowPlaceholders(false);
        event.preventDefault(); // Prevent the default action of Enter key in a textarea (new line)
        editor.update(() => {
          const root = $getRoot();
          const firstChild = root.getFirstChild();
          if (firstChild) {
            firstChild.selectStart();
            firstChild.insertBefore($createParagraphNode());
          }

          const newFirstChild = root.getFirstChild();
          if (newFirstChild) {
            newFirstChild.selectStart();
          }
        });
      }
    };

    useEffect(() => {
      if (!showPlaceholders) {
        editor.focus();
      }
    }, [showPlaceholders, editor]);

    useEffect(() => {
      if (!content || !content.text) return;

      let documentContent = {
        html: "",
        name: "",
        title: "",
      };
      documentContent = content.text[activeTabIndex] || documentContent;

      setTitle(documentContent.title);
      setEditor((editorStore) => {
        editorStore.title = documentContent.title;
      });
    }, [content.hash, activeTabIndex, content.text?.length, document]);

    useEffect(() => {
      if (!content || !content.text) return;

      if (
        debouncedTitle &&
        content?.text &&
        debouncedTitle !== content?.text[activeTabIndex]?.title
      ) {
        const updatedContent = {
          ...content,
          ...(type === "document" && {
            metadata: {
              ...content.metadata,
              active_tab_index: activeTabIndex,
            },
          }),
          text: content.text.map((text, index) =>
            index === activeTabIndex ? { ...text, title: debouncedTitle } : text
          ),
        };

        updateContentMutation.mutateAsync(updatedContent);

        setEditor((editorStore) => {
          editorStore.title = debouncedTitle;
        });
      }
    }, [debouncedTitle]);

    useEffect(() => {
      const handleResize = () => {
        if (ref.current) {
          ref.current.style.height = "40px";
          ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
      };

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, [ref, title]);

    return (
      <textarea
        id="title-input"
        ref={ref}
        value={title}
        disabled={readOnly}
        onChange={handleTitleChange}
        className="h-[40px] w-full leading-[1.2] px-8 mx-auto outline-none border-none 
  whitespace-pre-wrap break-words ring-transparent focus:outline-none 
  focus:border-none focus:ring-transparent text-[2em] font-bold 
  placeholder:text-zinc-400 dark:placeholder:text-zinc-600 
  bg-transparent text-zinc-900 dark:text-zinc-50 resize-none overflow-hidden"
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
    );
  }
);
