import { Button } from "@/components/Elements";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-xl text-gray-800 font-bold mb-4">Not found</h1>
      <p className="text-gray-600 mb-8">
        We could not find the page you were looking for.
      </p>
      <Button variant="primaryBlur" onClick={() => navigate("/")}>
        Return to home
      </Button>
    </div>
  );
};
