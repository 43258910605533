import { useUser } from "@/features/auth";
import { User } from "@/features/users";
import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";
import { canTrackUser } from "../utils/canTrackUser";
import { getIdentifyTraits } from "../utils/traits";

type IdentifyUserPayload = {
  userId: string;
  traits: string;
};

export const identifyUser = ({
  userId,
  traits,
  user,
}: IdentifyUserPayload & { user: User }): Promise<any> => {
  let baseTraits = getIdentifyTraits(user);
  if (!canTrackUser(baseTraits.email)) {
    return Promise.resolve();
  }

  let mergedTraits = { ...baseTraits, ...JSON.parse(traits) };

  if (mergedTraits.verified === false) {
    return Promise.resolve();
  }

  return axios.post("/identifyUser", {
    userId,
    traits: JSON.stringify(mergedTraits),
  });
};

export const useIdentifyUser = () => {
  const { data: user } = useUser();

  return useMutation((traits: string) => {
    if (!user) {
      return Promise.resolve();
    }
    return identifyUser({
      userId: user.id.toString(),
      traits,
      user: user as User,
    });
  });
};
