import { useNotificationStore } from "@/stores/notifications";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TbArrowLeft } from "react-icons/tb";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as z from "zod";
import { AuthUser } from "..";
import {
  Button,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "../../../components/Elements";
import { useResetPasswordWithKey } from "../api/resetPasswordWithKey";
import { useSendForgotPasswordEmail } from "../api/sendForgotPasswordEmail";

const emailSchema = z.object({
  email: z
    .string()
    .email("Invalid email address.")
    .min(1, "Email is required."),
});

const passwordSchema = z.object({
  password: z
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .regex(
      /(?=.*[a-zA-Z])(?=.*[0-9]|.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
      "Password must contain at least one letter, one number or special character."
    ),
  confirmPassword: z
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .regex(
      /(?=.*[a-zA-Z])(?=.*[0-9]|.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
      "Password must contain at least one letter, one number or special character."
    ),
});

export const ResetPasswordForm = ({
  onSuccess,
  user,
}: {
  onSuccess?: () => void;
  user?: AuthUser | null;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("_key");
  const [emailSent, setEmailSent] = useState(false);
  const { addNotification } = useNotificationStore();
  const { mutateAsync: sendForgotPasswordEmail, isLoading: isSendingEmail } =
    useSendForgotPasswordEmail();
  const { mutateAsync: resetPassword, isLoading: isResettingPassword } =
    useResetPasswordWithKey();

  const form = useForm({
    resolver: zodResolver(resetPasswordToken ? passwordSchema : emailSchema),
    defaultValues: resetPasswordToken
      ? { password: "", confirmPassword: "" }
      : { email: "" },
  });

  const onSubmitEmail = async (values) => {
    try {
      await sendForgotPasswordEmail({ email: values.email });
      setEmailSent(true);
    } catch (error) {
      const message =
        error.response?.status === 400
          ? "We were unable to find a user with that email address."
          : "Failed to send reset password email.";
      const title = error.response?.status === 400 ? "User not found" : "Error";
      addNotification({
        type: "error",
        title: title,
        message: message,
      });
    }
  };

  const onSubmitPassword = async (values) => {
    if (values.password !== values.confirmPassword) {
      addNotification({
        type: "error",
        title: "Error",
        message: "Passwords do not match.",
      });
      return;
    }

    try {
      await resetPassword({ resetPasswordToken, password: values.password });
      addNotification({
        type: "success",
        title: "Success",
        message: "Your password has been reset successfully.",
      });
      onSuccess && onSuccess();
    } catch (error) {
      addNotification({
        type: "error",
        title: "Error",
        message: "Failed to reset password.",
      });
    }
  };

  return (
    <div>
      {emailSent ? (
        <div className="text-center space-y-4">
          <p className="text-medium font-medium text-zinc-900">
            Please locate the email sent to {form.getValues().email} to verify
            your identity.
          </p>
          <p className="text-sm text-zinc-600">
            Please contact team@frase.io if you have not received the email.
          </p>
        </div>
      ) : resetPasswordToken ? (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmitPassword)}
            className="space-y-6"
          >
            <FormField
              control={form.control}
              name="password"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="New password"
                    {...field}
                  />
                  {fieldState.error && (
                    <FormMessage>{fieldState.error.message}</FormMessage>
                  )}
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>Confirm New Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Confirm new password"
                    {...field}
                  />
                  {fieldState.error && (
                    <FormMessage>{fieldState.error.message}</FormMessage>
                  )}
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              variant="primaryBlur"
              disabled={isResettingPassword}
              isLoading={isResettingPassword}
            >
              Confirm new password
            </Button>
          </form>
        </Form>
      ) : (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmitEmail)}
            className="space-y-6"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>Enter your Frase email address</FormLabel>
                  <Input
                    type="email"
                    placeholder="Frase email address"
                    {...field}
                  />
                  {fieldState.error && (
                    <FormMessage>{fieldState.error.message}</FormMessage>
                  )}
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              variant="primaryBlur"
              disabled={isSendingEmail}
              isLoading={isSendingEmail}
            >
              Continue
            </Button>
          </form>
        </Form>
      )}
      {!user ? (
        <div className="mt-8 text-center">
          <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
            Already have an account?{" "}
            <Link
              to="/login"
              className="text-emerald-600 hover:text-emerald-700 font-medium"
            >
              Log in
            </Link>
          </span>
        </div>
      ) : (
        <Button
          variant="text"
          startIcon={<TbArrowLeft />}
          className="mt-8 w-full"
          onClick={() => {
            navigate("/app/settings");
          }}
        >
          Return to settings
        </Button>
      )}
    </div>
  );
};
