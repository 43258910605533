import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

type UpdateBillingInfoPayload = {
  name: string;
  email: string;
  country: string;
  city: string;
  address_line_1: string;
  address_line_2?: string;
  zip_code: string;
  state: string;
};

type UpdateBillingInfoResponse = {
  billing_info: any;
};

export const updateBillingInfo = async (
  payload: UpdateBillingInfoPayload
): Promise<UpdateBillingInfoResponse> => {
  const { data } = await axios.post("/updateBillingInfo", payload);
  return data;
};

export const useUpdateBillingInfo = ({
  setOpen,
  config,
}: {
  setOpen: (open: boolean) => void;
  config?: any;
}) => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(updateBillingInfo, {
    ...config,
    onSuccess: () => {
      addNotification({
        type: "success",
        title: "Billing info updated",
        message: "Your billing information has been successfully updated.",
      });
      setOpen(false);
      queryClient.invalidateQueries("subscriptionUpdate");
    },
  });
};
