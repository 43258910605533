import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

interface RemoveUserPayload {
  userEmail: string;
}

const removeUserFromOrg = async (payload: RemoveUserPayload): Promise<void> => {
  await axios.post("/removeUserFromOrg", payload);
};

export const useRemoveUser = (): UseMutationResult<
  void,
  unknown,
  RemoveUserPayload
> => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  return useMutation(removeUserFromOrg, {
    onSuccess: () => {
      addNotification({
        type: "success",
        title: "User removed",
        message: "The user was successfully removed from the organization.",
      });
      queryClient.refetchQueries("usersForOrg");
    },
  });
};
