import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

interface BeginSubscriptionRequest {
  source: {
    id: string;
  };
  plans: Array<{ plan: string; quantity?: number }>;
}

const beginCustomerSubscription = async (
  requestData: BeginSubscriptionRequest
) => {
  const { data } = await axios.post(
    "/stripe/begin_customer_subscription",
    requestData
  );
  return data;
};

export const useBeginSubscription = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(beginCustomerSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("subscription");
      queryClient.invalidateQueries("upcomingInvoice");

      addNotification({
        type: "success",
        title: "Subscription started",
        message: "Your subscription has been successfully started.",
      });
    },
    onError: (error) => {
      addNotification({
        type: "error",
        title: "Subscription failed",
        message: `An error occurred: ${error.message}. Please try again or contact support.`,
      });
    },
  });
};
