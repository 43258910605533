import { Button, DialogModal, Textarea } from "@/components/Elements";
import { useEffect, useState } from "react";
import { TbInfoCircle } from "react-icons/tb";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";
import { useInviteUsersToOrg } from "../../teams/api/inviteUser";

export const InviteMembersButton = ({
  disabled = false,
  maxTeamSize = Infinity,
  currentTeamSize = 0,
}) => {
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState("");
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotificationStore();
  const { mutate: inviteUsers, isLoading: inviteUsersQuery } =
    useInviteUsersToOrg();

  useEffect(() => {
    if (open) {
      setEmails("");
      setLoading(false);
    }
  }, [open]);

  const checkUserExists = async (email) => {
    try {
      const response = await axios.post("/doesUserExistSystem", { email });
      return response; // Assuming the API returns { exists: boolean }
    } catch (error) {
      // Handle error (e.g., show notification)
      return false;
    }
  };

  const handleSubmit = async () => {
    const processedEmails = emails.split(",").map((email) => email.trim());

    // Check if adding these users would exceed the team size limit
    if (currentTeamSize + processedEmails.length > maxTeamSize) {
      addNotification({
        title: "Team Size Limit",
        message: `Adding ${
          processedEmails.length
        } members would exceed your plan's team size limit of ${maxTeamSize} members. You can add up to ${
          maxTeamSize - currentTeamSize
        } more members.`,
        type: "error",
      });
      setLoading(false);
      return;
    }

    let invalidEmails = [];
    let nonExistentEmails = [];
    let existingEmails = [];
    setLoading(true);

    for (const email of processedEmails) {
      // Simple email validation check
      if (!/^\S+@\S+\.\S+$/.test(email)) {
        invalidEmails.push(email);
      } else {
        // Check if the user exists
        const exists = await checkUserExists(email);
        if (!exists) {
          nonExistentEmails.push(email);
        } else {
          existingEmails.push(email);
        }
      }
    }

    if (invalidEmails.length > 0) {
      addNotification({
        title: "Invalid Email",
        message: `The following emails are invalid: ${invalidEmails.join(
          ", "
        )}.`,
        type: "error",
      });
      setLoading(false);
      return;
    }

    if (nonExistentEmails.length > 0) {
      const payload = {
        userEmails: nonExistentEmails.map((email) => ({ email, role: 2 })),
      };

      // Use the `inviteUsers` mutation to send invites
      inviteUsers(payload, {
        onSuccess: () => {
          addNotification({
            title: `${nonExistentEmails.length} invites sent`,
            message: `Invites have been sent to the following emails: ${nonExistentEmails.join(
              ", "
            )}.`,
            type: "success",
          });
          setOpen(false);
        },
        onError: (error) => {
          // Handle the error case
          addNotification({
            title: "Error",
            message: "There was an error sending the invites.",
            type: "error",
          });
        },
      });
    } else {
      addNotification({
        title: "Users already exist",
        message: `The users with the following emails already exist: ${processedEmails.join(
          ", "
        )}.`,
        type: "info",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="button"
        variant="outlineBlur"
        size="xs"
        className="flex-shrink-0 h-8"
        onClick={() => setOpen(true)}
        disabled={disabled}
        title={
          disabled ? `Team size limit of ${maxTeamSize} members reached` : ""
        }
      >
        Invite members
      </Button>
      <DialogModal
        open={open}
        setOpen={setOpen}
        className="gap-0"
        variant="fitContent"
        title="Invite members by email"
        footer={
          <div className="mt-4 text-center flex-col">
            <Button
              type="submit"
              variant="primaryBlur"
              disabled={emails.length === 0}
              isLoading={inviteUsersQuery || loading}
              onClick={handleSubmit}
            >
              Send invites
            </Button>
          </div>
        }
      >
        <div className="mt-2">
          <Textarea
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            placeholder="Enter email addresses separated by commas"
            rows={3}
          />
          {maxTeamSize !== Infinity && (
            <div className="flex items-center space-x-2">
              <TbInfoCircle className="text-zinc-500" />
              <p className="text-xs text-zinc-500">
                You can add up to {maxTeamSize - currentTeamSize} more team
                members
              </p>
            </div>
          )}
        </div>
      </DialogModal>
    </>
  );
};
