import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";

type RefreshTokenResponse = {
  accessToken: string;
  refreshToken: string;
};

export const refreshAccessToken = async (
  refreshToken: string
): Promise<RefreshTokenResponse> => {
  return axios.post("/api/refreshToken", {
    refresh_token: refreshToken,
  });
};

export const useRefreshAccessToken = () => {
  return useMutation(refreshAccessToken);
};
