export function setMenuPosition(
  floatingElem: HTMLElement,
  anchorElem: HTMLElement,
  targetLineElem: HTMLElement,
  topPadding: number = 0
) {
  // Only proceed if the floating element is visible
  if (floatingElem.style.display === "none") {
    return;
  }

  const anchorElementRect = anchorElem.getBoundingClientRect();
  const targetLineRect = targetLineElem.getBoundingClientRect();

  const top =
    targetLineRect.top -
    anchorElementRect.top +
    topPadding -
    targetLineRect.height -
    4;

  const left = 24;

  // get the computed margin of the anchor element
  const { marginLeft } = window.getComputedStyle(anchorElem);

  // apply the margin to the floating element
  floatingElem.style.marginLeft = marginLeft;

  floatingElem.style.opacity = "1";
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
  if (topPadding === 0) {
    floatingElem.style.width = `100%`;
  }
}

export function updateMenuPosition(
  inputElem: HTMLElement,
  menuElem: HTMLElement
) {
  // Only proceed if the menu element is visible
  if (menuElem.style.display === "none") {
    return;
  }

  const inputRect = inputElem.getBoundingClientRect();
  const menuRect = menuElem.getBoundingClientRect();

  // Calculate the top position of the menu to dock it to the bottom of the input element
  const top = inputRect.bottom - menuRect.top + 4;

  // Set the transition property for animation
  menuElem.style.transition = "transform 0.1s";

  // Set the menu top to align it with the bottom of the input element
  menuElem.style.top = `${top + 100}px`;
  menuElem.style.paddingBottom = `${200}px`;
  menuElem.style.scrollMarginBottom = `${200}px`;
}
