import { ContentLayout } from "@/components/Layout";

import { TemplatesList } from "../components/TemplatesList";

export const Templates = () => {
  return (
    <ContentLayout>
      <TemplatesList />
    </ContentLayout>
  );
};
