"use client";

import { CaretDownIcon } from "@radix-ui/react-icons";
import { Check } from "lucide-react";
import * as React from "react";
import { useEffect } from "react";
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/Elements";
import { languageOptions } from "../../../components/Elements/Dialog/newDocumentOptions";
import { cn } from "../../../utils/style";

export function LanguageSelect({ selectedLanguage, setSelectedLanguage }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(selectedLanguage);

  useEffect(() => {
    if (value !== selectedLanguage) {
      setSelectedLanguage(value);
    }
  }, [value, selectedLanguage]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outlineBlur"
          aria-expanded={open}
          size="sm"
          className={cn("w-full dark:bg-zinc-900 text-left")}
          textClassName="text-xs font-normal truncate w-full"
          role="combobox"
          endIcon={<CaretDownIcon />}
        >
          {value
            ? languageOptions.find((language) => language.value === value)
                ?.label
            : "Select language..."}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-[55]">
        <Command className="max-h-80">
          <CommandInput placeholder="Search language..." />

          <CommandEmpty>No language found.</CommandEmpty>
          <CommandGroup>
            {languageOptions.map((language) => (
              <CommandItem
                key={`${language.label}.${language.value}`}
                value={`${language.label}.${language.value}`}
                onSelect={(currentValue) => {
                  setValue(
                    currentValue === value
                      ? ""
                      : currentValue.split(".")[1] || ""
                  );
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === language.value ? "opacity-100" : "opacity-0"
                  )}
                />
                {language.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
