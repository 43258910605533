import { create } from "zustand";

type DialogType =
  | "freeTrialLimit"
  | "planLimit"
  | "aiArticleLimit"
  | "aiArticleUpgrade"
  | "aiArticleConfirmUpgrade"
  | "legacyUser"
  | "commentingUpgrade"
  | null;

type DialogsStore = {
  dialogType: DialogType;
  eventDisplayName: string;
  openDialog: (type: DialogType, displayName: string) => void;
  closeDialog: () => void;
};

export const useDialogStore = create<DialogsStore>((set) => ({
  dialogType: null,
  eventDisplayName: "",
  openDialog: (type, displayName) =>
    set({ dialogType: type, eventDisplayName: displayName }),
  closeDialog: () => set({ dialogType: null }),
}));
