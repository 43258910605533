import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation } from "react-query";

import { Folder } from "../types";

export const updateFolder = (channel: {
  id: string;
  name: string;
  description: string;
}): Promise<Folder> => {
  return axios.post(`/editTeamText`, {
    team_id: channel.id,
    team_name: channel.name,
    team_description: channel.description,
  });
};

type UseUpdateFolderOptions = {
  config?: MutationConfig<typeof updateFolder>;
};

export const useUpdateFolder = ({ config }: UseUpdateFolderOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    mutationFn: updateFolder,
    onSuccess: () => {
      queryClient.invalidateQueries("folderInfo");
      queryClient.invalidateQueries("channels");
      addNotification({
        type: "success",
        title: "Folder Updated",
      });
    },
    ...config,
  });
};
