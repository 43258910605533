import { cn } from "@/utils/style";

export const Card = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  props?: React.HTMLAttributes<HTMLDivElement>;
}) => {
  return (
    <div
      {...props}
      className={cn(
        "flex mt-2 px-2 py-2 text-2xs dark:text-white bg-white dark:bg-zinc-900 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 hover:bg-zinc-50 dark:hover:bg-zinc-800 hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5",
        className
      )}
    >
      {children}
    </div>
  );
};
