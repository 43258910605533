export function setSubmenuPosition(
  submenuElem: HTMLElement,
  menuElem: HTMLElement
) {
  const subMenuElementRect = submenuElem.getBoundingClientRect();
  const menuElementRect = menuElem.getBoundingClientRect();

  const top =
    menuElementRect.top -
    subMenuElementRect.top +
    menuElementRect.height -
    subMenuElementRect.height;
  const left =
    menuElementRect.left - subMenuElementRect.left + menuElementRect.width;

  submenuElem.style.opacity = "1";
  submenuElem.style.transform = `translate(${left}px, ${top}px)`;
}
