import { Button, DropdownDialogItem } from "@/components/Elements";
import { ArchiveBoxIcon, DocumentIcon } from "@heroicons/react/24/solid";
import { useUnarchiveDocument } from "../api/unarchiveDocument";

type UnarchiveDocumentProps = {
  documentTitles: string[];
  documentIds: string[];
  triggerButton?: React.ReactNode;
  onClose?: () => void;
  onSelect?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
};

const UnarchiveDocumentDialog = ({
  documentTitles,
  documentIds,
  onClose,
  onSelect,
  onOpenChange,
  ...itemProps
}: UnarchiveDocumentProps) => {
  const unarchiveDocumentMutation = useUnarchiveDocument({});

  const documentPlural = documentTitles.length > 1 ? "documents" : "document";
  const uppercaseDocumentPlural =
    documentPlural[0].toUpperCase() + documentPlural.slice(1);
  const body = (
    <>
      Are you sure you want to unarchive the following {documentPlural}?
      <ul className="list-disc list-inside space-y-2 my-2">
        {documentTitles.map((title) => (
          <li
            key={title}
            className="flex items-center list-none font-semibold text-zinc-900 dark:text-white dark:bg-zinc-700"
          >
            <DocumentIcon className="h-3 w-3 mr-2" />
            {title}
          </li>
        ))}
      </ul>
    </>
  );
  const confirmButton = (
    <Button
      isLoading={unarchiveDocumentMutation.isLoading}
      variant="primaryBlur"
      onClick={async () => {
        await Promise.all(
          documentIds.map((id) =>
            unarchiveDocumentMutation.mutateAsync({ documentId: Number(id) })
          )
        )
          .then(() => {
            onClose && onClose();
          })
          .catch(() => {});
      }}
    >
      Unarchive
    </Button>
  );

  return (
    <DropdownDialogItem
      icon={<ArchiveBoxIcon />}
      label="Unarchive document"
      title={`Unarchive ${uppercaseDocumentPlural}`}
      body={body}
      close={onClose}
      confirmButton={confirmButton}
      onSelect={onSelect}
      onOpenChange={onOpenChange}
      {...itemProps}
    />
  );
};

export const UnarchiveDocument = UnarchiveDocumentDialog;
