import { Template } from "@/features/templates";
import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

export const getAiToolBookmarks = async (ai: Template): Promise<Template[]> => {
  const response = await axios.post(`/getBookmarkedTemplates`, ai);
  const sortedData = response.sort(
    (a: Template, b: Template) =>
      b.metadata.last_run_dt - a.metadata.last_run_dt
  );
  return sortedData;
};

export const useGetAiToolBookmarks = (ai: Template) => {
  return useQuery(["getAiToolBookmarks", ai], () => getAiToolBookmarks(ai));
};
