import { useMutation, useQueryClient } from "react-query";

import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";

export const deleteTemplate = ({ templateId }: { templateId: string }) => {
  return axios.post(`/deleteTemplate`, {
    id: templateId,
  });
};

type UseDeleteTemplateOptions = {
  config?: MutationConfig<typeof deleteTemplate>;
  notifyOnSuccess?: boolean;
};

export const useDeleteTemplate = ({
  config,
  notifyOnSuccess = true,
}: UseDeleteTemplateOptions) => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteTemplate,
    onMutate: async ({ templateId }: { templateId: string }) => {
      await queryClient.cancelQueries(["templates"], { exact: false });

      if (!templateId) return;

      return { templateId };
    },
    onError: () => {
      addNotification({
        type: "error",
        title: "Failed to delete template",
        message: "Please contact support for help with this issue.",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("templates", { exact: false });

      if (!notifyOnSuccess) return;

      addNotification({
        type: "success",
        title: "Template deleted",
        message: "Template has been successfully deleted.",
      });
    },
    ...config,
  });
};
