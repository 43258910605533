import { Template } from "@/features/templates/types";
import { useEditorStore } from "@/stores/editor";
import { $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getRoot, $insertNodes } from "lexical";
import { useEffect } from "react";
import { getWordCountOfTextNode } from "../WordCountPlugin";

const CONTENT_LABEL = "Tab 1";

export default function PrepopulateTemplate({
  template,
}: {
  template: Template;
}) {
  const [editor] = useLexicalComposerContext();
  const { setEditor } = useEditorStore();

  useEffect(() => {
    editor.update(() => {
      if (!template || template.text === null) {
        return;
      }

      let templateContent = {
        html: "",
        title: "",
        name: CONTENT_LABEL,
      };
      templateContent = template.text[0] || templateContent;

      const parser = new DOMParser();
      const dom = parser.parseFromString(templateContent.html, "text/html");

      let nodes = $generateNodesFromDOM(editor, dom);
      $getRoot().select();
      $getRoot().clear();

      if (nodes.length === 0) {
        nodes.push($createParagraphNode());
      }

      $insertNodes(nodes);

      setEditor((editorState) => {
        editorState.isPopulated = true;
      });

      $getRoot().selectStart();
      const root = $getRoot();
      const text = root.getTextContent();
      const numWords = getWordCountOfTextNode(text);
      setEditor((editorState) => {
        editorState.wordCount = numWords;
        editorState.isRefreshingDocument = false;
      });
    });
  }, [template.hash, template.text.length]);

  return null;
}
