import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Elements";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { TrashIcon } from "lucide-react";
import * as React from "react";
import { TbPlus } from "react-icons/tb";

export type Filter = {
  value: string;
  label: string;
  children?: Filter[];
  parent?: string;
  type: "input" | "filter";
  icon?: React.ReactNode;
  color?: string;
};

export function Filter(props: {
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  filters?: Filter[];
  selectedFilters?: Filter[];
  variant?: "plus" | "default";
}) {
  const {
    setSelectedFilters,
    filters,
    selectedFilters,
    variant = "default",
  } = props;

  const [open, setOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState<Filter | null>(
    null
  );
  const [selectedFilter, setSelectedFilter] = React.useState<Filter | null>(
    null
  );
  const [filterSearch, setFilterSearch] = React.useState("");

  React.useEffect(() => {
    const down = (event: KeyboardEvent) => {
      if (
        event.target instanceof HTMLElement &&
        (event.target.tagName === "INPUT" ||
          event.target.tagName === "TEXTAREA" ||
          event.target.getAttribute("role") === "dialog")
      ) {
        return;
      }
      if (event.target?.isContentEditable) {
        return;
      }

      if (event.key === "f" && !open) {
        event.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [open]);

  React.useEffect(() => {
    if (!open) {
      setSelectedFilter(null);
      setSelectedCategory(null);
    }
  }, [open]);

  React.useEffect(() => {
    if (!selectedFilter) return;
    setSelectedFilters((filters) => [...filters, selectedFilter]);
  }, [selectedFilter]);

  const selectedFiltersCount = React.useMemo(() => {
    return selectedFilters?.length || 0;
  }, [selectedFilters]);

  const renderFilterIcon = (icon: React.ReactNode) => {
    const styledIcon = React.cloneElement(icon as React.ReactElement);

    return (
      <div className="h-3 w-3 text-zinc-600 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 mr-3">
        {styledIcon}
      </div>
    );
  };

  const renderButton = () => {
    if (selectedFiltersCount > 0 && variant === "plus") {
      return (
        <Button
          variant="outlineBlur"
          size="2xs"
          className="justify-start mr-2"
          textClassName="ml-1"
          buttonIcon={
            <TbPlus
              strokeWidth={2.5}
              className="flex-shrink-0 stroke-zinc-600 dark:stroke-white"
            />
          }
          tooltipContent="Add filter"
          tooltipModifierKey="F"
        >
          Add Filter
        </Button>
      );
    } else {
      if (selectedFiltersCount === 0) {
        return (
          <Button
            variant="outlineBlur"
            size="2xs"
            className="justify-start flex items-center flex-shrink-0 mr-2"
            textClassName="ml-2 flex items-center"
            buttonIcon={
              selectedCategory ? (
                selectedCategory.icon
              ) : (
                <TbPlus
                  strokeWidth={2.5}
                  className="flex-shrink-0 stroke-zinc-600 dark:stroke-white"
                />
              )
            }
            tooltipContent="Filter documents"
            tooltipModifierKey="F"
          >
            {selectedCategory ? selectedCategory.label : "Filter"}
          </Button>
        );
      } else {
        return (
          <Button
            variant="outlineBlur"
            size="2xs"
            className="justify-start whitespace-nowrap mr-2"
            startIcon={<TrashIcon />}
            onClick={(e) => {
              setSelectedFilters([]);
              e.stopPropagation();
            }}
          >
            Clear Filters
          </Button>
        );
      }
    }
  };

  return (
    <div className="flex items-center space-x-4 backdrop-blur-lg bg-white/50 dark:bg-zinc-900/50">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{renderButton()}</PopoverTrigger>
        {selectedCategory ? (
          <PopoverContent className="p-0" side="bottom" align="start">
            {selectedCategory.type === "input" ? (
              <Input
                autoFocus
                startIcon={<MagnifyingGlassIcon />}
                onChange={(value) => setFilterSearch(value)}
                onKeyDown={async function (
                  event: React.KeyboardEvent<HTMLInputElement>
                ): Promise<void> {
                  if (event.key === "Escape") {
                    setOpen(false);
                  } else if (event.key === "Enter") {
                    setSelectedFilter({
                      ...selectedCategory,
                      value: filterSearch,
                      label: filterSearch,
                    });

                    setOpen(false);
                  }
                }}
              />
            ) : (
              <Command>
                <CommandInput placeholder={selectedCategory.label} />
                <CommandList>
                  <CommandEmpty>No results found.</CommandEmpty>
                  <CommandGroup>
                    {selectedCategory.children?.map((filter) => (
                      <CommandItem
                        key={filter.value}
                        value={`${filter.value}:${filter.label}:${filter.parent}`}
                        onSelect={(value) => {
                          const [internalValue, searchLabel, parent] =
                            value.split(":");

                          setSelectedFilter(
                            selectedCategory.children?.find(
                              (priority) =>
                                priority.value.toString().toLowerCase() ===
                                internalValue.toString().toLowerCase()
                            ) || null
                          );

                          setOpen(false);
                        }}
                        className="flex items-center"
                      >
                        {renderFilterIcon(filter.icon)}
                        <span className="text-zinc-600 dark:text-zinc-300">
                          {filter.label}
                        </span>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            )}
          </PopoverContent>
        ) : (
          <PopoverContent className="p-0" side="bottom" align="start">
            <Command>
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  {filters
                    ?.filter(
                      (f) =>
                        selectedFilters?.find((s) => s.parent === f.value) ===
                        undefined
                    )
                    .map((filter) => (
                      <CommandItem
                        key={filter.value}
                        value={filter.value}
                        onSelect={(value) => {
                          setSelectedCategory(
                            filters.find(
                              (priority) =>
                                priority.value.toLowerCase() ===
                                value.toLowerCase()
                            ) || null
                          );
                        }}
                        className="flex items-center"
                      >
                        {renderFilterIcon(filter.icon)}
                        <span className="text-zinc-600 dark:text-zinc-300">
                          {filter.label}
                        </span>
                      </CommandItem>
                    ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        )}
      </Popover>
    </div>
  );
}
