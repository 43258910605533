import { axios } from "@/lib/axios";
import { useMutation } from "react-query";

interface DeleteAiToolOutputPayload {
  id: string;
  template_hash: string;
}

interface DeleteAiToolOutputResponse {
  success: boolean;
}

const deleteAiToolOutput = async (
  payload: DeleteAiToolOutputPayload
): Promise<DeleteAiToolOutputResponse> => {
  const response = await axios.post<DeleteAiToolOutputResponse>(
    `/deleteTemplateOutput`,
    payload
  );
  return response.data;
};

export const useDeleteAiToolOutput = () => {
  return useMutation<
    DeleteAiToolOutputResponse,
    Error,
    DeleteAiToolOutputPayload
  >(deleteAiToolOutput);
};
