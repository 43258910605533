import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

const fetchPendingUsers = async () => {
  const data = await axios.post("/getPendingUsers", {});
  return data;
};

export const useGetPendingUsers = () => {
  return useQuery("pendingUsers", fetchPendingUsers);
};
