"use client";

import {
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";

export function Notifications() {
  const { notifications, dismissNotification } = useNotificationStore();

  const ToastIcon = ({ type }: { type: string }) => {
    switch (type) {
      case "success":
        return <CheckCircleIcon className="w-4 h-4 fill-emerald-600" />;
      case "warning":
        return <ExclamationCircleIcon className="w-4 h-4 fill-amber-400" />;
      case "error":
        return <ExclamationCircleIcon className="w-4 h-4 fill-red-600" />;
      case "info":
        return <QuestionMarkCircleIcon className="w-4 h-4 fill-zinc-600" />;
      default:
        return null;
    }
  };

  return (
    <ToastProvider className="print:hidden">
      {notifications.map(function ({
        id,
        title,
        type,
        message,
        action,
        duration = 5000,
        ...props
      }) {
        return (
          <Toast
            key={id}
            {...props}
            className="flex-col items-start gap-1 print:hidden"
            duration={duration}
          >
            <div className="flex items-center">
              <ToastIcon type={type} />
              {title && <ToastTitle className="ml-2">{title}</ToastTitle>}
            </div>
            {typeof message === "string" ? (
              <ToastDescription className="pl-2">{message}</ToastDescription>
            ) : (
              <span className="pl-2">{message}</span>
            )}
            {action && (
              <ToastAction onClick={action?.onClick} altText={action?.label}>
                {action?.label}
              </ToastAction>
            )}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
