import { Template } from "@/features/templates";
import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";

export const getAiToolById = ({ id }: { id: string }): Promise<Template> => {
  return axios.post(`/getTemplateById`, {
    id: id,
  });
};

type QueryFnType = typeof getAiToolById;

type UseAiToolOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetAiToolById = ({ id, config }: UseAiToolOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["getAiToolById", id],
    queryFn: () => getAiToolById({ id }),
  });
};
