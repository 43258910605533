import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

type GetUserOrgPayload = {
  userHash: string;
};

type BillingInfo = {
  name: string;
  email: string;
  country: string;
  city: string;
  address_line_1: string;
  address_line_2: string;
  zip_code: string;
  state: string;
};

type GetUserOrgResponse = {
  id: number;
  name: string;
  description: string | null;
  icon: string | null;
  language: string | null;
  timezone: string | null;
  integrations: string | null;
  plan: string;
  api_plan: string;
  stripe_customer_id: string;
  subscription_id: string;
  auth_token: string;
  teams: string | null;
  users: string | null;
  orgAdmins: string | null;
  org_hash_id: string;
  orgRepo: string | null;
  referral: string | null;
  pauseSubscription: boolean;
  schema: string | null;
  answer_engine_form: boolean;
  add_on: boolean;
  appsumo_issue: boolean;
  fixed_annual_pricing: boolean;
  lock_account: boolean;
  service_plan: string | null;
  writers: string;
  writerEmails: string | null;
  writer_full_name: string | null;
  writerInfo: string | null;
  billing_info: BillingInfo;
  free_trial: string | null;
  legacy_answers: boolean;
  model_cd: string | null;
  model_desc: string | null;
  token: string | null;
  default_token: boolean;
  stripe_cancelled_dt: string | null;
};

export const getUserOrg = ({
  userHash,
}: GetUserOrgPayload): Promise<GetUserOrgResponse> => {
  return axios.post(`/getOrg`, {
    user_hash: userHash,
  });
};

type UseGetUserOrgOptions = {
  userHash: string;
  config?: any;
};

export const useGetUserOrg = ({ userHash, config }: UseGetUserOrgOptions) => {
  return useQuery<GetUserOrgResponse>({
    ...config,
    queryKey: ["getUserOrg", { userHash }],
    queryFn: () => getUserOrg({ userHash }),
  });
};
