import { ContentLayout } from "@/components/Layout";

import { DocumentsList } from "../components/DocumentsList";

export const Documents = () => {
  return (
    <ContentLayout>
      <DocumentsList />
    </ContentLayout>
  );
};
