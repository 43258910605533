import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import { Input } from "@/components/Elements/Input";
import React, { useState } from "react";
import { TbChevronRight } from "react-icons/tb";

interface QueryFilterDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onApplyFilter: (
    input: string | null,
    filterType:
      | "contains"
      | "not_contains"
      | "including_regex"
      | "excluding_regex"
  ) => void;
}

const QueryFilterDialog: React.FC<QueryFilterDialogProps> = ({
  open,
  setOpen,
  onApplyFilter,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [queryFilterType, setQueryFilterType] = useState<
    "contains" | "not_contains" | "including_regex" | "excluding_regex"
  >("contains");
  const [queryInput, setQueryInput] = useState("");

  const regexFormulas = [
    { name: "Starts with", regex: "^" },
    { name: "Ends with", regex: "$" },
    { name: "Contains exactly", regex: "\\b\\b" },
  ];

  const regexOptions: {
    value: "contains" | "not_contains" | "including_regex" | "excluding_regex";
    label: string;
  }[] = [
    { value: "contains", label: "Contains" },
    { value: "not_contains", label: "Not Contains" },
    { value: "including_regex", label: "Matches Regex" },
    { value: "excluding_regex", label: "Doesn't Match Regex" },
  ];

  const handleApplyFilter = async () => {
    setSubmitLoading(true);
    await onApplyFilter(queryInput, queryFilterType);
    setSubmitLoading(false);
    setOpen(false);
  };

  const setRegexFormula = (regex: string) => {
    setQueryInput((prev) => prev + regex);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="p-0">
        <DialogHeader className="pb-0 flex items-center justify-between p-4">
          <DialogTitle>Query Filter</DialogTitle>
          <DialogCloseButton close={() => setOpen(false)} />
        </DialogHeader>
        <DialogDescription className="px-4 pb-4">
          <div className="space-y-4">
            <div className="flex flex-wrap gap-4">
              {regexOptions.map(({ value, label }) => (
                <label
                  key={value}
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <input
                    type="radio"
                    value={value}
                    checked={queryFilterType === value}
                    onChange={() => setQueryFilterType(value)}
                    className="form-radio text-blue-500 focus:ring-blue-500"
                  />
                  <span className="text-sm text-zinc-600 dark:text-zinc-400">
                    {label}
                  </span>
                </label>
              ))}
            </div>
            <div className="border-b border-zinc-200 dark:border-zinc-700 pb-2">
              <Input
                placeholder="Input keyword..."
                value={queryInput}
                onChange={(value) => setQueryInput(value)}
                className="border-none text-base w-full"
              />
            </div>
            {queryFilterType === "including_regex" && (
              <div className="text-sm text-zinc-600 dark:text-zinc-400">
                <span>Regex Examples:</span>
                <ul className="mt-2 space-y-2">
                  {regexFormulas.map((formula, index) => (
                    <li key={index}>
                      <Button
                        variant="text"
                        size="sm"
                        onClick={() => setRegexFormula(formula.regex)}
                        className="text-blue-500 hover:text-blue-600 p-0"
                      >
                        {formula.name}
                        <TbChevronRight className="inline-block ml-1" />
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </DialogDescription>
        <DialogFooter className="px-4 py-4 bg-zinc-50 dark:bg-zinc-800 rounded-bl-md rounded-br-md">
          <Button
            variant="outlineBlur"
            onClick={() => setOpen(false)}
            disabled={submitLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primaryBlur"
            onClick={handleApplyFilter}
            disabled={!queryInput}
            isLoading={submitLoading}
          >
            Apply Filter
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default QueryFilterDialog;
