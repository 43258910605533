import { Button } from "@/components/Elements";
import clsx from "clsx";
import * as React from "react";
import { TbX } from "react-icons/tb";

const variantStyles = {
  small: "rounded-md ring-1 px-0.5 py-0.5 ring-inset",
  medium: "rounded-md ring-1 px-0.5 py-0.5 ring-inset",
};

const colorStyles = {
  emerald: {
    small:
      "ring-emerald-300 dark:ring-emerald-400/30 bg-emerald-400/10 text-emerald-600 dark:text-emerald-400",
    medium:
      "ring-emerald-300 dark:ring-emerald-400/30 bg-emerald-400/10 text-emerald-600 dark:text-emerald-400",
  },
  sky: {
    small:
      "ring-sky-300 dark:ring-sky-400/30 bg-sky-400/10 dark:bg-sky-400/10 text-sky-600 dark:text-sky-400",
    medium:
      "ring-sky-300 bg-sky-400/10 text-sky-600 dark:ring-sky-400/30 dark:bg-sky-400/10 dark:text-sky-400",
  },
  amber: {
    small:
      "ring-amber-300 dark:ring-amber-400/30 bg-amber-400/10 text-amber-600 dark:text-amber-400",
    medium:
      "ring-amber-300 bg-amber-400/10 text-amber-600 dark:ring-amber-400/30 dark:bg-amber-400/10 dark:text-amber-400",
  },
  rose: {
    small:
      "ring-rose-200 dark:ring-rose-500/20 bg-rose-50 dark:bg-rose-400/10 text-red-600 dark:text-rose-500",
    medium:
      "ring-rose-200 bg-rose-50 text-red-600 dark:ring-rose-500/20 dark:bg-rose-400/10 dark:text-rose-400",
  },
  zinc: {
    small:
      "ring-zinc-200 dark:ring-zinc-500/20 text-zinc-600 dark:text-zinc-300 bg-zinc-50 dark:bg-zinc-900 rounded-md",
    medium:
      "ring-zinc-200 bg-zinc-50 text-zinc-500 dark:ring-zinc-500/20 dark:bg-zinc-400/10 dark:text-zinc-400",
  },
  orange: {
    small:
      "ring-orange-300 dark:ring-orange-400/30 bg-orange-400/10 text-orange-600 dark:text-orange-400",
    medium:
      "ring-orange-300 bg-orange-400/10 text-orange-600 dark:ring-orange-400/30 dark:bg-orange-400/10 dark:text-orange-400",
  },
  blue: {
    small:
      "ring-blue-300 dark:ring-blue-400/30 bg-blue-400/10 text-blue-600 dark:text-blue-400",
    medium:
      "ring-blue-300 bg-blue-400/10 text-blue-600 dark:ring-blue-400/30 dark:bg-blue-400/10 dark:text-blue-400",
  },
};

const valueColorMap = {
  get: "emerald",
  post: "sky",
  put: "amber",
  delete: "rose",
};

export function FilterTag({
  children,
  variant = "medium",
  color = valueColorMap[children.toLowerCase()] ?? "emerald",
  startIcon,
  onClose,
  className,
  textClassName,
}) {
  startIcon =
    startIcon &&
    React.cloneElement(startIcon, { className: "h-3 w-3 ml-2 mr-2" });

  return (
    <div
      className={clsx(
        "inline-flex items-center text-2xs rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 ring-opacity-5 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none h-7",
        variantStyles[variant],
        colorStyles[color][variant],
        className
      )}
    >
      {startIcon}
      <p className={textClassName}>{children}</p>
      {onClose && (
        <Button
          variant="floatingIcon"
          className="px-2"
          size="xs"
          buttonIcon={<TbX strokeWidth={2.5} />}
          onClick={onClose}
        />
      )}
    </div>
  );
}
