import { Skeleton } from "@/components/Elements/Skeleton";
import { useGetAiArticleUsage } from "@/features/subscription/api/getAiArticleLimit";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { TbArrowLeft, TbInfoCircle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Progress,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/Elements";
import { WizardSteps } from "./WizardSteps";

const CreditsBadge = ({
  usage,
  limit,
  isLoading,
}: {
  usage: number;
  limit: number;
  isLoading: boolean;
}) => {
  return (
    <div className="flex flex-col justify-center border border-zinc-400/30 dark:border-zinc-500/40 rounded-md p-3 mb-4 mx-3 bg-zinc-50 dark:bg-zinc-800 shadow-sm dark:shadow-lg space-y-1">
      <div className="flex items-center">
        <span className={`text-xs font-medium text-zinc-600 dark:text-white`}>
          AI article credits
        </span>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="inline-flex ml-2">
                <TbInfoCircle className="text-zinc-500 h-3.5 w-3.5" />
              </span>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent className="z-[10000]">
                Add-on provides access to AI Article Wizard with GPT-4 (30
                articles/mo)
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
      </div>
      {isLoading ? (
        <Skeleton className="h-4 w-full mb-4" />
      ) : (
        <>
          <Progress progress={(usage / limit) * 10} />
          <div className="flex text-xs text-zinc-500 dark:text-white space-x-1">
            <span>
              {usage} / {limit}
            </span>
            <span>articles this month</span>
          </div>
        </>
      )}
    </div>
  );
};

export const AiWizardNavigation = ({
  wizardSteps,
  currentStep,
  setCurrentStep,
  isLoadingSerp,
  stepValidations,
  furthestStepReached,
}: {
  wizardSteps: any;
  currentStep: number;
  setCurrentStep: any;
  isLoadingSerp: boolean;
  stepValidations: any;
  furthestStepReached: number;
}) => {
  const navigate = useNavigate();
  const { data: aiArticleUsageData, isLoading: isLoadingAiArticleUsage } =
    useGetAiArticleUsage();

  const aiArticlesUsage = {
    currentMonth: aiArticleUsageData?.length || 0,
    limit: 30,
  };

  return (
    <div className="flex flex-col h-screen bg-zinc-50 dark:bg-zinc-800 w-[230px] border-r dark:border-r-zinc-700 flex-shrink-0">
      <div className="mt-4 flex flex-col h-full justify-between">
        <div>
          <div className="flex px-2">
            <Button
              variant="text"
              onClick={() => {
                navigate("/app");
              }}
              buttonIcon={<TbArrowLeft />}
              textClassName="ml-2 text-start flex w-full text-zinc-900 dark:text-white"
              className="mx-2 w-full hover:bg-zinc-900/5 dark:hover:bg-white/5"
            >
              AI Article Wizard
            </Button>
          </div>
          <div className="pt-6">
            <WizardSteps
              wizardSteps={wizardSteps}
              currentStep={currentStep}
              isLoadingSerp={isLoadingSerp}
              setCurrentStep={setCurrentStep}
              stepValidations={stepValidations}
              furthestStepReached={furthestStepReached}
            />
          </div>
        </div>
        <CreditsBadge
          usage={aiArticlesUsage.currentMonth}
          limit={aiArticlesUsage.limit}
          isLoading={isLoadingAiArticleUsage}
        />
      </div>
    </div>
  );
};
