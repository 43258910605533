import { axios, CancelToken } from "@/lib/axios";
import { useQuery } from "react-query";
import { parseStreamedJson } from "./getGptChatStream";

type GptUsingUrlsPayload = {
  urls: string[];
  query: string;
  prompt: string;
  lang: string;
  query_rewrites?: string[];
  onDataReceived: (data: string, shouldInsertParagraph: boolean) => void;
  onDownloadComplete: () => void;
  config?: any;
};

type GptUsingUrlsResponse = any;

let cancelTokenSource: CancelToken | null = null;

export const getGptUsingUrls = ({
  urls,
  query,
  prompt,
  lang,
  query_rewrites,
  onDataReceived,
  onDownloadComplete,
}: GptUsingUrlsPayload): Promise<GptUsingUrlsResponse> => {
  let lastResponseLength = 0;
  let buffer = "";
  let accumulatedText = "";

  if (cancelTokenSource) {
    cancelTokenSource.cancel("Cancelling previous request");
  }
  cancelTokenSource = CancelToken.source();

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: "/gpt_urls",
      data: {
        urls,
        query,
        prompt,
        lang,
        query_rewrites,
      },
      contentType: "text/event-stream",
      stream: true,
      cancelToken: cancelTokenSource.token,
      onDownloadProgress: (progressEvent: any) => {
        const status = progressEvent.target?.status;

        if (status === 400) {
          reject(new Error("400"));
          return;
        }

        if (status === 498) {
          return;
        }

        if (status === 524) {
          reject(new Error("524"));
          return;
        }

        const currentResponse = progressEvent.target?.responseText || "";
        const newResponse = currentResponse.slice(lastResponseLength);
        let result = ""; // Declare result here to ensure it is defined

        if (newResponse) {
          try {
            const { objects, buffer: newBuffer } = parseStreamedJson(
              newResponse,
              buffer
            );
            buffer = newBuffer;

            for (const parsedObject of objects) {
              const { choices } = parsedObject;
              if (choices && choices.length > 0) {
                const { delta } = choices[0];
                if (delta && delta.content) {
                  accumulatedText += delta.content; // Accumulate the content
                  let lastNewlineIndex = accumulatedText.lastIndexOf("\n");
                  while (lastNewlineIndex !== -1) {
                    // Send the text including the newline
                    onDataReceived(
                      accumulatedText.substring(0, lastNewlineIndex + 1),
                      true
                    );
                    // Keep the remaining text after the last newline
                    accumulatedText = accumulatedText.substring(
                      lastNewlineIndex + 1
                    );
                    // Check if there are more newlines
                    lastNewlineIndex = accumulatedText.lastIndexOf("\n");
                  }
                }
              }
            }
          } catch (error) {
            console.error("Error parsing streamed JSON:", error);
          }
        }

        lastResponseLength = currentResponse.length;
      },
    })
      .then((response: string) => {
        if (cancelTokenSource?.token.reason) {
          return;
        }

        // Check if there's any accumulated text without a newline at the end of the request
        if (accumulatedText.length > 0) {
          onDataReceived(accumulatedText, false);
          accumulatedText = ""; // Clear the accumulated text after it's sent
        }

        onDownloadComplete();
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const useGetGptChatStreamUsingUrls = ({
  urls,
  query,
  prompt,
  lang,
  query_rewrites,
  config,
  onDataReceived,
  onDownloadComplete,
}: GptUsingUrlsPayload) => {
  return useQuery({
    ...config,
    queryKey: ["gptChatStream", urls, query, prompt, lang, query_rewrites],
    queryFn: () =>
      getGptUsingUrls({
        urls,
        query,
        prompt,
        lang,
        query_rewrites,
        onDataReceived,
        onDownloadComplete,
      }),
  });
};

export const cancelGptChatStreamUsingUrls = () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Operation cancelled by the user.");
  }
};
