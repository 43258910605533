import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useGoogleAnalytics } from "@/features/analytics/hooks/useGoogleAnalytics";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useSeoAnalyticsStore } from "@/features/seo-analytics";
import {
  Coupon,
  getCouponStatus,
} from "@/features/subscription/api/getCouponStatus";
import { getBasePrice } from "@/features/subscription/utils/getBasePrice";
import { getPlanNickname } from "@/features/subscription/utils/getPlanNickname";
import { tiers } from "@/features/subscription/utils/tiers";
import { CheckIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose } from "@radix-ui/react-dialog";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { TbInfoCircle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  DialogCloseButton,
  DialogHeader,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  Switch,
} from "../../../components/Elements";
import { useNotificationStore } from "../../../stores/notifications";
import { cn } from "../../../utils/style";
import { useUser } from "../../auth";
import { useBeginSubscription } from "../../subscription/api/beginPlan";
import { useGetCreditCards } from "../../subscription/api/getCreditCards";
import { useUpdateCreditCard } from "../../subscription/api/updateCreditCard";
import { useUpdatePlan } from "../../subscription/api/updatePlan";
import { getBrandIcon } from "../routes";

interface GoogleAnalytics {
  (command: "event", type: string, options: any): void;
  (command: "conversion", type: string, options: any): void;
}

declare var gtag: GoogleAnalytics;

interface ConfirmPlanChangeDialogProps {
  shouldResubscribe?: boolean;
  className?: string;
  status?: string;
  actionType?: "upgrade" | "downgrade" | "resubscribe" | "change";
  upgradeProAddOn?: boolean;
  actionTypeDisplay?: string;
  triggerButton?: React.ReactNode;
  triggerType?: "button" | "dialog";
  title?: string;
  onClose?: (close: () => void) => void;
  planDetails?: {
    name: string;
    title: string;
    price: number;
    stripeName: string;
  };
  pricingPeriod?: "monthly" | "yearly";
}

type SuccessResponse = any; // Replace 'any' with the actual success response type if known

// Define the type for the onError callback
type ErrorResponse = {
  message: string;
};

// Define the type for the request payload
type RequestPayload = {
  source: { id: string };
  plans: Array<{ plan: string; quantity?: string }>;
  coupon?: string;
};

const schema = z.object({
  email: z
    .string()
    .min(1, "Email is required.")
    .email("Invalid email address."),
  fullName: z.string().min(1, "Full name is required."),
  promoCode: z.string().optional(),
});

export const ConfirmPlanChangeDialog: React.FC<
  ConfirmPlanChangeDialogProps
> = ({
  shouldResubscribe = false,
  className,
  actionType,
  triggerButton,
  title,
  triggerType = "button",
  upgradeProAddOn = false,
  planDetails,
  onClose,
  pricingPeriod: incomingPricingPeriod,
}) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { data: user } = useUser();
  const [open, setOpen] = useState(triggerType === "dialog" ? true : false);
  const [isEditingCard, setIsEditingCard] = useState(false);
  const [loadingUpgrade, setLoadingUpgrade] = useState(false);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [isValidatingCoupon, setIsValidatingCoupon] = useState(false);
  const [pendingCouponValidation, setPendingCouponValidation] = useState(false);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { mutate: beginSubscription, isLoading: beginSubscriptionLoading } =
    useBeginSubscription();
  const { mutate: updatePlan, isLoading: updatePlanLoading } = useUpdatePlan();
  const { mutate: updateCreditCard, isLoading: updateCreditCardLoading } =
    useUpdateCreditCard();
  const trackEvent = useTrackEvent();
  const { trackPurchase } = useGoogleAnalytics();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      fullName: "",
    },
  });

  useEffect(() => {
    form.setValue("email", user?.username || "");
    form.setValue("fullName", user?.fullName || "");
  }, [user]);

  const { addNotification } = useNotificationStore();

  const status = subscriptionData?.status || "inactive";
  const isAdmin = user?.org_role === 1;

  const { data: creditCards } = useGetCreditCards({
    enabled: isAdmin,
  });

  const creditCardDetails = creditCards &&
    creditCards.data &&
    creditCards.data.length > 0 && {
      brand: creditCards.data[0].brand,
      lastFourDigits: creditCards.data[0].last4,
      expMonth: creditCards.data[0].exp_month,
      expYear: creditCards.data[0].exp_year,
      id: creditCards.data[0].id,
    };

  const planNickname = getPlanNickname(subscriptionData?.plan || "Free");
  const hasAddOn = subscriptionData?.add_on;
  const pricingPeriod =
    incomingPricingPeriod ||
    (subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly");

  const subscriptionDetails = {
    plan: planNickname,
    pricingPeriod: pricingPeriod,
    planName: subscriptionData?.plan || "Free Plan",
    proAddon: subscriptionData?.add_on ? "Active" : "Inactive",
    isAppsumoActive: subscriptionData?.appsumo || false,
    price:
      getBasePrice(
        planNickname,
        subscriptionData?.appsumo || false,
        pricingPeriod
      ) || 0,
    hasAddOn: !!subscriptionData?.add_on,
  };

  const subscriptionPlanDetails = planDetails || {
    name: subscriptionDetails.planName,
    title: subscriptionDetails.plan,
    price: subscriptionDetails.price,
    stripeName: subscriptionDetails.planName,
  };
  const isAppsumoUser = subscriptionData?.appsumo;

  const [includeAddOn, setIncludeAddOn] = useState(
    status === "inactive" || hasAddOn
  );

  const totalCost = useMemo(() => {
    let baseCost = subscriptionPlanDetails.price;
    let addOnCost =
      actionType === "change"
        ? pricingPeriod === "monthly"
          ? 357
          : 35
        : pricingPeriod === "monthly"
        ? 35
        : 357;

    let discountBase = 0;
    let discountAddOn = 0;

    if (coupon) {
      if (coupon.percent_off) {
        discountBase = (baseCost * coupon.percent_off) / 100;
        discountAddOn =
          ((upgradeProAddOn || includeAddOn ? addOnCost : 0) *
            coupon.percent_off) /
          100;
        baseCost = baseCost - discountBase;
        addOnCost =
          (upgradeProAddOn || includeAddOn ? addOnCost : 0) - discountAddOn;
      } else if (coupon.amount_off) {
        // For amount_off coupons, we distribute the discount proportionally
        const total =
          baseCost + (upgradeProAddOn || includeAddOn ? addOnCost : 0);
        const discountRatio = baseCost / total;
        discountBase = coupon.amount_off * discountRatio;
        discountAddOn = coupon.amount_off * (1 - discountRatio);
        baseCost = baseCost - discountBase;
        addOnCost =
          (upgradeProAddOn || includeAddOn ? addOnCost : 0) - discountAddOn;
      }
    }

    const totalCost =
      baseCost + (upgradeProAddOn || includeAddOn ? addOnCost : 0);
    return {
      total: totalCost.toFixed(2),
      baseCost: baseCost.toFixed(2),
      addOnCost: (upgradeProAddOn || includeAddOn ? addOnCost : 0).toFixed(2),
      discountBase: discountBase.toFixed(2),
      discountAddOn: discountAddOn.toFixed(2),
    };
  }, [
    subscriptionPlanDetails.price,
    upgradeProAddOn,
    includeAddOn,
    actionType,
    pricingPeriod,
    coupon,
  ]);

  const close = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  const formatPlanName = (stripeName: string) => {
    // Handle addon plans first
    if (stripeName.startsWith("addon")) {
      const isYearly = stripeName.includes("yearly");
      return `Addon (${isYearly ? "Yearly" : "Monthly"})`;
    }

    // Handle regular plans
    const tier = tiers.find(
      (t) =>
        t.monthly.stripeName === stripeName ||
        t.yearly.stripeName === stripeName
    );

    if (!tier) return stripeName;

    const isYearly = stripeName.includes("yearly");
    return `${tier.name} (${isYearly ? "Yearly" : "Monthly"})`;
  };

  const handleValidateCoupon = async (couponId: string) => {
    try {
      setIsValidatingCoupon(true);
      const data = await getCouponStatus(
        couponId,
        subscriptionPlanDetails.stripeName.toLowerCase()
      );

      if (data.status === "valid") {
        form.clearErrors("promoCode");
        setCoupon({ ...data, id: couponId });
        setPendingCouponValidation(false);
      } else {
        setCoupon(null);
        setPendingCouponValidation(true);
        let errorMessage = "The coupon code is invalid or expired.";

        switch (data.status) {
          case "invalid_plan":
            errorMessage = `This coupon is only valid for the following plans: ${data.validPlans
              ?.map((plan) => formatPlanName(plan))
              .join(", ")}`;
            break;
          case "expired":
            errorMessage = data.expirationDate
              ? `This coupon expired on ${data.expirationDate}`
              : "This coupon has expired";
            break;
          case "invalid_coupon":
            errorMessage = "The coupon code is invalid. Please try again.";
            break;
        }

        form.setError("promoCode", {
          type: "manual",
          message: errorMessage,
        });
      }
    } catch (error: any) {
      setCoupon(null);
      setPendingCouponValidation(true);
      form.setError("promoCode", {
        type: "manual",
        message:
          "An error occurred while validating the coupon. Please try again.",
      });
    } finally {
      setIsValidatingCoupon(false);
    }
  };

  const handleTrackNewCustomer = async (stripeName: string) => {
    trackEvent.mutate({
      event: "new_customer",
      properties: JSON.stringify({}),
    });
    trackPurchase(stripeName);
  };

  const handleTrackUpgrade = async () => {
    trackEvent.mutate({
      event: "customer_upgrade",
      properties: JSON.stringify({}),
    });
  };

  const handleTrackDowngrade = async () => {
    trackEvent.mutate({
      event: "customer_downgrade",
      properties: JSON.stringify({}),
    });
  };

  const handleUpgrade = async () => {
    setLoadingUpgrade(true);
    form.clearErrors();
    let requestPayload: RequestPayload;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoadingUpgrade(false);
      return;
    }

    // Always create a new card token for resubscribe to ensure the new card is used
    if (shouldResubscribe) {
      const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        // Handle the error by updating the form state with the error message
        form.setError("creditCard", {
          type: "manual",
          message: error.message,
        });
        setLoadingUpgrade(false);
        return; // Stop the form submission process
      }

      requestPayload = {
        source: { id: token.id },
        plans: [],
      };
    } else if (creditCardDetails && !isEditingCard) {
      // Use the existing credit card details for the request payload
      requestPayload = {
        source: { id: creditCardDetails.id },
        plans: [],
      };
    } else {
      // If editing or adding a new card, create a token with the CardElement
      const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        // Handle the error by updating the form state with the error message
        form.setError("creditCard", {
          type: "manual",
          message: error.message,
        });
        setLoadingUpgrade(false);
        return; // Stop the form submission process
      }

      requestPayload = {
        source: { id: token.id },
        plans: [],
      };
    }

    // Add plans to the request payload
    if (subscriptionPlanDetails.stripeName) {
      if (isAppsumoUser) {
        if (subscriptionData?.subscription_start_date === null) {
          shouldResubscribe = true;
        }

        requestPayload.plans.push({
          plan: subscriptionDetails.planName,
        });
      } else {
        requestPayload.plans.push({
          plan: subscriptionPlanDetails.stripeName.toLowerCase(),
        });
      }
      if (
        subscriptionPlanDetails.stripeName.indexOf("team") !== -1 &&
        !isAppsumoUser
      ) {
        requestPayload.plans[0].quantity = "3";
      }
    }

    // Add add-on to the request if included
    if (upgradeProAddOn || includeAddOn) {
      let addonPlan =
        actionType === "change"
          ? pricingPeriod === "monthly"
            ? "addon_yearly_357"
            : "addon"
          : pricingPeriod === "monthly"
          ? "addon"
          : "addon_yearly_357";
      requestPayload.plans.push({ plan: addonPlan });
    }

    // Add coupon to the request if included
    if (coupon) {
      requestPayload.coupon = coupon.id;
    }

    const userPlanName = getPlanNickname(
      requestPayload.plans[0].plan || "freeTrial"
    );
    const tier = tiers.find((t) => t.name === userPlanName);
    const domainLimit = tier?.gscDomainLimit || 0;

    // Call the appropriate API based on the user's subscription status
    if (status === "canceled" || status === "inactive" || shouldResubscribe) {
      // Call the API to create the plan
      beginSubscription(requestPayload, {
        onSuccess: () => {
          setOpen(false);
          handleTrackNewCustomer(subscriptionPlanDetails.stripeName);
          useSeoAnalyticsStore.getState().setDomainLimit(domainLimit);
          navigate("/app/settings/subscription");
        },
        onError: (error: ErrorResponse) => {
          addNotification({
            type: "error",
            title: `Subscription ${actionType} failed`,
            message: `An error occurred: ${error.message}. Please try again or contact support.`,
          });
        },
      });
    } else {
      // Call the API to update the plan
      updatePlan(requestPayload, {
        onSuccess: () => {
          setOpen(false);
          if (actionType === "upgrade") {
            handleTrackUpgrade();
          } else if (actionType === "downgrade") {
            handleTrackDowngrade();
          }
          useSeoAnalyticsStore.getState().setDomainLimit(domainLimit);
          navigate("/app/settings/subscription");
        },
        onError: (error: ErrorResponse) => {
          addNotification({
            type: "error",
            title: `Subscription ${actionType} failed`,
            message: `An error occurred: ${error.message}. Please try again or contact support.`,
          });
        },
      });
    }

    setLoadingUpgrade(false);
  };

  const actionTypeDisplay = actionType
    ? actionType?.charAt(0).toUpperCase() + actionType?.slice(1) + " "
    : "";

  const actionButton = (
    <Button
      variant="primaryBlur"
      type="submit"
      isLoading={
        beginSubscriptionLoading ||
        updatePlanLoading ||
        updateCreditCardLoading ||
        loadingUpgrade
      }
      disabled={
        beginSubscriptionLoading ||
        updatePlanLoading ||
        updateCreditCardLoading ||
        loadingUpgrade ||
        parseInt(totalCost.total) === 0 ||
        isValidatingCoupon ||
        pendingCouponValidation
      }
    >
      Confirm {actionType}
    </Button>
  );

  const dialogTitle = title ? (
    <DialogTitle>{title}</DialogTitle>
  ) : (
    <DialogTitle>
      {actionTypeDisplay}
      to{" "}
      {actionType === "change"
        ? pricingPeriod === "monthly"
          ? `${subscriptionPlanDetails.name} Yearly`
          : `${subscriptionPlanDetails.name} Monthly`
        : subscriptionPlanDetails.name}
    </DialogTitle>
  );

  const dialogTriggerButton = triggerButton || (
    <Button
      variant={actionType === "upgrade" ? "primary" : "outlineBlur"}
      className={cn("w-full", className)}
      size="xs"
    >
      {actionTypeDisplay}{" "}
      {actionType === "change"
        ? `to ${pricingPeriod === "monthly" ? "yearly" : "monthly"}`
        : ""}
    </Button>
  );

  const promoInputVariants = {
    hidden: { opacity: 0, height: "auto" },
    visible: { opacity: 1, height: "auto" },
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open: boolean) => {
        if (!open && onClose) {
          onClose(close);
        } else {
          setOpen(open);
          setIsEditingCard(
            !creditCards ||
              !creditCards.data ||
              creditCards.data.length === 0 ||
              shouldResubscribe
          );
          setIncludeAddOn(status === "inactive" || hasAddOn);
          setLoadingUpgrade(false);
          // Reset coupon state when dialog opens/closes
          setCoupon(null);
          setPendingCouponValidation(false);
          setShowPromoInput(false);
          form.setValue("promoCode", "");
        }
      }}
    >
      {triggerType === "button" && (
        <DialogTrigger asChild>{dialogTriggerButton}</DialogTrigger>
      )}
      <DialogContent className="p-0 m-0">
        <DialogHeader className="pb-0 flex items-center justify-between">
          {dialogTitle}
          <DialogCloseButton
            close={() => {
              if (onClose) {
                onClose(close);
              } else {
                setOpen(false);
              }
            }}
          />
        </DialogHeader>
        <DialogDescription className="overflow-y-hidden">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleUpgrade)}>
              <div className="space-y-6">
                <div className="space-y-4 bg-zinc-100 dark:bg-zinc-900 px-4 py-4 border-t border-b pb-6 dark:border-zinc-700">
                  <FormField
                    control={form.control}
                    name="fullName"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>Full name</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} />
                        </FormControl>
                        {fieldState.error && (
                          <FormMessage>{fieldState.error.message}</FormMessage>
                        )}
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>Email address</FormLabel>
                        <FormControl>
                          <Input type="email" placeholder="" {...field} />
                        </FormControl>
                        {fieldState.error && (
                          <FormMessage>{fieldState.error.message}</FormMessage>
                        )}
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="creditCard"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <Label>Credit card</Label>
                        {creditCardDetails && !isEditingCard ? (
                          <div className="flex items-center w-full justify-between">
                            <div className="flex  justify-between items-center w-full h-8 py-2 px-2 rounded-md bg-white text-zinc-900  ring-1 ring-inset ring-zinc-900/7.5 transition dark:bg-zinc-900 dark:text-white dark:ring-inset disabled:cursor-not-allowed disabled:opacity-50 hover:ring-zinc-300 dark:ring-white/10 dark:hover:ring-white/20 focus:[&:not(:focus-visible)]:outline-none focus:outline-none file:-mx-3 file:-my-2 file:overflow-hidden file:bg-zinc-100 dark:file:bg-zinc-700 file:px-2.5 file:outline-none file:border-none file:py-3 file:text-zinc-700 dark:file:text-zinc-100 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] focus:ring-emerald-600/50 focus:ring-inset">
                              <div className="flex items-center">
                                <span className="mr-2 dark:text-white">
                                  {getBrandIcon(creditCardDetails.brand)}
                                </span>
                                <span className="mr-2 text-sm dark:text-white">
                                  {creditCardDetails.brand
                                    .charAt(0)
                                    .toUpperCase() +
                                    creditCardDetails.brand.slice(1)}{" "}
                                  ending in
                                </span>
                                <code className="bg-zinc-100 dark:bg-zinc-800 px-1 rounded-md text-sm dark:text-white">
                                  {creditCardDetails.lastFourDigits}
                                </code>
                              </div>

                              <Button
                                variant="buttonIcon"
                                size="xs"
                                onClick={() => setIsEditingCard(true)}
                              >
                                Edit
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="h-8 py-2 px-2 rounded-md bg-white text-zinc-900  ring-1 ring-inset ring-zinc-900/7.5 transition dark:bg-zinc-900 dark:text-white dark:ring-inset disabled:cursor-not-allowed disabled:opacity-50 hover:ring-zinc-300 dark:ring-white/10 dark:hover:ring-white/20 focus:[&:not(:focus-visible)]:outline-none focus:outline-none file:-mx-3 file:-my-2 file:overflow-hidden file:bg-zinc-100 dark:file:bg-zinc-700 file:px-2.5 file:outline-none file:border-none file:py-3 file:text-zinc-700 dark:file:text-zinc-100 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] focus:ring-emerald-600/50 focus:ring-inset">
                            <CardElement />
                          </div>
                        )}
                        {fieldState.error && (
                          <FormMessage>{fieldState.error.message}</FormMessage>
                        )}
                      </FormItem>
                    )}
                  />

                  <AnimatePresence>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={promoInputVariants}
                      transition={{ duration: 0.2 }}
                    >
                      {!showPromoInput ? (
                        <Button
                          variant="none"
                          className="px-0"
                          textClassName="px-0 text-emerald-600 hover:text-emerald-700"
                          onClick={() => setShowPromoInput(true)}
                        >
                          Add promotion code
                        </Button>
                      ) : (
                        <FormField
                          control={form.control}
                          name="promoCode"
                          render={({ field, fieldState }) => (
                            <FormItem>
                              <FormLabel>Promotion code</FormLabel>
                              <FormControl>
                                <Input
                                  startIcon={
                                    coupon
                                      ? ((
                                          <CheckIcon strokeWidth={4} />
                                        ) as React.ReactElement)
                                      : undefined
                                  }
                                  startIconClassName="h-6 text-emerald-600"
                                  placeholder="Enter promotion code"
                                  {...field}
                                  autoFocus
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setCoupon(null);
                                    setPendingCouponValidation(!!value);

                                    if (fieldState.error) {
                                      form.clearErrors("promoCode");
                                    }
                                  }}
                                  onBlur={() => {
                                    if (!field.value) {
                                      setShowPromoInput(false);
                                      setPendingCouponValidation(false);
                                    }
                                  }}
                                  secondButtonVariant="text"
                                  secondButtonClassName={cn("h-6")}
                                  secondButtonDisabled={
                                    !field.value ||
                                    coupon !== null ||
                                    isValidatingCoupon
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleValidateCoupon(field.value);
                                    }
                                  }}
                                  secondButtonText="Apply"
                                  secondButtonTextClassName={cn(
                                    "text-xs font-medium text-emerald-600",
                                    !field.value &&
                                      "opacity-0 cursor-not-allowed"
                                  )}
                                  secondButtonAction={() => {
                                    handleValidateCoupon(field.value);
                                  }}
                                  secondButtonIsLoading={isValidatingCoupon}
                                  showSecondButtonOnHover={false}
                                />
                              </FormControl>
                              {coupon && (
                                <div className="text-xs">
                                  {coupon.durationInMonths && (
                                    <p>
                                      <span>{coupon.id}</span> lasts{" "}
                                      {coupon.durationInMonths}{" "}
                                      {coupon.durationInMonths === 1
                                        ? "month"
                                        : "months"}
                                      , and provides a {coupon.percent_off}%
                                      discount.
                                    </p>
                                  )}
                                  {coupon.duration === "forever" && (
                                    <p>
                                      <span>{coupon.id}</span> provides a{" "}
                                      {coupon.percent_off}% discount forever.
                                    </p>
                                  )}
                                </div>
                              )}

                              {fieldState.error && (
                                <FormMessage>
                                  {fieldState.error.message}
                                </FormMessage>
                              )}
                            </FormItem>
                          )}
                        />
                      )}
                    </motion.div>
                  </AnimatePresence>
                </div>

                <div className="px-4 pb-2">
                  <table className="min-w-full divide-y divide-zinc-200 table-fixed dark:divide-zinc-700">
                    <thead className="text-left">
                      <tr className="text-left ">
                        <th className="py-1.5 font-medium text-zinc-600 dark:text-zinc-400">
                          Item
                        </th>
                        <th className="py-1.5 font-medium text-zinc-600 dark:text-zinc-400">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-zinc-200 dark:divide-zinc-700 border-t dark:border-zinc-700">
                      <tr className="text-sm text-zinc-600 dark:text-zinc-400">
                        <td className="py-1.5 font-medium">
                          {subscriptionPlanDetails.title}
                        </td>
                        <td className="py-1.5">
                          ${totalCost.baseCost}{" "}
                          {actionType === "change"
                            ? pricingPeriod === "monthly"
                              ? "per year"
                              : "per month"
                            : pricingPeriod === "monthly"
                            ? "per month"
                            : "per year"}
                        </td>
                      </tr>
                      <tr className="text-sm text-zinc-600 dark:text-zinc-400">
                        <td className="py-1.5 font-medium flex items-center">
                          AI Article Wizard (GPT-4)
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger
                                asChild={false}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                }}
                              >
                                <TbInfoCircle className="ml-1" />
                              </TooltipTrigger>
                              <TooltipPortal>
                                <TooltipContent className="z-[10000]">
                                  Add-on provides access to AI Article Wizard
                                  with GPT-4 (30 articles/mo)
                                </TooltipContent>
                              </TooltipPortal>
                            </Tooltip>
                          </TooltipProvider>
                          {!upgradeProAddOn && (
                            <Switch
                              checked={includeAddOn}
                              onCheckedChange={setIncludeAddOn}
                              className="ml-4"
                              size="sm"
                            >
                              Pro add-on
                            </Switch>
                          )}
                        </td>
                        <td className="py-1.5">
                          {upgradeProAddOn || includeAddOn
                            ? actionType === "change"
                              ? pricingPeriod === "monthly"
                                ? `$${totalCost.addOnCost} per year`
                                : `$${totalCost.addOnCost} per month`
                              : pricingPeriod === "monthly"
                              ? `$${totalCost.addOnCost} per month`
                              : `$${totalCost.addOnCost} per year`
                            : actionType === "change"
                            ? pricingPeriod === "monthly"
                              ? "$357 per year"
                              : "$35 per month"
                            : pricingPeriod === "monthly"
                            ? "$35 per month"
                            : "$357 per year"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex items-center justify-between px-4 py-4">
                <div className="flex flex-row items-center space-x-2">
                  <p className="text-lg font-medium text-zinc-900 dark:text-white">
                    Total{" "}
                    {actionType === "change"
                      ? pricingPeriod === "monthly"
                        ? "yearly"
                        : "monthly"
                      : pricingPeriod === "monthly"
                      ? "monthly"
                      : "yearly"}{" "}
                    cost
                  </p>
                  {coupon?.percent_off && (
                    <div className="flex items-center font-medium text-xs bg-emerald-500/20 text-emerald-800 px-1.5 rounded dark:bg-emerald-900 dark:text-emerald-200 h-5 leading-none">
                      {coupon.percent_off}% off applied
                    </div>
                  )}
                </div>
                <p className="text-lg font-medium text-zinc-900 dark:text-white">
                  ${totalCost.total}
                </p>
              </div>
              <div className="px-4 py-4 flex w-full sm:justify-between border-t dark:border-zinc-700">
                <DialogClose asChild>
                  <Button variant="outlineBlur">Cancel</Button>
                </DialogClose>
                {actionButton}
              </div>
            </form>
          </Form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
