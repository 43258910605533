export const isValidTemplate = (template: any) => {
  const prompt = template?.metadata?.template_prompt;
  return prompt ? getPromptVariables(prompt).length > 0 : false;
};

const getPromptVariables = (str: string) => {
  const regex = /%([^%]+)%/g;
  const variables = [];
  let match;

  while ((match = regex.exec(str)) !== null) {
    variables.push({ name: match[1] });
  }

  return variables;
};
