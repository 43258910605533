import { ContinueWizardGenerationDialog } from "@/features/documents/components/ContinueWizardGenerationDialog";
import { useAiWizardDialogStore } from "@/stores/wizard";

export const AiWizardDialog = () => {
  const { dialogType, closeDialog } = useAiWizardDialogStore();

  return (
    <>
      {dialogType === "continueGeneratingAiArticle" && (
        <ContinueWizardGenerationDialog open={true} setOpen={closeDialog} />
      )}
    </>
  );
};
