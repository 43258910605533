import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

type GetFolderInfoPayload = { teamId: string };

export const getFolderInfo = ({
  teamId,
}: GetFolderInfoPayload): Promise<any> => {
  return axios.post(`/getTeamInfo`, { teamId });
};

type UseGetFolderInfoOptions = {
  teamId: string;
  config?: any;
};

export const useGetFolderInfo = ({
  teamId,
  config,
}: UseGetFolderInfoOptions) => {
  return useQuery({
    ...config,
    queryKey: ["folderInfo", teamId],
    queryFn: () => getFolderInfo({ teamId }),
  });
};
