import { TbArrowRight } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "../../../components/Elements";
import storage from "../../../utils/storage";
import { useUser } from "../../auth";
import { Layout } from "../../auth/components/Layout";
import { useSendVerificationEmail } from "../api/sendVerificationEmail";

export const VerifyEmail = () => {
  const { data: user, isLoading: isLoadingUser } = useUser();
  const { mutate: sendVerificationEmail, isLoading } =
    useSendVerificationEmail();
  const navigate = useNavigate();

  const handleReturnHome = () => {
    storage.setShowOnboardingNextSession(true);
    if (user?.onBoarded) {
      navigate("/app", { replace: true });
    } else {
      navigate("/app/onboarding", { replace: true });
    }
  };

  if (isLoadingUser) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (user?.verified) {
    return (
      <Layout title={""}>
        <div className="flex flex-col items-center justify-center space-y-8">
          <p className="text-center dark:text-white">
            Your email address has been successfully verified.
          </p>

          <Button
            isLoading={isLoading}
            onClick={handleReturnHome}
            className="w-full"
            endIcon={<TbArrowRight className="w-4 h-4" />}
          >
            Go to Frase
          </Button>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title={"Please verify your email"}>
      <div className="flex flex-col items-center justify-center space-y-8">
        <p className="text-center dark:text-white">
          We sent an email to{" "}
          <span className="font-semibold">{user?.username}</span> to verify your
          email address. Please check your inbox and click the link in the
          email.
        </p>

        <div className="flex flex-col items-center justify-center space-y-4 w-full">
          <Button
            variant="primaryBlur"
            className="w-full"
            onClick={() =>
              sendVerificationEmail({
                email: user?.username,
                id: user?.id,
              })
            }
            isLoading={isLoading}
          >
            Resend verification email
          </Button>
          <Button
            variant="text"
            className="w-full"
            onClick={() => {
              storage.clearToken();
              window.location.assign(
                window.location.origin as unknown as string
              );
            }}
          >
            Logout
          </Button>
        </div>
      </div>
    </Layout>
  );
};
