import frase_bot from "@/assets/frase_bot.png";
import { Button } from "@/components/Elements";
import { useGoogleAnalytics } from "@/features/analytics/hooks/useGoogleAnalytics";
import { useState } from "react";
import { TbCircle, TbCircleCheckFilled } from "react-icons/tb";
import { useAuth } from "../../../lib/auth";
import { cn } from "../../../utils/style";
import { useIdentifyUser } from "../../analytics/api/identifyUser";
import { useTrackEvent } from "../../analytics/api/trackUser";
import { Layout } from "../../auth/components/Layout";
import { useUpdateOnboardStatus } from "../api/updateOnboardStatus";

interface GoogleAnalytics {
  (command: "event", type: string, options: any): void;
  (command: "conversion", type: string, options: any): void;
}

declare var gtag: GoogleAnalytics;

const personaTypes = [
  {
    variable: "self-employed",
    display: "Self-employed",
  },
  {
    variable: "agency",
    display: "Agency",
  },
  {
    variable: "in-house team",
    display: "In-house team",
  },
];
const seatSizes = [
  { variable: "1_person", display: "1 person" },
  { variable: "2-3_people", display: "2 - 3 people" },
  { variable: "4-10_people", display: "4 - 10 people" },
  { variable: "10+_people", display: "> 10 people" },
];
const seoTools = [
  { variable: "semrush", display: "Semrush" },
  { variable: "ahrefs", display: "Ahrefs" },
  { variable: "moz", display: "Moz" },
  { variable: "ubersuggest", display: "Ubersuggest" },
  { variable: "other", display: "Other SEO tools" },
  { variable: "none", display: "I don't use keyword research tools" },
];

export const NavigationButtons = ({
  currentStep,
  maxSteps,
  onNext,
  onPrevious,
  onSubmit,
  isLoading,
  isOptionSelected,
}) => {
  return (
    <div className="flex gap-x-2 justify-center w-full">
      {currentStep < maxSteps - 1 ? (
        <div className="flex flex-col w-full space-y-4">
          <div className="flex gap-x-2 justify-center w-full">
            {currentStep > 0 && (
              <Button
                variant="outlineBlur"
                onClick={onPrevious}
                className="w-full"
              >
                Back
              </Button>
            )}
            <Button
              onClick={onNext}
              className="w-full"
              disabled={!isOptionSelected}
            >
              Next
            </Button>
          </div>
          <div className="text-zinc-400 dark:text-zinc-600 text-xs mx-auto">
            Step {currentStep + 1} out of {maxSteps}
          </div>{" "}
        </div>
      ) : (
        <div className="flex flex-col w-full space-y-4">
          <div className="flex gap-x-2 justify-center w-full">
            {currentStep > 0 && (
              <Button
                variant="outlineBlur"
                onClick={onPrevious}
                className="w-full"
              >
                Back
              </Button>
            )}
            <Button
              isLoading={isLoading}
              onClick={onSubmit}
              className="w-full"
              disabled={!isOptionSelected}
            >
              Next
            </Button>
          </div>
          <div className="text-zinc-500 dark:text-zinc-400 text-xs mx-auto">
            Step {currentStep + 1} out of {maxSteps}
          </div>
        </div>
      )}
    </div>
  );
};

export const Step = ({
  step,
  currentStep,
  options,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <div
      className={cn(
        "flex flex-wrap justify-center w-full gap-2 transition-opacity ease-in duration-700 opacity-100",
        currentStep === step ? "visible" : "hidden"
      )}
    >
      {options.map((option) => (
        <Button
          key={option.variable || option}
          variant="outlineBlur"
          className="flex w-full items-center h-12"
          textClassName="ml-2 w-full"
          style={
            selectedOption === (option.variable || option)
              ? { border: "2px solid #0ea473" }
              : {}
          }
          onClick={() => setSelectedOption(option.variable || option)}
        >
          <div className="flex w-full items-center">
            {selectedOption === (option.variable || option) ? (
              <span>
                <TbCircleCheckFilled
                  className="w-4 h-4 mr-4"
                  style={{ color: "#0ea473" }}
                />
              </span>
            ) : (
              <span>
                <TbCircle className="w-4 h-4 mr-4" />
              </span>
            )}
            {option.display || option}
          </div>
        </Button>
      ))}
    </div>
  );
};

const QuestionText = ({ step }) => {
  const questions = [
    "Hi, how would you describe your work environment?",
    "How many people could use Frase on your team?",
    "Which keyword research tool do you use for content planning?",
  ];

  return (
    <div className="text-zinc-900 dark:text-white font-medium text-lg">
      <img
        src={frase_bot}
        alt="Frase Genius"
        className="rounded-xl w-12 h-12 border-2 start mb-4"
      />
      <p>{questions[step]}</p>
    </div>
  );
};

export const OnboardingSurvey = () => {
  const { mutate: updateOnboardStatus, isUpdatingStatus } =
    useUpdateOnboardStatus();
  const { mutateAsync: identifyUser, isLoading: isIdentifyingUser } =
    useIdentifyUser();
  const { mutateAsync: trackEvent, isLoading: isTrackingEvent } =
    useTrackEvent();
  const { user, refetchUser } = useAuth();
  const { trackConversion, trackSignup } = useGoogleAnalytics();

  const [qualificationStep, setQualificationStep] = useState(0);
  const [persona, setPersona] = useState("");
  const [seatSize, setSeatSize] = useState("");
  const [seoTool, setSeoTool] = useState("");

  const handleNextStep = () => {
    setQualificationStep((prevStep) => prevStep + 1);
    trackEvent({
      event: "onboarding_survey_step",
      properties: JSON.stringify({
        step: qualificationStep + 1,
      }),
    });
  };

  const handlePreviousStep = () => {
    setQualificationStep((prevStep) => {
      const previousStep = Math.max(prevStep - 1, 0);
      return previousStep;
    });
    trackEvent({
      event: "onboarding_survey_step",
      properties: JSON.stringify({
        step: qualificationStep - 1,
      }),
    });
  };

  const isLoading = isUpdatingStatus || isIdentifyingUser || isTrackingEvent;

  const handleSubmit = async () => {
    const { data: user } = await refetchUser();
    const userPersona = {
      user_persona: persona,
      number_of_potential_seats: seatSize,
      keyword_research_tool: seoTool,
    };

    await identifyUser(JSON.stringify(userPersona))
      .then(() => {
        return trackEvent({
          event: "signup",
          properties: JSON.stringify({
            email: user?.username,
            fullName: user?.fullName,
            signUpSource: "web",
            timezoneOffset: new Date().getTimezoneOffset(),
            mobileRegistration: "false",
          }),
        });
      })
      .then(() => {
        trackConversion("AW-794600763/I8-YCNvDzv8CELvK8voC");
        trackSignup(persona);
        updateOnboardStatus(undefined);
        trackEvent({
          event: "onboarding_survey_step_completed",
          properties: JSON.stringify({
            email: user?.username,
            fullName: user?.fullName,
          }),
        });
      });
  };

  const isOptionSelected = () => {
    switch (qualificationStep) {
      case 0:
        return persona !== "";
      case 1:
        return seatSize !== "";
      case 2:
        return seoTool !== "";
      default:
        return false;
    }
  };

  return (
    <Layout
      className="flex flex-col items-center justify-center"
      showLogo={false}
    >
      <div className="w-full flex-col">
        <div className="flex flex-col items-center justify-center space-y-12">
          <QuestionText step={qualificationStep} />
          <Step
            step={0}
            currentStep={qualificationStep}
            options={personaTypes}
            selectedOption={persona}
            setSelectedOption={setPersona}
          />
          <Step
            step={1}
            currentStep={qualificationStep}
            options={seatSizes}
            selectedOption={seatSize}
            setSelectedOption={setSeatSize}
          />
          <Step
            step={2}
            currentStep={qualificationStep}
            options={seoTools}
            selectedOption={seoTool}
            setSelectedOption={setSeoTool}
          />

          <NavigationButtons
            currentStep={qualificationStep}
            maxSteps={3}
            onNext={handleNextStep}
            onPrevious={handlePreviousStep}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            isOptionSelected={isOptionSelected()}
          />
        </div>
      </div>
    </Layout>
  );
};
