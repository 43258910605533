import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";

type SendVerificationEmailPayload = {
  email: string;
  id: number;
};

export const sendVerificationEmail = ({
  email,
  id,
}: SendVerificationEmailPayload): Promise<any> => {
  return axios.post(`/triggerVerificationEmail`, { email, id });
};

export const useSendVerificationEmail = () => {
  return useMutation(sendVerificationEmail);
};
