import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

interface UseGetPromptHistoryOptions {
  config?: QueryConfig<typeof getPromptHistory>;
}

export const getPromptHistory = async () => {
  const response = await axios.post("/getPromptHistory", {});
  return response;
};

type QueryFnType = typeof getPromptHistory;

export const useGetPromptHistory = ({
  config = {},
}: UseGetPromptHistoryOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: "promptHistory",
    queryFn: getPromptHistory,
    ...config,
  });
};
