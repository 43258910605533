import { getTierByPlanName, legacyTiers, tiers } from "./tiers";

export interface TierFeatures {
  [key: string]: string | boolean;
}

export interface Feature {
  name: string;
  tooltip?: string;
  tiers: TierFeatures;
}

export interface Section {
  name: string;
  features: Feature[];
}

// Helper function to get tier names
const getTierNames = () => {
  // For legacy Solo and Team, use current tiers
  return ["Solo", "Basic", "Team", "Enterprise"];
};

// Helper function to find tier by name - check both current and legacy tiers
const findTierByName = (name: string, planName?: string) => {
  // For legacy plans, check legacy tiers first
  const legacyPlans = [
    "solo",
    "solo_yearly",
    "team_monthly_115",
    "team_yearly_115",
  ];
  const isLegacyPlan = planName ? legacyPlans.includes(planName) : false;

  // Only show legacy tier if the user is on that specific tier and we're looking at that tier
  // e.g. if user is on legacy team, only show legacy team tier for the Team column
  if (isLegacyPlan) {
    const isLegacySolo = ["solo", "solo_yearly"].includes(planName || "");
    const isLegacyTeam = ["team_monthly_115", "team_yearly_115"].includes(
      planName || ""
    );

    // Only use legacy tier if we're looking at the same tier type as user's plan
    if (
      (isLegacySolo && name === "Solo") ||
      (isLegacyTeam && name === "Team")
    ) {
      const legacyTier = legacyTiers.find((t) => t.name === name);
      if (legacyTier) return legacyTier;
    }
  }

  // For Solo tier, don't filter by display
  if (name === "Solo") {
    return tiers.find((t) => t.name === name);
  }

  // Otherwise use current tiers with display filter
  return tiers.find((t) => t.name === name && t.display);
};

// Helper function to format values
const formatValue = (value: any, suffix?: string) => {
  if (value === undefined || value === null) return false;
  if (typeof value === "boolean") return value;
  return suffix ? `${value}${suffix}` : value;
};

export const sections = [
  {
    name: "Usage",
    features: [
      {
        name: "User Seats",
        tooltip: "Number of people in your organization.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.users,
          }),
          {}
        ),
      },
      {
        name: "SEO Documents",
        tooltip:
          "Number of documents with search queries you want to optimize content for.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: formatValue(
              findTierByName(name)?.documents,
              findTierByName(name)?.documents === "Unlimited" ? "" : " docs/mo"
            ),
          }),
          {}
        ),
      },
      {
        name: "AI Word Count Limit",
        tooltip: "Number of words you can write with AI per month.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: formatValue(
              findTierByName(name)?.aiWordCount,
              findTierByName(name)?.aiWordCount === "Unlimited"
                ? ""
                : " words/mo"
            ),
          }),
          {}
        ),
      },
      {
        name: "API",
        tooltip:
          "Number of URLs you can process search results for using the API per month.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.apiUsage,
          }),
          {}
        ),
      },
    ],
  },
  {
    name: "SEO Workflow",
    features: [
      {
        name: "SERP Analysis",
        tooltip:
          "Analyze search results for your target queries. Research headings, topics, and more.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.serpAnalysis,
          }),
          {}
        ),
      },
      {
        name: "Competitive Intelligence",
        tooltip:
          "Analyze your competitors' content strategies and gain insights into their SEO performance.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.competitiveIntelligence,
          }),
          {}
        ),
      },
      {
        name: "Outline Builder",
        tooltip:
          "Curate headings from competitors and generate outline ideas with AI.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.outlineBuilder,
          }),
          {}
        ),
      },
      {
        name: "Content Optimization",
        tooltip:
          "Use Frase's topic score as a blueprint of authoritative content.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.contentOptimization,
          }),
          {}
        ),
      },
      {
        name: "Multi-Query Documents",
        tooltip: "Create documents that analyze multiple search queries.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.multiQueryDocuments,
          }),
          {}
        ),
      },
      {
        name: "SEO Analytics",
        tooltip: "Track your SEO performance and get insights.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.seoAnalytics,
          }),
          {}
        ),
      },
      {
        name: "Automatic Content Brief Generation",
        tooltip: "Generate content briefs for your articles.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.automaticContentBrief,
          }),
          {}
        ),
      },
    ],
  },
  {
    name: "Writing",
    features: [
      {
        name: "AI Tools (Templates)",
        tooltip: "Use AI tools to generate content.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.aiTools,
          }),
          {}
        ),
      },
      {
        name: "Languages",
        tooltip: "Use AI to write in multiple languages.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.languages,
          }),
          {}
        ),
      },
    ],
  },
  {
    name: "Governance",
    features: [
      {
        name: "Collaboration Tools, Commenting, Kanban, Campaign  Mgmt.",
        tooltip: "Collaborate with your team and manage your campaigns.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.collaboration,
          }),
          {}
        ),
      },
    ],
  },
  {
    name: "Plugins",
    features: [
      {
        name: "Chrome Extension",
        tooltip: "Use Frase in your browser.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.chromeExtension,
          }),
          {}
        ),
      },
      {
        name: "WordPress Plugin",
        tooltip: "Optimize your content inside WordPress.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.wordpressPlugin,
          }),
          {}
        ),
      },
      {
        name: "Google Docs Plugin",
        tooltip: "Optimize your content inside Google Docs.",
        tiers: getTierNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: findTierByName(name)?.features.googleDocsPlugin,
          }),
          {}
        ),
      },
    ],
  },
  {
    name: "Advanced Security & Support",
    features: [
      {
        name: "Priority Support",
        tooltip: "Get faster response times and dedicated support channels",
        tiers: {
          Solo: false,
          Basic: false,
          Team: true,
          Enterprise: true,
        },
      },
      {
        name: "Dedicated Account Manager & Custom Onboarding",
        tooltip:
          "Personal account manager for strategic guidance and tailored onboarding process",
        tiers: {
          Solo: false,
          Basic: false,
          Team: false,
          Enterprise: true,
        },
      },
      {
        name: "Custom Contracts & Security Compliance",
        tooltip:
          "Custom legal agreements and security compliance documentation",
        tiers: {
          Solo: false,
          Basic: false,
          Team: false,
          Enterprise: true,
        },
      },
      {
        name: "LLM Optionality",
        tooltip: "Choose and customize your preferred LLM models",
        tiers: {
          Solo: false,
          Basic: false,
          Team: false,
          Enterprise: true,
        },
      },
    ],
  },
];

export function getSectionsByPlanName(planName: string): Section[] {
  // Get the tier using the Stripe plan name
  const tier = getTierByPlanName(planName);

  if (!tier) return sections;

  // Create a new sections array with updated features for the specific tier
  return sections.map((section) => ({
    ...section,
    features: section.features.map((feature) => {
      // For each feature, get values for all tiers
      const updatedTiers = getTierNames().reduce((acc, tierName) => {
        const tierToUse = findTierByName(tierName, planName);
        if (!tierToUse) return acc;

        return {
          ...acc,
          [tierName]: getFeatureValue(feature.name, tierToUse),
        };
      }, {});

      return {
        ...feature,
        tiers: updatedTiers,
      };
    }),
  }));
}

// Helper function to get the correct feature value based on the feature name
function getFeatureValue(featureName: string, tier: any): string | boolean {
  switch (featureName) {
    case "SEO Documents":
      return tier.documents === "Unlimited"
        ? "Unlimited"
        : `${tier.documents} docs/mo`;
    case "User Seats":
      return tier.users;
    case "API":
      return tier.apiUsage;
    case "AI Word Count Limit":
      return tier.aiWordCount === "Unlimited"
        ? "Unlimited"
        : `${tier.aiWordCount} words/mo`;
    case "Multi-Query Documents":
      return tier.features.multiQueryDocuments;
    case "SEO Analytics":
      return tier.features.seoAnalytics;
    case "Languages":
      return tier.features.languages;
    case "SERP Analysis":
      return tier.features.serpAnalysis;
    case "Competitive Intelligence":
      return tier.features.competitiveIntelligence;
    case "Outline Builder":
      return tier.features.outlineBuilder;
    case "Content Optimization":
      return tier.features.contentOptimization;
    case "Automatic Content Brief Generation":
      return tier.features.automaticContentBrief;
    case "AI Tools (Templates)":
      return tier.features.aiTools;
    case "Collaboration Tools, Commenting, Kanban, Campaign  Mgmt.":
      return tier.features.collaboration;
    case "Chrome Extension":
      return tier.features.chromeExtension;
    case "WordPress Plugin":
      return tier.features.wordpressPlugin;
    case "Google Docs Plugin":
      return tier.features.googleDocsPlugin;
    case "Priority Support":
      return tier.features.prioritySupport;
    case "Dedicated Account Manager & Custom Onboarding":
      return tier.features.dedicatedAccountManagerAndCustomOnboarding;
    case "Custom Contracts & Security Compliance":
      return tier.features.customContractsAndSecurityCompliance;
    case "LLM Optionality":
      return tier.features.llmOptionality;
    default:
      console.warn(`Unknown feature: ${featureName}`);
      return false;
  }
}
