import {
  ArticleMetadata,
  Checkbox,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { cn } from "@/utils/style";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { useEffect, useMemo, useState } from "react";

interface CustomizeResultsCardProps {
  title: string;
  url: string;
  rank: number;
  wordCount: number;
  links: number;
  domainAuthority: number;
  className?: string;
  blacklist?: Record<string, any>;
  isCustomImport?: boolean;
  handleUpdateBlacklist: (url: string, isSelected: boolean) => void;
}

const ArticleCardHeader = ({ title }) => (
  <h3 className="mt-1 text-sm font-medium text-zinc-900 dark:text-zinc-50">
    {title}
  </h3>
);

const ArticleUrl = ({ url }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <a
          href={url}
          target="_blank"
          rel="noopener"
          className="mt-1 text-2xs text-zinc-400 dark:text-zinc-600 hover:underline hover:text-zinc-600 flex-shrink truncate"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ zIndex: 1000 }}
        >
          {url}
        </a>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{url}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  </TooltipProvider>
);

export const CustomizeResultsCard = ({
  title,
  url,
  domainAuthority,
  className,
  blacklist,
  isCustomImport,
  handleUpdateBlacklist,
}: CustomizeResultsCardProps) => {
  // Removed useEffect and directly set the selected state during render
  let isSelected = !(blacklist && blacklist[url]);
  const [selected, setSelected] = useState(isSelected);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const handleCheckboxChange = () => {
    setSelected(!selected);
    handleUpdateBlacklist(url, !selected);
  };

  // Function to validate the URL
  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  // Use useMemo to cache the calculation of urlHost
  const urlHost = useMemo(() => {
    return isValidHttpUrl(url) ? new URL(url).hostname.replace("www.", "") : "";
  }, [url]);

  return (
    <li
      className={cn(
        "pl-2 flex w-full items-center first-of-type:mt-1 group cursor-pointer hover:bg-zinc-100 dark:hover:bg-zinc-800",
        className
      )}
      onClick={handleCheckboxChange}
    >
      <Checkbox
        className={cn(
          "ml-8 mx-2 mt-[22px]",
          selected &&
            "bg-emerald-600 dark:bg-emerald-600 hover:dark:bg-emerald-600"
        )}
        checked={selected}
        onCheckedChange={handleCheckboxChange}
        aria-label="Select row"
      />
      <div className="flex flex-col py-3 px-2 w-full">
        <ArticleMetadata
          url={url}
          urlHost={urlHost}
          domainAuthority={domainAuthority}
          isCustomImport={isCustomImport}
        />
        <ArticleCardHeader title={title} />
      </div>
    </li>
  );
};
