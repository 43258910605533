import React from "react";
import { cn } from "../../../utils/style";
import { WizardStep } from "./WizardStep";

export const WizardSteps = ({
  wizardSteps,
  currentStep,
  isLoadingSerp,
  setCurrentStep,
  stepValidations,
  furthestStepReached,
  className,
}: {
  wizardSteps: any;
  currentStep: number;
  isLoadingSerp: boolean;
  setCurrentStep: any;
  stepValidations: any;
  furthestStepReached: number;
  className?: string;
}) => (
  <div
    className={cn(
      "flex flex-col justify-start rounded-t-md w-full flex-wrap space-y-2 px-4",
      className
    )}
    aria-label="Progress"
  >
    {wizardSteps.map((step, index) => (
      <React.Fragment key={index}>
        <div className="flex items-center space-x-2">
          <WizardStep
            step={step.id}
            currentStep={currentStep}
            label={step.label}
            isLoading={isLoadingSerp && step.id === 1}
            isValid={step.id <= furthestStepReached}
            onClick={() => {
              if (step.id <= furthestStepReached) {
                setCurrentStep(step.id);
              }
            }}
          />
        </div>
        <div className="flex items-center space-x-2 w-full">
          {index < wizardSteps.length - 1 && (
            <div
              className={cn(
                "transition-all flex h-2.5 w-1 rounded-md mx-3.5",
                currentStep > step.id
                  ? "bg-emerald-600/40"
                  : "bg-zinc-200 dark:bg-zinc-700"
              )}
            />
          )}
          <div></div>
        </div>
      </React.Fragment>
    ))}
  </div>
);
