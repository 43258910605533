import {
  Button,
  Card,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { DialogPortal } from "@radix-ui/react-dialog";
import { BsWordpress } from "react-icons/bs";
import { SiGooglechrome, SiGoogledocs } from "react-icons/si";

export const IntegrationsDialog = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogPortal>
        <DialogContent className="p-0" variant="fitContent">
          <div className="h-full w-full rounded-lg">
            <div className="flex flex-col justify-center pt-4">
              <DialogCloseButton
                className={`absolute top-4 right-4`}
                close={onClose}
              />
            </div>
            <div>
              <DialogHeader className="flex items-center space-x-4 pl-6">
                <DialogTitle className="text-2xl text-zinc-900">
                  Integrations
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="px-6 pb-8">
                <div className="flex flex-col">
                  <p className="text-normal font-medium text-zinc-700 dark:text-white pb-2">
                    Enhance your content workflow with these powerful
                    integrations.
                  </p>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    {/* Google Docs Add-on Integration */}
                    <Card className="flex flex-col">
                      <div className="flex items-center space-x-2 flex-shrink-0">
                        <div className="flex items-center justify-center w-8 h-8 m-1 rounded-md bg-white/90 dark:bg-zinc-900/50 shadow-sm ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none">
                          <SiGoogledocs className="text-2xl text-blue-500 dark:text-blue-400" />
                        </div>
                        <h3 className="text-sm dark:text-white font-medium whitespace-nowrap text-zinc-900">
                          Google Docs Add-on
                        </h3>
                      </div>
                      <p className="text-xs text-zinc-500 dark:text-zinc-400 px-2 mt-2 pb-4">
                        Use Frase AI to optimize and improve content in Docs.
                      </p>
                      <Button
                        variant="primaryBlur"
                        className="w-fit mx-2 mb-2 mt-auto"
                        onClick={() =>
                          window.open(
                            "https://workspace.google.com/marketplace/app/frase/265423059553",
                            "_blank"
                          )
                        }
                      >
                        Install Add-On
                      </Button>
                    </Card>

                    {/* Wordpress Plugin Integration */}
                    <Card className="flex flex-col">
                      <div className="flex items-center space-x-2">
                        <div className="flex items-center justify-center w-8 h-8 m-1 rounded-md bg-white/90 dark:bg-zinc-900/50 shadow-sm ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none">
                          <BsWordpress className="text-2xl text-zinc-700 dark:text-zinc-300" />
                        </div>
                        <h3 className="text-sm dark:text-white font-medium whitespace-nowrap text-zinc-900">
                          WordPress Plugin
                        </h3>
                      </div>
                      <p className="text-xs text-zinc-500 dark:text-zinc-400 px-2 mt-2 pb-4">
                        Seamlessly optimize and enhance content directly within
                        WordPress.
                      </p>
                      <Button
                        variant="primaryBlur"
                        className="w-fit mx-2 mb-2 mt-auto"
                        onClick={() =>
                          window.open(
                            "https://wordpress.org/plugins/frase/",
                            "_blank"
                          )
                        }
                      >
                        Install Plugin
                      </Button>
                    </Card>

                    {/* Chrome Extension Integration */}
                    <Card className="flex flex-col">
                      <div className="flex items-center space-x-2 flex-shrink-0">
                        <div className="flex items-center justify-center w-8 h-8 m-1 rounded-md bg-white/90 dark:bg-zinc-900/50 shadow-sm ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none">
                          <SiGooglechrome className="text-2xl" />
                        </div>
                        <h3 className="text-sm dark:text-white font-medium whitespace-nowrap text-zinc-900">
                          Chrome Extension
                        </h3>
                      </div>
                      <p className="text-xs text-zinc-500 dark:text-zinc-400 px-2 mt-2 pb-4">
                        Enhance your writing with Frase AI tools across the web.
                      </p>
                      <Button
                        variant="primaryBlur"
                        className="w-fit mx-2 mb-2 mt-auto"
                        onClick={() =>
                          window.open(
                            "https://chromewebstore.google.com/detail/frase-your-ai-writing-sup/aobfdmffblkbjcbdppaackahbjbcpgaf",
                            "_blank"
                          )
                        }
                      >
                        Install Extension
                      </Button>
                    </Card>
                  </div>
                </div>
              </DialogDescription>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
