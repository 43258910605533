import { Combobox } from "@/components/Elements";
import { cn } from "@/utils/style";
import { AddFolderTag, FolderTag } from "../Tag/FolderTag";

export const FolderHeader = () => {
  return <p className="ml-2 text-xs">Folder</p>;
};

export const FolderCell = ({
  document,
  folders,
}: {
  document: Document;
  folders: Folder[];
}) => {
  const folderName =
    folders.find((folder) => folder?.value === document?.teamId?.toString())
      ?.label || null;

  return (
    <Combobox
      data={document}
      options={folders}
      buttonVariant="buttonNode"
      buttonClassName="rounded-full"
      value={document.teamId.toString()}
      showEndIcon={false}
      enableSearch
      searchPlaceholder="folders"
      className={cn("h-7", folderName && "")}
      trigger={
        folderName ? <FolderTag>{folderName}</FolderTag> : <AddFolderTag />
      }
    />
  );
};
