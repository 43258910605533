import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/solid";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useEffect, useState } from "react";

export const SubmitTicketMenuItem = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (window.hbspt) {
      // Load HubSpot form
      window.hbspt.forms.create({
        portalId: "6359064",
        formId: "0840b1ce-15fd-4436-a421-bc2216537074",
        target: "#ticket-form",
      });
    } else {
      // If hbspt is not available, load the HubSpot script
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "6359064",
            formId: "0840b1ce-15fd-4436-a421-bc2216537074",
            target: "#ticket-form",
          });
        }
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="text"
          size="xs"
          onSelect={() => setOpen(true)}
          className="w-full"
          buttonIcon={<ChatBubbleLeftIcon />}
          textClassName="text-start w-full ml-2"
        >
          Contact Support
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Contact support</DialogTitle>
            <DialogCloseButton close={() => setOpen(false)} />
          </DialogHeader>
          <DialogDescription className="px-4">
            <div id="ticket-form"></div>
          </DialogDescription>
          <DialogFooter className="px-4"></DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
