import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { DialogPortal } from "@radix-ui/react-dialog";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useNotificationStore } from "../../../stores/notifications";
import { useUpdateCreditCard } from "../../subscription/api/updateCreditCard";

export const UpdateCardDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { mutate: updateCreditCard } = useUpdateCreditCard();
  const { addNotification } = useNotificationStore();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      addNotification({
        title: "Credit card update failed",
        message: `There was an issue processing your request: ${error.message}. Please verify your information and try again.`,
        type: "error",
      });
    } else {
      // Use the updateCreditCard mutation to send the token to the server
      updateCreditCard({ source: { id: token.id } });
      setOpen(false); // Close the dialog after successful submission
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outlineBlur" className="w-fit" size="xs">
          Update credit card
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Update credit card</DialogTitle>
            <DialogCloseButton close={() => setOpen(false)} />
          </DialogHeader>
          <DialogDescription className="px-4 py-4">
            <CardElement />
          </DialogDescription>
          <DialogFooter className="px-4 pb-4">
            <Button type="submit" disabled={!stripe} onClick={handleSubmit}>
              Update
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
