import {
  Button,
  CommandDialog,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/Elements";
import { useDocuments } from "@/features/documents/api/getDocuments";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { TbSearch } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { LogoSpinner } from "../Spinner/LogoSpinner";

function SearchIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.01 12a4.25 4.25 0 1 0-6.02-6 4.25 4.25 0 0 0 6.02 6Zm0 0 3.24 3.25"
      />
    </svg>
  );
}

function NoResultsIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.01 12a4.237 4.237 0 0 0 1.24-3c0-.62-.132-1.207-.37-1.738M12.01 12A4.237 4.237 0 0 1 9 13.25c-.635 0-1.237-.14-1.777-.388M12.01 12l3.24 3.25m-3.715-9.661a4.25 4.25 0 0 0-5.975 5.908M4.5 15.5l11-11"
      />
    </svg>
  );
}

function SearchResult({ result, setOpen }) {
  const navigate = useNavigate();

  return (
    <CommandItem
      key={result.id}
      value={result.id}
      onSelect={() => {
        navigate(`/app/documents/${result.hash}`);
        setOpen(false);
      }}
    >
      <DocumentIcon
        style={{
          height: "0.875rem",
          width: "0.875rem",
          marginRight: "0.5rem",
        }}
      />
      {result.metadata?.name || result.query}
      <span className="text-transparent">{result.id}</span>
    </CommandItem>
  );
}

function SearchResults({ results, query, setOpen }) {
  const documents = results;

  if (!documents || documents.length === 0) {
    return (
      <div className="p-6 text-center">
        <NoResultsIcon className="mx-auto h-5 w-5 stroke-zinc-900 dark:stroke-zinc-600" />
        <p className="mt-2 text-xs text-zinc-700 dark:text-zinc-400">
          Nothing found for{" "}
          <strong className="break-words font-semibold text-zinc-900 dark:text-white">
            &lsquo;{query}&rsquo;
          </strong>
          . Please try again.
        </p>
      </div>
    );
  }

  return (
    <CommandGroup heading="Results" className="mt-1">
      {documents.map((result, resultIndex) => (
        <SearchResult key={result.id} result={result} setOpen={setOpen} />
      ))}
    </CommandGroup>
  );
}

export function SearchButton({ disableKeyEvent }) {
  let [modifierKey, setModifierKey] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setModifierKey(
      /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? "⌘" : "Ctrl "
    );
  }, []);

  return (
    <>
      <Button
        variant="outlineBlur"
        size="xs"
        aria-label="Search..."
        tooltipContent="Search"
        tooltipModifierKey={`${modifierKey}K`}
        onClick={() => setOpen(true)}
      >
        <TbSearch
          strokeWidth={2.5}
          className="h-3 w-3 stroke-zinc-600 dark:stroke-white"
        />
      </Button>
      <SearchDialog
        className="hidden lg:block"
        open={open}
        setOpen={setOpen}
        disableKeyEvent={disableKeyEvent}
      />
    </>
  );
}

function SearchDialog({ open, setOpen, disableKeyEvent }) {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [searchQuery] = useDebounce(query, 1000);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const documentsQuery = useDocuments({
    query: searchQuery,
    config: {
      enabled: open,
    },
  });

  useEffect(() => {
    setLoading(true);
  }, [query]);

  useEffect(() => {
    setResults(documentsQuery.data?.documents);
    setLoading(false);
  }, [documentsQuery.data]);

  useEffect(() => {
    if (disableKeyEvent) {
      return;
    }

    function onKeyDown(event) {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setOpen(true);
      }
    }

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const Suggestions = () => {
    return (
      <CommandGroup heading="Suggestions" className="mt-1">
        <CommandItem
          onSelect={() => {
            navigate("/app/documents");
            setOpen(false);
          }}
        >
          <DocumentIcon
            style={{
              height: "0.875rem",
              width: "0.875rem",
              marginRight: "0.5rem",
            }}
          />
          <span>Documents</span>
        </CommandItem>
      </CommandGroup>
    );
  };

  const Loading = () => {
    return (
      <div className="p-6 flex-col items-center">
        <div className="flex">
          <span className="self-center mx-auto">
            <LogoSpinner variant="md" loadingText="" />
          </span>
        </div>

        <div className="flex">
          <p className="self-center mx-auto mt-6 text-xs text-zinc-700 dark:text-zinc-400">
            Searching for{" "}
            <strong className="break-words font-semibold text-zinc-900 dark:text-white">
              &lsquo;{query}&rsquo;
            </strong>
            ...
          </p>
        </div>
      </div>
    );
  };

  return (
    <CommandDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        setQuery("");
      }}
    >
      <CommandInput
        onValueChange={(val) => setQuery(val)}
        value={query}
        placeholder="Search documents..."
      />
      <CommandList className="max-h-96 overflow-y-auto">
        {loading && <Loading />}

        {!loading && searchQuery.length === 0 && <Suggestions />}

        {!loading &&
          searchQuery.length > 0 &&
          results &&
          results.length > 0 && (
            <SearchResults results={results} query={query} setOpen={setOpen} />
          )}

        {!loading && searchQuery.length === 0 && (
          <CommandGroup heading="Recent">
            {results?.slice(0, 3).map((result, resultIndex) => (
              <SearchResult key={result.id} result={result} setOpen={setOpen} />
            ))}
          </CommandGroup>
        )}
      </CommandList>
    </CommandDialog>
  );
}
