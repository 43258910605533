import { axiosService } from "@/lib/axios";
import {
  ProcessedBingSearchResult,
  getBingSearchResults,
} from "../api/getBingSearchResults";
import { breakDownURL, removeAccents } from "./serp";
import { getHostname } from "./url";

interface Modifier {
  questions: string[];
  commercial: string[];
  informational: string[];
}

interface Modifiers {
  [key: string]: Modifier;
}

interface Args {
  modifier: string | null;
}

interface RequestConfig {
  query: string;
  count: string;
  type: string;
  country: string | null;
}

interface RedditRequestConfig {
  query: string;
  count: number;
}

interface RedditData {
  title: string;
  url: string;
}

interface RedditChild {
  data: RedditData;
}

interface RedditResponse {
  kind: string;
  data: {
    children: RedditChild[];
  };
}

interface RedditOutput {
  question: string;
  domain: string;
  url: string;
  source: string;
}

/**
 * Get autocomplete queries for Bing Autosuggest using provided query, language, and country.
 * @function
 * @param {string} query - The search query.
 * @param {string} lang - The language to be used for suggestions.
 * @param {string} country - The country to be used for suggestions.
 * @param {Args} [args] - Optional argument object with a modifier.
 * @returns {RequestConfig[]} - Returns an array of autocomplete request configurations.
 */
export const getAutocompleteQueries = (
  query: string,
  lang: string,
  country: string,
  args?: Args
): RequestConfig[] => {
  let suffixList: string[] = [];
  let prefixList: string[] = [];

  if (args && args.modifier !== null) {
    suffixList = [args.modifier];
  } else {
    prefixList = getModifiers(lang).questions;
    suffixList = [""];
  }

  const geo = lang && country ? `${lang}-${country}` : null;

  const reqList: RequestConfig[] = [];

  prefixList.forEach((prefix) => {
    const q = `${prefix} ${query}`;
    reqList.push({ query: q, count: "50", type: "autosuggest", country: geo });
  });

  suffixList.forEach((suffix) => {
    const q = `${query} ${suffix}`;
    reqList.push({ query: q, count: "50", type: "autosuggest", country: geo });
  });

  return reqList;
};

/**
 * Get Quora-specific search query based on the initial query and language.
 * @function
 * @param {string} query - The search query.
 * @param {string} lang - The language to be used.
 * @param {string} country - The country to be used.
 * @returns {RequestConfig[]} - Returns an array with the Quora-specific search query.
 */
export const getQuoraQuery = (
  query: string,
  lang: string,
  country: string
): RequestConfig[] => {
  const domainMap: { [key: string]: string } = {
    en: "quora.com",
    es: "es.quora.com",
    fr: "fr.quora.com",
    de: "de.quora.com",
    da: "da.quora.com",
    pt: "pt.quora.com",
    it: "it.quora.com",
    nl: "nl.quora.com",
  };

  const domain = domainMap[lang] || "quora.com";

  const quoraQuery = `${query} site:${domain}`;
  const geo = lang && country ? `${lang}-${country}` : null;

  const payload: RequestConfig[] = [
    {
      query: quoraQuery,
      country: geo,
      count: "50",
      type: "web",
    },
  ];

  return payload;
};

interface QuoraResult {
  question: string;
  domain: string;
  url: string;
  source: string;
}

/**
 * Postprocess function to process the response from a Quora search.
 * @function
 * @param {ProcessedBingSearchResult[]} resp - The response array with title and url values.
 * @returns {QuoraResult[]} - Returns an array of postprocessed objects with query, domain, url, and source information.
 */
export const processQuoraResults = (
  resp: ProcessedBingSearchResult[]
): QuoraResult[] => {
  const list: QuoraResult[] = [];

  if (resp && resp.length > 0) {
    resp.forEach((value) => {
      let q = value.title;

      if (q.includes("...")) {
        value.url = decodeURI(value.url);
        q = value.url.split("/")[value.url.split("/").length - 1];
        q = q.split("-").join(" ");
      }

      if (q.includes("- Quora")) {
        q = q.split("- Quora")[0];
      }

      const domain = getHostname(value.url);

      list.push({
        question: q,
        domain,
        url: value.url,
        source: "Quora",
      });
    });
  }

  return list;
};

/**
 * Get Reddit-specific search query based on the initial query and language.
 * @function
 * @param {string} query - The search query.
 * @param {string} lang - The language to be used.
 */
export const getRedditQuery = (
  query: string,
  lang: string
): RedditRequestConfig[] => {
  const prefixList: string[] = getModifiers(lang).questions;
  const list: RedditRequestConfig[] = [];

  prefixList.forEach((prefix) => {
    const q = `title:${prefix} ${query}`;
    list.push({ query: q, count: 20 });
  });

  return list;
};

/**
 * Postprocess function to process the response from a Reddit search.
 * @function
 * @param {RedditResponse[]} resp - The response object with 'children' array containing title and url values.
 * @param {string} query - The search query.
 * @returns {RedditOutput[]} - Returns an array of postprocessed objects with query, domain, url, and source information.
 */
export const processRedditResults = (
  results: RedditResponse[],
  query: string
): RedditOutput[] => {
  const list: RedditOutput[] = [];

  const resultsArray = Array.isArray(results) ? results : [results];

  resultsArray.forEach((resp) => {
    const item = resp.data;
    if (resp && resp.data) {
      const domain = getHostname(item.url);
      const isMatch = checkMatches(query, item.title);

      if (domain === "reddit.com" && isMatch) {
        list.push({
          question: item.title,
          url: item.url,
          domain: domain,
          source: "Reddit",
        });
      }
    }
  });

  return list;
};

/**
 * Check if any keyword from the provided query matches the given term.
 * @function
 * @param {string} query - The search query.
 * @param {string} term - The term to check for keyword matches.
 * @returns {boolean} - Returns true if there is a match, false otherwise.
 */
function checkMatches(query: string, term: string): boolean {
  if (!query || query.length === 0) {
    return false;
  }

  const keywords = query.split(" ");
  const lowercasedTerm = term.toLowerCase();

  return keywords.some((kw) => {
    kw = kw.trim().toLowerCase();

    return kw.length > 0 && lowercasedTerm.includes(kw);
  });
}

/**
 * Get language-based modifiers.
 * @function
 * @param {string} lang - The language code.
 * @returns {Modifier} - Returns an object of language-based modifiers.
 */
function getModifiers(lang: string): Modifier {
  const modifiers: Modifiers = {
    en: {
      questions: ["what", "how", "why", "where", "when"],
      commercial: ["top", "best", "versus", "review", "compare"],
      informational: ["how to", "guide", "with", "without", "and"],
    },
    es: {
      questions: ["qué", "cómo", "por qué", "quién", "dónde", "cuándo"],
      commercial: ["mejor", "versus", "review", "comparar"],
      informational: ["cómo", "guiar", "con", "sin", "y"],
    },
    fr: {
      questions: ["quoi", "comment", "pourquoi", "qui", "où", "quand"],
      commercial: ["top", "versus", "review"],
      informational: ["comment faire", "guider", "avec", "sans", "et"],
    },
    de: {
      questions: ["was", "wie", "warum", "wer", "wo", "wann"],
      commercial: ["top", "versus", "review"],
      informational: ["wie man", "führt", "mit", "ohne", "und"],
    },
    pt: {
      questions: ["o que", "como", "por que", "quem", "onde", "quando"],
      commercial: ["top", "versus", "review"],
      informational: ["como fazer", "guiar", "com", "sem", "e"],
    },
    it: {
      questions: ["cosa", "come", "perché", "chi", "dove", "quando"],
      commercial: ["top", "versus", "review", "compare"],
      informational: ["come fare", "guida", "con", "senza", "e"],
    },
    nl: {
      questions: ["wat", "hoe", "waarom", "wie", "waar", "wanneer"],
      commercial: ["top", "versus", "review", "vergelijken"],
      informational: ["hoe", "begeleiden", "met", "zonder", "en"],
    },
    da: {
      questions: ["hvad", "hvordan", "hvorfor", "hvor", "hvornår"],
      commercial: ["top", "bedst", "versus", "anmeld", "sammenlign"],
      informational: ["hvordan", "guide", "med", "uden", "og"],
    },
  };

  return modifiers[lang] || ({} as Modifier);
}

export const getQuestions = (
  input,
  domain,
  options,
  lang = "en",
  country,
  args
) => {
  const reqList = [];

  if (options && options.length > 0) {
    options.forEach((value) => {
      if (value === "google") {
        reqList.push(getGoogleQuestions(input, lang, country, args));
      } else if (value === "autocomplete") {
        reqList.push(getAutocomplete(input, lang, country, args));
      } else if (value === "quora") {
        reqList.push(getQuoraQuestions(input, lang, country));
      } else if (value === "reddit") {
        reqList.push(getRedditQuestions(input, lang));
      }
    });
  } else {
    reqList.push(getGoogleQuestions(input, lang, country, args));
    reqList.push(getAutocomplete(input, lang, country, args));
    reqList.push(getQuoraQuestions(input, lang, country));
    reqList.push(getRedditQuestions(input, lang));
  }

  return Promise.all(reqList).then((values) => {
    const dupeMap = {};
    const questionMap = {
      All: [],
      "People Also Ask": [],
      Related: [],
      Autocomplete: [],
      Quora: [],
      Reddit: [],
    };

    values.forEach((list) => {
      if (list && list.length > 0) {
        list.forEach((value) => {
          if (
            isQuestion(value.q) ||
            value.source === "Autocomplete" ||
            value.source === "Related"
          ) {
            const cleanQ = parseQuestion(value.q);
            if (!dupeMap[cleanQ.toLowerCase()]) {
              dupeMap[cleanQ.toLowerCase()] = true;
              value.q = removeAccents(cleanQ);
              questionMap[value.source].push(value);
              questionMap.All.push(value);
            }
          }
        });
      }
    });

    return questionMap;
  });
};

const isQuestion = (str, strict) => {
  let isValid = true;
  if (str && str.length > 0) {
    str = str.trim().toLowerCase();
    str = removeAccents(str);
    if (strict) {
      const enWords = [
        "what",
        "which",
        "where",
        "how",
        "why",
        "when",
        "who",
        "can",
        "should",
        "would",
        "is",
        "will",
        "are",
        "do",
        "does",
      ];
      const esWords = ["que", "como", "por que", "quien", "donde", "cuando"];
      const frWords = ["quoi", "comment", "pourquoi", "qui", "ou", "quand"];
      const itWords = ["cosa", "come", "perché", "chi", "dove", "quando"];
      const deWords = ["was", "wie", "warum", "wer", "wo", "wann"];
      const ptWords = ["o que", "como", "por que", "quem", "onde", "quando"];
      const nlWords = ["wat", "hoe", "waarom", "wie", "waar", "wanneer"];
      const fullList = [
        ...enWords,
        ...esWords,
        ...frWords,
        ...itWords,
        ...deWords,
        ...ptWords,
        ...nlWords,
      ];
      isValid = false;
      fullList.forEach((value) => {
        if (str.split(" ")[0] === value) {
          isValid = true;
        }
      });
      if (str.endsWith("?")) {
        isValid = true;
      }
    }
    if (str.split(" ").length < 4) {
      isValid = false;
    }
    if (str.length > 200) {
      isValid = false;
    }
  }
  return isValid;
};

const parseQuestion = (str) => {
  str = str.split(" - ")[0];
  str = str.split(" — ")[0];
  str = str.split("|")[0];
  str = str.split("?")[0];
  str = str.replace("¿", "");
  str = str.replace(/&#?[a-z0-9]+;/g, "");
  str = str.replace("  ", " ");
  return str.trim();
};

const getQuoraQuestions = (query, lang, country) => {
  let quoraQuery = `${query}`;
  if (!lang || lang === "en") {
    quoraQuery += " site:quora.com";
  } else if (lang === "es") {
    quoraQuery += " site:es.quora.com";
  } else if (lang === "fr") {
    quoraQuery += " site:fr.quora.com";
  } else if (lang === "de") {
    quoraQuery += " site:de.quora.com";
  } else if (lang === "da") {
    quoraQuery += " site:da.quora.com";
  } else if (lang === "pt") {
    quoraQuery += " site:pt.quora.com";
  } else if (lang === "it") {
    quoraQuery += " site:it.quora.com";
  } else if (lang === "nl") {
    quoraQuery += " site:nl.quora.com";
  }
  const geo = lang && country ? `${lang}-${country}` : null;
  const payload = [
    { query: quoraQuery, country: geo, count: "50", type: "web" },
  ];

  return getBingSearchResults({ queries: payload }).then((resp) => {
    const list = [];
    resp = resp[0];
    if (resp && resp.length > 0) {
      resp.forEach((value) => {
        let q = value.title;
        if (q.includes("...")) {
          value.url = decodeURI(value.url);
          q = value.url.split("/").pop().split("-").join(" ");
        }
        if (q.includes("- Quora")) {
          q = q.split("- Quora")[0];
        }
        const domain = breakDownURL(value.url);
        list.push({
          q,
          domain,
          url: value.url,
          source: "Quora",
        });
      });
    }
    return list;
  });
};

const getGoogleQuestions = (query, lang, country, args) => {
  const clicks = args && args.clicks ? args.clicks : 4;
  return axiosService
    .post("/getGoogleQuestions", {
      query,
      num_clicks: clicks,
      lang,
      country,
    })
    .then((resp) => {
      if (resp === "error") {
        return [];
      } else {
        const list = [];
        if (resp.questions?.length > 0) {
          resp.questions.forEach((value) => {
            list.push({
              q: value,
              source: "People Also Ask",
            });
          });
        }
        if (resp?.cards?.length > 0) {
          resp.cards.forEach((value) => {
            if (
              value &&
              !value.toLowerCase().includes("change to") &&
              !value.toLowerCase().includes("more results from")
            ) {
              list.push({
                q: value,
                source: "Related",
              });
            }
          });
        }
        return list;
      }
    })
    .catch(() => []);
};

const getRedditQuestions = (query, lang) => {
  if (lang !== "en") {
    return Promise.resolve([]);
  } else {
    const prefixList = [
      "what",
      "how",
      "why",
      "can",
      "where",
      "when",
      "will",
      "who",
    ];
    const reqList = prefixList.map((prefix) =>
      redditFactory.searchReddit(`title:${prefix} ${query}`, 20)
    );
    return Promise.all(reqList).then((values) => {
      const list = [];
      values.forEach((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.children &&
          resp.data.children.length > 0
        ) {
          resp.data.children.forEach((value) => {
            const q = value.data.title;
            const domain = breakDownURL(value.data.url);
            const isMatch = checkMatches(query, q);
            if (domain === "reddit.com" && isMatch) {
              list.push({
                q,
                url: value.data.url,
                domain: "reddit.com",
                source: "Reddit",
              });
            }
          });
        }
      });
      return list;
    });
  }
};

const getAutocomplete = (query, lang, country, args) => {
  const suffixList = args && args.modifier != null ? [args.modifier] : [""];
  const prefixList =
    args && args.modifier != null
      ? []
      : utilitiesFactory.modifiers[lang].questions;
  const geo = lang && country ? `${lang}-${country}` : null;
  const reqList = [
    ...prefixList.map((prefix) => ({
      query: `${prefix} ${query}`,
      count: "50",
      type: "autosuggest",
      country: geo,
    })),
    ...suffixList.map((suffix) => ({
      query: `${query} ${suffix}`,
      count: "50",
      type: "autosuggest",
      country: geo,
    })),
  ];

  return searchFactory.bingAutosuggest(reqList).then((resp) => {
    const list = [];
    if (resp && resp.length > 0) {
      resp.forEach((value) => {
        value.forEach((result) => {
          const q = result.query;
          const isMatch = checkMatches(query, q);
          if (isMatch) {
            list.push({
              q,
              source: "Autocomplete",
            });
          }
        });
      });
    }
    return list;
  });
};
