import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Label,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import { Input } from "@/components/Elements/Input";
import { marked } from "marked";
import { useState } from "react";
import { TbFilePlus } from "react-icons/tb";
import { PreviewEditor } from "../../ai/components/AiDocumentPreviewDialog";

export function CreateDocumentFromOutputDialog({
  id,
  generation,
  promptMap,
  onCreate,
  onOpenChange,
}) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = async () => {
    setIsLoading(true);
    const htmlString = marked.parse(generation || "");
    await onCreate(title, htmlString, promptMap);
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        onOpenChange?.(isOpen);
      }}
    >
      <DialogTrigger asChild>
        <Button
          id={id}
          variant="buttonIcon"
          buttonIcon={<TbFilePlus />}
          tooltipContent="Create document from output"
        />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitle>Create document from output</DialogTitle>
          <DialogCloseButton close={() => setOpen(false)} />
        </DialogHeader>
        <DialogDescription className="px-4 space-y-2">
          <div className="flex flex-col space-y-1">
            <Label>Document title</Label>
            <Input
              autoFocus
              value={title}
              onChange={(value) => setTitle(value)}
              placeholder="Enter a document title..."
              className="mb-4"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <Label>Content preview</Label>
            <div className="h-[60vh] w-full overflow-scroll rounded-md bg-white pl-2.5 text-sm text-zinc-900 ring-1 ring-inset ring-zinc-900/7.5 transition dark:bg-zinc-900 dark:text-white dark:ring-inset disabled:cursor-not-allowed disabled:opacity-50 hover:ring-zinc-300 dark:ring-white/10 dark:hover:ring-white/20 focus:[&:not(:focus-visible)]:outline-none focus:outline-none border-none !outline-none focus:ring-emerald-600/50 focus:ring-inset">
              <PreviewEditor html={generation} />
            </div>
          </div>
        </DialogDescription>
        <DialogFooter className="px-4 pb-4">
          <Button variant="outlineBlur" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="primaryBlur"
            onClick={handleCreate}
            isLoading={isLoading}
            disabled={!title}
          >
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
