import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../../../components/Elements";
import {
  TopicInputTag,
  TopicTag,
} from "../../../components/Elements/Tag/TopicTag";
import { useDocumentStore } from "../../../stores/document";
import { useNotificationStore } from "../../../stores/notifications";
import { useUpdateDocument } from "../../documents/api/updateDocument";

export const TopicsStep = ({
  topics = [],
  selectedTopics,
  setSelectedTopics,
}) => {
  const [unselectedTopics, setUnselectedTopics] = useState([]);
  const { addNotification } = useNotificationStore();
  const { document: fraseDocument } = useDocumentStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });

  const handleUpdateDocumentTopics = () => {
    const updatedDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        topics: JSON.stringify(selectedTopics),
      },
    };

    updateDocumentMutation.mutateAsync(updatedDocument);
  };

  useEffect(() => {
    if (selectedTopics.length === 0) {
      setSelectedTopics(topics);
    }
  }, [topics]);

  useEffect(() => {
    const updateTopics = async () => {
      const currentTopics = fraseDocument.metadata?.topics;
      if (!isEqual(selectedTopics, JSON.parse(currentTopics || "[]"))) {
        await handleUpdateDocumentTopics();
      }
    };

    updateTopics();
  }, [selectedTopics]);

  useEffect(() => {
    const newUnselectedTopics = topics.filter(
      (topic) =>
        !selectedTopics.some((selected) => selected.entity === topic.entity)
    );
    setUnselectedTopics(newUnselectedTopics);
  }, [topics, selectedTopics]);

  const handleCustomSubmit = (value) => {
    if (selectedTopics.length < 50) {
      setSelectedTopics([value, ...selectedTopics]);
    } else {
      addNotification({
        title: "Maximum limit reached",
        message: "You have reached the maximum limit of 50 topics.",
        type: "error",
      });
    }
  };

  const addTopic = (topic) => {
    if (selectedTopics.length < 50) {
      const updatedUnselectedTopics = unselectedTopics.filter(
        (t) => t.entity !== topic.entity
      );
      setUnselectedTopics(updatedUnselectedTopics);
      setSelectedTopics([topic, ...selectedTopics]);
    } else {
      addNotification({
        title: "Maximum limit reached",
        message: "You have reached the maximum limit of 50 topics.",
        type: "error",
      });
    }
  };

  const removeTopic = (topic) => {
    const updatedSelectedTopics = selectedTopics.filter(
      (t) => t.entity !== topic.entity
    );
    setSelectedTopics(updatedSelectedTopics);
    setUnselectedTopics([topic, ...unselectedTopics]);
  };

  return (
    <div className="flex flex-col h-full justify-between p-4 w-1/2 max-w-[700px] mx-auto">
      <h2 className="text-xl font-semibold text-zinc-800 dark:text-zinc-100">
        Topics
      </h2>
      <p className="text-sm mt-4 font-normal text-zinc-600 dark:text-zinc-300">
        Including semantically relevant topics in your article is an essential
        part of SEO content optimization. Frase's AI will aim to incorporate
        your list of topics in your article. You can continue to optimize your
        content later using the Frase editor.
      </p>

      <div className="flex items-center justify-between w-full">
        <h2 className="text-xs mt-4 mb-1 text-zinc-600 font-medium dark:text-zinc-400">
          Selected Topics
        </h2>
        <Button
          variant="text"
          onClick={() => {
            setSelectedTopics([]);
            setUnselectedTopics([...unselectedTopics, ...selectedTopics]);
          }}
          className="mt-2"
          size="xs"
        >
          Remove all
        </Button>
      </div>
      <div className="flex-grow overflow-y-scroll border dark:border-zinc-800 dark:bg-zinc-900 flex-wrap px-2 pt-2 rounded-md items-start min-h-[82px]">
        <TopicInputTag onAdd={handleCustomSubmit} />
        {selectedTopics.map((topic) => {
          return (
            <TopicTag key={topic.entity} onRemove={() => removeTopic(topic)}>
              {topic.entity}
            </TopicTag>
          );
        })}
      </div>

      {unselectedTopics.length > 0 && (
        <>
          <div className="flex items-center justify-between w-full mt-4">
            <h2 className="mt-4 mb-1 text-xs text-zinc-600 font-medium flex items-center dark:text-zinc-400">
              Suggested topics from SERP
            </h2>
            <Button
              variant="text"
              onClick={() => {
                setSelectedTopics([...selectedTopics, ...unselectedTopics]);
                setUnselectedTopics([]);
              }}
              className="mt-2"
              size="xs"
            >
              Add all
            </Button>
          </div>
          <div className="flex-grow overflow-y-scroll border dark:border-zinc-600 flex-wrap dark:bg-zinc-900 px-2 pt-2 rounded-md items-start min-h-[80px]">
            {unselectedTopics.length === 0 && (
              <p className="text-xs text-zinc-500 mb-2 pl-1">
                Enter a search query to generate recommended topics
              </p>
            )}
            {unselectedTopics.map((topic) => {
              return (
                <TopicTag key={topic.entity} onAdd={() => addTopic(topic)}>
                  {topic.entity}
                </TopicTag>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
