import { cn } from "@/utils/style";
import { motion } from "framer-motion";
import { TbArrowRight } from "react-icons/tb";
import { Button, Progress, Spinner } from "../../../components/Elements";
import { FraseDocument } from "../../documents";
import { useUpdateDocument } from "../../documents/api/updateDocument";

export const WizardGeneratingMessage = ({
  fraseDocument,
  title,
  onNavigateToDocument = () => {},
  renderNavigationButton = true,
  progress,
}: {
  fraseDocument: FraseDocument;
  title?: string;
  onNavigateToDocument?: () => void;
  renderNavigationButton?: boolean;
  progress?: number;
}) => {
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });

  const handleCancel = async () => {
    const updatedDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        ai_article: undefined,
      },
    };

    await updateDocumentMutation.mutateAsync(updatedDocument);
  };

  return (
    <div className="flex flex-col items-center space-y-4 w-full h-full">
      <div className="flex items-center space-x-2 mt-20">
        <Spinner size="sm" />
        <p className="text-zinc-600 font-medium dark:text-zinc-300">
          Generating article with AI - this may take a few minutes...
        </p>
      </div>
      <Progress
        progress={Math.round((progress || 0) * 10)}
        className={`w-[300px]`}
        stripColor={"emerald"}
      />
      <p className="text-zinc-600 dark:text-zinc-300 text-sm font-medium">
        {progress ? `${Math.round(progress * 100)}%` : "0%"} completed
      </p>
      <div className="flex flex-col border-l border-r border-t w-1/2 overflow-hidden max-w-[700px] rounded-tl-lg rounded-tr-lg relative dark:border-zinc-700 py-8">
        {title && (
          <p className="font-semibold text-zinc-900 text-xl dark:text-zinc-400 px-8 pb-8">
            {title}
          </p>
        )}
        {Array.from({ length: 6 }).map((_, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scaleY: 0.5 }}
            animate={{ opacity: 1, scaleY: 1 }}
            exit={{ opacity: 0, scaleY: 0.5 }}
            transition={{
              delay: index * 0.3,
              duration: 0.5,
              repeat: Infinity,
              repeatType: "reverse",
              repeatDelay: 1.5,
            }}
            className={cn(
              "h-4 mb-2 mx-8 bg-emerald-600/20 rounded-lg dark:bg-emerald-400/10",
              index === 0 && "ml-16",
              index === 5 && "mr-16"
            )}
          />
        ))}
        <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent dark:from-zinc-900 dark:to-transparent"></div>
      </div>
      {renderNavigationButton && (
        <Button
          variant="primaryBlur"
          onClick={() => {
            if (onNavigateToDocument) {
              onNavigateToDocument();
            }
          }}
          endIcon={<TbArrowRight />}
        >
          Go to document
        </Button>
      )}
      {/*<Button variant="outlineBlur" onClick={handleCancel} size="2xs">
        Cancel
        </Button>*/}
    </div>
  );
};
