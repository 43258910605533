import { Navigate, Route, Routes } from "react-router-dom";
import AiTool from "./AiTool";
import { AiToolBuilder } from "./AiToolBuilder";
import AiTools from "./AiTools";

export const AiToolsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<AiTools />} />
      <Route path=":aiToolHash" element={<AiTool />} />
      <Route path="/new/:aiToolId" element={<AiToolBuilder />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
