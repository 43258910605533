import {
  ArticleMetadata,
  Button,
  Tag,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import ArticleAssets from "@/components/Elements/Asset/ArticleAssets";
import {
  useHandlePasteCitation,
  useHandlePasteContent,
  useHandlePasteHeadings,
} from "@/features/documents/utils/pasting";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { useState } from "react";

export const CompetitorCard = ({
  title,
  wordCount,
  url,
  urlHost,
  score,
  rank,
  isUserDoc,
  domainAuthority,
  links,
  topicScore,
  averageScore,
  assets,
  isCustomImport,
}) => {
  const [assetsVisible, setAssetsVisible] = useState(false);
  const handlePasteContent = useHandlePasteContent();
  const handlePasteCitation = useHandlePasteCitation();
  const handlePasteHeadings = useHandlePasteHeadings();
  let color = topicScore > averageScore ? "emerald" : "amber";
  if (topicScore <= averageScore / 2) {
    color = "rose";
  }
  let activeDocStyles = "";
  if (color === "emerald") {
    activeDocStyles =
      "ring-emerald-400 dark:ring-emerald-400 ring-1 shadow-emerald-500 shadow-glow bg-emerald-100/10 dark:bg-emerald-900";
  } else if (color === "amber") {
    activeDocStyles =
      "ring-amber-400 dark:ring-amber-400 ring-1 shadow-amber-500 shadow-glow bg-amber-100/10 dark:bg-amber-900";
  } else if (color === "rose") {
    activeDocStyles =
      "ring-rose-400 dark:ring-rose-400 ring-1 shadow-rose-500 shadow-glow bg-rose-100/10 dark:bg-rose-900";
  }

  return (
    <div
      onClick={() => setAssetsVisible(!assetsVisible)}
      className={clsx(
        "group p-2 py-2.5 cursor-pointer relative items-center rounded-md bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 hover:bg-white",
        isUserDoc && activeDocStyles
      )}
    >
      <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
      <div className="flex justify-between">
        <div>
          <div className="flex">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span>
                    <Tag
                      variant="medium"
                      className="ml-1"
                      color={color}
                      textClassName="font-bold"
                    >
                      {`${Math.floor(topicScore)}%`}
                    </Tag>
                  </span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Test</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
            <ArticleMetadata
              className="self-center"
              rank={rank}
              wordCount={wordCount}
              links={links}
              domainAuthority={domainAuthority}
              isCustomImport={isCustomImport}
            />
            <Button
              variant="buttonIcon"
              className="mr-1 invisible group-hover:visible"
              buttonIcon={<Square2StackIcon />}
              tooltipContent={`Paste to editor`}
              onClick={(event) => {
                handlePasteContent(title);
                event.stopPropagation();
              }}
            />
          </div>
          <div className="flex-col ml-2 mt-2 space-y-1">
            {urlHost ? (
              <div className="flex items-center">
                <div>
                  <img
                    className="w-3 h-3 self-center rounded-full bg-zinc-50 dark:bg-zinc-800"
                    src={`http://www.google.com/s2/favicons?domain=${urlHost}`}
                    alt=""
                  />
                </div>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener"
                  className="ml-2 text-2xs text-zinc-400 dark:text-zinc-600 hover:underline hover:text-zinc-600"
                >
                  {urlHost}
                </a>
              </div>
            ) : (
              <div className="flex">
                <Tag variant="small" color="zinc" className="self-center h-6">
                  Your document
                </Tag>
              </div>
            )}
            <p className="text-xs self-center font-medium dark:text-zinc-50">
              {title}
            </p>
          </div>
        </div>
      </div>
      {/*<Progress
        progress={topicScore / 10}
        className={`mx-1`}
        stripColor={color}
        averageProgress={averageScore}
            />*/}
      {assetsVisible && (
        <ArticleAssets
          assets={assets}
          selectedAssets={[]}
          handlePasteHeadings={handlePasteHeadings}
          handlePasteCitation={handlePasteCitation}
          handlePasteContent={handlePasteContent}
        />
      )}
    </div>
  );
};
