import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements";
import { Navigation } from "@/components/Layout/MobileNavigation";
import { DeleteContentButton } from "@/features/documents/components/DeleteContentButton";
import { cn } from "@/utils/style";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionsCell } from "./ActionsCell";
import { SelectCell } from "./SelectCell";
import { TableProps, TemplateRow } from "./TemplateTable.types";

import {
  NewTemplateButton,
  NewTemplateButtonAction,
} from "@/features/templates/components/NewTemplateButton";
import { TbLayoutGrid } from "react-icons/tb";

export const TemplateTable: React.FC<TableProps> = ({
  templates = [],
  templatesCount,
  selectedFilters,
  setSelectedFilters,
  isTemplatesLoading,
}) => {
  const navigate = useNavigate();
  const [comboboxOpen, setComboboxOpen] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const [tableWidth, setTableWidth] = useState(0);
  const tableRef = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.getBoundingClientRect().width);
      }
    };

    if (tableRef.current) {
      setTableWidth(tableRef.current.getBoundingClientRect().width);
    }

    // Add the event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tableRef]);

  const columns: ColumnDef<TemplateRow>[] = [
    {
      id: "select",
      meta: { label: "Select" },
      header: SelectCell.Header,
      cell: SelectCell.Cell,
      enableSorting: false,
      enableHiding: false,
      size: 0,
    },
    {
      accessorKey: "text[0].title",
      meta: { label: "Name" },
      header: ({ column }) => {
        return <p className="text-xs">Name</p>;
      },
      cell: ({ row }) => {
        let title = row.original.text?.[0]?.title || "Untitled";
        title = title.length > 0 ? title.split(",")[0] : title;
        return <p className="text-xs font-medium dark:text-white">{title}</p>;
      },
      size: 500,
    },
    {
      accessorKey: "udpated_dt",
      meta: { label: "Edited" },
      header: ({ column }) => <p className="text-xs">Edited</p>,
      cell: ({ row }) => {
        return (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            {dayjs().to(dayjs(row.original.updated_dt))}
          </p>
        );
      },
      size: 500,
    },
    {
      accessorKey: "doc_owner_name",
      meta: {
        label: "User",
      },
      header: ({ column }) => <p className="text-xs">User</p>,
      cell: ({ row }) => {
        return (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            {row.original.template_author}
          </p>
        );
      },
      size: 200,
    },
    {
      id: "actions",
      meta: { label: "Actions" },
      cell: ({ row }) => (
        <ActionsCell.Cell row={row} table={table} type="template" />
      ),
      enableSorting: false,
      enableHiding: false,
      size: 0,
    },
  ];

  const table = useReactTable({
    data: templates,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const handlePageChange = (pageIndex: number) => {
    table.setPageIndex(pageIndex - 1); // pageIndex is usually 0-indexed
  };

  const handlePageSizeChange = (pageSize: number) => {
    table.setPageSize(pageSize);
  };

  return (
    <div className="flex h-full w-full">
      <div className="flex flex-col h-full w-full relative">
        <div className="flex flex-col h-fit">
          <div
            className="flex justify-between items-center px-6 h-14 w-full border-b dark:border-zinc-800"
            ref={tableRef}
          >
            <div className="flex items-center">
              <Navigation />

              <span className="flex items-center mr-4 [(:first-child)]:ml-1 text-sm font-medium dark:text-white ">
                {table.getIsSomeRowsSelected() ? (
                  <>
                    {table.getFilteredSelectedRowModel().rows.length} templates
                    selected
                  </>
                ) : (
                  <>All templates</>
                )}
              </span>
              {table.getFilteredSelectedRowModel().rows.length > 0 && (
                <>
                  <DeleteContentButton
                    table={table}
                    entityIds={[
                      ...table
                        .getFilteredSelectedRowModel()
                        .rows.map((row) => row.original.id),
                    ]}
                    entityTitles={[
                      ...table
                        .getFilteredSelectedRowModel()
                        .rows.map((row) => row.original.text?.[0]?.title),
                    ]}
                    entityType={"template"}
                  />
                </>
              )}
            </div>
            <div className="flex">
              <NewTemplateButton
                className="w-30"
                buttonVariant={"primaryBlur"}
              />
            </div>
          </div>
        </div>

        {table.getRowModel().rows.length > 0 ? (
          <div
            className={cn(
              "flex w-full overflow-auto pb-20",
              selectedFilters.length > 0
                ? "max-h-[calc(100vh-112px)]"
                : "max-h-[calc(100vh-105px)]"
            )}
          >
            <Table className="w-full">
              <TableHeader
                className="sticky top-0 z-[9] bg-white py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8
            dark:bg-zinc-900 dark:text-white"
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          style={{
                            boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                            width: header.getSize(),
                          }}
                          className="px-4"
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody className="overflow-y-scroll">
                {table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.original.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn(
                      "hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer"
                    )}
                    onClick={() => {
                      if (!comboboxOpen) {
                        navigate(`/app/templates/${row.original.hash}`);
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className="px-4">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <div className=" bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-lg border space-y-4 dark:border-zinc-700">
              <div className="flex items-center space-x-2">
                <TbLayoutGrid className="w-6 h-6 text-zinc-600 dark:text-zinc-400 fill-zinc-600 dark:fill-zinc-400" />
                <h3 className="text-xl font-medium text-zinc-800 dark:text-zinc-200">
                  Templates
                </h3>
              </div>
              <p className="text-sm text-zinc-600 dark:text-zinc-400">
                Create custom templates for your content briefs and streamline
                your SEO writing process.
              </p>
              <p className="text-sm text-zinc-600 dark:text-zinc-400">
                Import templates from existing documents by clicking the "Import
                template" button from the editor. You can also create new
                templates from scratch.
              </p>
              <div>
                <NewTemplateButtonAction
                  className="w-fit"
                  size="sm"
                  startIcon={<></>}
                  text="Create new template"
                />
              </div>
            </div>
          </div>
        )}
        <div className="absolute bottom-0 bg-white border-t dark:border-t-zinc-800 h-[48px] max-h-[48px] w-full dark:bg-zinc-900">
          <Pagination
            type="template"
            documentsCount={templatesCount || 0}
            currentPage={table.getState().pagination.pageIndex + 1}
            itemsPerPage={table.getState().pagination.pageSize}
            totalItems={
              table.getFilteredRowModel().rows.length ||
              table.getRowModel().rows.length
            }
            onPageChange={handlePageChange}
            onItemsPerPageChange={handlePageSizeChange}
            table={table}
          />
        </div>
      </div>
    </div>
  );
};
