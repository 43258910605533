import { sendVerificationEmail } from "../features/verification/api/sendVerificationEmail";

export const handleSendVerificationEmail = async ({
  email,
  id,
}: {
  email: string;
  id: number;
}) => {
  await sendVerificationEmail({
    email: email,
    id: id,
  });
};
