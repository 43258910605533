"use client";

import { useNavigationStore } from "@/components/Layout/MobileNavigation";
import { useSidebarNavigationStore } from "@/components/Layout/SidebarToggle";
import { VersionControlDialog } from "@/features/documents/components/VersionControlDialog";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from "lexical";
import { SplitSquareHorizontal } from "lucide-react";
import { useState } from "react";
import {
  TbChevronRight,
  TbClockHour3,
  TbDots,
  TbFileExport,
  TbFileText,
  TbFileTypeHtml,
  TbFileTypePdf,
  TbLink,
  TbMessageCircle,
} from "react-icons/tb";
import { useReactToPrint } from "react-to-print";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/Elements";
import { useDocumentStore } from "../../../stores/document";
import { useEditorStore } from "../../../stores/editor";
import { useNotificationStore } from "../../../stores/notifications";

type EditorOptionsMenuProps = {
  type: "document" | "template";
  editorRef: React.RefObject<any>;
};

export function EditorOptionsMenu({ type, editorRef }: EditorOptionsMenuProps) {
  const { editor: editorStore, toggleSplitView, setEditor } = useEditorStore();
  const [editor] = useLexicalComposerContext();
  const { document: fraseDocument } = useDocumentStore();
  const { activeTabIndex } = editorStore;
  const { addNotification } = useNotificationStore();
  const [versionControlDialogOpen, setVersionControlDialogOpen] =
    useState(false);
  const { closeNav, isNavOpen, openNav } = useNavigationStore();
  const { closeSidebar, isSidebarOpen, openSidebar } =
    useSidebarNavigationStore();

  const handleExportToPDF = useReactToPrint({
    content: () => editorRef.current,
  });

  const handleCopyDocumentLink = () => {
    navigator.clipboard.writeText(window.location.href);
    addNotification({
      type: "success",
      title: `Copied ${type} link to clipboard`,
    });
  };

  const handleExportToHTML = () => {
    let documentContent = {
      html: "",
      title: "",
      name: "Tab 1",
    };
    documentContent = fraseDocument.text[activeTabIndex] || documentContent;
    let html = documentContent.html;
    html = html.replaceAll("&nbsp;", " "); // Replace non-breaking spaces with regular spaces
    const doc = new DOMParser().parseFromString(html, "text/html");
    for (const element of doc.querySelectorAll("*")) {
      element.removeAttribute("style");
      element.removeAttribute("class");
      element.removeAttribute("dir");
    }
    const blob = new Blob([doc.documentElement.outerHTML], {
      type: "text/plain",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${documentContent.title}.txt`;
    link.click();
    addNotification({
      type: "success",
      title: "Document exported as HTML file",
    });
  };

  const handleExportToText = () => {
    let documentContent = {
      html: "",
      title: "",
      name: "Tab 1",
    };
    documentContent = fraseDocument.text[activeTabIndex] || documentContent;
    const stringifiedEditorState = JSON.stringify(
      editor.getEditorState().toJSON()
    );
    const parsedEditorState = editor.parseEditorState(stringifiedEditorState);

    let editorStateTextString = parsedEditorState.read(() =>
      $getRoot().getTextContent()
    );
    editorStateTextString = editorStateTextString.replaceAll("&nbsp;", " "); // Replace non-breaking spaces with regular spaces
    const blob = new Blob([editorStateTextString], {
      type: "text/plain",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${documentContent.title || "document"}.txt`; // Fallback to 'document' if title is empty
    link.click();
    addNotification({
      type: "success",
      title: "Document exported as text file",
    });
  };

  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="buttonIcon" buttonIcon={<TbDots />}></Button>
                </DropdownMenuTrigger>
                <TooltipContent>Export options</TooltipContent>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuGroup>
                    <DropdownMenuGroup>
                      <DropdownMenuItem onClick={handleCopyDocumentLink}>
                        <TbLink className="mr-2 h-4 w-4" />
                        <span>Copy {type} link</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => setVersionControlDialogOpen(true)}
                      >
                        <TbClockHour3 className="mr-2 h-4 w-4" />
                        Document history
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          toggleSplitView();
                          if (!isSidebarOpen) {
                            openSidebar();
                          }
                        }}
                      >
                        <SplitSquareHorizontal className="mr-2 h-4 w-4" />
                        <span>Toggle split view</span>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      onClick={() => {
                        setEditor((state) => {
                          state.isCommentsPanelVisible =
                            !state.isCommentsPanelVisible;
                        });
                      }}
                    >
                      <TbMessageCircle className="mr-2 h-4 w-4" />
                      <span>View all comments</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuSub>
                      <DropdownMenuSubTrigger>
                        <TbFileExport className="mr-2 h-4 w-4" />
                        <span>Export</span>
                        <TbChevronRight className="ml-auto h-4 w-4" />
                      </DropdownMenuSubTrigger>
                      <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                          <DropdownMenuItem onClick={handleExportToHTML}>
                            <TbFileTypeHtml className="mr-2 h-4 w-4" />
                            <span>HTML</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={handleExportToPDF}>
                            <TbFileTypePdf className="mr-2 h-4 w-4" />
                            <span>PDF</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={handleExportToText}>
                            <TbFileText className="mr-2 h-4 w-4" />
                            <span>Text</span>
                          </DropdownMenuItem>
                        </DropdownMenuSubContent>
                      </DropdownMenuPortal>
                    </DropdownMenuSub>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </span>
          </TooltipTrigger>
        </Tooltip>
      </TooltipProvider>
      <VersionControlDialog
        open={versionControlDialogOpen}
        setOpen={setVersionControlDialogOpen}
      />
    </>
  );
}
