import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

const cancelAddOn = async () => {
  const data = await axios.post("/stripe/remove_add_on");
  return data;
};

export const useCancelAddOn = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(cancelAddOn, {
    onSuccess: () => {
      queryClient.invalidateQueries("upcomingInvoice");
      queryClient.invalidateQueries("subscription");

      addNotification({
        type: "success",
        title: "Add-on cancelled",
        message: "Your add-on has been successfully cancelled.",
      });
    },
  });
};
