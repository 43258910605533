import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getRoot, $isParagraphNode } from "lexical";
import { useEffect } from "react";

export default function EmptyParagraphClickPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const handleEditorClick = (event: MouseEvent) => {
      const editorRootElement = editor.getRootElement();
      const rect = editorRootElement?.getBoundingClientRect();

      // Check if the click was below the editor's content
      if (
        rect &&
        event.clientY > rect.bottom &&
        (event.target as HTMLElement)?.id === "lexical-editor-container"
      ) {
        editor.update(() => {
          const root = $getRoot();
          const lastChild = root.getLastChild();
          if (!$isParagraphNode(lastChild) || !lastChild.isEmpty()) {
            const paragraphNode = $createParagraphNode();
            root.append(paragraphNode);
            paragraphNode.select();
          } else {
            lastChild.select();
          }
        });
      }
    };

    // Add the event listener to the lexical-editor-container
    const editorContainer = document.getElementById("lexical-editor");
    editorContainer?.addEventListener("click", handleEditorClick);

    return () => {
      // Remove the event listener from the lexical-editor-container
      editorContainer?.removeEventListener("click", handleEditorClick);
    };
  }, [editor]);

  return null;
}
