import { isLegacyPlan } from "./tiers";

export function getPlanNickname(name: string | null): string {
  if (typeof name !== 'string') {
    return 'Free';
  }

  // Check for legacy plan names first
  if (isLegacyPlan(name)) {
    if (["solo", "solo_yearly"].includes(name)) {
      return "Solo";
    } else if (["team_monthly_115", "team_yearly_115"].includes(name)) {
      return "Team";
    }
  }

  if (name.includes("solo")) {
    return "Solo";
  } else if (name.includes("team")) {
    return "Team";
  } else if (name.includes("basic")) {
    return "Basic";
  } else if (name.includes("answer")) {
    return "Growth";
  } else {
    return "Free";
  }
}
