import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/Elements/Chart/Chart";
import { useSeoAnalyticsStore } from "@/features/seo-analytics/store";
import { addCommasToNumber } from "@/features/seo-analytics/utils";
import { useIsDarkMode } from "@/utils/isDarkMode";
import dayjs from "dayjs";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { kpiLabels } from "../KpiRow";

interface ChartDataPoint {
  date: string;
  [key: string]: number | string;
  tooltipValue: number | string;
  tooltipLabel: string;
}

function chartData(
  kpis: Record<string, Record<number, number>> | null,
  type: string
): ChartDataPoint[] {
  if (!kpis) return [];

  const data: ChartDataPoint[] = [];
  const keys = Object.keys(kpis[type]).sort((a, b) => Number(a) - Number(b));

  keys.forEach((index) => {
    const value = kpis[type][Number(index)];
    const date = dayjs().subtract(Number(index) * 30 + 2, "days");

    let tooltipValue: number | string = value;
    if (type === "clicks_growth") {
      tooltipValue = `${value}%`;
    } else {
      tooltipValue = addCommasToNumber(value);
    }

    data.push({
      date: date.format("MMM DD YYYY"), // Updated date format
      [type]: value,
      tooltipValue,
      tooltipLabel: type.split("_").join(" "),
    });
  });

  // Remove the last item for clicks_growth
  if (type === "clicks_growth") {
    data.pop();
  }

  return data.reverse();
}

function GSCChart() {
  const { globalKpis, activeKpi } = useSeoAnalyticsStore();
  const isDarkMode = useIsDarkMode();

  const data = chartData(globalKpis, activeKpi);

  const chartConfig = {
    [activeKpi]: {
      label: kpiLabels.get(activeKpi) || activeKpi,
      color: "#059669",
    },
  } satisfies ChartConfig;

  if (!data.length) return null;

  return (
    <ResponsiveContainer
      height={200}
      width={"90%"}
      style={{ maxWidth: "1200px" }}
      className="aspect-auto h-[200px] w-full p-4 my-8 mx-auto"
    >
      <ChartContainer config={chartConfig}>
        <BarChart data={data} className="text-zinc-800 dark:text-zinc-200">
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            interval={0}
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            minTickGap={32}
            tickFormatter={(value) => {
              const date = new Date(value);
              return date.toLocaleDateString("en-US", {
                month: "short",
              });
            }}
          />
          <YAxis
            tick={{ fill: isDarkMode ? "#d4d4d8" : "#3f3f46" }}
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tickCount={3}
            tickFormatter={(value) => {
              if (value >= 1000000) {
                return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
              } else if (value >= 1000) {
                return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K";
              }
              return value;
            }}
          />
          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent
                color="#059669"
                indicator="dot"
                labelFormatter={(label, payload) => {
                  return dayjs(label).format("DD MMM YYYY");
                }}
                className="bg-white dark:bg-zinc-900 dark:text-white dark:border-zinc-800"
              />
            }
          />
          <Bar dataKey={activeKpi} barSize={35} radius={4}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry[activeKpi] >= 0 ? "#059669" : "#f87171"}
              />
            ))}
          </Bar>
        </BarChart>
      </ChartContainer>
    </ResponsiveContainer>
  );
}

export default GSCChart;
