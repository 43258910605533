import { Button } from "@/components/Elements/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/Elements/DropdownMenu";
import { FraseDocument } from "@/features/documents";
import { PrepoulateContentPreviewPlugin } from "@/features/templates/components/ImportTemplateButton";
import { useEditorStore } from "@/stores/editor";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { CaretDownIcon } from "@radix-ui/react-icons";
import * as Sentry from "@sentry/react";
import { TextNode } from "lexical";
import PlaygroundNodes from "./nodes/PlaygroundNodes";
import { ExtendedTextNode } from "./plugins/ExtendedTextNodePlugin/ExtendedTextNodePlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";

export const DocumentSidebarPreview = ({
  document,
}: {
  document: FraseDocument;
}) => {
  const editorStore = useEditorStore();
  const { editor, setEditor, toggleSplitView } = editorStore;
  const { splitTabIndex, isSplitViewVisible } = editor;

  const handleTabChange = (index: number) => {
    setEditor((state) => {
      state.splitTabIndex = index;
    });
  };

  const markdownWithTitle = document.text[splitTabIndex].html || "";
  const title = document.text[splitTabIndex].title || "";

  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-start p-4 h-[93px] border-b">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outlineBlur"
              size="md"
              label="Active tab"
              labelClassName="text-2xs mr-2 px-2"
              className="font-medium flex-shrink-0"
              textClassName="text-2xs font-base text-zinc-900 dark:text-white !px-0 whitespace-nowrap"
              endIcon={<CaretDownIcon />}
              tooltipContent="Select tab"
              tooltipDirection="bottom"
            >
              {document.text[splitTabIndex].name || `Tab ${splitTabIndex + 1}`}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {document.text.map((tab, index) => (
              <DropdownMenuItem
                key={index}
                onSelect={() => handleTabChange(index)}
              >
                {tab.name || `Tab ${index + 1}`}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {isSplitViewVisible && (
          <Button
            variant="outlineBlur"
            size="md"
            className="font-medium flex-shrink-0"
            textClassName="text-2xs font-base text-zinc-900 dark:text-white !px-0 whitespace-nowrap"
            onClick={toggleSplitView}
          >
            Exit split view
          </Button>
        )}
      </div>
      <div className="w-full h-full overflow-y-scroll">
        <LexicalComposer
          initialConfig={{
            editable: false,
            namespace: "Preview",
            onError: (error: Error) => {
              Sentry.captureException(error);
            },
            nodes: [
              ...PlaygroundNodes,
              ExtendedTextNode,
              {
                replace: TextNode,
                with: (node: TextNode) => new ExtendedTextNode(node.__text),
              },
            ],
            theme: PlaygroundEditorTheme,
          }}
        >
          <p className="h-auto w-full leading-[1.2] mx-auto outline-none border-none whitespace-pre-wrap break-words ring-transparent focus:outline-none  focus:border-none focus:ring-transparent text-[2em] font-bold  placeholder:text-zinc-400 dark:placeholder:text-zinc-600  bg-transparent text-zinc-900 dark:text-zinc-50 resize-none overflow-hidden mt-16 mb-8 px-8">
            {title}
          </p>
          <PlainTextPlugin
            contentEditable={
              <ContentEditable className="h-full w-full flex-1 mx-auto min-w-0 mb-40 px-8" />
            }
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <PrepoulateContentPreviewPlugin html={markdownWithTitle} />
        </LexicalComposer>
        <div className="h-80"></div>
      </div>
    </div>
  );
};
