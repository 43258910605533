import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

interface UpdateTeamPayload {
  name: string;
}

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  return useMutation(
    (payload: UpdateTeamPayload) => axios.post("/updateOrg", payload),
    {
      onSuccess: () => {
        addNotification({
          type: "success",
          title: "Team updated",
          message: "The organization name was successfully updated.",
        });
        queryClient.invalidateQueries("subscriptionUpdate");
      },
    }
  );
};
