import { OutlineStep } from "../components/OutlineStep";
import { ReviewStep } from "../components/ReviewStep";
import { SearchQueryStep } from "../components/SearchQueryStep";
import { ToneOfVoiceStep } from "../components/SettingsStep";
import { TitleStep } from "../components/TitleStep";
import { TopicsStep } from "../components/TopicsStep";

export const wizardSteps = [
  { id: 1, label: "Search Query", component: SearchQueryStep },
  { id: 2, label: "Title", component: TitleStep },
  { id: 3, label: "Topics", component: TopicsStep },
  { id: 4, label: "Outline & Instructions", component: OutlineStep },
  { id: 5, label: "Tone of Voice", component: ToneOfVoiceStep },
  { id: 6, label: "Review", component: ReviewStep },
];
