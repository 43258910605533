import { Navigate, Route, Routes } from "react-router-dom";

import { Template } from "./Template";
import { Templates } from "./Templates";

export const TemplatesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Templates />} />
      <Route path=":templateHash" element={<Template />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
