import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

type Classification = {
  action: string;
  guidelines: string;
  query: string;
  urls: string[];
};

type GptClassificationResponse = {
  classification: Classification;
};

type GptClassificationPayload = {
  prompt: string;
  lang: string;
  docHash: string;
};

export const getGptClassification = ({
  prompt,
  lang,
  docHash,
}: GptClassificationPayload): Promise<GptClassificationResponse> => {
  return axios.post("/gpt_prompt_classify", {
    prompt,
    lang,
    docHash,
  });
};

type UseGetGptClassificationOptions = {
  prompt: string;
  lang: string;
  docHash: string;
  isChatQueryEnabled?: boolean;
  config?: any;
};

export const useGetGptClassification = ({
  prompt,
  lang,
  docHash,
  isChatQueryEnabled,
  config,
}: UseGetGptClassificationOptions) => {
  return useQuery({
    ...config,
    queryKey: [
      "gptClassification",
      { prompt, lang, docHash, isChatQueryEnabled },
    ],
    queryFn: () => getGptClassification({ prompt, lang, docHash }),
    staleTime: Infinity,
  });
};
