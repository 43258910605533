import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  TooltipProvider,
} from "@/components/Elements";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { useUser } from "@/features/auth";
import { cn } from "@/utils/style";
import React from "react";
import { useForm } from "react-hook-form";
import { TbPlus } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import { useTrackEvent } from "../../../features/analytics/api/trackUser";
import { useCreateTemplate } from "../api/createTemplate";

const newTemplateFormSchema = z.object({
  name: z.string().min(1, "Name is required"),
});

type NewTemplateStore = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const useNewTemplateStore = create<NewTemplateStore>((set) => ({
  open: false,
  setOpen: (value: boolean) => set({ open: value }),
}));

type NewTemplateFormValues = z.infer<typeof newTemplateFormSchema>;

export const NewTemplateButtonAction = React.forwardRef(
  (
    {
      className,
      buttonVariant = "primaryBlur",
      text = "New template",
      size = "2xs",
      startIcon,
    },
    ref
  ) => {
    const { setOpen, open } = useNewTemplateStore();

    return (
      <Button
        ref={ref}
        variant={buttonVariant}
        size={size}
        onClick={() => {
          setOpen(true);
        }}
        className={cn(
          "w-full flex-nowrap whitespace-nowrap px-[9.5px]",
          className
        )}
        textClassName="w-full text-left ml-[3px]"
        startIcon={
          startIcon || (
            <TbPlus
              strokeWidth={2.5}
              style={{
                height: "1rem",
                width: "1rem",
              }}
            />
          )
        }
        tooltipContent="Create new template"
      >
        {text}
      </Button>
    );
  }
);

export const NewTemplateButton = ({
  className,
  buttonVariant,
}: {
  className?: string;
  buttonVariant?: string;
}) => {
  const { open, setOpen } = useNewTemplateStore();
  const trackEvent = useTrackEvent();

  const createTemplate = useCreateTemplate();
  const { data: user } = useUser();
  const navigate = useNavigate();

  const defaultValues: Partial<NewTemplateFormValues> = {
    name: "",
  };

  const form = useForm<NewTemplateFormValues>({
    resolver: zodResolver(newTemplateFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const handleTrackNewDocumentEvent = (query: string) => {
    trackEvent.mutate({
      event: "new_document",
      properties: JSON.stringify({
        type: tempDocType,
        query,
      }),
    });
  };

  async function onSubmit(data: NewTemplateFormValues) {
    const res = await createTemplate
      .mutateAsync({
        created_by: user?.id,
        metadata: {},
        org_id: user?.orgId,
        text: [{ html: "", title: data.name, name: data.name }],
        type: "manual",
      })
      .catch(() => {
        setOpen(false);
      });
    /*handleTrackNewDocumentEvent(
      data.queries.map((query) => query.value).join(",")
    );*/
    navigate(`/app/templates/${res?.hash}`);
    form.reset(defaultValues);
    setOpen(false);
  }

  const renderSeoDocumentForm = () => {
    return (
      <div className="p-4">
        <DialogTitle className="flex items-center justify-between pb-4">
          <div className="flex items-center">New template</div>
          <DialogCloseButton close={() => setOpen(false)} />
        </DialogTitle>
        <TooltipProvider>
          <DialogDescription>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <NameField form={form} />
                <div className="text-end flex-col">
                  <Button
                    type="submit"
                    size="xs"
                    disabled={!form.formState.dirtyFields.name}
                    isLoading={createTemplate.isLoading}
                  >
                    Create template
                  </Button>
                </div>
              </form>
            </Form>
          </DialogDescription>
        </TooltipProvider>
      </div>
    );
  };

  return (
    <Dialog
      onOpenChange={(open) => {
        setOpen(open);
        form.reset(defaultValues);
      }}
      open={open}
    >
      <DialogTrigger asChild>
        <NewTemplateButtonAction
          className={className}
          buttonVariant={buttonVariant}
          setOpen={setOpen}
        />
      </DialogTrigger>
      <DialogContent>{renderSeoDocumentForm()}</DialogContent>
    </Dialog>
  );
};

export const NameField = ({ form }) => {
  const { errors, submitCount } = form.formState;
  const showError = submitCount > 0 && errors.name;

  return (
    <div>
      <FormField
        control={form.control}
        name={"name"}
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input
                {...field}
                placeholder={`Enter a template name`}
                className="mb-2"
                autoFocus
              />
            </FormControl>

            {showError && (
              <FormMessage>{fieldState.error?.message}</FormMessage>
            )}
          </FormItem>
        )}
      />
    </div>
  );
};
