import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import { Input } from "@/components/Elements/Input";
import React, { useState } from "react";

interface PageFilterDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onApplyFilter: (
    input: string | null,
    filterType:
      | "contains"
      | "not_contains"
      | "including_regex"
      | "excluding_regex"
  ) => void;
}

const PageFilterDialog: React.FC<PageFilterDialogProps> = ({
  open,
  setOpen,
  onApplyFilter,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const [pageFilterType, setPageFilterType] = useState<
    "contains" | "not_contains" | "including_regex" | "excluding_regex"
  >("contains");
  const [pageInput, setPageInput] = useState("");

  const filterTypes: {
    value: "contains" | "not_contains" | "including_regex" | "excluding_regex";
    label: string;
  }[] = [
    { value: "contains", label: "Contains" },
    { value: "not_contains", label: "Not Contains" },
    { value: "including_regex", label: "Matches Regex" },
    { value: "excluding_regex", label: "Doesn't Match Regex" },
  ];

  const handleApplyFilter = async () => {
    setSubmitLoading(true);
    await onApplyFilter(pageInput, pageFilterType);
    setSubmitLoading(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="p-0">
        <DialogHeader className="pb-0 flex items-center justify-between p-4">
          <DialogTitle>Page Filter</DialogTitle>
          <DialogCloseButton close={() => setOpen(false)} />
        </DialogHeader>
        <DialogDescription className="px-4 pb-4">
          <div className="space-y-4">
            <div className="flex flex-wrap gap-4">
              {filterTypes.map(({ value, label }) => (
                <label
                  key={value}
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <input
                    type="radio"
                    checked={pageFilterType === value}
                    onChange={() => setPageFilterType(value)}
                    className="form-radio text-blue-500 focus:ring-blue-500"
                  />
                  <span className="text-sm text-zinc-600 dark:text-zinc-400">
                    {label}
                  </span>
                </label>
              ))}
            </div>
            <div className="border-b border-zinc-200 dark:border-zinc-700 pb-2">
              <Input
                placeholder="Input keyword..."
                value={pageInput}
                onChange={(value) => setPageInput(value)}
                className="border-none text-base w-full"
              />
            </div>
          </div>
        </DialogDescription>
        <DialogFooter className="px-4 py-4 bg-zinc-50 dark:bg-zinc-800 rounded-bl-md rounded-br-md">
          <Button
            variant="outlineBlur"
            onClick={() => setOpen(false)}
            disabled={submitLoading}
          >
            Cancel
          </Button>
          <Button
            isLoading={submitLoading}
            variant="primaryBlur"
            onClick={handleApplyFilter}
            disabled={submitLoading}
          >
            Apply Filter
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PageFilterDialog;
