import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

export type Coupon = {
  amount_off: number;
  created: number;
  currency: string;
  duration: string;
  durationInMonths: number | null;
  id: string;
  livemode: boolean;
  maxRedemptions: number | null;
  metadata: Record<string, unknown>;
  object: string;
  percent_off: number | null;
  redeemBy: number | null;
  timesRedeemed: number;
  valid: boolean;
};

export type CouponResponse = {
  status: "valid" | "invalid_coupon" | "invalid_plan" | "expired";
  validPlans?: string[];
  expirationDate?: string;
} & Partial<Coupon>;

const VALID_PLAN_NAMES = [
  "addon_yearly_357",
  "team_yearly_1173",
  "basic_yearly_459",
] as const;

type ValidPlanName = (typeof VALID_PLAN_NAMES)[number];

export const getCouponStatus = async (
  couponId: string,
  stripePlanName: string
): Promise<CouponResponse> => {
  if (couponId.toUpperCase() === "BLACKFRIDAY2024") {
    if (!VALID_PLAN_NAMES.includes(stripePlanName as ValidPlanName)) {
      return {
        status: "invalid_plan",
        validPlans: [...VALID_PLAN_NAMES],
      };
    }

    return {
      status: "valid",
      amount_off: null,
      currency: null,
      duration: "once",
      percent_off: 40.0,
      redeem_by: new Date("2024-12-02").getTime() / 1000,
      valid: true,
    };
  }

  const response = await axios.post<CouponResponse>("/stripe/getCouponStatus", {
    coupon_id: couponId,
    plan: stripePlanName,
  });

  return response;
};

export const useGetCouponStatus = (
  couponId: string,
  stripePlanName: string,
  config = {}
) => {
  return useQuery(
    ["couponStatus", couponId],
    () => getCouponStatus(couponId, stripePlanName),
    {
      ...config,
    }
  );
};
