import { Article, Cluster, ClusterEntity } from "../api/getUrlBatch";
import { FraseDocument } from "../types";
import { occurrences } from "./text";

/**
 * Score articles based on topics
 * @param topics - Array of topics to process.
 * @param articles - Array of articles to score.
 * @returns Array of scored articles sorted by topic score.
 */
export const scoreSERP = (
  topics: ClusterEntity[],
  articles: Article[],
  document: FraseDocument
): Article[] => {
  const blacklist = document.metadata?.blacklist_topics || {};

  const scoredArticles = (articles || []).map((article) => {
    const topicScoreMap: Record<
      string,
      {
        count: number;
        status?: string;
        bg_color?: string;
      }
    > = {};

    if (article.word_count && article.word_count > 0) {
      const text = prepareText(article.clean_text);
      const title = prepareText(article.title);
      let topicScore = 0;

      topics.forEach((value) => {
        if (!blacklist[value.entity.toLowerCase()]) {
          const ent = value.sing_lower
            .replace(/[-`~!@#$%^&*()_|+=?;:,.<>\{\}\[\]\\\/\\"'"]/gi, " ")
            .trim();
          const count = countOccurrences(value, `${text} ${title}`);
          const density = count / article.word_count;
          topicScoreMap[ent] = { count };

          let scoreValue = 0;
          let status = "";
          let bg_color = "";

          if (count > 1 && density > 0.02) {
            scoreValue = -1;
            status = "overuse";
            bg_color = "rose";
          } else if (count >= parseInt(value.frequency)) {
            scoreValue = 1;
            status = "completed";
            bg_color = "emerald";
          } else if (count > 0) {
            scoreValue = 0.5;
            status = "inProgress";
            bg_color = "amber";
          } else {
            status = "topicGap";
            bg_color = "zinc";
          }

          topicScore += scoreValue;
          setTopicDetails(topicScoreMap, ent, status, bg_color);
        }
      });

      return {
        ...article,
        topic_score: topicScore,
        topic_score_map: topicScoreMap,
      };
    } else {
      return {
        ...article,
        topic_score: 0,
        topic_score_map: {},
      };
    }
  });

  if (scoredArticles.length === 0) {
    return [];
  }

  scoredArticles.sort(sortByTopicScore);
  const topScore = scoredArticles[0].topic_score;

  const updatedScoredArticles = scoredArticles.map((value) => {
    const raw_topic_score = value.topic_score;
    const topic_score = (value.topic_score / topScore) * 100;

    return {
      ...value,
      raw_topic_score,
      topic_score,
    };
  });

  return updatedScoredArticles;
};

const prepareText = (text: string): string => {
  return text
    .replace(/[-`~!@#$%^&*()_|+=?;:,.<>\{\}\[\]\\\/\\"'"]/gi, " ")
    .trim();
};

const setTopicDetails = (
  map: Record<string, any>,
  key: string,
  status: string,
  bgColor: string
) => {
  map[key].status = status;
  map[key].bg_color = bgColor;
};

/**
 * Count occurrences of a topic in a text
 * @param topic - A topic object to count occurrences.
 * @param text - A string to find occurrences.
 * @returns The number of occurrences of a topic in the text.
 */
export const countOccurrences = (
  topic: ClusterEntity,
  text: string
): number => {
  const originalEnt = prepareText(topic.entity.toLowerCase());

  let count = 0;
  const singEnt = topic.sing_lower && prepareText(topic.sing_lower);
  const pluralEnt = topic.plural_lower && prepareText(topic.plural_lower);

  if (singEnt) {
    count += countOccurrencesOfString(text, singEnt);
  }

  if (pluralEnt && pluralEnt !== singEnt) {
    count += countOccurrencesOfString(text, pluralEnt);
  }

  if (originalEnt !== singEnt && originalEnt !== pluralEnt) {
    count += countOccurrencesOfString(text, originalEnt);
  }

  return count;
};

const countOccurrencesOfString = (
  text: string,
  searchString: string
): number => {
  const regex = new RegExp(`\\b${searchString}\\b`, "gi");
  const matches = text.match(regex);
  return matches ? matches.length : 0;
};

/**
 * Calculate topic score value, status, and background color based on count and density
 * @param topic - A topic object to calculate score for.
 * @param count - Number of occurrences for the topic.
 * @param density - Density of topic occurrences in the text.
 * @returns An object containing value, status, and background color of the topic score.
 */
const calculateTopicScore = (
  topic: ClusterEntity,
  count: number,
  density: number
): { value: number; status: string; bg_color: string } => {
  let value = 0;
  let status = "";
  let bg_color = "";

  if (count > 1 && density > 0.02) {
    value = -1;
    status = "overuse";
    bg_color = "rose";
  } else if (count >= topic.frequency) {
    value = 1;
    status = "completed";
    bg_color = "emerald";
  } else if (count > 0) {
    value = 0.5;
    status = "inProgress";
    bg_color = "amber";
  } else {
    status = "topicGap";
    bg_color = "zinc";
  }

  return { value, status, bg_color };
};

/**
 * Sort Article items by topic score
 * @param a - First Article object.
 * @param b - Second Article object.
 * @returns Sorting order.
 */
export const sortByTopicScore = (a: Article, b: Article): number => {
  return b.topic_score - a.topic_score;
};

/**
 * Score topics for the given items, topics, and clusters considering an entity type.
 * @param {Article[]} items - Array of Article objects.
 * @param {ClusterEntity[]} topics - Array of ClusterEntity objects.
 * @param {string} selectedType - Entity type with a default value of "Long Tail".
 * @param {Cluster[]} clusters - Array of Cluster objects.
 * @returns {object} - Object containing scored topics, clusters, and other relevant information.
 */
export const scoreTopics = (
  articles: Article[],
  topics: ClusterEntity[],
  clusters: Cluster[],
  document: FraseDocument,
  selectedType: string,
  headerNodes: string[],
  textNodes: string[],
  title: string
): {
  score: number;
  avg_score: number;
  top_score: number;
  clusters: any[];
  topics: any[];
} => {
  if (!Array.isArray(clusters)) {
    console.error("Invalid clusters: clusters must be an array");
    return {
      score: 0,
      avg_score: "0",
      top_score: "0",
      clusters: [],
      topics: [],
    };
  }

  if (["longTail", "clusters", "serpScores"].includes(selectedType)) {
    selectedType = "topTopics";
  }

  let text = textNodes.join(" ");
  text = text.replace(/\n/g, " "); //remove newlines
  text = text.concat(" " + title + " ");
  text = text
    .toLowerCase()
    .replace(/[-`~!@#$%^&*()_|+=?;:,.<>\{\}\[\]\\\/\\"'"]/gi, " ")
    .trim();
  // prepend the title to the header nodes
  headerNodes = [title, ...headerNodes];
  let headerText = headerNodes.join(" ");
  headerText = headerText
    .toLowerCase()
    .replace(/[-`~!@#$%^&*()_|+=?;:,.<>\{\}\[\]\\\/\\"'"]/gi, " ")
    .trim();

  const word_count = text ? text.split(" ").length : 0;

  const blacklist = document.metadata?.blacklist_topics || {};

  let user_score = 0;

  const updatedTopics = topics.map((topic) => {
    if (blacklist && blacklist[topic.entity.toLowerCase()]) {
      return { ...topic, blacklist: true };
    }
    if (topic.blacklist) {
      return topic; // Return the topic unchanged if it's blacklisted
    }

    let updatedTopic = getCountsForTopicInEditor(
      topic,
      headerText,
      text,
      title
    );
    updateUserScoreAndHighDensity(updatedTopic, word_count);
    updatedTopic = getTopicStatus(updatedTopic, selectedType);

    updatedTopic.mentioned = updatedTopic.user_count > 0;
    updatedTopic.over_used =
      updatedTopic.frequency > 1 &&
      updatedTopic.user_count > updatedTopic.frequency * 2;
    const density = updatedTopic.user_count / word_count;
    updatedTopic.high_density = updatedTopic.user_count > 1 && density > 0.02;

    updatedTopic.topic_user_score = 0; // Default score
    if (updatedTopic.user_count > 0) {
      if (
        updatedTopic.high_density ||
        updatedTopic.user_count >= parseInt(updatedTopic.frequency)
      ) {
        updatedTopic.topic_user_score = 1;
      } else {
        updatedTopic.topic_user_score = 0.5;
      }
    }

    if (updatedTopic.high_density) {
      user_score -= 1;
    } else {
      user_score += updatedTopic.topic_user_score;
    }

    return getTopicStatus(updatedTopic, selectedType);
  });

  let updatedClusters = clusters.map((cluster) => {
    // Update the cluster entities with user_count from updatedTopics
    let clusterUserCount = 0;
    let clusterFrequency = 0;
    const updatedEntities = cluster.cluster_entities.map((entity) => {
      const matchingTopic = updatedTopics.find(
        (topic) => topic.sing_lower === entity.sing_lower
      );
      if (matchingTopic) {
        clusterUserCount += matchingTopic.user_count;
        clusterFrequency += matchingTopic.frequency;
        return {
          ...entity,
          user_count: matchingTopic.user_count,
          frequency: matchingTopic.frequency,
          high_density: matchingTopic.high_density,
        };
      }
      return entity;
    });

    return {
      ...cluster,
      cluster_entities: updatedEntities,
      user_count: clusterUserCount,
      frequency: clusterFrequency,
    };
  });

  articles = articles.sort(sortByTopicScore);
  var top_score = articles[0].raw_topic_score || 0;
  var total_score = 0;
  articles.forEach((value) => {
    total_score += value.topic_score;
  });
  var avg_score = (total_score / articles.length).toFixed(1);
  user_score = (user_score / top_score) * 100 || 0;
  if (user_score < 0) {
    user_score = 0;
  }
  if (user_score > 100) {
    user_score = 100;
  }
  if (isNaN(user_score) == true) {
    user_score = 0;
  } else {
    user_score = user_score.toFixed(1);
  }
  if (isNaN(top_score) == true) {
    top_score = 0;
  } else {
    top_score = top_score.toFixed(1);
  }
  clusters = getClusterStatus(updatedClusters);
  var resp = {
    score: user_score,
    avg_score: avg_score,
    top_score: top_score,
    clusters: clusters,
    topics: updatedTopics,
  };
  return resp;
};

function updateUserScoreAndHighDensity(
  value: ClusterEntity,
  word_count: number
): void {
  value.mentioned = value.user_count > 0;
  value.over_used =
    value.frequency > 1 && value.user_count > value.frequency * 2;
  const density = value.user_count / word_count;
  value.high_density = value.user_count > 1 && density > 0.02;

  value.topic_user_score =
    value.user_count > 0
      ? value.high_density || value.user_count >= value.frequency
        ? 1
        : 0.5
      : 0;
}

function updateTotalUserScore(
  user_score: number,
  value: ClusterEntity
): number {
  return value.high_density
    ? user_score - 1
    : user_score + value.topic_user_score;
}

function calculateScoreStats(items: Article[]): {
  top_score: number;
  total_score: number;
  avg_score: string;
} {
  const top_score = items[0].raw_topic_score;
  const total_score = items.reduce((acc, val) => acc + val.raw_topic_score, 0);
  const avg_score = (total_score / items.length).toFixed(1);

  return { top_score, total_score, avg_score };
}

/**
 * Get counts for a topic in the editor content.
 * @param {ClusterEntity} value - The given ClusterEntity object.
 * @returns {ClusterEntity} - The updated ClusterEntity object with user counts.
 */
function getCountsForTopicInEditor(
  value: ClusterEntity,
  headerText: string,
  text: string,
  title: string
): ClusterEntity {
  const title_text = title;
  const header_text = headerText;
  const original_ent = value.entity
    .toLowerCase()
    .replace(/[-`~!@#$%^&*()_|+=?;:,.<>\{\}\[\]\\\/\\"'"]/gi, " ")
    .trim();
  let sing_ent: string;
  let plural_ent: string;

  // Initialize counts
  let user_title_count = 0;
  let user_header_count = 0;
  let user_count = 0;

  // Setup singular entity
  if (value.sing_lower) {
    sing_ent = value.sing_lower
      .replace(/[-`~!@#$%^&*()_|+=?;:,.<>\{\}\[\]\\\/\\"'"]/gi, " ")
      .trim();

    user_title_count += occurrences(title_text, sing_ent);
    user_header_count += occurrences(header_text, sing_ent);
    user_count += occurrences(text, sing_ent);
  }

  // Setup plural entity
  if (value.plural_lower && value.plural_lower != value.sing_lower) {
    plural_ent = value.plural_lower
      .replace(/[-`~!@#$%^&*()_|+=?;:,.<>\{\}\[\]\\\/\\"'"]/gi, " ")
      .trim();

    user_title_count += occurrences(title_text, plural_ent);
    user_header_count += occurrences(header_text, plural_ent);
    user_count += occurrences(text, plural_ent);
  }

  // Setup original entity (handle model mistakes in singularizing/pluralizing)
  if (original_ent != sing_ent && original_ent != plural_ent) {
    user_title_count += occurrences(title_text, original_ent);
    user_header_count += occurrences(header_text, original_ent);
    user_count += occurrences(text, original_ent);
  }

  // Return the new object extending the existing one with updated properties
  return { ...value, user_title_count, user_header_count, user_count };
}

/**
 * Get the status of a topic based on the entity type.
 * @param {ClusterEntity} value - The given ClusterEntity object.
 * @param {string} ent_type - Entity type with a default value of "topTopics".
 * @returns {ClusterEntity} - The updated ClusterEntity object with status, bg_color properties.
 */
function getTopicStatus(value: ClusterEntity, ent_type: string): ClusterEntity {
  if (
    (ent_type == "topTopics" && (!value.user_count || value.user_count == 0)) ||
    (ent_type == "titles" &&
      (!value.user_title_count || value.user_title_count == 0)) ||
    (ent_type == "headers" &&
      (!value.user_header_count || value.user_header_count == 0))
  ) {
    value.bg_color = "zinc";
    value.status = "topicGap";
  } else if (
    (ent_type == "topTopics" && value.high_density) ||
    (ent_type == "titles" &&
      value.user_title_count > 3 &&
      value.user_title_count > value.title_frequency * 2) ||
    (ent_type == "headers" &&
      value.user_header_count > 3 &&
      value.user_header_count > value.header_frequency * 2)
  ) {
    value.bg_color = "rose";
    value.status = "overuse";
  } else if (
    (ent_type == "topTopics" && value.user_count < value.frequency) ||
    (ent_type == "titles" && value.user_title_count < value.title_frequency) ||
    (ent_type == "headers" && value.user_header_count < value.header_frequency)
  ) {
    value.bg_color = "amber";
    value.status = "inProgress";
  } else if (
    (ent_type == "topTopics" && value.user_count >= value.frequency) ||
    (ent_type == "titles" && value.user_title_count >= value.title_frequency) ||
    (ent_type == "headers" && value.user_header_count >= value.header_frequency)
  ) {
    value.bg_color = "emerald";
    value.status = "completed";
  }
  return value;
}

/**
 * Get the status for each cluster in the given array of clusters.
 * @param {Cluster[]} clusters - An array of Cluster objects.
 * @returns {Cluster[]} - The updated array of Cluster objects with status, and bg_color properties.
 */
function getClusterStatus(clusters: Cluster[]): Cluster[] {
  const updatedClusters = clusters.map((cluster) => {
    const ents: ClusterEntity[] = cluster.cluster_entities;
    let cluster_total_frequency = 0;
    let cluster_user_mentions = 0;
    let cluster_over_used = false;

    ents.forEach((value) => {
      const { frequency, user_count, high_density } = value;

      if (frequency !== null && user_count !== null) {
        cluster_total_frequency += frequency;
        cluster_user_mentions += user_count ?? 0;
        cluster_over_used ||= high_density;
      }
    });

    let status = "";
    let color = "";
    let bg_color = "";

    if (cluster.user_count === 0) {
      status = "topicGap";
      color = "zinc";
      bg_color = "zinc";
    } else if (cluster_over_used) {
      status = "overuse";
      color = "rose";
      bg_color = "rose";
    } else if (cluster.user_count < cluster.frequency) {
      status = "inProgress";
      color = "amber";
      bg_color = "amber";
    } else if (cluster.user_count >= cluster.frequency) {
      status = "completed";
      color = "emerald";
      bg_color = "emerald";
    }

    return {
      ...cluster,
      frequency: cluster_total_frequency,
      user_count: cluster_user_mentions,
      status,
      color,
      bg_color,
    };
  });

  return updatedClusters;
}
interface DocumentMetadata {
  blacklist_topics: Record<string, boolean>;
}

interface DocumentFactory {
  activeDocument: {
    metadata: DocumentMetadata;
  };
}

interface Entity {
  entity?: string;
  long_tail?: boolean;
  title_count?: number;
  header_count?: number;
  status?: string;
}

interface Cluster {
  status?: string;
  cluster_entities: Entity[];
}

/**
 * Retrieves the blacklist from the metadata of the active document.
 * @param {FraseDocument} activeDocument - The active document.
 * @returns {Record<string, boolean>} - The blacklist.
 */
const getBlacklistFromMetadata = (
  activeDocument: FraseDocument
): Record<string, boolean> => activeDocument?.metadata?.blacklist_topics || {};

/**
 * Filters a topic by its status.
 * @param {Entity} value - The topic entity.
 * @param {string} ent_status - The desired status to filter the topic.
 * @returns {boolean} - True if the topic's status matches the desired status; false otherwise.
 */
const filterTopicsByStatus = (value: Entity, ent_status: string): boolean => {
  return ent_status === value.status || ent_status === "all";
};

/**
 * Filters an entity by its type.
 * @param {Entity} value - The entity.
 * @param {string} ent_type - The desired entity type.
 * @param {Record<string, boolean>} blacklist - The blacklist to use for filtering.
 * @returns {boolean} - True if the entity matches the desired type; false otherwise.
 */
const filterEntitiesByType = (
  value: Entity,
  ent_type: string,
  blacklist: Record<string, boolean>
): boolean => {
  const entity = value.entity?.toLowerCase();

  switch (ent_type) {
    case "longTail":
      return value.long_tail;
    case "titles":
      return value.title_count !== 0;
    case "headers":
      return value.header_count !== 0;
    case "blacklist":
      return entity && !!blacklist[entity];
    default:
      return !entity || !blacklist[entity];
  }
};

/**
 * Filters topics by entity type and status.
 * @param {Entity[]} entities - An array of topic entities.
 * @param {string} ent_type - The desired entity type.
 * @param {string} ent_status - The desired status to filter the topic.
 * @returns {Entity[]} - A filtered array of topic entities.
 */
export const filterTopics = function (
  entities: Entity[],
  ent_type: string,
  ent_status: string,
  document: FraseDocument
): Entity[] {
  const blacklist = getBlacklistFromMetadata(document);

  return entities.filter(
    (value) =>
      filterEntitiesByType(value, ent_type, blacklist) &&
      filterTopicsByStatus(value, ent_status)
  );
};

/**
 * Sets the blacklist property for an entity.
 * @param {Entity} entity - The entity.
 * @param {Record<string, boolean>} blacklist - The blacklist to use for setting the property.
 * @returns {Entity} - The updated entity with the blacklist property.
 */
const setBlacklistForEntity = (
  entity: Entity,
  blacklist: Record<string, boolean>
): Entity => ({
  ...entity,
  blacklist: blacklist[entity.entity?.toLowerCase()] || false,
});

/**
 * Filters clusters by entity status.
 * @param {Cluster[]} clusters - An array of clusters.
 * @param {string} ent_status - The desired status to filter the clusters.
 * @returns {Cluster[]} - A filtered array of clusters.
 */
export const filterClusters = function (
  clusters: Cluster[],
  ent_status: string,
  document: FraseDocument
): Cluster[] {
  const blacklist = getBlacklistFromMetadata(document);

  return clusters
    .filter((cluster) => filterTopicsByStatus(cluster, ent_status))
    .map((cluster) => ({
      ...cluster,
      cluster_entities: cluster.cluster_entities.map((entity) =>
        setBlacklistForEntity(entity, blacklist)
      ),
    }));
};

export const getSerpMentions = (topic, articles) => {
  const updatedArticles = articles.map((article) => {
    if (article.isValid && article.entities) {
      const target_topic = article.entities.find(
        (value) => topic.sing_lower === value.sing_lower
      );
      if (target_topic) {
        let mentions = getSentencesForEntity(target_topic, article);
        // Filter out duplicate mentions based on 'cleanText'
        const uniqueMentions = new Set();
        mentions = mentions.filter((mention) => {
          const isUnique = !uniqueMentions.has(mention.cleanText);
          uniqueMentions.add(mention.cleanText);
          return isUnique;
        });

        if (mentions.length > 0) {
          return { ...article, mentions };
        }
      }
    }
    return article;
  });
  return updatedArticles.filter(
    (article) => article.mentions && article.mentions.length > 0
  );
};

function getSentencesForEntity(entity_obj, source) {
  const final_sentences = [];
  const { entity, sing_lower } = entity_obj;
  const allSentences = tokenizeSentences(source.clean_text);
  allSentences.forEach((sentence) => {
    let added = false;
    [entity, sing_lower].forEach((target) => {
      if (!added) {
        const match_count = occurrences(sentence, target);
        if (match_count > 0) {
          const sentence_edited = classParagraph(sentence, target);
          final_sentences.push({
            editedText: sentence_edited,
            cleanText: sentence,
            source,
          });
          added = true;
        }
      }
    });
  });
  return final_sentences;
}

function tokenizeSentences(str) {
  str = str.replaceAll("&nbsp;", " ");
  const re = /\b(\w\.\ \w\.|\w\.\w\.)|([.?!])\s+(?=[A-Za-z])/g;
  const result = str.replace(re, (m, g1, g2) => (g1 ? g1 : g2 + "\r"));
  return result.split("\r");
}

function classParagraph(paragraph, sentence) {
  if (!paragraph || !sentence) return "";
  paragraph = paragraph.trim();
  sentence = sentence.trim();
  if (paragraph === sentence) {
    return `<span class='ent'>${paragraph}</span>`;
  }
  try {
    const searchRegExp = new RegExp(`(\\b${sentence}\\b)`, "gi");
    return paragraph.replace(searchRegExp, "<span class='ent'>$1</span>");
  } catch (err) {
    return paragraph;
  }
}

export const getTopicHeatmapForSERP = function (
  articles: Article[],
  topics: any[],
  userTopics: any[]
) {
  let series = [];
  let blacklist = {};
  const sortedArticles = [...articles].sort((a, b) => a.index - b.index);

  //if (documentFactory.activeDocument?.metadata?.blacklist_topics) {
  //  blacklist = documentFactory.activeDocument.metadata.blacklist_topics;
  //}
  topics &&
    topics.forEach((value) => {
      let ent = value.sing_lower
        .replace(/[-`~!@#$%^&*()_|+=?;:,.<>\{\}\[\]\\\/\\"'"]/gi, " ")
        .trim();
      var arr = [];
      const userTopic = userTopics.find(
        (userTopic) =>
          userTopic.sing_lower === ent || userTopic.entity.toLowerCase() === ent
      );
      arr.push({
        count: (userTopic && userTopic.user_count) || 0,
        bg_color: (userTopic && userTopic.bg_color) || "zinc",
        status: (userTopic && userTopic.status) || "topic gap",
      });
      sortedArticles &&
        sortedArticles.forEach((item, index) => {
          if (item.topic_score_map && item.topic_score_map[ent]) {
            arr.push(item.topic_score_map[ent]);
          } else {
            arr.push({ count: 0, status: "topic gap", bg_color: "zinc" });
          }
        });
      var obj = {
        entity: ent,
        long_tail: value.long_tail,
        heatmap_list: arr,
      };
      series.push(obj);
    });

  return {
    series,
    articles: sortedArticles,
    topics: topics,
  };
};

/**
 * Improved getTopicsForSERP function
 * @param {Array} clusters - Array of cluster objects
 * @returns {Array} - Sorted list of topics
 */
export const getTopicsForSERP = (clusters) => {
  var list = [];
  clusters.forEach((c, index) => {
    c.cluster_entities.forEach((value, index) => {
      const updatedValue = {
        ...value,
        frequency: parseInt(value.frequency),
      };

      if (
        updatedValue.header_frequency > 0 &&
        updatedValue.header_frequency < 1
      ) {
        updatedValue.header_frequency = 1;
      } else {
        updatedValue.header_frequency = parseInt(updatedValue.header_frequency);
      }
      if (updatedValue.title_count > 0) {
        updatedValue.title_frequency = 1;
      }
      if (updatedValue.entity.split(" ").length > 1) {
        updatedValue.long_tail = true;
      }
      updatedValue.net_score = getTopicScore(updatedValue, c.count);
      list.push(updatedValue);
    });
  });
  list = list.sort(sortByItemCount);
  return list;
};

function getTopicScore(value) {
  var word_count = value.entity.split(" ").length;
  if (word_count > 3) {
    word_count = 3;
  }
  var score =
    (word_count + value.title_count + value.header_count + value.frequency) *
    value.item_count;
  return score;
}

function sortByItemCount(a, b) {
  if (a.item_count < b.item_count) return 1;
  if (a.item_count > b.item_count) return -1;
  return 0;
}
