import {
  Button,
  Tag,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { useHandlePasteContent } from "@/features/documents/utils/pasting";
import { getHostname } from "@/features/documents/utils/url";
import { Copy, User } from "lucide-react";

const TopicHeatmapTable = ({ rows, columns }) => {
  const handlePasteContent = useHandlePasteContent();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="inline-block h-full w-full pt-20">
      <table className="divide-y divide-zinc-200/40 border-b border-zinc-200/400">
        <thead className="border-b">
          <tr>
            <th scope="col" className="relative h-16 whitespace-nowrap w-8">
              <div className="-rotate-45 text-2xs font-medium text-zinc-500 translate-x-[116px] translate-y-[14px] w-8">
                <span className="w-8 ml-1.5">Google Rank</span>
                <span className="absolute bottom-0 left-0 w-20 flex-1 border-b"></span>
              </div>
            </th>
            <th scope="col" className="relative h-16 whitespace-nowrap w-8">
              <div className="-rotate-45 text-2xs font-medium text-zinc-500 translate-x-[33px] translate-y-[14px] w-8">
                <span className="w-8 ml-1.5">My Content</span>
                <span className="absolute bottom-0 left-0 w-20 flex-1 border-b"></span>
              </div>
            </th>
            {columns.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="relative h-16 whitespace-nowrap w-8"
              >
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger
                      className="w-full h-full"
                      asChild={false}
                      onClick={() =>
                        openInNewTab(
                          columns[index - 1] && columns[index - 1].url
                        )
                      }
                    >
                      <div className="-rotate-45 text-2xs font-medium text-zinc-500 translate-x-[33px] translate-y-[14px] w-8">
                        <span className="relative flex items-center w-24">
                          <img
                            className="w-3 h-3 rounded-full bg-zinc-50 dark:bg-zinc-800"
                            src={`http://www.google.com/s2/favicons?domain=${item.url}`}
                            alt=""
                          />
                          <a className="ml-1.5 text-ellipsis whitespace-nowrap">
                            {getHostname(item.url)}
                          </a>
                        </span>
                        <span className="absolute bottom-0 left-0 w-20 flex-1 border-b"></span>
                      </div>
                    </TooltipTrigger>
                    {columns[index - 1] && (
                      <TooltipContent>
                        {columns[index - 1].url || ""}
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-zinc-200/40">
          <tr>
            <td className="relative group flex items-center justify-between text-2xs font-medium text-zinc-500 border-zinc-200/40 border-r whitespace-nowrap w-32 max-w-32 bg-transparent">
              <span className="inline-block w-32 group-hover:w-28 overflow-hidden text-ellipsis px-4 py-[7px] bg-transparent">
                Topics
              </span>
            </td>
            {columns.map((data, dataIndex) => (
              <td className="justify-between text-center items-center text-2xs font-medium text-zinc-500 border-zinc-200/40 border-r px-1 w-8 bg-transparent">
                {dataIndex === 0 ? (
                  <User className="w-4 h-4 text-zinc-500 self-center m-auto" />
                ) : (
                  <>#{dataIndex}</>
                )}
              </td>
            ))}
            <td className="justify-between text-center items-center text-2xs font-medium text-zinc-500 border-zinc-200/40 border-r px-1 w-8 bg-transparent">
              #{columns.length}
            </td>
          </tr>
          {rows.map((topic) => (
            <tr key={topic.entity}>
              <td className="relative group flex text-2xs font-medium text-zinc-500 border-zinc-200/40 border-r whitespace-nowrap w-32 max-w-32">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="inline-block text-start w-32 group-hover:w-28 overflow-hidden text-ellipsis px-4 py-[7px]">
                      <span>{topic.entity}</span>
                    </TooltipTrigger>
                    <TooltipContent>{topic.entity}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <Button
                  variant="buttonIcon"
                  size="xs"
                  className="absolute right-0 mt-0.5 mr-1 group-hover:visible invisible"
                  buttonIcon={<Copy />}
                  tooltipContent={`Paste to editor`}
                  onClick={(event) => {
                    handlePasteContent(topic.entity);
                    event.stopPropagation();
                  }}
                />
              </td>
              {topic.heatmap_list.map((data, dataIndex) => (
                <td
                  key={dataIndex}
                  className="border-zinc-200/40 border-r px-1 w-8"
                >
                  <Tag
                    color={data.bg_color || "zinc"}
                    className="w-9 h-full"
                    variant="highContrast"
                    textClassName="text-center w-full h-full"
                  >
                    {data.count}
                  </Tag>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopicHeatmapTable;
