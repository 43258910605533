import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "@/components/Elements";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { DialogPortal } from "@radix-ui/react-dialog";
import dayjs from "dayjs";
import { useState } from "react";
import { TbAlertTriangleFilled, TbArrowRight } from "react-icons/tb";
import { getDocumentByHash } from "../api/getDocumentByHash";
import { useUpdateDocument } from "../api/updateDocument";

export const EditorConflictOnSaveDialog = ({
  open,
  setOpen,
  lastEditorFullName,
  lastEditedTime,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  lastEditorFullName: string;
  lastEditedTime: number;
}) => {
  const { document: fraseDocument } = useDocumentStore();
  const { setEditor } = useEditorStore();
  const [editor] = useLexicalComposerContext();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });
  const [isResolvingConflict, setIsResolvingConflict] = useState(false);

  const handleRefreshDocument = async () => {
    setIsResolvingConflict(true);
    // get the latest document
    const refreshedFraseDocument = await getDocumentByHash({
      documentHash: fraseDocument.hash,
    });

    // check if the document has been edited by another user
    await updateDocumentMutation.mutateAsync(refreshedFraseDocument);
    setIsResolvingConflict(false);
    setEditor((editorState) => {
      editorState.isRefreshingDocument = true;
    });
  };

  const handleOverwriteDocument = async () => {
    setIsResolvingConflict(true);

    // get the latest document content
    editor.update(() => {
      const overwrittenHtml = $generateHtmlFromNodes(editor, null);
      const overwrittenDocument = {
        ...fraseDocument,
        text: fraseDocument.text.map((text, index) =>
          index === fraseDocument.metadata.active_tab_index
            ? { ...text, html: overwrittenHtml }
            : text
        ),
      };

      updateDocumentMutation.mutateAsync(overwrittenDocument);
    });

    setIsResolvingConflict(false);
  };

  const body = (
    <div className="flex text-zinc-600 dark:text-zinc-400 items-center">
      <TbAlertTriangleFilled className="mr-2" />
      This document was last edited by{" "}
      <p className="mx-1 font-medium">
        {lastEditorFullName ? lastEditorFullName : "another user"}{" "}
      </p>
      {dayjs(lastEditedTime).fromNow()}
    </div>
  );

  const handleOpenChange = (open) => {
    setOpen(open);
    if (open) {
      // Reset the state when the dialog is opened
    }
  };

  const confirmButton = (
    <div className="flex flex-col space-y-2">
      <Button
        variant="outlineBlur"
        onClick={() => {
          handleOverwriteDocument();
          setOpen(false);
        }}
        endIcon={<TbArrowRight />}
        isLoading={isResolvingConflict}
      >
        Overwrite with my changes
      </Button>
      <Button
        variant="primaryBlur"
        onClick={() => {
          handleRefreshDocument();
          setOpen(false);
        }}
        endIcon={<TbArrowRight />}
        isLoading={isResolvingConflict}
      >
        Get latest content and continue editing
      </Button>
    </div>
  );

  return (
    <Dialog onOpenChange={handleOpenChange} open={open} modal={true}>
      <DialogPortal>
        <DialogContent
          variant="fitContent"
          className="w-fit"
          preventCloseOnEscape={true}
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader className="pb-0 flex items-center justify-between"></DialogHeader>
          <DialogDescription className="px-4">{body}</DialogDescription>
          <DialogFooter className="px-4 pb-4">{confirmButton}</DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
