import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

import { FraseDocument } from "../types";

// Add the optional parameters with their default values
export const getDocuments = ({
  archived = null,
  assignment = false,
  doc_status = null,
  last_edited = null,
  org_id = null,
  query = null,
  size = null,
  team_id = null,
  user_id = null,
  page = null,
  name = null,
}): Promise<{ documents: FraseDocument[]; total_docs: number }> => {
  return axios.post("/getDocumentsByFilterCriteria", {
    archived,
    assignment,
    doc_status,
    last_edited,
    org_id,
    query,
    size,
    team_id,
    user_id,
    page,
    name,
  });
};

type QueryFnType = typeof getDocuments;

type UseDocumentOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useDocuments = ({
  archived = null,
  assignment = false,
  doc_status = null,
  last_edited = null,
  org_id = null,
  query = null,
  size = null,
  team_id = null,
  user_id = null,
  page = null,
  name = null,
  config,
}: UseDocumentOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "documents",
      JSON.stringify({
        archived,
        assignment,
        doc_status,
        last_edited,
        org_id,
        query,
        size,
        team_id,
        user_id,
        page,
        name,
      }),
    ],
    queryFn: () =>
      getDocuments({
        archived,
        assignment,
        doc_status,
        last_edited,
        org_id,
        query,
        size,
        team_id,
        user_id,
        page,
        name,
      }),
  });
};
