import { Template } from "@/features/templates/types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type TemplateStore = {
  template: Template;
  setTemplate: (template: Template) => void;
};

export const defaultTemplate: Template = {
  created_by: 0,
  created_dt: "",
  email: "",
  hash: "",
  id: 0,
  metadata: {},
  org_id: 0,
  template_author: "",
  text: [
    {
      html: "",
      name: "",
      title: "",
    },
  ],
  type: "",
  updated_dt: "",
};

export const useTemplateStore = create(
  devtools<TemplateStore>(
    (set) => ({
      template: defaultTemplate,
      setTemplate: (template) => set({ template }),
    }),
    { name: "templateStore" }
  )
);
