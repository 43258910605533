import { FolderIcon as Folder } from "@heroicons/react/24/solid";
import { PlusIcon } from "lucide-react";

export const FolderIcon = () => {
  return <Folder className="mr-2 h-3 w-3" />;
};

export const FolderTag = ({ children, showIcon }) => {
  return (
    <span className="inline-flex items-center rounded-full px-2 py-1 text-2xs text-zinc-600 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-white w-32">
      {showIcon && <FolderIcon />}
      <span className="truncate">{children}</span>
    </span>
  );
};

export const AddFolderTag = () => {
  return (
    <span className="inline-flex items-center rounded-full px-2 py-1 text-2xs text-zinc-400 dark:text-zinc-600 hover:text-zinc-900 dark:hover:text-white">
      <PlusIcon className="mr-2 h-3 w-3" />
      Folder
    </span>
  );
};
