import { Button } from "@/components/Elements";
import { useDocumentStore } from "@/stores/document";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";
import { BlacklistCard } from "./BlacklistCard";

const BlacklistEmptyState = ({ setBlacklistVisible, hideHeaderNavigation }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full py-20">
      <p className="text-zinc-500 text-sm px-8 text-center">
        Add topics to the blacklist to prevent them from being calculated in the
        topic score.
      </p>
      <Button
        variant="primaryBlur"
        size="xs"
        textClassName="text-2xs"
        className="mt-2 flex-shrink-0"
        onClick={() => {
          setBlacklistVisible(false);
          hideHeaderNavigation();
        }}
      >
        Add topics
      </Button>
    </div>
  );
};

export default function TopicBlacklist({ setBlacklistVisible }) {
  const { document } = useDocumentStore();
  const blacklistedTopics = document.metadata?.blacklist_topics || {};
  const { hideHeaderNavigation } = useHeaderNavigation();

  const renderBlacklistCards = () =>
    Object.keys(blacklistedTopics).map((item) => (
      <BlacklistCard
        item={item}
        topic={item}
        key={item} // Use item.entity instead of index as key
      />
    ));

  if (Object.keys(blacklistedTopics).length === 0) {
    return (
      <BlacklistEmptyState
        setBlacklistVisible={setBlacklistVisible}
        hideHeaderNavigation={hideHeaderNavigation}
      />
    );
  }

  return <div className="px-4 space-y-2 pb-20">{renderBlacklistCards()}</div>;
}
