// src/features/subscription/api/getCreditCards.ts
import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

export const getCreditCards = async () => {
  const data = await axios.post("/stripe/get_customer_cards");
  return data;
};

export const useGetCreditCards = (config = {}) => {
  return useQuery("creditCards", getCreditCards, {
    ...config,
  });
};
