import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../Table";
import { Skeleton } from "./Skeleton";

function DocumentTableSkeleton({ rows, columns }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {new Array(columns).fill().map((_, i) => (
            <TableHead key={`thead_${i}`}>
              <Skeleton className="h-4 my-1.5 w-16" />
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {new Array(rows).fill().map((_, i) => (
          <TableRow key={`row_${i}`}>
            {new Array(columns).fill().map((_, j) => (
              <TableCell key={`cell_${i}_${j}`}>
                <Skeleton className="h-4 my-1.5 w-full" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export { DocumentTableSkeleton };
