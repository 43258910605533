import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  PASTE_COMMAND,
} from "lexical";
import { useEffect } from "react";

import { $insertDataTransferForRichText } from "@lexical/clipboard";
import { $generateNodesFromDOM } from "@lexical/html";
import { marked } from "marked";

const PATTERNS = [
  /^\s*#{1,6}\s*/, // Heading
  /\*\*/, // Bold
  /__/, // Italics
  /~~/, // Strikethrough
  /`/, // Code
  /\[.*\]\(.*\)/, // Link
  /\[.*\]\[.*\]/, // Reference
  /!\[.*\]\(.*\)/, // Image
  /!\[.*\]\[.*\]/, // Image reference
  /\|.*\|/, // Table row
  /\|.*\|.*\|/, // table header
];

const containsMarkdown = (str) => {
  return PATTERNS.some((pattern) => pattern.test(str));
};

/**
 * Hook to register a command in the provided editor in the context
 * @param {Object} editor The editor in the composer context
 * @param {string} commandType The type of command to register
 * @param {Function} commandHandler The function to handle the command
 * @param {Number} priority The priority of the command
 */
function useRegisterCommandEffect(
  editor,
  commandType,
  commandHandler,
  priority
) {
  useEffect(() => {
    return editor.registerCommand(commandType, commandHandler, priority);
  }, [editor, commandType, commandHandler, priority]);
}

/**
 * Handle the pasting of the text into the editor
 * @param {Object} event The paste event
 * @return {boolean} The status of the operation
 */
export function usePasteText() {
  const [editor] = useLexicalComposerContext();

  return (event) => {
    event.preventDefault();
    const { clipboardData } = event;

    editor.update(() => {
      if (
        clipboardData.types.includes("text/html") &&
        !containsMarkdown(clipboardData.getData("text"))
      ) {
        const selection = $getSelection();
        const clipboardData =
          event instanceof InputEvent || event instanceof KeyboardEvent
            ? null
            : event.clipboardData;
        if (clipboardData != null && $isRangeSelection(selection)) {
          $insertDataTransferForRichText(clipboardData, selection, editor);
        }
      } else {
        const text = clipboardData.getData("text/plain");
        if (!text) return false;

        const selection = $getSelection();

        if (!selection) return;

        const htmlString = marked.parse(text);
        const doc = new DOMParser().parseFromString(htmlString, "text/html");

        const nodes = $generateNodesFromDOM(editor, doc);

        selection.insertNodes(nodes);
      }
    });

    return true;
  };
}

export default function MarkdownTransformerPlugin() {
  const [editor] = useLexicalComposerContext();

  // Use custom hook to register paste command
  useRegisterCommandEffect(
    editor,
    PASTE_COMMAND,
    usePasteText(),
    COMMAND_PRIORITY_LOW
  );
}
