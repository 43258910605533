import {
  Avatar,
  AvatarFallback,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { useAuth } from "@/lib/auth";
import { useEditorStore } from "@/stores/editor";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { TbX } from "react-icons/tb";
import {
  Comment,
  CommentStore,
  Thread,
  useCommentStore,
} from "../../commenting";
import CommentThread from "./CommentThread";

interface CommentsPanelProps {
  documentHash: string;
  onClose: () => void;
  commentStore: CommentStore;
}

interface CommentWithThread {
  isThread: boolean;
}

export function CommentsPanel({
  documentHash,
  onClose,
  commentStore,
}: CommentsPanelProps) {
  const { editor } = useEditorStore();
  const { user } = useAuth();
  const userFullName = user?.fullName || "";
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] =
    useState<CommentWithThread | null>(null);

  const { comments, getThread } = useCommentStore(commentStore);

  if (!editor.instance) {
    return null;
  }

  const handleAddComment = async (
    commentOrThread: Comment | Thread,
    isInlineComment: boolean,
    thread?: Thread
  ) => {
    await commentStore.addComment(commentOrThread, thread);
  };

  const handleEditComment = async (
    id: number,
    content: string,
    section_id: string
  ) => {
    await commentStore.editComment(id, content, section_id);
  };

  const handleDeleteComment = async (
    commentOrThread: Comment | Thread,
    thread?: Thread
  ) => {
    await commentStore.deleteCommentOrThread(commentOrThread, thread);
    setIsConfirmDialogOpen(false);
    setCommentToDelete(null);
  };

  const confirmDeleteComment = (comment: CommentWithThread) => {
    setCommentToDelete(comment);
    setIsConfirmDialogOpen(true);
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-start p-4 h-[93px] border-b">
        <div className="font-medium text-xs mt-[2px]">Comments</div>
        <Button
          onClick={onClose}
          className="CommentPlugin_CloseButton"
          variant="buttonIcon"
          buttonIcon={<TbX />}
          tooltipContent="Close comments"
        ></Button>
      </div>
      {comments.length === 0 ? (
        <div className="flex items-center justify-center h-full text-sm text-zinc-500 dark:text-zinc-400">
          No comments yet
        </div>
      ) : (
        <div className="space-y-2 p-2 h-full overflow-y-scroll pb-40">
          {comments.map((item) => (
            <div
              key={item.id}
              className="rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm ring-1 ring-zinc-900/5 dark:ring-zinc-50/5"
            >
              {item.type === "thread" && (
                <div className="flex flex-col w-full">
                  <div className="text-xs text-zinc-500 dark:text-zinc-400 px-3 py-2 border-b border-zinc-100 dark:border-zinc-800 bg-zinc-50/50 dark:bg-zinc-800/50 flex gap-2">
                    <div className="w-0.5 bg-zinc-300 dark:bg-zinc-600 rounded-full flex-shrink-0" />
                    <div>{item.quote}</div>
                  </div>
                  <CommentThread
                    className="w-full max-w-full mt-0 rounded-none rounded-b-md backdrop-blur-none shadow-none ring-0 dark:ring-0"
                    key={item.id}
                    comment={item}
                    confirmDeleteComment={confirmDeleteComment}
                    editor={editor.instance}
                    userFullName={userFullName}
                    thread={item}
                    documentHash={documentHash}
                    submitAddComment={handleAddComment}
                    submitEditComment={handleEditComment}
                    submitDeleteComment={handleDeleteComment}
                    cancelAddComment={() => {}}
                    anchorElem={document.body}
                    isReadOnly={true}
                  />
                </div>
              )}
              {item.type === "comment" && (
                <div className="p-3">
                  <div className="flex items-start gap-3">
                    <Avatar>
                      <AvatarFallback>{item.author[0]}</AvatarFallback>
                    </Avatar>
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <div className="font-medium">{item.author}</div>
                        <div className="text-xs text-zinc-500 dark:text-zinc-400">
                          {dayjs(item.timeStamp).fromNow()}
                        </div>
                      </div>
                      <div className="text-sm">{item.content}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Comment</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this comment? This action cannot
              be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsConfirmDialogOpen(false);
                setCommentToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() =>
                commentToDelete && handleDeleteComment(commentToDelete as any)
              }
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
