import { ArticleMetadata, Button, Input } from "@/components/Elements";
import { Skeleton } from "@/components/Elements/Skeleton";
import { getSerpLinks } from "@/features/documents/utils/links";
import { useHandlePasteInlineCitation } from "@/features/documents/utils/pasting";
import { getHostname } from "@/features/documents/utils/url";
import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { Copy } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";
import { VirtualizedList } from "../ResearchPlugin/Questions";

type ExternalLink = {
  domain: string;
  item_count: number;
  internal_links: Link[];
  external_links: Link[];
};

export default function ExternalLinks() {
  const [activeLink, setActiveLink] = useState<ExternalLink | null>(null);
  const [links, setLinks] = useState<ExternalLink[]>([]);
  const { document } = useDocumentStore();
  const { serp } = useSerpStore();
  const { domainMap } = serp[document.id] || { domainMap: {} };
  const handlePasteInlineCitation = useHandlePasteInlineCitation();
  const [filteredLinks, setFilteredLinks] = useState<ExternalLink[]>([]);
  const { setHeaderNavigation } = useHeaderNavigation();
  const parentRef = useRef();

  useEffect(() => {
    setFilteredLinks(links);
  }, [links]);

  const onFilterChange = (value: string) => {
    const filtered = value
      ? links.filter((link) => link.domain.includes(value))
      : links;
    setFilteredLinks(filtered);
  };

  useEffect(() => {
    const domainList = getSerpLinks(domainMap || {}) as ExternalLink[];
    setLinks(domainList);
  }, [domainMap]);

  const handleSetActiveLink = (link: ExternalLink) => {
    setActiveLink(link);
    setHeaderNavigation({
      title: link.domain,
      titleIcon: (
        <img
          className="ml-1 w-3 h-3 rounded-full bg-zinc-50 dark:bg-zinc-800 self-center"
          src={`http://www.google.com/s2/favicons?domain=${getHostname(
            link.domain
          )}`}
          alt=""
        />
      ),
      onBack: () => {
        setActiveLink(null), setFilteredLinks(links);
      },
    });
  };

  const isLoading = !links.length;

  const renderLoadingSkeleton = () =>
    Array.from({ length: 10 }).map((_, i) => (
      <div
        key={i}
        className="group mt-2 p-2 cursor-pointer relative items-center rounded-md bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 hover:bg-white"
      >
        <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
        <Skeleton className="w-full h-4" />
      </div>
    ));

  const handlePasteSplitContentAndCitation = (item) => {
    const parts = item.raw_text.split(item.anchor);
    if (parts.length === 2) {
      handlePasteInlineCitation(parts[0], item.anchor, item.url, parts[1]);
    }
  };

  const renderExternalLinkItem = (item) => (
    <div
      className="group mt-2 px-2 py-2 text-2xs rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 dark:bg-white/2.5 "
      key={item.url}
    >
      <div className="flex items-center justify-between">
        <ArticleMetadata
          url={item.url}
          urlHost={item.url}
          className="w-[80%] ml-1"
        />
        <Button
          variant="buttonIcon"
          className="invisible group-hover:visible"
          buttonIcon={<Copy />}
          tooltipContent={`Paste to editor`}
          onClick={(event) => {
            handlePasteSplitContentAndCitation(item);
            event.stopPropagation();
          }}
        />
      </div>
      <p className="text-xs text-zinc-600 dark:text-zinc-400 w-auto px-1">
        {item.raw_text.split(item.anchor).map((part, index, array) =>
          index < array.length - 1 ? (
            <span key={index}>
              {part}
              <a
                className="underline px-1"
                href={item.url}
                target="_blank"
                rel="noopener"
              >
                {item.anchor}
              </a>
            </span>
          ) : (
            part
          )
        )}
      </p>
    </div>
  );

  if (activeLink) {
    return (
      <div className="flex flex-col">
        <div className="flex items-center justify-between px-4 w-full pr-8">
          <p className="text-xs text-zinc-400 dark:text-zinc-500 self-center whitespace-nowrap">
            <span className="font-semibold text-zinc-800 text-base dark:text-zinc-200">
              {activeLink.external_links.length}
            </span>
            <span className="text-xs font text-zinc-500 ml-1">links</span>
          </p>
        </div>
        <div className="space-y-2 px-4">
          <div className="space-y-2 mt-2">
            {isLoading ? (
              renderLoadingSkeleton()
            ) : (
              <VirtualizedList
                items={activeLink.external_links}
                renderItem={renderExternalLinkItem}
                parentRef={parentRef}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between pl-4 pr-4">
        <Input
          onChange={onFilterChange}
          placeholder="Filter by keyword..."
          className="text-xs "
          containerClassName="h-[32px] my-1"
        />
      </div>
      <div className="space-y-2 pl-4 pr-1">
        {isLoading ? (
          renderLoadingSkeleton()
        ) : (
          <VirtualizedList
            items={filteredLinks.filter(
              (item) => item.external_links.length > 0
            )}
            renderItem={(item) => (
              <div
                className="group p-2 cursor-pointer relative items-center rounded-md transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5  first:mt-2"
                key={item.domain}
                onClick={() => handleSetActiveLink(item)}
              >
                <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      className="ml-0.5 w-3 h-3 rounded-full bg-zinc-50 dark:bg-zinc-800 self-center"
                      src={`http://www.google.com/s2/favicons?domain=${getHostname(
                        item.domain
                      )}`}
                      alt=""
                    />
                    <a
                      href={item.domain}
                      target="_blank"
                      rel="noopener"
                      className="font-normal text-xs dark:text-zinc-50 hover:underline hover:text-zinc-600 ml-2.5 self-center"
                    >
                      {item.domain}
                    </a>
                  </div>
                  <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-500 self-center">{`${item.external_links.length} links`}</p>
                </div>
              </div>
            )}
            parentRef={parentRef}
          />
        )}
      </div>
    </div>
  );
}
