import { DocumentEditorContainer } from "@/components/AdvancedEditor";
import { Spinner } from "@/components/Elements";
import { ContentLayout } from "@/components/Layout";
import { useParams } from "react-router-dom";
import { useDocumentPreview } from "../api/getDocumentPreview";

export const DocumentPreview = () => {
  const params = useParams<{ documentHash: string }>();
  const documentHash = params.documentHash;
  const documentQuery = useDocumentPreview({ documentHash: documentHash! });

  if (documentQuery.isError) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <p>Error loading document</p>
      </div>
    );
  }

  if (documentQuery.isLoading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  return (
    <ContentLayout>
      <DocumentEditorContainer
        documentMetadata={documentQuery.data}
        isReadOnly={true}
        isSubscriptionCancelled={false}
      />
    </ContentLayout>
  );
};
