import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

export const getDocumentPreviewHash = ({
  docHash,
}: {
  docHash: string;
}): Promise<string> => {
  return axios.post(`/generateDocumentPreviewHash`, {
    doc_hash: docHash,
  });
};

type DocumentPreviewHashQueryFnType = typeof getDocumentPreviewHash;

type UseDocumentPreviewHashOptions = {
  docHash: string;
  config?: QueryConfig<DocumentPreviewHashQueryFnType>;
};

export const useDocumentPreviewHash = ({
  docHash,
  config,
}: UseDocumentPreviewHashOptions) => {
  return useQuery<ExtractFnReturnType<DocumentPreviewHashQueryFnType>>({
    ...config,
    queryKey: ["getDocumentPreviewHash", docHash],
    queryFn: () => getDocumentPreviewHash({ docHash }),
  });
};
