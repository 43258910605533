import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

export const getAiArticleUsage = (): Promise<[]> => {
  return axios.post("/getAIArticleUsage");
};

export const useGetAiArticleUsage = () => {
  return useQuery<[]>("aiArticleUsage", getAiArticleUsage);
};
