import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

export const useDeletePlan = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(() => axios.post("/stripe/cancel_customer_subscription"), {
    onSuccess: () => {
      queryClient.invalidateQueries("upcomingInvoice");
      queryClient.invalidateQueries("subscription");

      addNotification({
        type: "success",
        title: "Subscription cancelled",
        message: "Your subscription has been successfully cancelled.",
      });
    },
  });
};
