import { create } from "zustand";

export const useHeaderNavigation = create((set) => ({
  isVisible: false,
  title: "",
  titleIcon: null,
  onBack: () => {},

  showHeaderNavigation: () => set((state) => ({ ...state, isVisible: true })),

  hideHeaderNavigation: () => set((state) => ({ ...state, isVisible: false })),

  setHeaderNavigation: ({
    title,
    titleIcon,
    onBack,
  }: {
    title: string;
    titleIcon?: React.ReactNode;
    onBack: () => void;
  }) => {
    const onBackHandler = () => {
      set({
        isVisible: false,
      });
      onBack();
    };
    set({
      titleIcon,
      title,
      onBack: onBackHandler,
      isVisible: true,
    });
  },
}));
