import {
  ArticleMetadata,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  PasteAssetButton,
  ViewAssetButton,
} from "@/components/Elements";
import { isValidUrl } from "@/utils/isValidUrl";
import { useState } from "react";

export function QuestionAsset({
  headerText,
  assetText,
  url,
  collapseDisabled = false,
  handlePasteContent,
  handlePasteCitation,
}) {
  const [assetsVisible, setAssetsVisible] = useState(false);

  const urlHost = isValidUrl(url)
    ? new URL(url).hostname.replace("www.", "")
    : url;

  return (
    <Collapsible
      className="group/asset relative px-2 py-2 text-2xs rounded-md ring-1 ring-inset ring-zinc-900/7.5 dark:ring-white/10 hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5 mb-2"
      onOpenChange={() => setAssetsVisible(!assetsVisible)}
      open={assetsVisible}
    >
      {url && (
        <div className="flex items-center justify-between pl-2">
          <ArticleMetadata url={url} urlHost={urlHost} />
        </div>
      )}
      <div className="flex group">
        <CollapsibleTrigger
          className="w-full text-start flex items-center text-zinc-900 justify-between"
          onClick={(event) => event.stopPropagation()}
          disabled={collapseDisabled}
        >
          <div className="flex items-center justify-start w-full text-start text-zinc-900">
            <span className="dark:text-zinc-50 ml-2 self-center">
              {headerText}
            </span>
          </div>
          {!collapseDisabled && (
            <ViewAssetButton assetsVisible={assetsVisible} />
          )}
        </CollapsibleTrigger>
        <PasteAssetButton
          handlePasteContent={handlePasteContent}
          headerText={`${headerText}\n\n${assetText.join("\n\n")}`}
          handlePasteCitation={handlePasteCitation}
          url={url}
        />
      </div>
      <CollapsibleContent>
        {assetText &&
          assetText.map((text, index) => (
            <p
              key={index}
              className="px-2 mt-2 mr-4 text-2xs text-zinc-900 dark:text-white"
            >
              {text}
            </p>
          ))}
      </CollapsibleContent>
    </Collapsible>
  );
}
