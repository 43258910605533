import { axios } from "@/lib/axios";

import { MutationConfig } from "@/lib/react-query";
import { useDocumentStore } from "@/stores/document";
import { useNotificationStore } from "@/stores/notifications";
import * as Sentry from "@sentry/react";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../../lib/auth";
import { useEditorConflictDialogStore } from "../../../stores/conflict";
import { FraseDocument } from "../types";
import { checkEditorConflict, setLastEditor } from "../utils/lastEditor";

export const updateDocument = async (
  fraseDocument: FraseDocument,
  userId: number,
  fullName: string,
  isResolvingConflict: boolean
): Promise<FraseDocument> => {
  // set last document editor
  const fraseDocumentWithEditor = await setLastEditor(
    fraseDocument,
    userId,
    fullName
  );

  if (!fraseDocumentWithEditor) {
    return Promise.reject(
      new Error("Failed to set the last editor for the document.")
    );
  }

  if (isResolvingConflict === false) {
    // check user is last editor
    await checkEditorConflict(fraseDocumentWithEditor).catch((error) => {
      return Promise.reject(error);
    });
  }

  const response = await axios.post("/saveDocument", fraseDocumentWithEditor);
  return response;
};

type UseUpdateDocumentOptions = {
  config?: MutationConfig<typeof updateDocument>;
  notifyOnSuccess?: boolean;
  isResolvingConflict?: boolean;
};

export const useUpdateDocument = ({
  config,
  notifyOnSuccess = true,
  isResolvingConflict = false,
}: UseUpdateDocumentOptions) => {
  const { setDocument } = useDocumentStore();
  const { user } = useAuth();
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fraseDocument) =>
      updateDocument(
        fraseDocument as FraseDocument,
        user?.id,
        user?.fullName,
        isResolvingConflict
      ),
    onMutate: async (updatingDocument: FraseDocument) => {
      await queryClient.cancelQueries(["document", updatingDocument.hash]);
      await queryClient.cancelQueries(["documents"], { exact: false });

      if (!updatingDocument?.text) {
        return;
      }

      return { previousDocument: updatingDocument };
    },
    onError: (error: { message: string; fullName: string; time: number }) => {
      if (error.message === "not last editor") {
        // Close any open dialogs
        useEditorConflictDialogStore.getState().closeDialog();
        useEditorConflictDialogStore
          .getState()
          .openDialog("editorConflictOnSave", error.fullName, error.time);
      } else {
        /*addNotification({
          type: "error",
          title: "Failed to update document",
          message: "Please contact support for help with this issue.",
        });*/
        Sentry.captureException(error);
      }
    },
    onSuccess: (updatedDocument) => {
      queryClient.setQueryData(
        ["document", updatedDocument.hash],
        updatedDocument
      );

      queryClient
        .getQueryCache()
        .findAll(["documents"])
        .forEach((query) => {
          const [documentsQuery] = queryClient.getQueriesData(query.queryKey);

          const [_, documentsData] = documentsQuery;

          if (!documentsData) return;

          const documents = documentsData.documents as FraseDocument[] | [];

          queryClient.setQueryData(query.queryKey, {
            documents:
              documents &&
              documents.map((document) => {
                if (document.hash === updatedDocument.hash) {
                  return updatedDocument;
                } else {
                  return document;
                }
              }),

            total_docs: documentsData.total_docs,
          });
        });

      setDocument(updatedDocument);

      if (!notifyOnSuccess) return;

      addNotification({
        type: "success",
        title: "Document updated successfully",
      });
    },
    ...config,
  });
};
