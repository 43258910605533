import { useEditorStore } from "@/stores/editor";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

export function EditorStorePlugin() {
  const [editor] = useLexicalComposerContext();
  const { setEditor } = useEditorStore();

  useEffect(() => {
    setEditor((editorState) => {
      editorState.instance = editor;
    });
    return () => {
      setEditor((editorState) => {
        editorState.instance = null;
      });
    };
  }, [editor]);

  return null;
}
