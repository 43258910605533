import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import storage from "../../../utils/storage";

export const useDeleteUserAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(() => axios.post("/deleteUserAccount", {}), {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
      storage.clearToken();
      window.location.assign(window.location.origin as unknown as string);
    },
  });
};
