import { shortenNumber } from "@/utils/number";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import clsx from "clsx";
import React from "react";
import { Tag } from "..";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Tooltip";

interface ArticleMetadataProps {
  url: string;
  urlHost: string;
  sourceLink?: string;
  rank: number;
  wordCount: number;
  links: number;
  domainAuthority: number;
  isCustomImport?: boolean;
  className?: string;
  isProcessed?: boolean;
  isGoogleNews?: boolean;
}

const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const ArticleMetadata: React.FC<ArticleMetadataProps> = React.memo(
  ({
    url,
    urlHost,
    sourceLink,
    rank,
    wordCount,
    links,
    domainAuthority,
    isCustomImport,
    className,
    isProcessed = true,
    isGoogleNews = false,
  }) => {
    return (
      <div
        className={clsx(
          "group flex flex-shrink items-center whitespace-nowrap max-w-[90%]",
          className
        )}
      >
        {urlHost && (
          <>
            <img
              className="w-3 h-3 rounded-full bg-zinc-50 dark:bg-zinc-800"
              src={`http://www.google.com/s2/favicons?domain=${urlHost}`}
              alt=""
              onError={(e) => {
                e.currentTarget.src = "https://www.google.com/favicon.ico";
              }}
            />
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <a
                    href={sourceLink || url}
                    target="_blank"
                    rel="noopener"
                    className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400 hover:underline hover:text-zinc-600 flex-shrink truncate"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {urlHost}
                  </a>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>
                    {truncateText(sourceLink || url, 80)}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
          </>
        )}
        {!isCustomImport && rank ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400 cursor-pointer">
                  {`#${rank} search rank`}
                </p>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>
                  This is the actual ranking position of the page in search
                  results.
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </TooltipProvider>
        ) : null}
        {isCustomImport && (
          <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">
            Imported URL
          </p>
        )}
        {wordCount ? (
          <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">{`${shortenNumber(
            wordCount
          )} words`}</p>
        ) : null}
        {links ? (
          <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">{`${shortenNumber(
            links
          )} links`}</p>
        ) : null}
        {domainAuthority ? (
          <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">{`${domainAuthority} DA`}</p>
        ) : null}
        {!isProcessed && (
          <Tag
            className="ml-2 h-6 opacity-70"
            variant="small"
            color="zinc"
            textClassName="text-[11px] font-medium"
          >
            Not Processed
          </Tag>
        )}
        {isGoogleNews && (
          <Tag
            className="ml-2 h-6 opacity-70"
            variant="small"
            color="sky"
            textClassName="text-[11px] font-medium"
          >
            Google News
          </Tag>
        )}
      </div>
    );
  }
);
