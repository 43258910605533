// AvatarStack component
import { useUser } from "@/features/auth";
import { useInviteUsersToFolder } from "@/features/documents/api/inviteUsersToFolder";
import { useRemoveUserFromFolder } from "@/features/documents/api/removeUserFromFolder";
import { cn, usernameToColor } from "@/utils/style";
import { ChevronRightIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Button } from "../Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../DropdownMenu";
import { ScrollArea } from "../ScrollArea";
import { Avatar, AvatarFallback, AvatarImage } from "./Avatar";

export const AvatarStack = ({
  users,
  folderInfo,
  type,
  TriggerComponent = DropdownMenuTrigger,
  ContentComponent = DropdownMenuContent,
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = React.useState(users);
  const [addedUsers, setAddedUsers] = React.useState([]);
  const [removedUsers, setRemovedUsers] = React.useState([]);
  const inviteUsers = useInviteUsersToFolder();
  const removeUsers = useRemoveUserFromFolder();
  const { data: currentUser } = useUser();

  const allFolderUsers = folderInfo.users.map((user) => user.username);
  const [selectedUsers, setSelectedUsers] = React.useState(allFolderUsers);
  const [newSelectedUsers, setNewSelectedUsers] = React.useState([]);

  const handleCheckedChange = (checked, user) => {
    const isOriginalMember = allFolderUsers.includes(user.username);

    // Ignore if it's the current user
    if (user.username === currentUser?.username) return;

    // New user is checked; add to addedUsers & newSelectedUsers
    if (checked && !isOriginalMember) {
      setAddedUsers((prev) => [...prev, user.username]);
      setNewSelectedUsers((prev) => [...prev, user.username]);
    }

    // New user is unchecked; remove from addedUsers & newSelectedUsers
    if (!checked && !isOriginalMember) {
      setAddedUsers((prev) =>
        prev.filter((username) => username !== user.username)
      );
      setNewSelectedUsers((prev) =>
        prev.filter((username) => username !== user.username)
      );
    }

    // Original member is checked; remove from removedUsers & add to selectedUsers
    if (checked && isOriginalMember) {
      setRemovedUsers((prev) =>
        prev.filter((username) => username !== user.username)
      );
      setSelectedUsers((prev) => [...prev, user.username]);
    }

    // Original member is unchecked; add to removedUsers & remove from selectedUsers
    if (!checked && isOriginalMember) {
      setRemovedUsers((prev) => [...prev, user.username]);
      setSelectedUsers((prev) =>
        prev.filter((username) => username !== user.username)
      );
    }
  };

  const handleSaveChanges = async () => {
    if (addedUsers.length > 0) {
      await inviteUsers.mutate({
        channelId: folderInfo.id.toString(),
        userEmails: addedUsers.map((username) => ({
          role: 1,
          email: username,
        })),
      });
    }

    if (removedUsers.length > 0) {
      await Promise.all(
        removedUsers.map((username) =>
          removeUsers.mutateAsync({
            teamId: folderInfo.id.toString(),
            username: username,
          })
        )
      );
    }

    // Now all mutations are successful, update the selected users accordingly
    const updatedFolderMembers = [...selectedUsers, ...addedUsers].filter(
      (username) => !removedUsers.includes(username)
    );
    setSelectedUsers(updatedFolderMembers);
    setNewSelectedUsers(
      newSelectedUsers.filter((username) => !removedUsers.includes(username))
    ); // remove any user that was removed afterwards

    setAddedUsers([]);
    setRemovedUsers([]);
    setDropdownOpen(false);
  };

  React.useEffect(() => {
    if (!dropdownOpen) {
      setSelectedUsers(allFolderUsers);
      setNewSelectedUsers([]);
      setAddedUsers([]);
      setRemovedUsers([]);
    }
  }, [dropdownOpen]);

  const AvatarList = ({ folderInfo }) => (
    <>
      {folderInfo.users.slice(0, 3).map((user) => (
        <Avatar key={user.id} className="w-6 h-6">
          <AvatarImage src={user.avatarUrl} alt={user.fullName} />
          <AvatarFallback
            className={cn(
              "text-zinc-50 text-4xs",
              usernameToColor(user.fullName)
            )}
          >
            {user.fullName
              .split(" ")
              .map((name) => name[0])
              .slice(0, 2)
              .join("")}
          </AvatarFallback>
        </Avatar>
      ))}
      {folderInfo.users.length > 3 && (
        <div className="w-6 h-6 bg-zinc-200 dark:bg-zinc-700 dark:text-white flex items-center justify-center text-4xs font-semibold rounded-full">
          +{folderInfo.users.length - 3}
        </div>
      )}
    </>
  );

  return (
    <DropdownMenu
      open={dropdownOpen}
      onOpenChange={(open) => setDropdownOpen(open)}
      modal
    >
      <TriggerComponent>
        {type === "folderDropdown" ? (
          <>
            <UserCircleIcon className="mr-2 h-3 w-3" />
            Manage users
            <ChevronRightIcon className="ml-6 h-3 w-3" />
          </>
        ) : (
          <Button
            variant="textNode"
            textClassName="flex -space-x-2 overflow-hidden"
            className="h-7 mr-2"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <AvatarList folderInfo={folderInfo} />
          </Button>
        )}
      </TriggerComponent>
      <ContentComponent className="w-56">
        <DropdownMenuGroup>
          <DropdownMenuLabel className="flex items-center justify-between">
            {type === "folderDropdown" ? (
              <span className="flex -space-x-2 overflow-hidden">
                <AvatarList folderInfo={folderInfo} />
              </span>
            ) : (
              <span> Manage Users</span>
            )}
            {selectedUsers.length !== folderInfo.users.length ? (
              <Button
                variant="textNode"
                size="2xs"
                textClassName="text-emerald-600"
                onClick={() => {
                  setAddedUsers([
                    ...filteredUsers
                      .map((user) => user.username)
                      .filter((username) => !allFolderUsers.includes(username)),
                  ]);
                  setRemovedUsers([]);
                  setSelectedUsers([...allFolderUsers]);
                  setNewSelectedUsers([
                    ...filteredUsers
                      .map((user) => user.username)
                      .filter((username) => !allFolderUsers.includes(username)),
                  ]);
                }}
              >
                Select All
              </Button>
            ) : (
              <Button
                variant="textNode"
                size="2xs"
                textClassName="text-emerald-600"
                onClick={() => {
                  setAddedUsers([]);
                  setRemovedUsers([
                    ...allFolderUsers.filter(
                      (username) => username !== currentUser?.username
                    ),
                  ]);
                  setSelectedUsers([]);
                  setNewSelectedUsers([]);
                }}
              >
                Deselect All
              </Button>
            )}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <ScrollArea className="max-h-56 overflow-y-auto">
            {filteredUsers.map((user) => (
              <DropdownMenuCheckboxItem
                key={user.id}
                onCheckedChange={(checked) => {
                  handleCheckedChange(checked, user);
                }}
                checked={
                  selectedUsers.includes(user.username) ||
                  newSelectedUsers.includes(user.username)
                }
                onSelect={(event) => event.preventDefault()}
                disabled={user.username === currentUser?.username}
              >
                {user.fullName}
              </DropdownMenuCheckboxItem>
            ))}
          </ScrollArea>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup className="flex gap-1 justify-between">
          <Button
            variant="text"
            size="2xs"
            onClick={() => {
              setDropdownOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primaryBlur"
            size="2xs"
            onClick={() => handleSaveChanges()}
            disabled={addedUsers.length === 0 && removedUsers.length === 0}
          >
            Save changes
          </Button>
        </DropdownMenuGroup>
      </ContentComponent>
    </DropdownMenu>
  );
};
