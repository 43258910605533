import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { addCommasToNumber } from "@/features/seo-analytics/utils";
import { cn } from "@/utils/style";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { useSeoAnalyticsStore } from "../../../../store";

const kpis = [
  {
    key: "clicks",
    label: "Clicks",
    tooltip: "Organic visits from Google",
    comma: true,
  },
  {
    key: "impressions",
    label: "Impressions",
    tooltip: "Number of times your pages appeared in search results",
    comma: true,
  },
  {
    key: "ctr",
    label: "Avg. CTR",
    append: "%",
    tooltip: "Click-through from search results",
  },
  {
    key: "position",
    label: "Avg. Position",
    tooltip: "Average position of your pages in search results",
    comma: true,
  },
  {
    key: "clicks_growth",
    label: "Clicks Growth",
    append: "%",
    tooltip: "Growth rate of organic traffic",
  },
  {
    key: "ranked_pages",
    label: "Ranked Pages",
    tooltip: "Number of pages driving organic traffic",
    comma: true,
  },
  {
    key: "rank_rate",
    label: "Rank Rate",
    append: "%",
    tooltip: "Percentage of ranked pages out of total pages",
    comma: true,
  },
];

export const kpiLabels = new Map(
  kpis.map((kpi) => [kpi.key.replace("_", " "), kpi.label])
);

const KpiRow = () => {
  const { globalKpis, setActiveKpi, activeKpi } = useSeoAnalyticsStore();

  return (
    <TooltipProvider>
      <div className="grid grid-cols-7 px-12 gap-4 max-w-[1200px] mx-auto my-4 overflow-y-hidden h-[107px] ">
        {kpis.map(({ key, label, append, comma, tooltip }) => {
          const isActive = key === activeKpi;
          return (
            <Tooltip key={key}>
              <TooltipTrigger>
                <div
                  className={cn(
                    "flex h-fit w-fit mt-2 px-2 py-4 text-2xs dark:text-white bg-white dark:bg-zinc-900 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 hover:bg-zinc-50 dark:hover:bg-zinc-800 hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5",
                    "relative flex flex-col items-center w-full cursor-pointer transition-all duration-200",
                    {
                      "bg-emerald-100 dark:bg-emerald-900 ring-2 ring-emerald-500":
                        isActive,
                      "hover:bg-zinc-100 dark:hover:bg-zinc-800": !isActive,
                    }
                  )}
                  onClick={() => setActiveKpi(key)}
                >
                  <div className="flex flex-col items-center justify-center text-center h-full">
                    <h3
                      className={`text-sm font-bold ${
                        isActive
                          ? "text-emerald-700 dark:text-emerald-300"
                          : "text-zinc-900 dark:text-zinc-50"
                      }`}
                    >
                      {label}
                    </h3>
                    <p
                      className={`mt-1 text-2xs ${
                        isActive
                          ? "text-emerald-600 dark:text-emerald-400"
                          : "text-zinc-600 dark:text-zinc-400"
                      }`}
                    >
                      {comma
                        ? addCommasToNumber(globalKpis?.[key][0] || 0)
                        : globalKpis?.[key][0] || "-"}
                      {append ? append : ""}
                    </p>
                  </div>
                </div>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>{tooltip}</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          );
        })}
      </div>
    </TooltipProvider>
  );
};

export default KpiRow;
