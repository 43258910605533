import {
  Button,
  CountryField,
  DialogModal,
  Form,
  Input,
  LanguageField,
  TargetSearchQueryField,
  TooltipProvider,
} from "@/components/Elements";
import {
  countryOptions,
  languageOptions,
} from "@/components/Elements/Dialog/newDocumentOptions";
import { getStatusOptions } from "@/components/Elements/DocumentTable/StatusCell";
import { FraseDocument } from "@/features/documents";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { cn } from "@/utils/style";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useFieldArray, useForm } from "react-hook-form";
import { TbSettings } from "react-icons/tb";
import * as z from "zod";

interface SearchProps {
  fraseDocument: FraseDocument;
  setDocument: (value: Document) => void;
  setProcessResults: (value: boolean) => void;
  containerClassName?: string;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  isReadOnly: boolean;
  settingsVisible: boolean;
}

interface SearchInputProps {
  className: string;
  onSubmit: () => void;
  onKeyDown: React.KeyboardEventHandler;
  value: string;
  onChange: (value: string) => void;
  countryCode: string;
  settingsModalOpen: boolean;
  setSettingsModalOpen: (value: boolean) => void;
}

const CountryFlag: React.FC<{ countryCode?: string }> = ({ countryCode }) => (
  <ReactCountryFlag countryCode={countryCode || "US"} svg />
);

const SearchInput: React.FC<SearchInputProps> = ({
  className,
  onSubmit,
  onKeyDown,
  value,
  onChange,
  countryCode,
  setSettingsModalOpen,
  isReadOnly,
  settingsVisible,
}) => (
  <Input
    disabled={isReadOnly}
    className={cn(className, "w-full disabled:opacity-100")}
    containerClassName="h-[32px]"
    onSubmit={onSubmit}
    onKeyDown={onKeyDown}
    value={value}
    onChange={onChange}
    firstIcon={<CountryFlag countryCode={countryCode} />}
    firstButtonVariant="node"
    placeholder="Enter search query"
    showFirstButtonOnHover={false}
    secondIcon={settingsVisible ? <TbSettings /> : null}
    secondButtonClassName={
      settingsVisible ? "opacity-100 pr-1 text-zinc-900 dark:text-zinc-100" : ""
    }
    secondButtonAction={
      settingsVisible ? () => setSettingsModalOpen(true) : undefined
    }
    showSecondButtonOnHover={false}
  />
);

const SearchQuerySettingsModal = ({
  fraseDocument,
  searchQuery,
  open,
  setOpen,
}) => {
  const updateDocumentMutation = useUpdateDocument({});

  let statusOptions = getStatusOptions(() => {});
  statusOptions = statusOptions.map((status, index) => ({
    ...status,
    value: index + 1,
  }));

  const SearchQueryFormSchema = z.object({
    queries: z
      .array(
        z.object({
          value: z.string({
            required_error: "Please enter a search query",
          }),
        })
      )
      .refine(
        (queries) => {
          const totalWords = queries.reduce(
            (acc, query) => acc + query.value.split(" ").length,
            0
          );
          return totalWords <= 32;
        },
        {
          message:
            "The combined number of words in all queries must be 32 or less",
        }
      ),
    country: z.string(),
    language: z.string(),
  });

  const defaultValues: Partial<z.infer<typeof SearchQueryFormSchema>> = {
    queries: [
      {
        value:
          searchQuery && searchQuery.length > 0
            ? searchQuery
            : fraseDocument.query,
      },
    ],
    country: fraseDocument.metadata.code,
    language: fraseDocument.metadata.lang_code,
  };

  const form = useForm<z.infer<typeof SearchQueryFormSchema>>({
    resolver: zodResolver(SearchQueryFormSchema),
    defaultValues: {
      queries: [{ value: searchQuery || fraseDocument.query }],
      country: fraseDocument.metadata.code,
      language: fraseDocument.metadata.lang_code,
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    form.reset({
      queries: [{ value: searchQuery || fraseDocument.query }],
      country: fraseDocument.metadata.code,
      language: fraseDocument.metadata.lang_code,
    });
  }, [fraseDocument, searchQuery, form.reset]);

  const { fields, append, remove } = useFieldArray({
    name: "queries",
    control: form.control,
  });

  const onSubmit = (data: z.infer<typeof SearchQueryFormSchema>) => {
    const { queries, country, language } = data;
    const query = queries.map((query) => query.value).join(", ");

    const newDocument = {
      ...fraseDocument,
      query: query,
      metadata: {
        ...fraseDocument.metadata,
        code: country,
        display_code: countryOptions.find((option) => option.value === country)
          ?.label,
        lang_code: language,
        lang_display: languageOptions.find(
          (option) => option.value === language
        )?.label,
      },
    };

    updateDocumentMutation
      .mutateAsync(newDocument)
      .then(() => {
        setOpen(false);
      })
      .catch((err) => {
        setOpen(false);
      });
  };

  useEffect(() => {
    const queries = (searchQuery || fraseDocument.query || "")
      .split(",")
      .map((q) => q.trim())
      .filter((q) => q.length > 0)
      .slice(0, 5);

    form.reset({
      queries: queries.map((query) => ({ value: query })),
      country: fraseDocument.metadata.code,
      language: fraseDocument.metadata.lang_code,
    });
  }, [fraseDocument, searchQuery, form.reset, open]);

  // Watch the queries field to dynamically validate the word count
  const queries = form.watch("queries");
  const totalWords = queries.reduce(
    (acc, query) => acc + query.value.split(" ").length,
    0
  );
  const isWordLimitExceeded = totalWords > 32;

  return (
    <DialogModal
      open={open}
      setOpen={(open) => {
        setOpen(open);
        if (!open) {
          form.reset(defaultValues);
        }
      }}
      title="Search query settings"
      footer={<></>}
    >
      <TooltipProvider>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <TargetSearchQueryField
              form={form}
              fields={fields}
              append={append}
              remove={remove}
              isWordLimitExceeded={isWordLimitExceeded}
            />
            <CountryField form={form} />
            <LanguageField form={form} />
            <div className="mt-4 text-center flex-col">
              <Button
                type="button"
                variant="outlineBlur"
                onClick={() => setOpen(false)}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primaryBlur"
                disabled={
                  !form
                    .getValues()
                    .queries?.every(({ value }) => value.trim() !== "") ||
                  isWordLimitExceeded
                }
                isLoading={updateDocumentMutation.isLoading}
              >
                Save
              </Button>
            </div>
          </form>
        </Form>
      </TooltipProvider>
    </DialogModal>
  );
};

const SearchQuery: React.FC<SearchProps> = ({
  setProcessResults,
  containerClassName = "",
  searchQuery,
  setSearchQuery,
  isReadOnly,
  settingsVisible = true,
}) => {
  const { resetSerp } = useSerpStore();
  const { document: fraseDocument, setDocument } = useDocumentStore();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  useEffect(() => {
    const query =
      fraseDocument.query ||
      (fraseDocument.metadata.name !== "Untitled"
        ? fraseDocument.metadata.name
        : "");
    setSearchQuery(query);
  }, [
    fraseDocument.hash,
    fraseDocument.metadata.name,
    fraseDocument.query,
    setSearchQuery,
  ]);

  const handleSubmit = () => {
    resetSerp();
    setDocument({ ...fraseDocument, query: searchQuery });
    setProcessResults(true);
  };

  return (
    <div
      className={cn(
        "flex flex-row py-1.5 pb-[9.5px] rounded-md items-center",
        containerClassName
      )}
    >
      <SearchInput
        className="font-medium h-[32px] pr-[72px] text-zinc-900 dark:text-zinc-100"
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
        isReadOnly={isReadOnly}
        value={searchQuery}
        onChange={(value) => setSearchQuery(value)}
        countryCode={fraseDocument?.metadata?.code}
        settingsModalOpen={settingsModalOpen}
        setSettingsModalOpen={setSettingsModalOpen}
        settingsVisible={settingsVisible}
      />
      <SearchQuerySettingsModal
        open={settingsModalOpen}
        setOpen={setSettingsModalOpen}
        searchQuery={searchQuery}
        fraseDocument={fraseDocument}
      />
    </div>
  );
};

export default SearchQuery;
