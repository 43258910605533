import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";
import { ExtractFnReturnType, QueryConfig } from "../../../lib/react-query";

export interface AiWordsData {
  "12_months": { [key: string]: number };
  "30_days": { [key: string]: number };
}

export const getAiWordsForOrg = (): Promise<AiWordsData> => {
  return axios.post("/getAIWordsForOrg", {});
};

type QueryFnType = typeof getAiWordsForOrg;

type UseGetAiWordsForOrgOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetAiWordsForOrg = ({
  config = {},
}: UseGetAiWordsForOrgOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["aiWordsForOrg"],
    queryFn: getAiWordsForOrg,
  });
};
