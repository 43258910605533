import { TooltipContent } from "@/components/Elements";
import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";

export function Progress({
  progress,
  className,
  stripColor,
  averageProgress,
}: {
  progress: number;
  className?: string;
  stripColor?: string;
  averageProgress: number;
}) {
  const progressWidth = {
    width: `${progress * 10}%`,
  };

  const averageProgressWidth = {
    left: `${averageProgress}%`,
  };

  const stripMap = {
    amber: "bg-amber-300 dark:bg-amber-400/30",
    emerald: "bg-emerald-600 dark:bg-emerald-400/30",
    rose: "bg-rose-500 dark:bg-rose-400/30",
    zinc: "bg-zinc-500 dark:bg-zinc-400/30",
  };

  return (
    <div className="relative">
      <div
        className={clsx(
          "text-white dark:text-white rounded-full h-1.5 bg-zinc-200 dark:bg-zinc-600 overflow-hidden relative",
          className
        )}
      >
        <div
          className={clsx(
            "transition-all duration-500 h-1.5 rounded-full mr-4",
            stripColor
              ? stripMap[stripColor]
              : "bg-emerald-500 dark:bg-emerald-400/30"
          )}
          style={progressWidth}
        ></div>
      </div>
      {averageProgress && (
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div
                className={clsx(
                  "absolute -top-1 bottom-0 rounded-full w-3.5 h-3.5 border-emerald-600 border-2 hover:shadow-glow hover:shadow-emerald-500 bg-white z-50"
                )}
                style={averageProgressWidth}
              ></div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <TooltipContent>
                <b>{averageProgress}%</b> is the average score across all
                competitors
                <Tooltip.Arrow className="fill-white" />
              </TooltipContent>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      )}
    </div>
  );
}
