import { axios } from "@/lib/axios";
import { useQuery } from "react-query";
import { Subscription } from "./getSubscription";

export const getSubscriptionByDocHash = async (
  docHash: string
): Promise<Subscription> => {
  const data = await axios.post("/stripe/getPlanNameForDoc", {
    doc_hash: docHash,
  });
  return data;
};

export const useGetSubscriptionByDocHash = (docHash: string, config?: any) => {
  return useQuery(
    ["subscriptionByDocHash", docHash],
    () => getSubscriptionByDocHash(docHash),
    config
  );
};
