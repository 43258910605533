import { DomainMap } from '@/stores/serp';

/**
 * Transforms a domain map into a sorted list of domains with additional information.
 *
 * @param {DomainMap} domainMap - A Map containing domain information, where keys are domain names and values are objects with 'items', 'internal_links', and 'external_links' properties.
 * @returns {Array} An array of objects containing domain, item_count, internal_links, and external_links, sorted by item_count in descending order.
 */
export const getSerpLinks = (domainMap: DomainMap) => {
  var domain_list: any[] = [];

  Object.entries(domainMap).forEach(([key, value]) => {
    var item_count = Object.keys(value.items).length;
    domain_list.push({
      domain: key,
      item_count: item_count,
      internal_links: value.internal_links,
      external_links: value.external_links,
    });
  });
  domain_list = domain_list.sort(sortByItemCount);
  return domain_list;
};

/**
 *
 * @param {Object} a - An object containing a property 'item_count'.
 * @param {Object} b - An object containing a property 'item_count'.
 * @returns {number} The difference between the 'item_count' properties of the two objects.
 * @description A comparator function for sorting objects by their 'item_count' property in descending order.
 *  */
const sortByItemCount = (a, b) => {
  return b.external_links.length - a.external_links.length;
};
