import { Button, Input } from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements/Table";
import {
  sortByVariable,
  useSeoAnalyticsStore,
} from "@/features/seo-analytics/store";
import { Cluster } from "@/features/seo-analytics/types";
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";

const PAGE_SIZE = 12;

const ClustersTable: React.FC = () => {
  const {
    allClusters,
    sort,
    sortType,
    setSort,
    setSortType,
    loading,
    setQueryFilter,
  } = useSeoAnalyticsStore();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!loading) setCurrentPage(1);
  }, [loading]);

  const [clusterFilter, setClusterFilter] = useState("");

  const filteredClusters: Cluster[] = useMemo(() => {
    if (!allClusters) return [];

    const filterAndSort = (list: Cluster[]) => {
      const filteredList = list.filter((cluster) =>
        cluster.label_word.toLowerCase().includes(clusterFilter.toLowerCase())
      );

      return sortByVariable(filteredList, false, sort, sortType);
    };

    return filterAndSort(allClusters);
  }, [allClusters, clusterFilter, sort, sortType]);

  const paginatedClusters = filteredClusters.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  const getPageCount = (total: number) => Math.ceil(total / PAGE_SIZE);

  const sortList = (field: keyof Cluster) => {
    if (sort === field) {
      setSortType(sortType === "ascending" ? "descending" : "ascending");
    } else {
      setSort(field);
      setSortType("descending");
    }
  };

  if (!allClusters || loading) {
    return (
      <div className="relative flex items-center w-full h-[50%] justify-center">
        <LogoSpinner variant="md" loadingText="Loading clusters..." />
      </div>
    );
  }

  if (allClusters.length === 0) {
    return (
      <div className="flex items-center justify-center py-16 text-gray-500 dark:text-gray-400">
        No clusters found.
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="my-2 mx-4">
        <Input
          type="text"
          className="w-fit"
          placeholder="Filter clusters..."
          value={clusterFilter}
          onChange={(value) => setClusterFilter(value)}
        />
      </div>
      <div className="w-full">
        <Table className="w-full">
          <TableHeader className="bg-white dark:bg-zinc-900 shadow-sm">
            <TableRow>
              <TableHead
                className="w-[15%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                Cluster Label
              </TableHead>
              <TableHead
                className="w-[40%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                Top Queries
              </TableHead>
              <TableHead
                className="w-[11%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                <Button
                  variant="textNode"
                  endIcon={
                    sort === "impressions" && sortType === "descending" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  }
                  onClick={() => sortList("impressions")}
                >
                  Impressions
                </Button>
              </TableHead>
              <TableHead
                className="w-[11%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                <Button
                  variant="textNode"
                  endIcon={
                    sort === "clicks" && sortType === "descending" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  }
                  onClick={() => sortList("clicks")}
                >
                  Clicks
                </Button>
              </TableHead>
              <TableHead
                className="w-[11%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                <Button
                  variant="textNode"
                  className="whitespace-nowrap"
                  endIcon={
                    sort === "position" && sortType === "descending" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  }
                  onClick={() => sortList("position")}
                >
                  Avg. Ranking
                </Button>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedClusters.map((cluster, index) => (
              <TableRow
                key={index}
                onClick={() => setQueryFilter(cluster.label_word)}
                className="cursor-pointer hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-colors duration-200"
              >
                <TableCell className="w-[15%]">
                  <span className="font-bold">{cluster.label_word}</span>
                </TableCell>
                <TableCell className="w-[40%]">
                  {cluster.queries?.slice(0, 2).map((query, idx) => (
                    <span key={idx} className="block w-full">
                      {query.query}
                    </span>
                  ))}
                </TableCell>
                <TableCell className="w-[11%]">{cluster.impressions}</TableCell>
                <TableCell className="w-[11%]">{cluster.clicks}</TableCell>
                <TableCell className="w-[11%]">
                  {cluster.position ? cluster.position.toFixed(2) : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-center mt-4">
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          variant="outline"
          size="2xs"
          className="text-zinc-700 dark:text-zinc-300 mr-2"
        >
          Previous
        </Button>
        <span className="mx-4 text-zinc-700 dark:text-zinc-300">
          {currentPage} / {getPageCount(filteredClusters.length)}
        </span>
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage * PAGE_SIZE >= filteredClusters.length}
          variant="outline"
          size="2xs"
          className="text-zinc-700 dark:text-zinc-300 ml-2"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ClustersTable;
