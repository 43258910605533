import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { useDeleteTemplate } from "@/features/templates/api/deleteTemplate";
import { useNotificationStore } from "@/stores/notifications";
import { TrashIcon } from "@heroicons/react/24/outline";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { DialogClose, DialogPortal } from "@radix-ui/react-dialog";
import React from "react";
import { TbLayoutGrid } from "react-icons/tb";
import { useDeleteDocument } from "../api/deleteDocument";

type DeleteEntityProps = {
  entityTitles: string[];
  entityIds: string[];
  entityType: "document" | "template";
  table: any;
};

const ButtonDeleteContentDialog = ({
  entityTitles,
  entityIds,
  entityType,
  table,
  ...props
}: DeleteEntityProps) => {
  const deleteDocumentMutation = useDeleteDocument({
    notifyOnSuccess: false,
  });
  const deleteTemplateMutation = useDeleteTemplate({
    notifyOnSuccess: false,
  });
  const [open, setOpen] = React.useState(false);
  const { addNotification } = useNotificationStore();

  const entityPlural = entityTitles.length > 1 ? `${entityType}s` : entityType;
  const uppercaseEntityPlural =
    entityPlural[0].toUpperCase() + entityPlural.slice(1);
  const body = (
    <>
      Are you sure you want to delete the following {entityPlural}?
      <ul className="list-disc list-inside space-y-2 my-2 max-h-40 overflow-y-scroll">
        {entityTitles.map((title) => (
          <li
            key={title}
            className="flex items-center list-none font-semibold text-zinc-900 dark:text-white dark:bg-zinc-700"
          >
            {entityType === "document" ? (
              <DocumentIcon className="h-3 w-3 mr-2" />
            ) : (
              <TbLayoutGrid className="h-3 w-3 mr-2 fill-zinc-900" />
            )}
            {title}
          </li>
        ))}
      </ul>
      {entityType === "document" && (
        <>
          Your {entityPlural} will be archived for 30 days prior to permanent
          deletion.
        </>
      )}
    </>
  );
  const confirmButton = (
    <Button
      isLoading={
        entityType === "document"
          ? deleteDocumentMutation.isLoading
          : deleteTemplateMutation.isLoading
      }
      variant="destructiveBlur"
      className="bg-red-600"
      onClick={async () => {
        const mutation =
          entityType === "document"
            ? deleteDocumentMutation
            : deleteTemplateMutation;

        await Promise.all(
          entityIds.map((id) =>
            mutation.mutateAsync(
              entityType === "document"
                ? { documentId: id }
                : { templateId: id }
            )
          )
        )
          .then(() => {
            addNotification({
              type: "success",
              title: `${uppercaseEntityPlural} ${
                entityType === "template" ? "deleted" : "archived"
              }`,
            });
          })
          .catch(() => {});
        setOpen(false);
        table.toggleAllRowsSelected(false);
      }}
    >
      Delete
    </Button>
  );

  return (
    <Dialog
      onOpenChange={(open) => {
        setOpen(open);
      }}
      open={open}
      modal={false}
    >
      <DialogTrigger asChild>
        <Button
          variant="destructiveBlur"
          onClick={open}
          startIcon={<TrashIcon className="h-3 w-3 mr-2" />}
          className="text-red-600 dark:text-red-400 h-7 ml-2"
          textClassName="font-normal text-xs"
          {...props}
        >
          Delete {uppercaseEntityPlural}
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>{`Delete ${entityPlural}`}</DialogTitle>
            <DialogCloseButton
              close={() => {
                setOpen(false);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4">{body}</DialogDescription>
          <DialogFooter className="px-4 pb-4">
            <DialogClose>
              <Button
                variant="outlineBlur"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </DialogClose>
            {confirmButton}
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export const DeleteContentButton = ButtonDeleteContentDialog;
