import {
  Button,
  Command,
  CommandGroup,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Elements";
import { ClipboardIcon } from "@radix-ui/react-icons";
import React, { useMemo } from "react";
import { TbDots, TbWorld } from "react-icons/tb";

interface Asset {
  header: string;
  header_tag: string;
  url: string;
}

interface OptionsProps {
  handlePasteHeadings: (
    headings: { header: string; user_header_tag: string; url: string }[]
  ) => void;
}

interface SerpMenuProps {
  url: string;
  assets: Asset[];
  handlePasteHeadings: OptionsProps["handlePasteHeadings"];
}

const options = ({ handlePasteHeadings }: OptionsProps) => [
  {
    value: "pasteOutline",
    label: "Paste Headings",
    icon: <ClipboardIcon className="mr-2 w-3 h-3" />,
    onClick: (url: string, assets: Asset[]) => {
      handlePasteHeadings(
        assets.map(({ header, header_tag, url }) => ({
          header,
          user_header_tag: header_tag,
          url,
        }))
      );
    },
  },
  {
    value: "openPage",
    label: "Open Page",
    icon: <TbWorld className="mr-2 w-3 h-3" />,
    onClick: (url: string) => {
      window.open(url, "_blank");
    },
  },
];

export const SerpMenu: React.FC<SerpMenuProps> = React.memo(
  ({ url, assets = [], handlePasteHeadings }) => {
    const memoizedOptions = useMemo(
      () => options({ handlePasteHeadings }),
      [handlePasteHeadings]
    );

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="floatingIcon"
            size="sm"
            className="absolute top-1.5 right-0.5 py-1 px-1 z-10 invisible group-hover:visible"
            buttonIcon={<TbDots />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <Command>
            <CommandGroup>
              {memoizedOptions.map((option, index) => (
                <CommandItem
                  key={option.value}
                  onSelect={() => option.onClick(url, assets)}
                >
                  {option.icon}
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
