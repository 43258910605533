import { TbArrowLeft, TbArrowRight } from "react-icons/tb";
import { Button } from "../../../components/Elements";

export const WizardNavigation = ({
  query,
  headings,
  currentStep,
  isLoadingSerp,
  stepValidations,
  setCurrentStep,
  handleSubmit,
  articles,
  isSubmitting,
  setFurthestStepReached,
  setIsInstructionDialogOpen,
}) => {
  return (
    <div className="flex w-full justify-between border-t border-zinc-100 dark:border-zinc-700 px-4 pt-4">
      <div className="flex space-x-4 flex-shrink-0">
        <Button
          variant="outlineBlur"
          onClick={() =>
            setCurrentStep(currentStep < 2 ? currentStep : currentStep - 1)
          }
          className={`${
            currentStep === 1 ? "pointer-events-none opacity-50" : ""
          } duration-350 transition`}
          startIcon={<TbArrowLeft />}
        >
          Back
        </Button>
      </div>

      <div className="flex space-x-2 flex-shrink-0">
        {currentStep < 6 ? (
          <Button
            variant="primaryBlur"
            disabled={!stepValidations[currentStep]}
            onClick={() => {
              if (
                currentStep === 4 &&
                headings.every(
                  (heading) =>
                    JSON.parse(heading.instruction || "[]").length === 0
                )
              ) {
                setCurrentStep(4);
                setIsInstructionDialogOpen(true);
                return;
              }

              setCurrentStep(currentStep > 5 ? currentStep : currentStep + 1);
              setFurthestStepReached(
                currentStep > stepValidations.length - 1
                  ? currentStep
                  : currentStep + 1
              );
            }}
            className={`${
              currentStep > stepValidations.length - 1
                ? "pointer-events-none opacity-50"
                : ""
            } bg duration-350 flex items-center justify-center  transition`}
            endIcon={<TbArrowRight />}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="primaryBlur"
            onClick={() => handleSubmit()}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
