import { marked } from "marked";

export const convertJsonToHtml = (jsonResponse) => {
  let html = "";

  jsonResponse.forEach((response) => {
    let heading, content;

    if (response.heading_type === "h1") {
      heading = `#${response.heading}</span></h1>`;
    } else if (response.heading_type === "h2") {
      heading = `<h2 class="PlaygroundEditorTheme__h2" dir="ltr"><span>${response.heading}</span></h2>`;
    } else if (response.heading_type === "h3") {
      heading = `<h3 class="PlaygroundEditorTheme__h3" dir="ltr"><span>${response.heading}</span></h3>`;
    } else if (response.heading_type === "h4") {
      heading = `<h4 class="PlaygroundEditorTheme__h4" dir="ltr"><span>${response.heading}</span></h4>`;
    } else {
      heading = `<p class="PlaygroundEditorTheme__heading" dir="ltr"><span>${response.heading}</span></p>`;
    }

    const htmlString = marked.parse(response.generation);
    content = htmlString;

    html += heading + content;
  });

  return html;
};
export const convertJsonToMarkdown = (jsonResponse, skipH1 = true) => {
  let markdown = "";

  for (let i = 0; i < jsonResponse.length; i++) {
    let heading, content;
    const response = jsonResponse[i];

    if (response.heading_type === "h1" && skipH1) {
      content = response.generation + "\n";
      markdown += content;
      continue;
    } else if (response.heading_type === "h1") {
      heading = `# ${response.heading}\n`;
    } else if (response.heading_type === "h2") {
      heading = `## ${response.heading}\n`;
    } else if (response.heading_type === "h3") {
      heading = `### ${response.heading}\n`;
    } else if (response.heading_type === "h4") {
      heading = `#### ${response.heading}\n`;
    } else {
      heading = `${response.heading}\n`;
    }

    content = response.generation + "\n";
    markdown += heading + content;
  }

  return markdown;
};
