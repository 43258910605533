"use client";

import * as React from "react";

import { Button, DialogTrigger, Input } from "@/components/Elements";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/Elements/Command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Elements/Popover";
import { cn } from "@/utils/style";
import { CheckIcon } from "@heroicons/react/24/outline";
import { FolderIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { ChevronDownIcon } from "lucide-react";

type ComboBoxProps = {
  options: {
    label: string;
    value: string;
    icon: React.ReactNode;
    isActive?: boolean;
    onClick?: (value: string, data: any) => void;
    shouldOpenDialog?: boolean;
    type?: "button" | "input";
  }[];
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  data: any;
  value: string;
  fixedValue?: any;
  disabled?: boolean;
  enableSearch?: boolean;
  isCheckable?: boolean;
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  trigger?: React.ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
  preventRowClick?: boolean;
  showEndIcon?: boolean;
  buttonVariant?: string;
  searchPlaceholder?: string;
  isLoading?;
};

export function Combobox(props: ComboBoxProps) {
  const {
    id,
    data,
    value,
    className,
    icon,
    title,
    isOpen,
    setIsOpen,
    preventRowClick,
    onOpenChange = () => {},
    showEndIcon = true,
    fixedValue,
    options,
    buttonVariant = "node",
    searchPlaceholder,
    folderName,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [showInput, setShowInput] = React.useState(false);
  const [inputFocused, setInputFocused] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      setShowInput(false);
    }
  }, [open]);

  React.useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const defaultTrigger = (
    <>
      {icon
        ? icon
        : value
        ? options.find((option) => option.value === value)?.label
        : fixedValue !== undefined
        ? fixedValue
        : "Select framework..."}
    </>
  );

  return (
    <Popover
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        onOpenChange(isOpen);
        setIsOpen?.(isOpen);
      }}
      modal
    >
      <PopoverTrigger asChild className={cn("rounded-md", className)}>
        <Button
          id={id}
          variant={buttonVariant}
          size="sm"
          textClassName={cn(
            "text-xs truncate font-normal text-zinc-600 dark:text-white",
            buttonVariant === "outlineBlur" && "font-medium"
          )}
          aria-expanded={open}
          disabled={props.disabled}
          tooltipContent={title}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(!open);
          }}
          endIcon={showEndIcon && <ChevronDownIcon className="h-3 w-3" />}
        >
          {props.trigger ? props.trigger : defaultTrigger}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="z-[55]"
        onInteractOutside={(event) => {
          if (inputFocused) {
            event.stopPropagation();
            event.preventDefault();
          } else {
            return true;
          }
        }}
      >
        <Command>
          {props.enableSearch && <CommandInput placeholder="Search..." />}
          <CommandList>
            {showInput ? null : (
              <CommandEmpty>No {searchPlaceholder} found.</CommandEmpty>
            )}
            <CommandGroup
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {props.options.map((option) => (
                <React.Fragment key={`${option.value}:${option.label}`}>
                  {option.type === "input" && showInput ? (
                    <Input
                      onChange={() => {}}
                      startIcon={<FolderIcon />}
                      startIconClassName="text-zinc-700 dark:text-zinc-300"
                      className="text-xs mb-1"
                      autoFocus
                      onFocus={() => setInputFocused(true)}
                      onBlur={() => setInputFocused(false)}
                      placeholder="Enter folder name"
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ): void => {
                        if (event.key === "Enter") {
                          event.stopPropagation();
                          event.preventDefault();
                          option.onClick?.(event.currentTarget.value, data);
                        }
                      }}
                    />
                  ) : (
                    <CommandItem
                      value={`${option.value}:${option.label}`}
                      onSelect={(currentValue) => {
                        if (option.type === "input") {
                          setShowInput(true);
                        } else {
                          const [internalValue, _] = currentValue.split(":");
                          option.onClick?.(internalValue, data);
                          setOpen(false);
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      {option.shouldOpenDialog ? (
                        <DialogTrigger>
                          <div className="flex row items-center justify-between w-full focus:outline-none focus:ring-transparent">
                            <div className="flex row items-center">
                              {option.icon}
                              {option.label}
                            </div>
                            <CheckIcon
                              className={clsx(
                                "ml-2 h-3 w-3",
                                value === option.value
                                  ? "opacity-100"
                                  : "opacity-0",
                                option.isActive ? "opacity-100" : "opacity-0"
                              )}
                            />
                          </div>
                        </DialogTrigger>
                      ) : (
                        <div className="flex row items-center justify-between w-full">
                          <div className="flex row items-center">
                            {option.icon}
                            {option.label}
                          </div>
                          <CheckIcon
                            className={clsx(
                              "ml-2 h-3 w-3",
                              value === option.value
                                ? "opacity-100"
                                : "opacity-0",
                              option.isActive ? "opacity-100" : "opacity-0"
                            )}
                          />
                        </div>
                      )}
                    </CommandItem>
                  )}
                </React.Fragment>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
