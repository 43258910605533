// src/features/auth/routes/ResetPassword.tsx
import { useAuth } from "@/lib/auth";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import { ResetPasswordForm } from "../components/ResetPasswordForm";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Layout title={user ? "Change your password" : "Reset your password"}>
      <ResetPasswordForm
        user={user || undefined}
        onSuccess={() => {
          navigate("/login");
        }}
      />
    </Layout>
  );
};
