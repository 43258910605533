import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/Elements";
import { countryOptions } from "@/components/Elements/Dialog/newDocumentOptions";
import { Navigation } from "@/components/Layout/MobileNavigation";
import { useSeoAnalyticsStore } from "@/features/seo-analytics/store";
import { useState } from "react";
import { TbChevronDown, TbPencil, TbX } from "react-icons/tb";
import PageFilterDialog from "../PageFilterDialog";
import QueryFilterDialog from "../QueryFilterDialog";

const FilterButton = ({
  label,
  active,
  onClick,
  onRemove,
}: {
  label: string;
  active: string | null;
  onClick: () => void;
  onRemove: (e: React.MouseEvent<SVGElement>) => void;
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const handleRemoval = async (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    setIsRemoving(true);
    await onRemove(e);
    setIsRemoving(false);
  };

  return (
    <Button
      onClick={onClick}
      variant="outline"
      size="2xs"
      className="flex items-center justify-start w-fit"
      startIcon={
        !active && !isRemoving ? (
          <TbPencil className="flex-shrink-0 w-3.5 h-3.5 text-gray-500" />
        ) : active && !isRemoving ? (
          <TbX
            className="flex-shrink-0 w-3.5 h-3.5 text-gray-500 cursor-pointer hover:text-gray-700 dark:hover:text-gray-300"
            onClick={handleRemoval}
          />
        ) : null
      }
      isLoading={isRemoving}
    >
      <div className="flex items-center overflow-hidden mr-2">
        <span className="font-semibold mr-1 flex-shrink-0">{label}:</span>
        <span className="truncate max-w-[100px]">
          {active
            ? active.length > 20
              ? `${active.slice(0, 20)}...`
              : active
            : "All"}
        </span>
      </div>
    </Button>
  );
};

const HeaderRow = () => {
  const [isChangingCountry, setIsChangingCountry] = useState(false);
  const [isChangingSite, setIsChangingSite] = useState(false);
  const [pageFilterDialogOpen, setPageFilterDialogOpen] = useState(false);
  const [queryFilterDialogOpen, setQueryFilterDialogOpen] = useState(false);
  const {
    siteUrl,
    sites,
    setSiteUrl,
    setCountry,
    activeGeo,
    activePage,
    activeQuery,
    setPageFilter,
    setQueryFilter,
    disableSearchConsole,
    isLoadingDisableSearchConsole,
    domainLimit,
  } = useSeoAnalyticsStore();

  const handleQueryFilterClick = () => setQueryFilterDialogOpen(true);
  const handlePageFilterClick = () => setPageFilterDialogOpen(true);

  const removeQueryFilter = async (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    await setQueryFilter(null);
  };

  const removePageFilter = async (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    await setPageFilter(null);
  };

  const handleCountryChange = async (country: typeof activeGeo | null) => {
    setIsChangingCountry(true);
    await setCountry(country);
    setIsChangingCountry(false);
  };

  const handleSiteChange = async (site: string) => {
    setIsChangingSite(true);
    await setSiteUrl(site);
    setIsChangingSite(false);
  };

  const isSingleSite = sites.length === 1;

  return (
    <div className="flex flex-col h-fit">
      <div className="flex flex-col sm:flex-row justify-between items-center px-6 h-14 w-full border-b dark:border-zinc-800">
        <div className="flex items-center mb-2 sm:mb-0">
          <Navigation />
          <span className="text-sm font-medium dark:text-white items-center whitespace-nowrap">
            SEO Analytics
          </span>
        </div>
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
          <DropdownMenu>
            <DropdownMenuTrigger
              asChild
              disabled={isChangingSite || domainLimit === 1}
            >
              <Button
                variant="outline"
                size="2xs"
                className="inline-flex items-center justify-between w-fit whitespace-nowrap"
                endIcon={!isSingleSite ? <TbChevronDown /> : undefined}
                label={"Domain"}
                isLoading={isChangingSite}
                disabled={isChangingSite || domainLimit === 1}
              >
                {siteUrl || "Select a site"}
              </Button>
            </DropdownMenuTrigger>
            {!isSingleSite && (
              <DropdownMenuContent className="w-full sm:w-[200px] max-h-[200px] overflow-y-auto">
                {sites.map((site) => (
                  <DropdownMenuItem
                    key={site}
                    onSelect={() => handleSiteChange(site)}
                  >
                    {site}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            )}
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild disabled={isChangingCountry}>
              <Button
                variant="outline"
                size="2xs"
                className="inline-flex items-center justify-between w-fit"
                endIcon={<TbChevronDown />}
                isLoading={isChangingCountry}
                label={"Country"}
              >
                <span className="truncate max-w-[120px]">
                  {activeGeo?.label || "All"}
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[200px] max-h-[200px] overflow-y-auto">
              <DropdownMenuItem onSelect={() => handleCountryChange(null)}>
                All
              </DropdownMenuItem>
              {countryOptions.map((country) => (
                <DropdownMenuItem
                  key={country.value}
                  onSelect={() => handleCountryChange(country)}
                >
                  {country.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <FilterButton
            label="Query"
            active={activeQuery}
            onClick={handleQueryFilterClick}
            onRemove={removeQueryFilter}
          />

          <FilterButton
            label="Page"
            active={activePage}
            onClick={handlePageFilterClick}
            onRemove={removePageFilter}
          />

          <Button
            variant="text"
            onClick={disableSearchConsole}
            size="2xs"
            className="whitespace-nowrap"
            isLoading={isLoadingDisableSearchConsole}
          >
            Log out of Search Console
          </Button>
        </div>
      </div>

      <PageFilterDialog
        open={pageFilterDialogOpen}
        setOpen={setPageFilterDialogOpen}
        onApplyFilter={setPageFilter}
      />
      <QueryFilterDialog
        open={queryFilterDialogOpen}
        setOpen={setQueryFilterDialogOpen}
        onApplyFilter={setQueryFilter}
      />
    </div>
  );
};

export default HeaderRow;
