import { cn } from "@/utils/style";
import * as React from "react";
import TextareaAutosize from "react-autosize-textarea";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <TextareaAutosize
        className={cn(
          "resize-none w-full rounded-md bg-white pl-2.5 py-1 text-sm text-zinc-900 ring-1 ring-inset ring-zinc-900/7.5 transition dark:bg-zinc-900 dark:text-white dark:ring-inset disabled:cursor-not-allowed disabled:opacity-50 hover:ring-zinc-300 dark:ring-white/10 dark:hover:ring-white/20 focus:[&:not(:focus-visible)]:outline-none focus:outline-none border-none !outline-none focus:ring-emerald-600/50 focus:ring-inset",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
