import { getSerpMentions } from "@/features/documents/utils/topics";
import { useEditorStore } from "@/stores/editor";
import { useEffect, useState } from "react";
import { ArticleMetadata } from "../../../Elements";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";

const TopicItem = ({ link, title, mentions }) => (
  <div className="flex w-full bg-white rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 dark:bg-white/2.5">
    <div className="flex-col pl-4 pr-4 py-4 w-full">
      <ArticleMetadata
        url={link}
        urlHost={link}
        rank={0}
        wordCount={0}
        links={0}
        domainAuthority={0}
      />
      <h3 className="mt-1 text-sm font-medium text-zinc-900 dark:text-zinc-50">
        {title}
      </h3>
      <div className="flex-col w-full overflow-hidden">
        {mentions.map((mention, index) => {
          const mentionText = mention.editedText.replace(
            /<span class='ent'>(.*?)<\/span>/g,
            (match, p1) => `{{${p1}}}`
          );
          const parts = mentionText.split(/({{.*?}})/g).map((part) => {
            if (part.startsWith("{{") && part.endsWith("}}")) {
              return (
                <span
                  key={index}
                  className="bg-amber-100 dark:text-zinc-900 underline decoration-amber-500"
                >
                  {part.slice(2, -2)}
                </span>
              );
            }
            return part;
          });

          return (
            <p
              key={index}
              className="text-xs text-zinc-700 dark:text-zinc-400 leading-normal mb-4 last:mb-0 first:mt-2"
            >
              {parts}
            </p>
          );
        })}
      </div>
    </div>
  </div>
);

export default function TopicDetail({
  setSelectedTopic,
  selectedTopic,
  articles,
}) {
  const [mentions, setMentions] = useState([]);
  const { setEditor } = useEditorStore();
  const { setHeaderNavigation, showHeaderNavigation } = useHeaderNavigation();

  useEffect(() => {
    const articleMentions = getSerpMentions(selectedTopic, articles);
    setMentions(articleMentions);
    setHeaderNavigation({
      title: (
        <div className="text-xs bg-zinc-100 dark:bg-zinc-800 px-2 rounded-md">
          {selectedTopic.entity}
        </div>
      ),
      onBack: () => {
        setSelectedTopic(null);
        setEditor((editorState) => {
          editorState.optimizeViewVisible = true;
        });
      },
    });
    showHeaderNavigation();
  }, [selectedTopic]);

  return (
    <div className="flex-col h-full w-full rounded-md ">
      <div className="flex w-full justify-between gap-2 pt-4 border-t dark:border-t-zinc-800">
        <div className="flex flex-col justify-between w-full pl-4 pr-1">
          <div className="text-xs font-medium dark:text-zinc-50 text-zinc-500">
            My mentions
          </div>
          <div className="flex items-center text-sm dark:text-zinc-50 text-zinc-900 font-medium">
            {selectedTopic.user_count}
          </div>
        </div>

        <div className="flex flex-col justify-between w-full space-y-1">
          <div className="text-xs font-medium dark:text-zinc-50 text-zinc-500">
            Avg. mentions
          </div>
          <div className="text-sm dark:text-zinc-50 font-medium text-zinc-900">
            {selectedTopic.frequency}
          </div>
        </div>
        <div className="flex flex-col justify-between w-full">
          <div className="text-xs font-medium dark:text-zinc-50 text-zinc-500">
            Search results
          </div>
          <div className="text-sm  dark:text-zinc-50 font-medium text-zinc-900">
            {mentions.length}
          </div>
        </div>
      </div>
      <div className="border-t border-zinc-900/10 dark:border-white/10 mt-4 mb-4"></div>
      <div className="space-y-2 pb-48 pl-4 pr-1">
        <div className="flex-col">
          <span className="font-semibold text-zinc-800 dark:text-zinc-200">
            {mentions.length}
          </span>
          <span className="text-xs text-zinc-500 ml-1">
            search results mention this topic
          </span>
        </div>
        {mentions.map((mention) => {
          return (
            <TopicItem
              key={mention.link}
              link={mention.url}
              title={mention.title}
              mentions={mention.mentions}
            />
          );
        })}
      </div>
    </div>
  );
}
