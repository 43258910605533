import { LoadSpinner } from "@/components/AdvancedEditor/plugins/ResearchPlugin/SerpPlaceholder";
import {
  Button,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Textarea,
} from "@/components/Elements";
import { Skeleton } from "@/components/Elements/Skeleton";
import { useGetGptTemplate } from "@/features/ai/api/getGptTemplate";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useUser } from "@/features/auth";
import { useNotificationStore } from "@/stores/notifications";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TbArrowLeft, TbPlus, TbSparkles } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAiToolById } from "../api/getAiToolById";
import { useUpdateAiToolConfig } from "../api/updateAiToolConfig";
import { AiToolHeader } from "../components/AiToolHeader";
import { AiToolPreviewHeader } from "../components/AiToolPreviewHeader";
import {
  GenerateMoreButton,
  GenerationOutput,
  LanguageSelection,
  ToolDescription,
} from "./AiTool";

export const AiToolBuilder = () => {
  const params = useParams<{ aiToolId: string }>();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const aiToolId = params.aiToolId;
  const { data: aiTool, isLoading: isAiToolLoading } = useGetAiToolById({
    id: aiToolId!,
  });
  const aiToolHash = aiTool?.hash;
  const isEditMode = location.pathname.includes("/new");

  const [localAiTool, setLocalAiTool] = useState({});
  const [activeOutputTab, setActiveOutputTab] = useState("input");

  const [toolName, setToolName] = useState("");
  const [toolDescription, setToolDescription] = useState("");
  const [toolCategory, setToolCategory] = useState<string | null>(null);
  const [showPromptInstruction, setShowPromptInstruction] = useState(false);
  const [promptInstructions, setPromptInstructions] = useState("");
  const [promptFormula, setPromptFormula] = useState("");
  const [promptSamples, setPromptSamples] = useState("");
  const [legacySampleContent, setLegacySampleContent] = useState("");
  const [personality, setPersonality] = useState("");
  const [lastSaved, setLastSaved] = useState<string | null>(null);
  const [fieldsData, setFieldsData] = useState<{ [key: string]: string }>({});
  const [toolHitCount, setToolHitCount] = useState(0);
  const [enteredFieldsData, setEnteredFieldsData] = useState<{
    [key: string]: string;
  }>({});
  const [isCommunity, setIsCommunity] = useState(false); // Add state for community checkbox

  const [outputs, setOutputs] = useState<any[]>([]);
  const [outputHistory, setOutputHistory] = useState<any[]>([]);
  const [isLoadingGptTemplate, setIsLoadingGptTemplate] = useState(false);
  const { data: user, isLoadingUser } = useUser();

  const userGeography = JSON.parse(user?.geography || "{}");
  const [selectedLanguage, setSelectedLanguage] = useState(
    userGeography?.lang || "en"
  );
  const trackEvent = useTrackEvent();

  const { refetch: getTemplateOutput } = useGetGptTemplate({
    template_hash: aiToolHash!,
    prompt_map: enteredFieldsData,
    num_return_sequences: 1,
    lang: selectedLanguage,
    config: {
      enabled: false,
    },
  });

  const updateAiToolConfig = useUpdateAiToolConfig();

  const debouncedSave = useMemo(
    () =>
      debounce((data) => {
        updateAiToolConfig.mutateAsync(data).then((updatedTool) => {
          setLocalAiTool(updatedTool);
        });
      }, 500),
    [updateAiToolConfig]
  );

  const htmlToText = (html: string): string => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const processNode = (node: Node): string => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent || "";
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        let text = "";

        for (const child of Array.from(element.childNodes)) {
          text += processNode(child);
        }

        if (
          element.tagName === "P" ||
          element.tagName === "DIV" ||
          element.tagName === "BR"
        ) {
          text += "\n\n";
        }

        return text;
      }

      return "";
    };

    return processNode(tempDiv).trim();
  };

  // Function to convert HTML to plain text with breaklines
  const htmlToPlainText = (html: string): string => {
    const convertedText = htmlToText(html).replace(/\n/g, "\n");
    return convertedText;
  };

  // Function to convert plain text back to HTML
  const plainTextToHtml = (text: string): string => {
    // Split the text by new lines and wrap each line in a <p> tag
    const lines = text
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("");
    // Wrap the entire content in an outer <p> tag
    return `<p>${lines}</p>`;
  };

  useEffect(() => {
    if (aiTool) {
      setLocalAiTool(aiTool);
      const toolText = aiTool.text?.[0];
      setToolName(toolText?.title || "");
      setToolDescription(aiTool.metadata?.template_description || "");
      setToolCategory(aiTool.metadata?.template_tags || "");
      setPromptInstructions(aiTool.metadata?.template_tutorial || "");
      setPromptFormula(htmlToText(aiTool.metadata?.template_prompt || "")); // Convert HTML to text
      setPersonality(aiTool.metadata?.template_system_prompt || "");
      setIsCommunity(aiTool.metadata?.community || false);
      setToolHitCount(aiTool.hit_count || 0);
      setLegacySampleContent(htmlToPlainText(aiTool.text?.[0]?.html || ""));

      // Extract prompt samples
      const cleanedPromptSamples =
        aiTool.metadata?.template_samples
          ?.map((sample) => sample.output)
          .join("\n") || "";

      setPromptSamples(cleanedPromptSamples);
      setLastSaved(dayjs(aiTool.updated_dt).toISOString());

      const initialFieldsData =
        aiTool.metadata?.template_samples?.[0]?.input || {};
      setFieldsData(initialFieldsData);
    }
  }, [aiTool]);

  const handlePersonalityChange = useCallback(
    (value) => {
      const newPersonality = value;
      setPersonality(newPersonality);
      debouncedSave({
        ...localAiTool,
        metadata: {
          ...localAiTool?.metadata,
          template_system_prompt: newPersonality,
        },
      });
    },
    [debouncedSave, localAiTool]
  );

  const handleToolNameChange = useCallback(
    (value) => {
      const newName = value;
      setToolName(newName);
      debouncedSave({
        ...localAiTool,
        text: [{ ...localAiTool?.text[0], title: newName }],
      });
    },
    [debouncedSave, localAiTool]
  );

  const handleToolDescriptionChange = useCallback(
    (e) => {
      const newDescription = e.target.value;
      setToolDescription(newDescription);
      debouncedSave({
        ...localAiTool,
        metadata: {
          ...localAiTool?.metadata,
          template_description: newDescription,
        },
      });
    },
    [debouncedSave, localAiTool]
  );

  const handleToolCategoryChange = useCallback(
    (value) => {
      setToolCategory(value);
      debouncedSave({
        ...localAiTool,
        metadata: {
          ...localAiTool?.metadata,
          template_tags: value,
        },
      });
    },
    [debouncedSave, localAiTool]
  );

  const handlePromptInstructionsChange = useCallback(
    (e) => {
      const newInstructions = e.target.value;
      setPromptInstructions(newInstructions);
      debouncedSave({
        ...localAiTool,
        metadata: {
          ...localAiTool?.metadata,
          template_tutorial: newInstructions,
        },
      });
    },
    [debouncedSave, localAiTool]
  );

  const handlePromptFormulaChange = useCallback(
    (e) => {
      const newFormula = e.target.value;
      const cleanedPromptFormula = newFormula.replace(/<\/?p>/g, ""); // Remove <p> and </p> tags
      const updatedPromptFormula = cleanedPromptFormula.replace(
        /[%{]([^%}]+)[%}]/g,
        (_, variableName) => fieldsData[variableName] || ""
      );
      const updatedPromptSamples = `${updatedPromptFormula}\n${promptSamples}`;
      setPromptFormula(newFormula);
      debouncedSave({
        ...localAiTool,
        text: [
          { ...localAiTool?.text[0], html: `<p>${updatedPromptSamples}</p>` },
        ],
        metadata: {
          ...localAiTool?.metadata,
          template_prompt: `<p>${newFormula}</p>`,
        },
      });
    },
    [debouncedSave, localAiTool, fieldsData, promptSamples]
  );

  const handleCommunityChange = useCallback(
    async (checked) => {
      if (checked && toolHitCount < 5) {
        addNotification({
          title: "Usage requirement not met",
          message:
            "You need to use the AI tool more than 5 times to make it visible to the community.",
          type: "warning",
        });
        return;
      }
      setIsCommunity(checked);
      debouncedSave({
        ...localAiTool,
        metadata: {
          ...localAiTool?.metadata,
          community: checked,
        },
      });
    },
    [debouncedSave, localAiTool, aiToolId, addNotification]
  );

  const handlePromptSamplesChange = useCallback(
    (e) => {
      const newSamples = e.target.value;

      const cleanedPromptFormula = promptFormula.replace(/<\/?p>/g, ""); // Remove <p> and </p> tags
      const updatedPromptFormula = cleanedPromptFormula.replace(
        /[%{]([^%}]+)[%}]/g,
        (_, variableName) => fieldsData[variableName] || ""
      );
      const updatedPromptSamples = `${updatedPromptFormula}\n${newSamples}`;
      setPromptSamples(newSamples);

      debouncedSave({
        ...localAiTool,
        text: [
          { ...localAiTool?.text[0], html: `<p>${updatedPromptSamples}</p>` },
        ],
        metadata: {
          ...localAiTool?.metadata,
          template_samples: [{ input: fieldsData, output: newSamples }],
        },
      });
    },
    [debouncedSave, localAiTool, fieldsData, promptFormula]
  );

  const handleTrackRunAiTool = (name: string, hash: string) => {
    trackEvent.mutate({
      event: "run_ai_tool",
      properties: JSON.stringify({
        name: name,
        hash: hash,
      }),
    });
  };

  const handleLegacySampleContentChange = useCallback(
    (e) => {
      const newSampleContent = e.target.value;
      setLegacySampleContent(newSampleContent);
      debouncedSave({
        ...localAiTool,
        text: [
          { ...localAiTool?.text[0], html: plainTextToHtml(newSampleContent) },
        ],
      });
    },
    [debouncedSave, localAiTool]
  );

  const handleGenerateClick = () => {
    setIsLoadingGptTemplate(true);
    getTemplateOutput().then((response) => {
      if (response.data) {
        setOutputs((prev) => [
          ...prev,
          {
            ...response.data,
            prompt_map: enteredFieldsData,
          },
        ]);
        setOutputHistory((prev) => [
          ...prev,
          {
            id: "",
            user_hash: user?.hash || "",
            template_hash: aiToolHash!,
            generation: response.data?.gpt_generations[0].generation!,
            prompt_map: enteredFieldsData,
            unix_timestamp: Math.floor(Date.now() / 1000),
          },
        ]);
        setToolHitCount((prevCount) => prevCount + 1);
      }
      setIsLoadingGptTemplate(false);
      setActiveOutputTab("output");
      handleTrackRunAiTool(toolName, aiToolHash!);
    });
  };

  if (
    user?.orgId !== aiTool?.org_id &&
    isLoadingGptTemplate === false &&
    isLoadingUser === false
  ) {
    return (
      <div className="flex flex-col items-center justify-center h-screen space-y-4">
        <p className="text-sm text-zinc-900 dark:text-white">
          You don't have permission to edit this template.
        </p>
        <Button
          size="xs"
          variant="primaryBlur"
          onClick={() => navigate(-1)}
          startIcon={<TbArrowLeft />}
        >
          Go Back
        </Button>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <ToolConfiguration
        isCommunity={isCommunity}
        setIsCommunity={handleCommunityChange}
        aiTool={localAiTool}
        legacySampleContent={legacySampleContent}
        setLegacySampleContent={handleLegacySampleContentChange}
        toolName={toolName}
        toolDescription={toolDescription}
        toolCategory={toolCategory}
        setToolDescription={handleToolDescriptionChange}
        setToolName={handleToolNameChange}
        setToolCategory={handleToolCategoryChange}
        promptInstructions={promptInstructions}
        setPromptInstructions={handlePromptInstructionsChange}
        promptSamples={promptSamples}
        setPromptSamples={handlePromptSamplesChange}
        promptFormula={promptFormula}
        setPromptFormula={handlePromptFormulaChange}
        lastSaved={lastSaved}
        showPromptInstruction={showPromptInstruction}
        setShowPromptInstruction={setShowPromptInstruction}
        fieldsData={fieldsData}
        setFieldsData={setFieldsData}
        templatePrompt={promptFormula}
        debouncedSave={debouncedSave}
        isAiToolLoading={isAiToolLoading}
        user={user}
        isEditMode={isEditMode}
        personality={personality}
        setPersonality={handlePersonalityChange}
        setEnteredFieldsData={setEnteredFieldsData}
      />
      <ToolOutput
        legacySampleContent={legacySampleContent}
        templatePrompt={promptFormula}
        onGenerate={handleGenerateClick}
        isLoadingOutput={isLoadingGptTemplate}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        setFieldsData={setFieldsData}
        fieldsData={fieldsData}
        enteredFieldsData={enteredFieldsData}
        setEnteredFieldsData={setEnteredFieldsData}
        outputs={outputs}
        aiToolHash={aiToolHash}
        setOutputs={setOutputs}
        outputHistory={outputHistory}
        setOutputHistory={setOutputHistory}
        templateDescription={toolDescription}
        activeTab={activeOutputTab}
        setActiveTab={setActiveOutputTab}
        promptSamples={promptSamples}
      />
    </div>
  );
};

function TemplatePersonality({
  personality,
  setPersonality,
  isLoadingTool,
}: {
  personality: string;
  setPersonality: (value: string) => void;
  isLoadingTool?: boolean;
}) {
  const maxLength = 300;

  return (
    <div className="flex flex-col">
      <Label>Personality</Label>
      <p className="text-sm text-zinc-500 my-1">
        Write a personality for the AI to use when generating content.
      </p>
      {isLoadingTool ? (
        <div className="text-sm space-y-2">
          <Skeleton className="h-6 w-full bg-zinc-200/50" />
        </div>
      ) : (
        <>
          <Textarea
            value={personality}
            onChange={(e) => setPersonality(e.target.value)}
            className="max-h-20"
            placeholder="For example: You are an AI that turns questions into concise bulleted lists. Be clear and precise."
            maxLength={maxLength}
          />
          <p className="mt-1 text-xs text-zinc-400">
            {personality.length} / {maxLength} characters
          </p>
        </>
      )}
    </div>
  );
}

function ToolDescriptionForm({
  toolDescription,
  setToolDescription,
  isLoadingTool,
}: {
  toolDescription: string;
  setToolDescription: (value: string) => void;
  isLoadingTool?: boolean;
}) {
  const maxLength = 100;

  return (
    <div className="flex flex-col">
      <Label>Description</Label>
      <p className="text-sm text-zinc-500 my-1">
        Describe what a user can do with this tool.
      </p>
      {isLoadingTool ? (
        <div className="text-sm space-y-2">
          <Skeleton className="h-6 w-full bg-zinc-200/50" />
        </div>
      ) : (
        <>
          <Textarea
            placeholder={`For example: Turn a question into a bulleted list of answers.`}
            value={toolDescription}
            onChange={setToolDescription}
            className="max-h-20"
            maxLength={maxLength}
          />
          <p className="mt-1 text-xs text-zinc-400">
            {toolDescription.length} / {maxLength} characters
          </p>
        </>
      )}
    </div>
  );
}

export function ToolConfiguration({
  aiTool,
  legacySampleContent,
  setLegacySampleContent,
  toolName,
  setToolName,
  toolDescription,
  setToolDescription,
  promptInstructions,
  setPromptInstructions,
  promptFormula,
  setPromptFormula,
  lastSaved,
  promptSamples,
  setPromptSamples,
  showPromptInstruction,
  setShowPromptInstruction,
  templatePrompt,
  fieldsData,
  setFieldsData,
  debouncedSave,
  isAiToolLoading,
  user,
  isEditMode,
  toolCategory,
  setToolCategory,
  personality,
  setPersonality,
  setEnteredFieldsData,
  isCommunity,
  setIsCommunity,
}) {
  const prompt = templatePrompt || "";
  const variableRegex = /[%{]([^%}]+)[%}]/g;

  const updatedFieldsData = useMemo(() => {
    const newFieldsData = {};
    let match;
    while ((match = variableRegex.exec(prompt)) !== null) {
      const variableName = match[1];
      newFieldsData[variableName] = fieldsData[variableName] || "";
    }
    return newFieldsData;
  }, [prompt, fieldsData]);

  useEffect(() => {
    setFieldsData(updatedFieldsData);
    setEnteredFieldsData((prev) => {
      const newEnteredFieldsData = { ...prev };
      Object.keys(updatedFieldsData).forEach((key) => {
        if (!(key in newEnteredFieldsData)) {
          newEnteredFieldsData[key] = updatedFieldsData[key];
        }
      });
      return newEnteredFieldsData;
    });
  }, [updatedFieldsData, setFieldsData, setEnteredFieldsData]);

  const handleFieldChange = useCallback(
    (fieldName: string, value: string) => {
      setFieldsData((prev) => {
        const newFieldsData = { ...prev, [fieldName]: value };
        const cleanedPromptFormula = promptFormula.replace(/<\/?p>/g, ""); // Remove <p> and </p> tags
        const updatedPromptFormula = cleanedPromptFormula.replace(
          /[%{]([^%}]+)[%}]/g,
          (_, variableName) => newFieldsData[variableName] || ""
        );
        const updatedPromptSamples = `${updatedPromptFormula}\n${promptSamples}`;
        debouncedSave({
          ...aiTool,
          text: [
            { ...aiTool?.text[0], html: `<p>${updatedPromptSamples}</p>` },
          ],
          metadata: {
            ...aiTool?.metadata,
            template_samples: [{ input: newFieldsData, output: promptSamples }],
          },
        });
        return newFieldsData;
      });
    },
    [debouncedSave, aiTool, promptFormula, promptSamples]
  );

  const fields = Object.keys(updatedFieldsData).map((variableName) => (
    <div className="flex flex-col first:mt-4" key={variableName}>
      <Label className="mb-2 text-sm bg-zinc-100 w-fit px-1.5 rounded-md py-0.5 dark:bg-zinc-800">
        {variableName[0].toUpperCase() + variableName.slice(1)}
      </Label>
      <Textarea
        id={variableName}
        placeholder={`Input ${variableName}...`}
        value={updatedFieldsData[variableName]}
        onChange={(e) => handleFieldChange(variableName, e.target.value)}
        className="max-h-40"
      />
      <p className="mt-1 text-xs text-zinc-400">
        {updatedFieldsData[variableName].split(" ").filter(Boolean).length}{" "}
        words
      </p>
    </div>
  ));

  return (
    <div className="flex flex-col w-1/2 border-r dark:border-zinc-700">
      <AiToolHeader
        aiToolId={aiTool.id}
        aiToolHash={aiTool.hash}
        title={toolName}
        lastSaved={lastSaved}
        aiTool={aiTool}
        isOwner={aiTool?.org_id === user?.orgId}
        isEditMode={isEditMode}
        isPublished={
          (Object.keys(fieldsData).length > 0 && promptSamples.trim() !== "") ||
          legacySampleContent.trim() !== ""
        }
      />
      <div className="flex flex-col space-y-6 w-full h-full p-8 overflow-y-scroll pb-20">
        <ToolDescriptionForm
          toolDescription={toolDescription}
          setToolDescription={setToolDescription}
          isLoadingTool={isAiToolLoading}
        ></ToolDescriptionForm>

        <div className="flex flex-col">
          <Label>Category</Label>
          <p className="text-sm text-zinc-500 my-1">
            Select a category for this tool to help users find it.
          </p>
          <Select onValueChange={setToolCategory} value={toolCategory}>
            <SelectTrigger>
              <SelectValue placeholder="Select a category" />
            </SelectTrigger>
            <SelectContent>
              {[
                "Advertising",
                "Blog",
                "Copywriting",
                "Email",
                "Rewrite",
                "SEO",
                "Social Media",
              ].map((tag) => (
                <SelectItem key={tag} value={tag}>
                  {tag}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col">
          <div className="flex items-center space-x-2">
            <Switch
              size="sm"
              checked={isCommunity}
              onCheckedChange={setIsCommunity}
            />
            <Label htmlFor="community-checkbox">Publish to Community</Label>
          </div>
          <p className="text-sm text-zinc-500 my-1">
            {`
              The tool needs to be used more than 5 times before it is visible to the community.
            `}
          </p>
        </div>
        <TemplatePersonality
          personality={personality}
          setPersonality={setPersonality}
          isLoadingTool={isAiToolLoading}
        />
        <div className="flex flex-col">
          <Label>Prompt</Label>
          <p className="text-sm text-zinc-500 my-1">
            {`Write the formula for the prompt. Use % to wrap variables.`}
          </p>
          <Textarea
            placeholder={`For example: Write 4 bullet points answering this question: %question%.`}
            value={promptFormula.replace(/<p>/g, "").replace(/<\/p>/g, "")}
            onChange={setPromptFormula}
            className="max-h-40"
          />
        </div>
        {fields.length > 0 && (
          <div className="flex flex-col">
            <Label>Sample Input</Label>
            <p className="text-sm text-zinc-500 my-1">
              {`Fill in the following fields with sample input:`}
            </p>
            <div className="flex flex-col space-y-4">{fields}</div>
          </div>
        )}
        {Object.keys(fieldsData).length > 0 && (
          <div className="flex flex-col">
            <Label>Sample Output</Label>
            <p className="text-sm text-zinc-500 my-1">
              Write the expected sample output for the prompt.
            </p>
            <Textarea
              placeholder={`Provide a sample output for the prompt.`}
              value={promptSamples}
              onChange={setPromptSamples}
              className="max-h-full"
            />
          </div>
        )}
        {/* Render Samples input if template_samples doesn't exist */}
        {!aiTool.metadata?.template_samples &&
          aiTool.text &&
          aiTool.text[0] && (
            <div className="flex flex-col">
              <Label>Samples</Label>
              <p className="text-sm text-zinc-500 my-1">
                Provide sample content for the tool.
              </p>
              <Textarea
                placeholder={`Enter sample content here.`}
                value={legacySampleContent}
                onChange={setLegacySampleContent}
                className="max-h-full"
              />
            </div>
          )}
        {showPromptInstruction && (
          <div className="flex flex-col">
            <Label>Tutorial</Label>
            <p className="text-sm text-zinc-500 my-1">
              Provide tips on how to best use this tool.
            </p>
            <Textarea
              placeholder={`For example: Provide a meaningful question to get the best results.`}
              value={promptInstructions}
              onChange={setPromptInstructions}
              className="max-h-20 min-h-20"
            />
          </div>
        )}

        {!showPromptInstruction && Object.keys(fieldsData).length > 0 && (
          <Button
            variant="text"
            onClick={() => setShowPromptInstruction(!showPromptInstruction)}
            className="mt-4 w-fit flex-shrink-0"
            size="xs"
            startIcon={<TbPlus />}
          >
            Add tutorial
          </Button>
        )}
      </div>
    </div>
  );
}

export function ToolOutput({
  legacySampleContent,
  templatePrompt,
  onGenerate,
  selectedLanguage,
  setSelectedLanguage,
  isLoadingOutput,
  fieldsData,
  setFieldsData,
  outputs,
  aiToolHash,
  setOutputs,
  outputHistory,
  setOutputHistory,
  templateDescription,
  activeTab,
  setActiveTab,
  enteredFieldsData,
  setEnteredFieldsData,
  promptSamples,
}) {
  const prompt = templatePrompt || "";
  const variableRegex = /[%{]([^%}]+)[%}]/g;

  const updatedFieldsData = useMemo(() => {
    const newFieldsData = {};
    let match;
    while ((match = variableRegex.exec(prompt)) !== null) {
      const variableName = match[1];
      newFieldsData[variableName] = fieldsData[variableName] || "";
    }
    return newFieldsData;
  }, [prompt, fieldsData]);

  useEffect(() => {
    setFieldsData(updatedFieldsData);
    setEnteredFieldsData((prev) => {
      const newEnteredFieldsData = { ...prev };
      Object.keys(updatedFieldsData).forEach((key) => {
        if (!(key in newEnteredFieldsData)) {
          newEnteredFieldsData[key] = updatedFieldsData[key];
        }
      });
      return newEnteredFieldsData;
    });
  }, [updatedFieldsData, setFieldsData, setEnteredFieldsData]);

  const handleFieldChange = (fieldName: string, value: string) => {
    setEnteredFieldsData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const allFieldsFilled = Object.values(enteredFieldsData).every(
    (value) => value.trim() !== ""
  );

  const fields = Object.keys(updatedFieldsData).map((variableName) => (
    <div className="flex flex-col" key={variableName}>
      <Label className="mb-2">
        {variableName[0].toUpperCase() + variableName.slice(1)}
      </Label>
      <Textarea
        id={variableName}
        placeholder={`Input ${variableName}...`}
        value={enteredFieldsData[variableName] || ""}
        onChange={(e) => handleFieldChange(variableName, e.target.value)}
        className="max-h-40"
      />
      <p className="mt-1 text-xs text-zinc-500">
        {enteredFieldsData[variableName]?.split(" ").filter(Boolean).length ||
          0}{" "}
        words
      </p>
    </div>
  ));

  if (isLoadingOutput) {
    return (
      <div className="flex flex-col w-1/2">
        <div className="flex flex-col items-center justify-center flex-1">
          <div className="relative w-full h-full mx-auto my-auto mt-40">
            <LoadSpinner
              searchResults={undefined}
              loadingText={"Frase AI is generating content..."}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-1/2">
      <AiToolPreviewHeader
        activeOutputTab={activeTab}
        setActiveOutputTab={setActiveTab}
        outputTotal={outputs.length}
      />
      {activeTab === "output" ? (
        <div className="flex flex-col flex-1 overflow-y-auto pb-40">
          {outputs.length === 0 ? (
            <div className="flex flex-col items-center justify-center flex-1">
              <p className="text-center text-zinc-500 text-sm">No output</p>
            </div>
          ) : (
            <>
              {outputs
                .slice()
                .reverse()
                .map((output, index) => (
                  <GenerationOutput
                    key={index}
                    outputs={outputs}
                    outputHistory={outputHistory}
                    generation={output.gpt_generations[0].generation}
                    outputWords={output.output_words}
                    aiToolId={output.id}
                    aiToolHash={aiToolHash}
                    generationTimestamp={outputHistory[index]?.unix_timestamp} // Ensure correct index
                    setOutputs={setOutputs}
                    setOutputHistory={setOutputHistory}
                    promptMap={output.prompt_map}
                    fieldsData={fieldsData}
                  />
                ))}
              <GenerateMoreButton
                onGenerate={onGenerate}
                isLoadingOutput={isLoadingOutput}
                allFieldsFilled={allFieldsFilled}
              />
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col w-full h-full p-8 bg-zinc-50 dark:bg-zinc-900 overflow-y-scroll">
          {fields.length > 0 &&
          (promptSamples.length > 0 || legacySampleContent) ? (
            <div className="flex flex-col space-y-6">
              {templateDescription && (
                <ToolDescription
                  description={templateDescription || "No description."}
                  isLoadingTool={false}
                />
              )}
              <LanguageSelection
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                isLoadingTool={false}
              />
              {fields}
              <Button
                variant="primary"
                className="flex-shrink-0"
                startIcon={
                  <TbSparkles className="w-4 h-4 mr-1 fill-white dark:fill-emerald-400" />
                }
                onClick={onGenerate}
                isLoading={isLoadingOutput}
                disabled={!allFieldsFilled}
              >
                Generate
              </Button>
            </div>
          ) : (
            <div className="flex items-center justify-center text-center text-zinc-500 border-dashed border-2 border-zinc-200 dark:border-zinc-800 p-4 h-full rounded-md">
              <span className="text-sm">
                Start by entering a prompt formula with variables and a sample
                output.
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
