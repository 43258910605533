import { axios } from "@/lib/axios";
import { useMutation, useQueryClient } from "react-query";

export const updateAiToolBookmark = (templateHash: string): Promise<number> => {
  return axios.post(`/bookmarkTemplate`, {
    template_hash: templateHash,
  });
};

export const useUpdateAiToolBookmark = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAiToolBookmark, {
    onSuccess: () => {
      queryClient.refetchQueries("bookmarks");
    },
  });
};
