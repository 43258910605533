import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Logo,
} from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const FreeTrialLimitDialog = ({ onClose, title, text }) => {
  const [open, setOpen] = useState(true);
  const [hasTracked, setHasTracked] = useState(false);
  const trackEvent = useTrackEvent();
  const navigate = useNavigate();

  const close = () => {
    setOpen(false);
  };

  const handleTrackFreeTrialLimitPopup = async () => {
    if (!hasTracked) {
      trackEvent.mutate({
        event: "free_trial_limit_popup",
        properties: JSON.stringify({}),
      });
      setHasTracked(true);
    }
  };

  if (open) {
    handleTrackFreeTrialLimitPopup();
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose(close);
        }
      }}
    >
      <DialogPortal>
        <DialogContent className="p-0">
          <div className="h-full w-full">
            <div className="flex flex-col justify-center pt-4">
              <DialogCloseButton
                className={`absolute top-4 right-4`}
                close={() => {
                  onClose(close);
                }}
              />
            </div>
            <div>
              <DialogHeader className="pb-4 flex items-center space-x-4 pl-6">
                <Logo renderText={false} />
                <DialogTitle className="text-[22px] text-zinc-800">
                  {title}
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="px-6 pb-6">
                <p className="mt-4 text-sm text-zinc-600 dark:text-zinc-200">
                  Thousands of freelancers, agencies and marketers trust Frase
                  to create high-quality content.
                </p>
                <p className="mt-2 text-sm text-zinc-600 dark:text-zinc-200">
                  Explore our plans, starting at{" "}
                  <span className="font-semibold dark:text-white">
                    $15/month
                  </span>
                  .
                </p>

                <p className="mt-4 text-sm font-medium text-emerald-700 dark:text-emerald-300 whitespace-nowrap">
                  Unlock a 20% discount on your first payment with code{" "}
                  <code className="font-semibold font-sans bg-emerald-500/20 text-emerald-800 px-1.5 py-1 rounded dark:bg-emerald-900 dark:text-emerald-200 ml-0.5">
                    FRASE20
                  </code>
                </p>

                {/*<div className="mt-4 grid grid-cols-2 gap-4">
                  <ul className="list-disc space-y-2">
                    <li className="flex items-center gap-2">
                      <TbCheck className="text-emerald-600 dark:text-emerald-400" />
                      <span className="text-sm text-zinc-500 dark:text-white">
                        Search Queries
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <TbCheck className="text-emerald-600 dark:text-emerald-400" />
                      <span className="text-sm text-zinc-5000 dark:text-white">
                        SERP Analysis
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <TbCheck className="text-emerald-600 dark:text-emerald-400" />
                      <span className="text-sm text-zinc-500 dark:text-white">
                        Outline Builder
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <TbCheck className="text-emerald-600 dark:text-emerald-400" />
                      <span className="text-sm text-zinc-500 dark:text-white">
                        Content Optimization
                      </span>
                    </li>
                  </ul>
                  <ul className="list-disc space-y-2">
                    <li className="flex items-center gap-2">
                      <TbCheck className="text-emerald-600 dark:text-emerald-400" />
                      <span className="text-sm text-zinc-500 dark:text-white">
                        In-editor Writing Assistant
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <TbCheck className="text-emerald-600 dark:text-emerald-400" />
                      <span className="text-sm text-zinc-500 dark:text-white">
                        AI Article Wizard
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <TbCheck className="text-emerald-600 dark:text-emerald-400" />
                      <span className="text-sm text-zinc-500 dark:text-white">
                        WordPress Plugin
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <TbCheck className="text-emerald-600 dark:text-emerald-400" />
                      <span className="text-sm text-zinc-500 dark:text-white">
                        Google Docs Plugin
                      </span>
                    </li>
                  </ul>
              </div>*/}
              </DialogDescription>

              <DialogFooter className="px-6 py-4 flex items-center justify-between sm:justify-between bg-white dark:bg-zinc-800 rounded-bl-md rounded-br-md border-t dark:border-t-zinc-700">
                <span className="flex items-center rounded-md text-2xs font-medium text-emerald-700 dark:text-emerald-400">
                  <CheckBadgeIcon className="h-4 w-4 mr-1.5" />
                  5-day money-back guarantee
                </span>

                <div className="flex items-center space-x-2">
                  <Button
                    variant="primaryBlur"
                    size="sm"
                    className="bg-emerald-100"
                    onClick={() => {
                      navigate("/app/settings/plans");
                      onClose(close);
                    }}
                  >
                    Choose a plan
                  </Button>
                </div>
              </DialogFooter>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
