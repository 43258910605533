import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type DocumentKey = number;

export type TopicScores = {
  [key in DocumentKey]: {
    score: number;
    avgScore: number;
    topScore: number;
    topics: any[];
    clusters: any[];
    selectedType:
      | "longTail"
      | "topTopics"
      | "clusters"
      | "topTopics"
      | "titles"
      | "headers"
      | "blacklist";
    selectedStatus: "all" | "overuse" | "completed" | "inProgress" | "topicGap";
  };
};

type TopicsStore = {
  topics: TopicScores;
  setTopics: (docId: DocumentKey, topic: TopicScores[DocumentKey]) => void;
};

export const useTopicsStore = create(
  immer<TopicsStore>((set, get) => ({
    topics: {},
    setTopics: (docId: DocumentKey, topic: TopicScores[DocumentKey]) =>
      set((state) => {
        state.topics[docId] = {
          score: 0,
          avgScore: 0,
          topScore: 0,
          topics: [],
          clusters: [],
          selectedType: "longTail",
          selectedStatus: "all",
        };
        state.topics[docId] = topic;
      }),
  }))
);
