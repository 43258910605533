import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";

export const deleteComment = (id: number): Promise<void> => {
  return axios.post(`/deleteCommentByID`, { id });
};

type UseDeleteCommentOptions = {
  config?: MutationConfig<typeof deleteComment>;
};

export const useDeleteComment = ({ config }: UseDeleteCommentOptions) => {
  return useMutation({
    ...config,
    mutationFn: deleteComment,
  });
};
