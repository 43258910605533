import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

import { Template } from "../types";

export const getTemplatesForOrg = async ({
  org_id,
  type,
}: {
  org_id: string;
  type?: string;
}): Promise<{
  templates: Template[];
}> => {
  let response = await axios.post("/getTemplatesForOrg", {
    org_id,
    type,
  });
  response = response
    .filter((template: Template) => template.type === "manual")
    .reverse();
  return response;
};

type QueryFnType = typeof getTemplatesForOrg;

type UseTemplatesForOrgOptions = {
  org_id: string;
  type?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useTemplatesForOrg = ({
  org_id,
  type,
  config,
}: UseTemplatesForOrgOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["templates", org_id, type],
    queryFn: () => getTemplatesForOrg({ org_id, type }),
  });
};
