import { FolderIcon } from "@heroicons/react/24/solid";

export const getFolderFilters = (channelsQuery) => {
  return [
    {
      value: "teamId",
      label: "Folder",
      children: channelsQuery.data?.map((channel) => ({
        value: channel?.id ? channel?.id : null,
        label: channel?.name,
        icon: <FolderIcon />,
        parent: "teamId",
      })),
      icon: <FolderIcon />,
    },
  ];
};
