import { FraseDocument } from "@/features/documents";
import { cn } from "@/utils/style";
import { useState } from "react";
import { Combobox } from "../Combobox";
import { StatusIcon } from "../TableFilter/StatusIcons";
import { StatusTag } from "../Tag";

// Mapping the statuses to their corresponding number
export const statusNameToIdMap = {
  researching: 1,
  writing: 2,
  optimizing: 3,
  completed: 4,
  published: 5,
};

export const statusIdToNameMap = {
  1: "researching",
  2: "writing",
  3: "optimizing",
  4: "completed",
  5: "published",
};

export const StatusHeader = () => {
  return <p className="ml-3 text-xs">Status</p>;
};

export const getStatusOptions = (onStatusClick) =>
  Object.keys(statusNameToIdMap).map((key) => ({
    value: key.charAt(0).toUpperCase() + key.slice(1),
    label: key.charAt(0).toUpperCase() + key.slice(1),
    onClick: onStatusClick,
    icon: StatusIcon(key.charAt(0).toUpperCase() + key.slice(1)),
  }));

export const StatusCell = ({
  document,
  variant,
  className,
  onFieldUpdate,
}: {
  document: FraseDocument;
  variant?: string;
  className?: string;
  onFieldUpdate: (
    updates: { field: string; value: any }[],
    data: FraseDocument | FraseDocument[]
  ) => void;
}) => {
  // Hook to control the visibility of the combobox
  const [comboboxOpen, setComboboxOpen] = useState(false);

  // Updated status of the document
  const onStatusClick = async (status: string) => {
    const statusId = statusNameToIdMap[status.toLowerCase()];
    onFieldUpdate(
      [
        { field: "doc_status", value: statusId },
        {
          field: "doc_status_desc",
          value: status.toLowerCase(),
        },
      ],
      document
    );
  };

  // Setting the default status to 'Researching' if status is not available
  const status =
    document.doc_status === 0
      ? "Researching"
      : document.doc_status_desc.charAt(0).toUpperCase() +
        document.doc_status_desc.slice(1);

  const statusOptions = getStatusOptions(onStatusClick);

  return (
    <Combobox
      data={document}
      options={statusOptions}
      buttonVariant="buttonNode"
      value={status}
      className={cn(className)}
      onOpenChange={setComboboxOpen}
      showEndIcon={false}
      trigger={<StatusTag status={status} variant={variant} />}
    />
  );
};
