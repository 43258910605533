import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { DocumentVersion } from "./getDocumentVersionsNoText";

export const getFullVersionForID = ({
  docHash,
  versionId,
}: {
  docHash: string;
  versionId: number;
}): Promise<DocumentVersion> => {
  return axios.post(`/full_version_for_id`, {
    doc_hash: docHash,
    version_id: versionId,
  });
};

type FullVersionQueryFnType = typeof getFullVersionForID;

type UseFullVersionOptions = {
  docHash: string;
  versionId: number;
  config?: QueryConfig<FullVersionQueryFnType>;
};

export const useFullVersionForID = ({
  docHash,
  versionId,
  config,
}: UseFullVersionOptions) => {
  return useQuery<ExtractFnReturnType<FullVersionQueryFnType>>({
    ...config,
    queryKey: ["fullVersionForID", docHash, versionId],
    queryFn: () => getFullVersionForID({ docHash, versionId }),
  });
};
