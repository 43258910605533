import { axios } from "@/lib/axios";
import { useMutation, UseMutationOptions } from "react-query";

export const updateComment = (id: number, comment: string): Promise<void> => {
  return axios.post(`/updateCommentContent`, { id, comment });
};

type UseUpdateCommentOptions = {
  config?: UseMutationOptions<void, unknown, { id: number; comment: string }>;
};

export const useUpdateComment = ({ config }: UseUpdateCommentOptions) => {
  return useMutation(
    (variables: { id: number; comment: string }) =>
      updateComment(variables.id, variables.comment),
    config
  );
};
