import { axios } from "@/lib/axios";
import { useMutation } from "react-query";

interface UpdateAiToolOutputsPayload {
  template_hash: string;
  generation: string;
  prompt_map: {
    [key: string]: string;
  };
}

interface UpdateAiToolOutputsResponse {
  id: string;
}

const updateAiToolOutputs = async (
  payload: UpdateAiToolOutputsPayload
): Promise<UpdateAiToolOutputsResponse> => {
  const response = await axios.post<UpdateAiToolOutputsResponse>(
    `/storeTemplateOutputs`,
    payload
  );

  // @ts-ignore
  return response.id;
};

export const useUpdateAiToolOutputs = () => {
  return useMutation<
    UpdateAiToolOutputsResponse,
    Error,
    UpdateAiToolOutputsPayload
  >(updateAiToolOutputs);
};
