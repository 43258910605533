import * as React from "react";
import { SettingsNavigation } from "../../features/settings/components/SettingsNavigation";
import { DevToolbar } from "./DevToolbar";

type SettingsLayoutProps = {
  children: React.ReactNode;
  subscriptionData?: any;
};

export const SettingsLayout = ({ children }: SettingsLayoutProps) => {
  const env = import.meta.env.VITE_REACT_APP_ENVIRONMENT;

  return (
    <div className="w-full h-screen overflow-y-hidden flex flex-col">
      <div
        className="flex-1 flex flex-row"
        style={{
          height: "calc(100vh - 4rem)",
        }}
      >
        <SettingsNavigation />
        {children}
      </div>
      {env === "development" && <DevToolbar />}
    </div>
  );
};
