// ParagraphPlaceholderPlugin.tsx

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isTableCellNode, $isTableRowNode } from "@lexical/table";
import { $getSelection, $isParagraphNode, $isRangeSelection } from "lexical";
import { useEffect } from "react";

type ParagraphPlaceholderPluginProps = {
  placeholder: string;
  paragraphRef: React.RefObject<HTMLElement>;
  hideOnEmptyEditor?: boolean;
};

const tailwindPlaceholderClasses = [
  "before:float-left",
  "before:text-zinc-400",
  "before:dark:text-zinc-600",
  "before:pointer-events-none",
  "before:h-0",
  "before:content-[attr(data-placeholder)]",
  "print:hidden",
];

export const LinePlaceholderPlugin = ({
  placeholder,
  paragraphRef,
}: ParagraphPlaceholderPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        const nativeSelection = window.getSelection();

        editorState.read(() => {
          // Cleanup
          if (paragraphRef?.current) {
            paragraphRef.current.removeAttribute("data-placeholder");
            paragraphRef.current.classList.remove(
              ...tailwindPlaceholderClasses
            );
            paragraphRef.current = null;
          }

          const selection = $getSelection();

          if (!nativeSelection || !selection || !$isRangeSelection(selection))
            return;

          const parentNode = selection.anchor.getNode();

          if (!$isParagraphNode(parentNode) || !parentNode.isEmpty()) return;

          // if it is a table node, don't show the placeholder
          if (
            $isTableCellNode(parentNode.getParent()) ||
            $isTableRowNode(parentNode.getParent())
          )
            return;

          // It's a paragraph node, it's empty, and it's selected

          // Now switch over to the native selection to get the paragraph DOM element

          const paragraphDOMElement = nativeSelection.anchorNode;

          if (!paragraphDOMElement) return;

          if (paragraphDOMElement instanceof HTMLParagraphElement) {
            paragraphRef.current = paragraphDOMElement;
            paragraphRef.current.setAttribute("data-placeholder", placeholder);
            paragraphRef.current.classList.add(...tailwindPlaceholderClasses);
          }
        });
      }
    );

    return () => {
      removeUpdateListener();
    };
  }, [editor, placeholder]);

  return null;
};

export default LinePlaceholderPlugin;
