export function setGeneratingMenuPosition(
  floatingElem: HTMLElement,
  anchorElem: HTMLElement,
  targetLineElem: HTMLElement,
  topPadding: number = 0
) {
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const targetLineRect = targetLineElem.getBoundingClientRect();

  const top =
    targetLineRect.top -
    anchorElementRect.top +
    topPadding -
    targetLineRect.height -
    4;

  const left = 24;

  // get the computed margin of the anchor element
  const { marginLeft } = window.getComputedStyle(anchorElem);

  // Set the transition property for animation
  floatingElem.style.transition = "transform 0.1s";

  // apply the margin to the floating element
  floatingElem.style.marginLeft = marginLeft;

  floatingElem.style.opacity = "1";
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
  if (topPadding === 0) {
    floatingElem.style.width = `100%`;
  }
}
