// src/features/teams/api/deletePendingUser.ts
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

interface RemovePendingUserPayload {
  userId: number;
  orgId: number;
}

const removePendingUserFromOrg = async (
  payload: RemovePendingUserPayload
): Promise<void> => {
  await axios.post("/removePendingUserFromOrg", payload);
};

export const useRemovePendingUser = (): UseMutationResult<
  void,
  unknown,
  RemovePendingUserPayload
> => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  return useMutation(removePendingUserFromOrg, {
    onSuccess: () => {
      // Invalidate and refetch the query for users in the organization

      addNotification({
        type: "success",
        title: "Removed invited user",
        message: "The invited user was successfully removed from organization.",
      });
      queryClient.refetchQueries("usersForOrg");
      queryClient.refetchQueries("pendingUsers");
    },
  });
};
