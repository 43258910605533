import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  SerpAsset,
} from "@/components/Elements";
import { cn } from "@/utils/style";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import React, { useCallback, useMemo, useState } from "react";

interface ArticleAssetsProps {
  assets: SerpAssetProps[];
  onAssetClick?: (isOpen: boolean) => void;
  selectionEnabled?: boolean;
  selectedAssets: Object[];
  handlePasteHeadings: (assets: any[]) => void;
  handlePasteCitation: (url: string) => void;
  handlePasteContent: (assets: any[]) => void;
}

interface SerpAssetProps {
  header: string;
  html: string[];
  header_tag: string;
  text: string[];
  url: string;
}

const PREVIEW_ASSETS_LENGTH = 10;

const ArticleAssets: React.FC<ArticleAssetsProps> = ({
  assets,
  onAssetClick,
  selectionEnabled,
  selectedAssets,
  handlePasteHeadings,
  handlePasteCitation,
  handlePasteContent,
}) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const previewAssets = useMemo(
    () => (assets ? assets.slice(0, PREVIEW_ASSETS_LENGTH) : []),
    [assets]
  );
  const hiddenAssets = useMemo(
    () => (assets ? assets.slice(PREVIEW_ASSETS_LENGTH) : []),
    [assets]
  );

  const renderSerpAsset = useCallback(
    (asset: SerpAssetProps, index: number) => (
      <SerpAsset
        key={index}
        headerText={asset.header}
        headerType={asset.header_tag}
        html={asset.html}
        assetText={asset.text.filter((text) => text !== "")}
        onClick={onAssetClick}
        selectionEnabled={selectionEnabled}
        selected={
          selectedAssets?.some(
            (selectedAsset) =>
              selectedAsset["header"] === asset.header &&
              selectedAsset["url"] === asset.url
          ) || false
        }
        url={asset.url}
        handlePasteHeadings={handlePasteHeadings}
        handlePasteCitation={handlePasteCitation}
        handlePasteContent={handlePasteContent}
      />
    ),
    [onAssetClick, selectionEnabled, selectedAssets]
  );

  const renderHiddenAssets = () => (
    <Collapsible onOpenChange={setIsPreviewOpen} open={isPreviewOpen}>
      <CollapsibleContent className="space-y-2">
        {hiddenAssets.map(renderSerpAsset)}
      </CollapsibleContent>
      <CollapsibleTrigger asChild onClick={(event) => event.stopPropagation()}>
        <Button
          variant="text"
          size="2xs"
          className={cn(isPreviewOpen && "mt-2")}
          startIcon={isPreviewOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        >
          {isPreviewOpen && hiddenAssets.length > 0 ? "Hide" : "Show"}{" "}
          {hiddenAssets.length}
          <span className="ml-1">more</span>
        </Button>
      </CollapsibleTrigger>
    </Collapsible>
  );

  return (
    <div
      className={cn(
        "flex flex-col h-full w-full overflow-y-scroll space-y-2 mt-2"
      )}
    >
      {previewAssets.map(renderSerpAsset)}
      {hiddenAssets.length >= PREVIEW_ASSETS_LENGTH && renderHiddenAssets()}
    </div>
  );
};

export default React.memo(ArticleAssets);
