import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";

export interface GoogleNewsQuery {
  query: string;
  country_code: string;
}

export interface GoogleNewsResult {
  title: string;
  url: string;
  unix_timestamp: number;
  is_news: boolean;
}

type QueryKey = string;

export type GoogleNewsResults = {
  [key in QueryKey]: GoogleNewsResult[];
};

export const getGoogleNewsResults = async ({
  queries,
}: {
  queries: GoogleNewsQuery[];
}): Promise<GoogleNewsResults> => {
  const requests = queries.map((query) => {
    const keyword_uri = encodeURIComponent(
      query.query
        .replace(/[^a-zA-Z ]/g, "")
        .trim()
        .toLowerCase()
    );
    const rss_link = `https://news.google.com/news/rss/search/section/q/${keyword_uri}?gl=${undefined}`;

    return axios
      .post("/processRssFeed", {
        feedUrl: rss_link,
      })
      .then((response) => response);
  });

  try {
    const responses = await Promise.all(requests);
    return responses.reduce((acc, response, index) => {
      const items = response.map((value: any) => ({
        title: value.title,
        url: value.source,
        source_link: value.url,
        unix_timestamp: value.unix_timestamp,
        is_google_news: true,
      }));
      acc[queries[index].query] = items;
      return acc;
    }, {} as GoogleNewsResults);
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching Google News results");
  }
};

type QueryFnType = typeof getGoogleNewsResults;

type UseGoogleNewsResultsOptions = {
  queries?: GoogleNewsQuery[];
  config?: QueryConfig<QueryFnType>;
};

export const useGoogleNewsResults = ({
  queries,
  config,
}: UseGoogleNewsResultsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["googleNews", JSON.stringify(queries)],
    queryFn: () => getGoogleNewsResults({ queries }),
  });
};
