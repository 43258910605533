import { useMemo } from "react";
import { Button } from "../Button";

interface ToggleGroupProps {
  options: { value: string; label: string; total?: number }[];
  selectedValue: string;
  setSelectedValue: (value: string) => void;
}

const ToggleGroup = ({
  options,
  selectedValue,
  setSelectedValue,
}: ToggleGroupProps) => {
  const getButtonVariant = useMemo(
    () => (value: string) => {
      return selectedValue === value ? "outlineBlur" : "text";
    },
    [selectedValue]
  );

  return (
    <div
      className="flex bg-zinc-100 w-fit p-0.5 rounded-md dark:bg-zinc-800 border border-zinc-200/50 dark:border-zinc-700"
      aria-label="Toggle options"
    >
      {options.map((option) => (
        <div key={option.value} className="flex items-center space-x-1">
          <Button
            className="h-[26px] flex"
            textClassName="flex items-center"
            key={option.value}
            variant={getButtonVariant(option.value)}
            onClick={() => setSelectedValue(option.value)}
            aria-label={`${option.label} pricing`}
            size="2xs"
          >
            {option.label}
            {option.total && option.total > 0 ? (
              <span className="ml-2 text-zinc-600 dark:text-zinc-400 bg-zinc-100 dark:bg-zinc-700 ring-1 ring-zinc-200 dark:ring-zinc-800 rounded-md px-1.5 py-[3px] text-[11px] leading-none self-center">
                {option.total}
              </span>
            ) : null}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default ToggleGroup;
