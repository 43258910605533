import { FraseDocument } from "@/features/documents";
import { useEditorStore } from "@/stores/editor";
import { $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
} from "lexical";
import { useMemo } from "react";
import { $createMarkNode } from "../../nodes/MarkNode";
import { ExtendedTextNode } from "../ExtendedTextNodePlugin/ExtendedTextNodePlugin";
import { getWordCountOfTextNode } from "../WordCountPlugin";

const CONTENT_LABEL = "Tab 1";

export default function PrepopulateDocument({
  fraseDocument,
}: {
  fraseDocument: FraseDocument;
}) {
  const [editor] = useLexicalComposerContext();
  const { editor: editorStore, setEditor } = useEditorStore();
  const { activeTabIndex, isRefreshingDocument } = editorStore;

  useMemo(() => {
    editor.update(() => {
      if (!fraseDocument || fraseDocument.text === null) {
        return;
      }
      // get array item that has name property that equals "my content"
      let documentContent = {
        html: "",
        title: "",
        name: CONTENT_LABEL,
      };
      documentContent = fraseDocument.text[activeTabIndex] || documentContent;

      const parser = new DOMParser();
      const dom = parser.parseFromString(documentContent.html, "text/html");
      // Once you have the DOM instance it's easy to generate LexicalNodes.

      let nodes = $generateNodesFromDOM(editor, dom);

      // Select the root
      $getRoot().select();

      // Clear the root
      $getRoot().clear();
      // If nodes is empty, insert a placeholder paragraph

      if (nodes.length === 0) {
        nodes.push($createParagraphNode());
      }
      // Insert the nodes

      $insertNodes(nodes);

      /** LEGACY BACKWARDS COMPATIBILITY: Convert comment-highlight className to MarkNode for comments backward compatibility
       *
       * This is a temporary solution to maintain backwards compatibility with comments.
       * The comment-highlight className will be removed in the future.
       *
       * */
      editor.registerNodeTransform(ExtendedTextNode, (node) => {
        const className = node.getClassName();
        const match = className.match(/comment-highlight comment-(\w+)/);
        if (match) {
          const id = match[1];
          const markNode = $createMarkNode([id]);

          // Create a new TextNode with the same text content
          const textContent = node.getTextContent();
          const textNode = $createTextNode(textContent);

          // Append the TextNode to the MarkNode
          markNode.append(textNode);

          // Replace the ExtendedTextNode with the MarkNode
          node.replace(markNode);
        }
      });
      // Set cursor at the start of the document

      $getRoot().selectStart();
      const root = $getRoot();
      const text = root.getTextContent();
      (async () => {
        const numWords = await getWordCountOfTextNode(text);
        setEditor((editorState) => {
          editorState.wordCount = numWords;
          editorState.isRefreshingDocument = false;
          editorState.isPopulated = true;
        });
      })();
    });
  }, [
    fraseDocument.hash,
    activeTabIndex,
    fraseDocument.text.length,
    isRefreshingDocument,
  ]);

  return null;
}
