import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

export const getUpcomingInvoice = async () => {
  const data = await axios.post("/stripe/upcoming_invoice");
  return data;
};

export const useGetUpcomingInvoice = ({ config }: { config?: any }) => {
  return useQuery("upcomingInvoice", getUpcomingInvoice, config);
};
