export interface WikipediaSearchQuery {
  query: string;
  lang?: string;
}

export interface WikipediaSearchResult {
  title: string;
  snippet: string;
}

type QueryKey = string;

export type WikipediaSearchResults = {
  [key in QueryKey]: WikipediaSearchResult[];
};

export const getWikipediaSearchResults = async ({
  queries,
}: {
  queries: WikipediaSearchQuery[];
}): Promise<WikipediaSearchResults> => {
  const requests = queries.map((query) => {
    const lang = query.lang || "en";
    const url = new URL(`https://${lang}.wikipedia.org/w/api.php`);
    url.searchParams.append("action", "query");
    url.searchParams.append("format", "json");
    url.searchParams.append("list", "search");
    url.searchParams.append("srsearch", query.query);
    url.searchParams.append("srwhat", "text");
    url.searchParams.append("srlimit", "50");
    url.searchParams.append("origin", "*");

    return fetch(url.toString()).then((response) => response.json());
  });

  try {
    const responses = await Promise.all(requests);
    return responses.reduce((acc, response, index) => {
      if (response.query && response.query.search) {
        acc[queries[index].query] = response.query.search.map((item: any) => ({
          title: item.title,
          snippet: item.snippet,
        }));
      }
      return acc;
    }, {} as WikipediaSearchResults);
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching Wikipedia search results");
  }
};
