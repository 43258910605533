import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

type GetApiUsagePayload = {
  orgId: string;
};

type ApiUsageEntry = {
  calls: number;
  org_id: number;
  request: string;
  url_count?: number;
  template_hash?: string;
  template_name?: string;
  output_chars?: number;
  date?: string;
};

type ApiUsageResponse = {
  "12_months": {
    [key: string]: ApiUsageEntry[];
  };
  "30_days": {
    [key: string]: ApiUsageEntry[];
  };
};

export const getApiUsage = ({
  orgId,
}: GetApiUsagePayload): Promise<ApiUsageResponse> => {
  return axios.post(`/getAPIUsage`, {
    org_id: orgId,
  });
};

type UseGetApiUsageOptions = {
  orgId: string;
  config?: any;
};

export const useGetApiUsage = ({ orgId, config }: UseGetApiUsageOptions) => {
  return useQuery<ApiUsageResponse>({
    ...config,
    queryKey: ["getApiUsage", { orgId }],
    queryFn: () => getApiUsage({ orgId }),
  });
};
