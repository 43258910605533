import { Filter, FilterTag } from "@/components/Elements/Filter";
import ToggleGroup from "@/components/Elements/ToggleGroup/ToggleGroup";
import { Navigation } from "@/components/Layout/MobileNavigation";
import { Template } from "@/features/templates";
import { MdLabel } from "react-icons/md";
import { TbFileFilled, TbUserFilled } from "react-icons/tb";
import { create } from "zustand";
import { NewAiToolButton } from "./NewAiToolButton";

interface AiToolsState {
  selectedType: string;
  setSelectedType: (type: string) => void;
}

export const useAiToolsStore = create<AiToolsState>((set) => ({
  selectedType: "featured",
  setSelectedType: (type) => set({ selectedType: type }),
}));

function AiToolsInfo({}) {
  return (
    <span className="flex items-center">
      <>AI Tools</>
    </span>
  );
}

function AiToolsTypeToggleGroup() {
  const { selectedType, setSelectedType } = useAiToolsStore();

  return (
    <ToggleGroup
      options={[
        { value: "featured", label: "Featured" },
        { value: "community", label: "Community" },
        { value: "created", label: "My Tools" },
      ]}
      selectedValue={selectedType}
      setSelectedValue={setSelectedType}
    />
  );
}

function RenderSelectedFilters({
  selectedFilters,
  removeFilter,
}: {
  selectedFilters: Filter[];
  removeFilter: (idx: number) => void;
}) {
  return (
    <div className="border-b border-zinc-200/80 dark:border-white/10 pl-5 flex items-center space-x-2 h-14">
      {selectedFilters.map((filter, idx) => (
        <FilterTag
          key={filter.value}
          variant="small"
          color="zinc"
          startIcon={filter.icon}
          onClose={(e) => {
            removeFilter(idx);
            e.stopPropagation();
          }}
        >
          {filter.label}
        </FilterTag>
      ))}
    </div>
  );
}

function AiToolsHeader({
  templates,
  selectedFilters,
  setSelectedFilters,
}: {
  templates: Template[] | undefined;
  selectedFilters: Filter[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
}) {
  const filters = templates
    ?.reduce((acc, template) => {
      template.metadata.template_tags &&
        template.metadata.template_tags.length > 0 &&
        template.metadata.template_tags.split(",").forEach((tag) => {
          const lowerTag = tag.toLowerCase();
          if (!acc.find((filter) => filter.value === lowerTag)) {
            acc.push({
              value: lowerTag,
              label: lowerTag.charAt(0).toUpperCase() + tag.slice(1),
              icon: <MdLabel />,
              parent: "metadata.template_tags",
              filterType: "tag",
            });
          }
        });
      return acc;
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label));

  const authors = templates
    ?.reduce((acc, template) => {
      const lowerAuthor = template.template_author.toLowerCase();
      if (!acc.find((author) => author.value === lowerAuthor)) {
        acc.push({
          value: lowerAuthor,
          label:
            lowerAuthor.charAt(0).toUpperCase() +
            template.template_author.slice(1),
          icon: <TbUserFilled />,
          parent: "template_author",
          filterType: "tag",
        });
      }
      return acc;
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label));

  const toolFilters = [
    {
      value: "text",
      parent: "text",
      label: "Name",
      icon: <TbFileFilled />,
      type: "input",
      filterType: "text",
    },
    {
      value: "metadata.template_tags",
      label: "Tag",
      children: filters,
      icon: <MdLabel />,
      filterType: "tag",
    },
    {
      value: "template_author",
      label: "Author",
      children: authors,
      icon: <TbUserFilled />,
      filterType: "tag",
    },
  ];

  const removeFilter = (idx: number) => {
    const newFilters = [...selectedFilters];
    newFilters.splice(idx, 1);
    setSelectedFilters(newFilters);
  };

  return (
    <div className="flex flex-col h-fit">
      <div className="flex justify-between items-center px-6 h-14 w-full border-b dark:border-zinc-800">
        <div className="flex items-center">
          <Navigation />
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium dark:text-white items-center">
              <AiToolsInfo />
            </span>
            <AiToolsTypeToggleGroup />
            <Filter
              setSelectedFilters={setSelectedFilters}
              filters={toolFilters}
              selectedFilters={selectedFilters}
            />
          </div>
        </div>

        <div className="flex">
          <NewAiToolButton className="w-30" buttonVariant={"primaryBlur"} />
        </div>
      </div>
      {selectedFilters.length > 0 && (
        <RenderSelectedFilters
          selectedFilters={selectedFilters}
          removeFilter={removeFilter}
          setSelectedFilters={setSelectedFilters}
          filters={filters}
        />
      )}
    </div>
  );
}

export default AiToolsHeader;
