import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

import { useTemplateStore } from "@/stores/template";
import { Template } from "../types";

export const getTemplateForHash = ({
  templateHash,
}: {
  templateHash: string;
}): Promise<Template> => {
  return axios.post(`/getTemplateForHash`, {
    hash: templateHash,
  });
};

type QueryFnType = typeof getTemplateForHash;

type UseTemplateOptions = {
  templateHash: string;
  config?: QueryConfig<QueryFnType>;
};

export const useTemplate = ({ templateHash, config }: UseTemplateOptions) => {
  const { setTemplate } = useTemplateStore();

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["template", templateHash],
    queryFn: () => getTemplateForHash({ templateHash }),
    onSuccess: (template) => {
      setTemplate(template);
    },
  });
};
