import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function usernameToColor(username: string) {
  if (!username) return "bg-rose-400";

  const colors = [
    "bg-rose-400",
    "bg-sky-400",
    "bg-emerald-600",
    "bg-amber-400",
    "bg-rose-400",
    "bg-indigo-400",
    "bg-orange-400",
  ];
  const index = username
    .split("")
    .map((char) => char.charCodeAt(0))
    .reduce((acc, curr) => acc + curr, 0);
  return colors[index % colors.length];
}
