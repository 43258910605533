import { Navigate, Route, Routes } from "react-router-dom";
import { Folder } from "./Folder";

export const FoldersRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Folder />} />
      <Route path=":folderId" element={<Folder />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
