import { axios } from "@/lib/axios";
import { useMutation } from "react-query";

type GenerateApiTokenPayload = {
  orgId: string;
};

export const generateApiToken = ({
  orgId,
}: GenerateApiTokenPayload): Promise<any> => {
  return axios.post(`/generateAPIToken`, {
    org_id: orgId,
  });
};

type UseGenerateApiTokenOptions = {
  orgId: string;
  config?: any;
};

export const useGenerateApiToken = ({
  orgId,
  config,
}: UseGenerateApiTokenOptions) => {
  return useMutation({
    ...config,
    mutationFn: () => generateApiToken({ orgId }),
  });
};
