import { Button, DropdownDialogItem } from "@/components/Elements";

import { useDeleteFolder } from "@/features/documents/api/deleteFolder";
import { useDisclosure } from "@/hooks/useDisclosure";
import { TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";

type DeleteFolderProps = {
  folderNames: string[];
  folderIds: string[];
  triggerButton?: React.ReactNode;
  onClose?: () => void;
  onSelect?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
};

const DeleteFolderDialog = ({
  folderNames,
  folderIds,
  onClose,
  onSelect,
  onOpenChange,
  ...itemProps
}: DeleteFolderProps) => {
  const deleteFolderMutation = useDeleteFolder();
  const { close, open, isOpen } = useDisclosure();
  const navigate = useNavigate();

  const folderPlural = folderNames.length > 1 ? "folders" : "folder";
  const uppercaseFolderPlural =
    folderPlural[0].toUpperCase() + folderPlural.slice(1);
  const body = (
    <>
      Are you sure you want to delete the following {folderPlural}?
      <ul className="list-disc list-inside space-y-2 my-2">
        {folderNames.map((name) => (
          <li
            key={name}
            className="list-none font-semibold text-zinc-900 dark:text-white dark:bg-zinc-700 border rounded-md p-2 pl-4"
          >
            {name}
          </li>
        ))}
      </ul>
      Documents inside this folder will be archived for 30 days prior to
      permanent deletion.
    </>
  );
  const confirmButton = (
    <Button
      isLoading={deleteFolderMutation.isLoading}
      variant="destructiveBlur"
      className="bg-red-600"
      onClick={async () => {
        await Promise.all(
          folderIds.map((id) => deleteFolderMutation.mutateAsync(id))
        )
          .then(() => {
            onClose && onClose();
            navigate("/app/documents");
          })
          .catch(() => {});
      }}
    >
      Delete
    </Button>
  );

  return (
    <DropdownDialogItem
      icon={<TrashIcon />}
      label="Delete folder"
      title={`Delete ${uppercaseFolderPlural}`}
      body={body}
      close={close}
      confirmButton={confirmButton}
      onSelect={onSelect}
      onOpenChange={onOpenChange}
      className="text-red-600"
      {...itemProps}
    />
  );
};

export const DeleteFolder = DeleteFolderDialog;
