import {
  AvatarStack,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/Elements";
import { useDeleteFolder } from "@/features/documents/api/deleteFolder";
import { useGetFolderInfo } from "@/features/documents/api/getFolder";
import { useInviteUsersToFolder } from "@/features/documents/api/inviteUsersToFolder";
import { useRemoveUserFromFolder } from "@/features/documents/api/removeUserFromFolder";
import { useGetUsersForOrg } from "@/features/users/api/getUsersForOrg";
import { Pencil1Icon } from "@radix-ui/react-icons";
import React from "react";
import { DeleteFolder } from "./DeleteFolder";

export const FolderDropdownMenu = ({
  id,
  title,
  onRename,
  triggerComponent,
}: {
  id: string;
  title: string;
  onRename: () => void;
  triggerComponent: React.ReactElement;
}) => {
  const [label, setLabel] = React.useState("feature");
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [hasOpenDialog, setHasOpenDialog] = React.useState(false);
  const dropdownTriggerRef = React.useRef(null);
  const deleteFolder = useDeleteFolder();
  const usersQuery = useGetUsersForOrg({});
  const folderQuery = useGetFolderInfo({
    teamId: id,
    config: {
      enabled: dropdownOpen,
    },
  });
  const inviteUsers = useInviteUsersToFolder();
  const removeUsers = useRemoveUserFromFolder();

  const handleDialogItemSelect = () => {
    dropdownTriggerRef.current.focus();
  };

  const handleDialogItemOpenChange = (open) => {
    setHasOpenDialog(open);
    if (!open) {
      setDropdownOpen(false);
    }

    // Delay restoring pointer events
    setTimeout(() => {
      document.body.style.pointerEvents = "auto";
    }, 2000);
  };

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger ref={dropdownTriggerRef}>
        {React.cloneElement(triggerComponent, {
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        })}
      </DropdownMenuTrigger>
      <DropdownMenuContent hidden={hasOpenDialog}>
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              setDropdownOpen(false);
              onRename();
            }}
          >
            <Pencil1Icon className="mr-2 h-3 w-3" />
            Rename folder
          </DropdownMenuItem>
          <DropdownMenuSub>
            <AvatarStack
              users={usersQuery.data || []}
              folderInfo={
                folderQuery.data || {
                  users: [],
                }
              }
              type="folderDropdown"
              TriggerComponent={DropdownMenuSubTrigger}
              ContentComponent={DropdownMenuSubContent}
            />
          </DropdownMenuSub>
          <DropdownMenuSeparator />
          <DeleteFolder
            folderNames={[title]}
            folderIds={[id]}
            onClose={handleDialogItemOpenChange}
            onSelect={handleDialogItemSelect}
            onOpenChange={handleDialogItemOpenChange}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
