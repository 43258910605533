import { EditorConflictOnLoadDialog } from "@/features/documents/components/EditorConflictOnLoadDialog";
import { EditorConflictOnSaveDialog } from "@/features/documents/components/EditorConflictOnSaveDialog";
import { useEditorConflictDialogStore } from "../../../stores/conflict";

export const EditorConflictDialog = () => {
  const { dialogType, closeDialog, lastEditorFullName, lastEditedTime } =
    useEditorConflictDialogStore();

  return (
    <>
      {dialogType === "editorConflictOnSave" && (
        <EditorConflictOnSaveDialog
          open={true}
          setOpen={closeDialog}
          lastEditorFullName={lastEditorFullName}
          lastEditedTime={lastEditedTime}
        />
      )}
      {dialogType === "editorConflictOnLoad" && (
        <EditorConflictOnLoadDialog
          open={true}
          setOpen={closeDialog}
          lastEditorFullName={lastEditorFullName}
          lastEditedTime={lastEditedTime}
        />
      )}
    </>
  );
};
