export const shortenNumber = (number: number): string => {
  let shortened = number;
  let unit = '';

  if (number >= 1000) {
    shortened = number / 1000;
    unit = 'k';
  } else if (number >= 1000000) {
    shortened = number / 1000000;
    unit = 'M';
  }

  // Round to one decimal place
  shortened = Math.round(shortened * 10) / 10;
  return `${shortened}${unit}`;
};
