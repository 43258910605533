import { cn } from "@/utils/style";
import { Plus } from "lucide-react";
import { StatusIcon } from "../TableFilter/StatusIcons";

// Define a reusable StatusTag component
export const StatusTag = ({
  status,
  variant,
}: {
  status: string;
  variant?: string;
}) => {
  const StatusIconComponent = StatusIcon(status);
  const className = cn(
    "inline-flex items-center rounded-md px-3 py-1 text-2xs",
    variant === "outlineBlur" &&
      "rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm dark:shadow-lg ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-white dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none"
  );

  return (
    <span className={className}>
      {StatusIconComponent}
      {status}
    </span>
  );
};

// Define the AddStatusTag component
export const AddStatusTag = () => {
  return (
    <span className="inline-flex items-center rounded-full px-3 py-1 text-2xs text-zinc-400 dark:text-zinc-600 hover:text-zinc-900 dark:hover:text-white">
      <Plus className="mr-2 h-3 w-3" />
      Status
    </span>
  );
};
