import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import * as React from "react";

type Color =
  | "emerald"
  | "sky"
  | "amber"
  | "rose"
  | "zinc"
  | "orange"
  | "blue"
  | "yellow"
  | "indigo"
  | "blank";
type Variant = "small" | "smallButton" | "medium" | "highContrast";
type ColorStyle = {
  small: string;
  smallButton?: string;
  medium: string;
  highContrast?: string;
};
type ValueColorMapKey = keyof typeof valueColorMap;

const colorStyles: Record<Color, ColorStyle> = {
  emerald: {
    small:
      "text-emerald-700 bg-emerald-100 ring-emerald-700/20 hover:bg-emerald-100 hover:text-emerald-900 dark:bg-emerald-900/50 dark:ring-1 dark:ring-emerald-50 dark:ring-opacity-10 dark:text-emerald-400 dark:ring-white/10 dark:hover:bg-emerald-900/20",
    smallButton:
      "text-emerald-700 bg-emerald-100 ring-emerald-700/20 hover:bg-emerald-200 hover:text-emerald-900 dark:bg-emerald-900/50 dark:ring-1 dark:ring-emerald-50 dark:ring-opacity-10 dark:text-emerald-400 dark:ring-white/10 dark:hover:bg-emerald-900/20",
    medium:
      "text-emerald-700 bg-emerald-100 ring-emerald-700/20 hover:bg-emerald-100 hover:text-emerald-900 dark:bg-emerald-900/50 dark:ring-1 dark:ring-emerald-50 dark:ring-opacity-10 dark:text-emerald-400 dark:ring-white/10 dark:hover:bg-emerald-900/20",
    highContrast:
      "text-white bg-emerald-600 hover:bg-emerald-700 dark:bg-emerald-700 dark:hover:bg-emerald-600",
  },
  sky: {
    small:
      "text-sky-700 bg-sky-100 ring-sky-600/20 hover:bg-sky-100 hover:text-sky-900 dark:bg-sky-900/50 dark:ring-1 dark:ring-sky-50 dark:ring-opacity-10 dark:text-sky-400 dark:ring-white/10 dark:hover:bg-sky-900/20",
    smallButton:
      "text-sky-700 bg-sky-100 ring-sky-600/20 hover:bg-sky-200 hover:text-sky-900 dark:bg-sky-900/50 dark:ring-1 dark:ring-sky-50 dark:ring-opacity-10 dark:text-sky-400 dark:ring-white/10 dark:hover:bg-sky-900/20",
    medium:
      "text-sky-700 bg-sky-100 ring-sky-600/20 hover:bg-sky-100 hover:text-sky-900 dark:bg-sky-900/50 dark:ring-1 dark:ring-sky-50 dark:ring-opacity-10 dark:text-sky-400 dark:ring-white/10 dark:hover:bg-sky-900/20",
  },
  amber: {
    small:
      "text-amber-800 bg-amber-100/60 ring-amber-700/20 hover:bg-amber-100 hover:text-amber-900 dark:bg-amber-900/50 dark:ring-1 dark:ring-amber-50 dark:ring-opacity-10 dark:text-amber-400 dark:ring-white/10 dark:hover:bg-amber-900/20",
    smallButton:
      "text-amber-800 bg-amber-100/60 ring-amber-700/20 hover:bg-amber-100 hover:text-amber-900 dark:bg-amber-900/50 dark:ring-1 dark:ring-amber-50 dark:ring-opacity-10 dark:text-amber-400 dark:ring-white/10 dark:hover:bg-amber-900/20",
    medium:
      "text-amber-800 bg-amber-100/60 ring-amber-700/20 hover:bg-amber-100 hover:text-amber-900 dark:bg-amber-900/50 dark:ring-1 dark:ring-amber-50 dark:ring-opacity-10 dark:text-amber-400 dark:ring-white/10 dark:hover:bg-amber-900/20",
    highContrast:
      "text-white bg-amber-500 hover:bg-amber-800 dark:bg-amber-800 dark:hover:bg-amber-700 ring-amber-600/20",
  },
  rose: {
    small:
      "text-rose-700 bg-rose-100 ring-rose-800/20 hover:bg-rose-100 hover:text-rose-900 dark:bg-rose-900/50 dark:ring-1 dark:ring-rose-50 dark:ring-opacity-10 dark:text-rose-400 dark:ring-white/10 dark:hover:bg-rose-900/20",
    smallButton:
      "text-rose-700 bg-rose-100 ring-rose-800/20 hover:bg-rose-200 hover:text-rose-900 dark:bg-rose-900/50 dark:ring-1 dark:ring-rose-50 dark:ring-opacity-10 dark:text-rose-400 dark:ring-white/10 dark:hover:bg-rose-900/20",
    medium:
      "text-rose-700 bg-rose-100 ring-rose-800/20 hover:bg-rose-100 hover:text-rose-900 dark:bg-rose-900/50 dark:ring-1 dark:ring-rose-50 dark:ring-opacity-10 dark:text-rose-400 dark:ring-white/10 dark:hover:bg-rose-900/20",
    highContrast:
      "text-white bg-rose-600 hover:bg-rose-700 dark:bg-rose-700 dark:hover:bg-rose-600 ring-rose-600/20 dark:ring-rose-50 dark:ring-opacity-10",
  },
  zinc: {
    small:
      "text-zinc-700 bg-zinc-100 ring-zinc-600/20 hover:bg-zinc-200 hover:text-zinc-900 dark:bg-zinc-900/50 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-zinc-900/20",
    medium:
      "text-zinc-700 bg-zinc-100 ring-zinc-600/20 hover:bg-zinc-200 hover:text-zinc-900 dark:bg-zinc-900/50 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-zinc-900/20",
    highContrast:
      "text-zinc-700 bg-zinc-100 ring-zinc-600/20 hover:bg-zinc-200 hover:text-zinc-900 dark:bg-zinc-900/50 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-zinc-900/20",
  },
  orange: {
    small:
      "text-orange-700 bg-orange-100 ring-orange-600/20 hover:bg-orange-100 hover:text-orange-900 dark:bg-orange-900/50 dark:ring-1 dark:ring-orange-50 dark:ring-opacity-10 dark:text-orange-400 dark:ring-white/10 dark:hover:bg-orange-900/20",
    medium:
      "text-orange-700 bg-orange-100 ring-orange-600/20 hover:bg-orange-100 hover:text-orange-900 dark:bg-orange-900/50 dark:ring-1 dark:ring-orange-50 dark:ring-opacity-10 dark:text-orange-400 dark:ring-white/10 dark:hover:bg-orange-900/20",
  },
  blue: {
    small:
      "text-blue-700 bg-blue-50 ring-blue-600/20 hover:bg-blue-100 hover:text-blue-900 dark:bg-blue-900/50 dark:ring-1 dark:ring-blue-50 dark:ring-opacity-10 dark:text-blue-400 dark:ring-white/10 dark:hover:bg-blue-900/20",
    medium:
      "text-blue-700 bg-blue-50 ring-blue-600/20 hover:bg-blue-100 hover:text-blue-900 dark:bg-blue-900/50 dark:ring-1 dark:ring-blue-50 dark:ring-opacity-10 dark:text-blue-400 dark:ring-white/10 dark:hover:bg-blue-900/20",
  },
  yellow: {
    small:
      "text-yellow-700 bg-yellow-50 ring-yellow-600/20 hover:bg-yellow-100 hover:text-yellow-900 dark:bg-yellow-900/50 dark:ring-1 dark:ring-yellow-50 dark:ring-opacity-10 dark:text-yellow-400 dark:ring-white/10 dark:hover:bg-yellow-900/20",
    medium:
      "text-yellow-700 bg-yellow-50 ring-yellow-600/20 hover:bg-yellow-100 hover:text-yellow-900 dark:bg-yellow-900/50 dark:ring-1 dark:ring-yellow-50 dark:ring-opacity-10 dark:text-yellow-400 dark:ring-white/10 dark:hover:bg-yellow-900/20",
  },
  indigo: {
    small:
      "text-indigo-700 bg-indigo-50 ring-indigo-600/20 hover:bg-indigo-100 hover:text-indigo-900 dark:bg-indigo-900/50 dark:ring-1 dark:ring-indigo-50 dark:ring-opacity-10 dark:text-indigo-400 dark:ring-white/10 dark:hover:bg-indigo-900/20",
    medium:
      "text-indigo-700 bg-indigo-50 ring-indigo-600/20 hover:bg-indigo-100 hover:text-indigo-900 dark:bg-indigo-900/50 dark:ring-1 dark:ring-indigo-50 dark:ring-opacity-10 dark:text-indigo-400 dark:ring-white/10 dark:hover:bg-indigo-900/20",
  },
  blank: {
    small:
      "text-gray-700 bg-white ring-gray-600/20 hover:bg-gray-100 hover:text-gray-900 dark:bg-gray-900/50 dark:ring-1 dark:ring-gray-50 dark:ring-opacity-10 dark:text-gray-400 dark:ring-white/10 dark:hover:bg-gray-900/20",
    medium:
      "text-gray-700 bg-white ring-gray-600/20 hover:bg-gray-100 hover:text-gray-900 dark:bg-gray-900/50 dark:ring-1 dark:ring-gray-50 dark:ring-opacity-10 dark:text-gray-400 dark:ring-white/10 dark:hover:bg-gray-900/20",
  },
};

const valueColorMap = {
  get: "emerald",
  post: "sky",
  put: "amber",
  delete: "rose",
};

export function Tag({
  children,
  variant = "medium" as Variant,
  color = "emerald" as Color,
  startIcon,
  onClose,
  className,
  textClassName,
}: {
  children: React.ReactNode;
  variant?: Variant;
  color?: Color;
  startIcon?: React.ReactElement;
  onClose?: () => void;
  className?: string;
  textClassName?: string;
}) {
  const childString =
    typeof children === "string" ? children.toLowerCase() : "";
  color = (valueColorMap[childString as ValueColorMapKey] as Color) || color;

  const colorStyle = colorStyles[color] || colorStyles.emerald;
  const variantStyle = colorStyle[variant] || colorStyle.medium;

  startIcon =
    startIcon && React.cloneElement(startIcon, { className: "h-3 w-3 mr-2" });

  return (
    <div
      className={clsx(
        "rounded-md py-1 px-2 text-2xs font-semibold ring-1 ring-inset flex items-center w-fit-content",
        variantStyle,
        className
      )}
    >
      {startIcon}
      <p className={textClassName}>{children}</p>
      {onClose && (
        <button
          type="button"
          className="ml-2 inline-flex items-center justify-center p-0.5 rounded-full text-zinc-500 hover:text-zinc-400"
          onClick={onClose}
        >
          <XMarkIcon className="h-3 w-3" aria-hidden="true" />
        </button>
      )}
    </div>
  );
}
