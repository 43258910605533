import { axios } from "@/lib/axios";
import { queryClient } from "@/lib/react-query";
import { useMutation } from "react-query";

export const deleteBookmarkedTemplate = (
  templateHash: string
): Promise<number> => {
  return axios.post(`/deleteBookmarkedTemplate`, {
    template_hash: templateHash,
  });
};

export const useDeleteBookmarkedTemplate = () => {
  return useMutation(deleteBookmarkedTemplate, {
    onSuccess: () => {
      queryClient.refetchQueries("bookmarks");
    },
  });
};
