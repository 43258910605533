import { queryClient } from "@/lib/react-query";
import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

export const restoreSubscription = async (): Promise<any> => {
  const response = await axios.post("/stripe/restoreSubscription");
  return response.data;
};

export const useRestoreSubscription = () => {
  const { addNotification } = useNotificationStore();

  return useMutation(restoreSubscription, {
    onSuccess: () => {
      addNotification({
        type: "success",
        title: "Subscription restored",
        message: "Your subscription has been successfully restored.",
      });
      queryClient.invalidateQueries("subscription");
    },
    onError: (error) => {
      addNotification({
        type: "error",
        title: "Restore failed",
        message: `An error occurred: ${error.message}. Please try again or contact support.`,
      });
    },
  });
};
