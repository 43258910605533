import { memo } from "react";
import { WizardStep as WizardStepWrapper } from "../../../components/Elements/WizardStep";
import { cn } from "../../../utils/style";

export const WizardStep = memo((props) => {
  const { currentStep, isLoading, label, step, onClick, isValid } = props;

  return (
    <div
      className={cn(
        "flex items-center space-x-4 transition-all duration-400 rounded-md px-2 py-1 w-full",
        isValid
          ? "cursor-pointer hover:bg-zinc-200 hover:dark:bg-zinc-700"
          : "cursor-not-allowed",
        currentStep === step && "bg-zinc-200 dark:bg-zinc-700"
      )}
      onClick={onClick}
    >
      <WizardStepWrapper
        step={step}
        currentStep={currentStep}
        label={label}
        isLoading={isLoading && step === 1}
        isValid={isValid}
      />
      <p
        className={cn(
          "text-sm font-medium text-zinc-900 dark:text-white w-full",
          currentStep >= step
            ? "opacity-100 text-zinc-900"
            : "opacity-50 text-zinc-600"
        )}
      >
        {label}
      </p>
    </div>
  );
});
