import { axios } from "@/lib/axios";
import { QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";

export interface DocumentVersion {
  created_by_name: string;
  created_by: number;
  date_created: number;
  doc_hash: string;
  id: number;
  text: string | null;
  version_number: number;
  word_count: number;
}

export const getVersionsForDocNoText = ({
  docHash,
}: {
  docHash: string;
}): Promise<DocumentVersion[]> => {
  return axios.post(`/versions_for_doc_no_text`, {
    doc_hash: docHash,
  });
};

type VersionsQueryFnType = typeof getVersionsForDocNoText;

type UseVersionsOptions = {
  docHash: string;
  config?: QueryConfig<VersionsQueryFnType>;
};

export const useGetDocumentVersionsNoText = ({
  docHash,
  config,
}: UseVersionsOptions) => {
  return useQuery<DocumentVersion[]>({
    ...config,
    queryKey: ["versionsForDocNoText", docHash],
    queryFn: () =>
      getVersionsForDocNoText({ docHash }).then((data) => data.slice(1)),
  });
};
