interface GoogleAnalytics {
  (command: "event", type: string, options: any): void;
  (command: "conversion", type: string, options: any): void;
}

declare var gtag: GoogleAnalytics;

export const useGoogleAnalytics = () => {
  const trackConversion = (conversionId: string) => {
    if (typeof gtag !== "undefined") {
      gtag("event", "conversion", {
        send_to: conversionId,
      });
    }
  };

  const trackSignup = (persona: string) => {
    if (typeof gtag !== "undefined") {
      gtag("event", "signup", {
        event_category: "in-app",
        event_label: persona,
      });
    }
  };

  const trackPurchase = (stripeName: string) => {
    if (typeof gtag !== "undefined") {
      gtag("event", "purchase", {
        event_category: "in-app",
        event_label: stripeName,
      });
    }
  };

  return { trackConversion, trackSignup, trackPurchase };
};
