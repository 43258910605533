import { axiosService } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useSerpStore } from "@/stores/serp";
import { isValidUrl } from "@/utils/isValidUrl";
import { useQuery } from "react-query";
import { FraseDocument } from "../types";
import { getQueries, validateQueries } from "../utils/research";

export interface GoogleSearchQuery {
  query: string;
  country: string;
  lang: string;
  count: number;
}

export interface GoogleSearchResult {
  serp: {
    author: string;
    dateCreated: string;
    image: string;
    snippet: string;
    title: string;
    url: string;
  };
  questions: string[];
}

type QueryKey = string;

export type GoogleSearchResults = {
  [key in QueryKey]: GoogleSearchResult[];
};

export const getGoogleSearchResults = ({
  queries,
  doc_hash,
}: {
  queries: GoogleSearchQuery[];
  doc_hash: string;
}): Promise<GoogleSearchResults> => {
  return axiosService.post(`/getLiveSearchResults`, {
    queries: queries,
    doc_hash: doc_hash,
  });
};

const getGoogleSearchResultsForWizard = ({
  queries,
  doc_hash,
}: {
  queries: GoogleSearchQuery[];
  doc_hash: string;
}): Promise<GoogleSearchResults> => {
  return axiosService.post(`/getLiveSearchResultsForWizard`, {
    queries: queries,
    doc_hash: doc_hash,
  });
};

type QueryFnType = typeof getGoogleSearchResults;

type UseGoogleSearchResultsOptions = {
  document: FraseDocument;
  searchQuery?: string;
  config?: QueryConfig<QueryFnType>;
  country?: string;
  language?: string;
  isAiArticleRequest?: boolean;
};

export const useGoogleSearchResults = ({
  searchQuery,
  document,
  config,
  country,
  language,
  isAiArticleRequest = false,
}: UseGoogleSearchResultsOptions) => {
  const { serp, setSerp } = useSerpStore();
  const countryCode = country || document.metadata?.code;
  const languageCode = language || document.metadata?.lang_code;
  const blacklist = document.metadata?.blacklist || {};
  const query = searchQuery || document.query;
  const queries = getQueries({
    query: query || document.query,
    document,
    country: countryCode,
    language: languageCode,
  });

  queries.filter((query) => query.query.trim().length > 0);

  const queryFn = isAiArticleRequest
    ? getGoogleSearchResultsForWizard
    : getGoogleSearchResults;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["googleSearchResults", query, isAiArticleRequest],
    queryFn: () => queryFn({ queries, doc_hash: document.hash }),
    onSuccess: (data) => {
      const { queries, results, urls, questions } = validateQueries(
        data,
        blacklist
      );

      // Filter results to only include those with valid URLs
      const validResults = results.filter((result) => isValidUrl(result.url));
      const validUrls = validResults.map((result) => result.url);

      setSerp(document.id, {
        ...serp[document.id],
        peopleAlsoAsk: questions,
        queries: queries,
        results: validResults,
        urls: validUrls,
        allArticles: validResults,
        articles: validResults,
        clusters: [],
      });
      return data;
    },
  });
};
