import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

interface UpdateCreditCardRequest {
  source: {
    id: string;
  };
}

export const useUpdateCreditCard = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(
    (requestData: UpdateCreditCardRequest) =>
      axios.post("/stripe/update_credit_card", requestData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("creditCards");
        addNotification({
          type: "success",
          title: "Credit card updated",
          message: "Your credit card has been successfully updated.",
        });
      },
    }
  );
};
