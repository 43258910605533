import { useMutation, useQueryClient } from "react-query";

import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";

export const unarchiveDocument = ({ documentId }: { documentId: number }) => {
  return axios.post(`/unArchiveDocument`, {
    doc_id: documentId,
  });
};

type UseUnarchiveDocumentOptions = {
  config?: MutationConfig<typeof unarchiveDocument>;
  notifyOnSuccess?: boolean;
};

export const useUnarchiveDocument = ({
  config,
  notifyOnSuccess = true,
}: UseUnarchiveDocumentOptions) => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unarchiveDocument,
    onMutate: async ({ documentId }: { documentId: number }) => {
      await queryClient.cancelQueries(["documents"], { exact: false });

      if (!documentId) return;

      return { documentId };
    },
    onError: () => {
      addNotification({
        type: "error",
        title: "Failed to unarchive document",
        message: "Please contact support for help with this issue.",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("documents", { exact: false });

      if (!notifyOnSuccess) return;

      addNotification({
        type: "success",
        title: "Document unarchived",
        message: "Document has been successfully unarchived.",
      });
    },
    ...config,
  });
};
