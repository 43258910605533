import { useMutation } from "react-query";

import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";

import { TbLayoutGrid } from "react-icons/tb";
import { Template } from "../types";

export const createTemplate = (data: Partial<Template>): Promise<Template> => {
  return axios.post(`/createTemplate`, data);
};

type UseCreateTemplateOptions = {
  config?: MutationConfig<typeof createTemplate>;
  notifyOnSuccess?: boolean;
};

export const useCreateTemplate = ({
  config,
  notifyOnSuccess = true,
}: UseCreateTemplateOptions = {}) => {
  const { addNotification } = useNotificationStore();

  const mutationKey = ["createTemplate"];

  return useMutation(mutationKey, {
    onMutate: async (newTemplate) => {
      await queryClient.cancelQueries("templates");

      const previousTemplates =
        queryClient.getQueryData<Template[]>("templates") || [];
      queryClient.setQueryData<Template[]>("templates", (oldQueryData) => {
        return [
          ...(Array.isArray(oldQueryData) ? oldQueryData : []),
          newTemplate,
        ];
      });

      return { previousTemplates };
    },
    onSuccess: (newTemplate: Template) => {
      queryClient.invalidateQueries("templates");

      if (!notifyOnSuccess) return;

      addNotification({
        type: "success",
        title: "Template created",
        message: (
          <span className="flex items-center">
            <TbLayoutGrid className="w-3.5 h-3.5 fill-zinc-500" />
            <span className="ml-2 text-xs">
              {newTemplate.text[0].name || "Untitled"}
            </span>
          </span>
        ),
      });
    },
    ...config,
    mutationFn: createTemplate,
  });
};
