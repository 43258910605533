import CommentThread from "@/components/AdvancedEditor/plugins/CommentPlugin/CommentThread";
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useEditorStore } from "@/stores/editor";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Mock data for the example CommentThread
const mockThread = {
  comments: [
    {
      id: "1",
      server_id: 1,
      author: "SEO Expert",
      content:
        "Consider adding more keywords related to 'organic traffic' to improve topic score.",
      timeStamp: new Date(Date.now() - 5 * 60 * 1000).toISOString(),
    },
    {
      id: "2",
      server_id: 2,
      author: "Content Strategist",
      content:
        "The current paragraph is great, but it could benefit from a call-to-action at the end.",
      timeStamp: new Date().toISOString(),
    },
  ],
};

export const CommentingUpgradeDialog = ({ onClose }) => {
  const [open, setOpen] = useState(true);
  const [hasTracked, setHasTracked] = useState(false);
  const trackEvent = useTrackEvent();
  const navigate = useNavigate();
  const { editor } = useEditorStore();

  const close = () => {
    setOpen(false);
  };

  const handleTrackCommentingUpgradePopup = async () => {
    if (!hasTracked) {
      trackEvent.mutate({
        event: "commenting_upgrade_popup",
        properties: JSON.stringify({}),
      });
      setHasTracked(true);
    }
  };

  if (open) {
    handleTrackCommentingUpgradePopup();
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose(close);
        }
      }}
    >
      <DialogPortal>
        <DialogContent
          className="z-[51] w-[600px] flex flex-col h-fit gap-4 rounded-lg border dark:border-zinc-700 bg-white dark:bg-zinc-800 shadow-2xl"
          variant="custom"
        >
          <div className="flex flex-col justify-center border-b rounded-tr-lg dark:border-b-zinc-600">
            <div className="relative h-[350px] w-full flex flex-col  pt-4 pb-12 px-6 rounded-t-lg bg-solid-page-gradient">
              <p className="pointer-events-none mt-8 leading-6 [&:not(:first-child)]:mt-3 mb-3 text-base font-normal text-white  break-words whitespace-pre-wrap bg-gradient-to-b from-transparent to-white dark:to-zinc-800">
                <mark className="bg-amber-400/50 dark:bg-amber-400/50 mr-1 text-white">
                  SEO is a critical<span className="cursor">|</span>part of any
                  website.
                </mark>
                It helps you drive more organic traffic to your site. Here is
                some additional text that will fade out towards the bottom of
                this paragraph to create a background effect.
              </p>
              <CommentThread
                editor={editor?.instance}
                cancelAddComment={() => {}}
                submitAddComment={() => {}}
                submitDeleteComment={() => {}}
                submitEditComment={() => {}}
                userFullName="John Doe"
                thread={mockThread}
                documentHash="sampleHash"
                className="absolute flex flex-col mt-16 ml-20 pointer-events-none"
              />

              <DialogCloseButton
                className="absolute top-4 right-4"
                close={() => {
                  onClose(close);
                }}
              />
            </div>
          </div>
          <DialogHeader className="flex items-center space-x-4 pl-6 pb-0 pt-2">
            <DialogTitle className="text-2xl text-zinc-900 dark:text-white font-semibold">
              Unlock commenting
            </DialogTitle>
          </DialogHeader>
          <DialogDescription className="px-6 pb-0">
            <p className="text-sm text-zinc-900 dark:text-white">
              Upgrade to the Basic or Team Plan to add comments and collaborate
              with your team.
            </p>
          </DialogDescription>

          <DialogFooter className="px-6 pb-4 pt-0 flex items-center rounded-bl-md rounded-br-md bg-white dark:bg-zinc-800">
            <div className="flex items-center space-x-2">
              <Button
                variant="primaryBlur"
                size="sm"
                className="bg-emerald-100"
                onClick={() => {
                  navigate("/app/settings/plans");
                  onClose(close);
                }}
              >
                Upgrade plan
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
