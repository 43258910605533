export const WizardWrapper = ({
  steps,
  currentStep,
  navigate,
  fraseDocument,
  title,
  setModifiedHeadings,
  ...rest
}) => {
  return steps.map((step, index) => {
    if (currentStep === step.id) {
      const StepComponent = step.component;
      return (
        <StepComponent
          key={index}
          navigate={navigate}
          fraseDocument={fraseDocument}
          title={title}
          setModifiedHeadings={setModifiedHeadings}
          {...rest}
        />
      );
    }
    return null;
  });
};
