import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";

const verifyEmail = async (hash: string) => {
  return axios.post(`/verification`, {
    hash,
  });
};

export const useVerifyEmail = () => {
  const queryClient = useQueryClient();

  return useMutation(verifyEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
  });
};
