import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useDocumentStore } from "@/stores/document";
import { useQuery } from "react-query";
import { FraseDocument } from "../types";

export const getDocumentById = ({
  documentId,
}: {
  documentId: string;
}): Promise<FraseDocument> => {
  return axios.post(`/getDocumentById`, {
    id: documentId,
  });
};

type QueryFnType = typeof getDocumentById;

type UseGetDocumentByIdOptions = {
  documentId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetDocumentById = ({
  documentId,
  config,
}: UseGetDocumentByIdOptions) => {
  const { setDocument } = useDocumentStore();

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["documentById", documentId],
    queryFn: () => getDocumentById({ documentId }),
    onSuccess: (document) => {
      setDocument(document);
    },
  });
};
