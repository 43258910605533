import { Button } from "@/components/Elements";
import { FraseDocument } from "@/features/documents";
import { ImportContentUrlButton } from "@/features/editor/components/ImportContentUrlButton";
import { useState } from "react";
import { TbPencil } from "react-icons/tb";

export const ImportUrlBadgePlugin = ({
  fraseDocument,
  setShowPlaceholder,
}: {
  fraseDocument: FraseDocument;
  setShowPlaceholder: (showPlaceholder: boolean) => void;
}) => {
  const [importUrlDialogOpen, setImportUrlDialogOpen] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleEditClick = () => {
    setImportUrlDialogOpen(true);
  };

  if (fraseDocument.metadata?.url) {
    return (
      <div className="flex items-center">
        <ImportContentUrlButton
          setCustomUrls={() => {}}
          open={importUrlDialogOpen}
          setOpen={setImportUrlDialogOpen}
          shouldSubmit={shouldSubmit}
          onSubmission={() => setShouldSubmit(false)}
          setShowPlaceholders={setShowPlaceholder}
          url={fraseDocument.metadata?.url}
          trigger={
            <Button
              variant="buttonIcon"
              buttonIcon={<TbPencil />}
              className="z-0"
              size="md"
              tooltipContent="Edit URL"
              onClick={handleEditClick}
            ></Button>
          }
        />
        <a
          href={fraseDocument.metadata?.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-xs text-zinc-500 px-1 hover:underline"
        >
          {fraseDocument.metadata?.url}
        </a>
      </div>
    );
  }

  return null;
};
