import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/solid";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useEffect, useState } from "react";
import { CommandItem } from "../../../components/Elements";

export const SubmitTicketButton = ({
  triggerButton,
}: {
  triggerButton?: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (window.hbspt) {
      // Load HubSpot form
      window.hbspt.forms.create({
        portalId: "6359064",
        formId: "0840b1ce-15fd-4436-a421-bc2216537074",
        target: "#ticket-form",
      });
    } else {
      // If hbspt is not available, load the HubSpot script
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "6359064",
            formId: "0840b1ce-15fd-4436-a421-bc2216537074",
            target: "#ticket-form",
          });
        }
      });
    }
  }, [open]);

  const dialogTriggerButton = triggerButton || (
    <CommandItem
      className="w-full"
      onSelect={(event) => {
        setOpen(true);
      }}
    >
      <ChatBubbleLeftIcon className="w-3 h-3 mr-2.5" />
      Contact Support
    </CommandItem>
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{dialogTriggerButton}</DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Contact support</DialogTitle>
            <DialogCloseButton close={() => setOpen(false)} />
          </DialogHeader>
          <DialogDescription className="px-4">
            <div id="ticket-form"></div>
          </DialogDescription>
          <DialogFooter className="px-4"></DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
