import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
} from "@/components/Elements";
import { QuestionAsset } from "@/components/Elements/Asset/QuestionAsset";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { useRedditSearchResults } from "@/features/documents/api/getRedditSearchResults";
import {
  useHandlePasteCitation,
  useHandlePasteContent,
} from "@/features/documents/utils/pasting";
import {
  getQuoraQuery,
  getRedditQuery,
} from "@/features/documents/utils/questions";
import { useQuoraSearchResults } from "@/features/serp/api/getQuoraSearchResults";
import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { useCallback, useMemo, useState } from "react";
import { TbChevronDown } from "react-icons/tb";

const panels = [
  {
    value: "serp",
    title: "SERP",
  },
  {
    value: "peopleAlsoAsk",
    title: "People Also Ask",
  },
  {
    value: "quora",
    title: "Quora",
  },
  {
    value: "reddit",
    title: "Reddit",
  },
];

export const VirtualizedList = ({ items, renderItem, parentRef }) => {
  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
  });

  if (items.length === 0) {
    return <EmptyContent />;
  }

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <div
      ref={parentRef}
      className="h-[calc(100vh-168px)] overflow-auto"
      style={{ contain: "strict" }}
    >
      <ul
        style={{
          height: rowVirtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map((virtualRow) => (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={rowVirtualizer.measureElement}
              className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
            >
              {renderItem(items[virtualRow.index], virtualRow.index)}
            </div>
          ))}
        </div>
      </ul>
    </div>
  );
};

const Serp = React.memo(
  ({ filter, handlePasteContent, handlePasteCitation }) => {
    const { document } = useDocumentStore();
    const { serp } = useSerpStore();
    const docId = document.id;
    const currentSerp = serp[docId] || {
      questions: {
        serp: [],
        people_also_ask: [],
        quora: [],
        reddit: [],
      },
    };
    const serpQuestions = useMemo(
      () => (currentSerp.questions && currentSerp.questions.serp) || [],
      [currentSerp]
    );
    const filteredQuestions = useMemo(
      () =>
        serpQuestions.filter((item) =>
          item.header.toLowerCase().includes(filter.toLowerCase())
        ),
      [serpQuestions, filter]
    );

    const parentRef = React.useRef();

    return (
      <VirtualizedList
        items={filteredQuestions}
        parentRef={parentRef}
        renderItem={(asset) => (
          <QuestionAsset
            url={asset.url}
            headerText={asset.header}
            headerType={asset.header_tag}
            html={asset.html}
            assetText={asset.text}
            handlePasteContent={handlePasteContent}
            handlePasteCitation={handlePasteCitation}
          />
        )}
      />
    );
  }
);

export const PeopleAlsoAsk = ({
  filter = "",
  renderEmptyContent = true,
  handlePasteContent,
}) => {
  const { document } = useDocumentStore();
  const { serp } = useSerpStore();
  const docId = document.id;
  const currentSerp = serp[docId] || {
    questions: {
      serp: [],
      people_also_ask: [],
      quora: [],
      reddit: [],
    },
  };
  const questions = currentSerp.peopleAlsoAsk || [];

  const filteredQuestions = questions
    .map((item) => (item ? item.replace(" ?", "?") : ""))
    .filter(
      (item) => item && item.toLowerCase().includes(filter.toLowerCase())
    );

  if (filteredQuestions.length === 0) {
    if (!renderEmptyContent) {
      return null;
    }

    return <EmptyContent />;
  }

  return (
    <ul className="grid">
      {filteredQuestions.map((item, index) => {
        return (
          <li key={index}>
            <QuestionAsset
              key={index}
              headerText={item}
              assetText={[]}
              collapseDisabled
              handlePasteContent={handlePasteContent}
              handlePasteCitation={() => {}}
            />
          </li>
        );
      })}
    </ul>
  );
};

function Quora({ filter, handlePasteContent, handlePasteCitation }) {
  const { document } = useDocumentStore();
  const queries = getQuoraQuery(
    document.query,
    document.metadata.lang_code,
    document.metadata.code
  );
  const quoraSearchQuery = useQuoraSearchResults({
    queries,
  });

  const parentRef = React.useRef();

  if (quoraSearchQuery.isLoading) {
    return <LoadingContent />;
  }

  const quoraSearchResults = quoraSearchQuery.data || [];
  const filteredResults = quoraSearchResults.filter(
    (item) =>
      item &&
      item.question &&
      item.question.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <VirtualizedList
      items={filteredResults}
      parentRef={parentRef}
      renderItem={(item) => (
        <QuestionAsset
          headerText={item.question}
          url={item.url}
          assetText={[]}
          handlePasteContent={handlePasteContent}
          handlePasteCitation={handlePasteCitation}
          collapseDisabled
        />
      )}
    />
  );
}

function Reddit({ filter, handlePasteContent, handlePasteCitation }) {
  const { document } = useDocumentStore();
  const queries = getRedditQuery(document.query, document.metadata.lang_code);
  const redditSearchQuery = useRedditSearchResults({
    queries,
  });

  const parentRef = React.useRef();

  if (redditSearchQuery.isLoading) {
    return <LoadingContent />;
  }

  const redditSearchResults = redditSearchQuery.data;
  const filteredResults = Object.entries(redditSearchResults).reduce(
    (acc, [title, questions]) => {
      const filteredQuestions = questions.filter((item) =>
        item.question.toLowerCase().includes(filter.toLowerCase())
      );
      if (filteredQuestions.length > 0) {
        acc[title] = filteredQuestions;
      }
      return acc;
    },
    {}
  );

  return (
    <VirtualizedList
      items={Object.entries(filteredResults).flatMap(([title, questions]) =>
        questions.map((item) => ({ ...item, title }))
      )}
      parentRef={parentRef}
      renderItem={(item) => (
        <QuestionAsset
          url={item.url}
          headerText={item.question}
          assetText={[]}
          collapseDisabled={true}
          handlePasteContent={handlePasteContent}
          handlePasteCitation={handlePasteCitation}
        />
      )}
    />
  );
}

function LoadingContent() {
  return (
    <div className="flex items-center justify-center w-full h-full mt-8">
      <LogoSpinner variant="md" loadingText="Loading results..." />
    </div>
  );
}

function EmptyContent() {
  return (
    <div className="flex items-center justify-center w-full h-full mt-4">
      <p className="text-sm text-gray-500">No results found</p>
    </div>
  );
}

function Questions() {
  const [activePanel, setActivePanel] = useState(panels[0].value);
  const [filter, setFilter] = useState("");
  const handlePasteContent = useHandlePasteContent();
  const handlePasteCitation = useHandlePasteCitation();

  const handlePanelSelect = useCallback((value) => {
    setActivePanel(value);
  }, []);

  const renderPanel = useCallback(() => {
    switch (activePanel) {
      case "serp":
        return (
          <Serp
            filter={filter}
            handlePasteContent={handlePasteContent}
            handlePasteCitation={handlePasteCitation}
          />
        );
      case "peopleAlsoAsk":
        return (
          <PeopleAlsoAsk
            filter={filter}
            handlePasteContent={handlePasteContent}
            handlePasteCitation={handlePasteCitation}
          />
        );
      case "quora":
        return (
          <Quora
            filter={filter}
            handlePasteContent={handlePasteContent}
            handlePasteCitation={handlePasteCitation}
          />
        );
      case "reddit":
        return (
          <Reddit
            filter={filter}
            handlePasteContent={handlePasteContent}
            handlePasteCitation={handlePasteCitation}
          />
        );
      default:
        return null;
    }
  }, [activePanel, filter, handlePasteContent, handlePasteCitation]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center px-4 my-2 space-x-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outlineBlur"
              size="xs"
              labelClassName="text-2xs mr-2 px-2"
              className="font-medium flex-shrink-0"
              textClassName="text-2xs font-base text-zinc-900 dark:text-white !px-0 whitespace-nowrap"
              endIcon={<TbChevronDown />}
              tooltipContent="Select tab"
              tooltipDirection="bottom"
            >
              {panels.find((panel) => panel.value === activePanel)?.title}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {panels.map((panel) => (
              <DropdownMenuItem
                key={panel.value}
                onSelect={() => handlePanelSelect(panel.value)}
              >
                {panel.title}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          value={filter}
          className="text-xs"
          onChange={(value) => setFilter(value)}
          placeholder="Filter by keyword..."
        />
      </div>
      <ul className="pl-4 pr-1 grid">{renderPanel()}</ul>
    </div>
  );
}

export default Questions;
