import * as React from "react";

import { Button } from "@/components/Elements/Button";
import { cn } from "@/utils/style";
import { Skeleton } from "../Skeleton";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onChange?: (val: string) => void;
  firstIcon?: React.ReactElement;
  firstButtonAction?: () => void;
  firstButtonDisabled?: boolean;
  firstButtonClassName?: string;
  firstButtonVariant?: string;
  showFirstButtonOnHover?: boolean;
  secondIcon?: React.ReactElement;
  secondButtonAction?: () => void;
  secondButtonDisabled?: boolean;
  secondButtonClassName?: string;
  showSecondButtonOnHover?: boolean;
  secondButtonVariant?: string;
  secondButtonText?: string;
  secondButtonTextClassName?: string;
  secondButtonIsLoading?: boolean;
  startIcon?: React.ReactElement;
  startIconClassName?: string;
  containerClassName?: string;
  isLoading?: boolean;
}

const InputLabel = ({
  label,
  className,
}: {
  label?: string;
  className?: string;
}) => {
  return (
    <label
      className={cn(
        "block text-sm font-medium text-zinc-500 dark:text-zinc-400 mb-1",
        className
      )}
    >
      {label}
    </label>
  );
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      containerClassName,
      label,
      onChange,
      type,
      startIcon,
      startIconClassName,
      firstIcon,
      firstButtonAction,
      firstButtonDisabled,
      firstButtonClassName,
      firstButtonVariant = "floatingIcon",
      firstButtonTooltip,
      showFirstButtonOnHover = true,
      secondIcon,
      secondButtonAction,
      secondButtonDisabled,
      secondButtonClassName,
      secondButtonVariant = "floatingIcon",
      secondButtonText,
      secondButtonTextClassName,
      secondButtonIsLoading,
      secondButtonTooltip,
      showSecondButtonOnHover = true,
      isLoading = false,
      ...props
    },
    ref
  ) => {
    startIcon =
      startIcon &&
      React.cloneElement(startIcon, {
        className: cn(
          "h-3 w-3",
          props.children && "mr-1",
          "text-zinc-500",
          startIconClassName,
          startIcon.props.className
        ),
      });

    if (isLoading) {
      return <Skeleton className="h-8 w-full rounded-md" />;
    }

    return (
      <div className={cn("w-full", containerClassName)}>
        {label && <InputLabel label={label} />}
        <div className={cn("relative group", containerClassName)}>
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
            {startIcon}
          </div>
          <input
            type={type}
            className={cn(
              "h-8 w-full items-center rounded-md truncate bg-white pl-[26px] pr-3 text-sm text-zinc-900 ring-1 ring-inset ring-zinc-900/7.5 transition dark:bg-zinc-900 dark:text-white dark:ring-inset disabled:cursor-not-allowed disabled:opacity-50 hover:ring-zinc-300 dark:ring-white/10 dark:hover:ring-white/20 lg:flex focus:[&:not(:focus-visible)]:outline-none focus:outline-none file:-mx-3 file:-my-2 file:overflow-hidden file:bg-zinc-100 dark:file:bg-zinc-700 file:px-2.5 file:outline-none file:border-none file:py-3 file:text-zinc-700 dark:file:text-zinc-100 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] border-none !outline-none focus:ring-emerald-600/50 focus:ring-inset",
              !startIcon ? "pl-3" : "pl-8",
              type === "file" &&
                "cursor-pointer file:cursor-pointer text-zinc-500 font-normal file:font-medium file:text-zinc-500 hover:bg-zinc-100 file:hover:bg-zinc-200 dark:hover:bg-zinc-800 dark:file:hover:bg-zinc-600",
              className
            )}
            onChange={(e) => {
              type === "file"
                ? onChange(e.target.files)
                : onChange(e.target.value);
            }}
            ref={ref}
            {...props}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-1.5 space-x-1">
            {firstIcon && (
              <Button
                disabled={firstButtonDisabled}
                variant={firstButtonVariant}
                buttonIcon={firstIcon}
                onClick={firstButtonAction}
                className={cn(
                  showFirstButtonOnHover && "group-hover:visible invisible",
                  firstButtonClassName,
                  "opacity-100"
                )}
                tooltipContent={firstButtonTooltip}
              />
            )}
            {(secondIcon || secondButtonText) && (
              <Button
                disabled={secondButtonDisabled}
                variant={secondButtonVariant}
                isLoading={secondButtonIsLoading}
                buttonIcon={secondIcon}
                onClick={secondButtonAction}
                textClassName={secondButtonTextClassName}
                className={cn(
                  showSecondButtonOnHover && "group-hover:visible invisible",
                  secondButtonClassName
                )}
                tooltipContent={secondButtonTooltip}
              >
                {secondButtonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input, InputLabel };
