import { CommandItem } from "@/components/Elements";
import { SunIcon } from "@heroicons/react/24/solid";

export function ModeToggle() {
  function disableTransitionsTemporarily() {
    document.documentElement.classList.add("[&_*]:!transition-none");
    window.setTimeout(() => {
      document.documentElement.classList.remove("[&_*]:!transition-none");
    }, 0);
  }

  function toggleMode() {
    disableTransitionsTemporarily();

    let isDarkMode = document.documentElement.classList.toggle("dark");
    window.localStorage.isDarkMode = isDarkMode;
  }

  return (
    <CommandItem onSelect={toggleMode}>
      <SunIcon className="w-4 h-4 mr-2" />
      Toggle Mode
    </CommandItem>
  );
}
