import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";

type SendForgotPasswordEmailPayload = {
  email: string;
};

export const sendForgotPasswordEmail = ({
  email,
}: SendForgotPasswordEmailPayload): Promise<any> => {
  return axios.post(`/forgetpassword`, { email });
};

export const useSendForgotPasswordEmail = () => {
  return useMutation(sendForgotPasswordEmail);
};
