import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  FraseBot,
  Input,
} from "@/components/Elements";
import { Card } from "@/components/Elements/Card";
import { Skeleton } from "@/components/Elements/Skeleton/Skeleton";
import { useUser } from "@/features/auth";
import { Template } from "@/features/templates";
import { cn, usernameToColor } from "@/utils/style";
import { useMemo, useState } from "react";
import {
  TbArrowLeft,
  TbBookmark,
  TbBookmarkFilled,
  TbChevronDown,
  TbChevronUp,
  TbPlus,
  TbSearch,
  TbUserFilled,
} from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { deleteBookmarkedTemplate } from "../api/deleteAiToolBookmark";
import { useGetAiToolBookmarks } from "../api/getAiToolBookmarks";
import { useUpdateAiToolBookmark } from "../api/updateAiToolBookmark";
import { isValidTemplate } from "../utils/isValidAiTool";
import { NewAiToolButtonAction } from "./NewAiToolButton";

export const DraftBadge = () => (
  <span className="ml-2 text-zinc-600 dark:text-zinc-400 bg-zinc-100 dark:bg-zinc-800 rounded-md px-1.5 py-[4px] text-[11px] leading-none self-center">
    Draft
  </span>
);

export const ReadyBadge = () => (
  <span className="ml-2 text-emerald-600 dark:text-emerald-400 bg-emerald-100 dark:bg-emerald-800 rounded-md px-1.5 py-[4px] text-[11px] leading-none self-center">
    Ready
  </span>
);

const AiToolsGrid = ({
  templates,
  selectedType,
  setSelectedAuthorHash,
  setSelectedAuthorName,
  selectedAuthorName,
  selectedAuthorHash,
  isLoadingTemplates,
}: {
  templates: Template[] | undefined;
  selectedType: string;
  setSelectedAuthorHash: (hash: string | null) => void;
  setSelectedAuthorName: (name: string | null) => void;
  selectedAuthorName: string | null;
  selectedAuthorHash: string | null;
  isLoadingTemplates: boolean;
}) => {
  const navigate = useNavigate();
  const { data: user, isLoading: isLoadingUser } = useUser();
  const { data: bookmarks, isLoading: isLoadingBookmarks } =
    useGetAiToolBookmarks({});
  const [bookmarkedTemplates, setBookmarkedTemplates] = useState<Template[]>(
    []
  );
  const updateBookmark = useUpdateAiToolBookmark();
  const [searchQuery, setSearchQuery] = useState("");
  const [showAll, setShowAll] = useState<{ [key: string]: boolean }>({});
  const isLoading = isLoadingUser || isLoadingBookmarks || isLoadingTemplates;

  useMemo(() => {
    if (bookmarks) {
      setBookmarkedTemplates(bookmarks);
    }
  }, [bookmarks]);

  const handleBookmark = (template: Template) => {
    const isBookmarked = bookmarkedTemplates.some((t) => t.id === template.id);
    if (isBookmarked) {
      setBookmarkedTemplates((prev) =>
        prev.filter((t) => t.id !== template.id)
      );
      deleteBookmarkedTemplate(template.hash).catch(() => {
        setBookmarkedTemplates((prev) => [...prev, template]);
      });
    } else {
      setBookmarkedTemplates((prev) => [...prev, template]);
      updateBookmark.mutate(template.hash, {
        onError: () => {
          setBookmarkedTemplates((prev) =>
            prev.filter((t) => t.id !== template.id)
          );
        },
      });
    }
  };

  const filterAndSortTemplates = (
    filterFn: (template: Template) => boolean
  ) => {
    if (!templates) return [];
    return templates
      .filter(filterFn)
      .filter((template) =>
        template.text[0].title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        const aBookmarked = bookmarkedTemplates.some((t) => t.id === a.id);
        const bBookmarked = bookmarkedTemplates.some((t) => t.id === b.id);
        if (aBookmarked && !bBookmarked) return -1;
        if (!aBookmarked && bBookmarked) return 1;
        return 0;
      });
  };

  const recentDrafts = useMemo(
    () =>
      filterAndSortTemplates(
        (template) =>
          !isValidTemplate(template) && template.email === user?.username
      ),
    [templates, bookmarkedTemplates, searchQuery, user?.username]
  );
  const createdTemplates = useMemo(
    () => filterAndSortTemplates((template) => template.org_id === user?.orgId),
    [templates, bookmarkedTemplates, searchQuery, user?.username, user?.orgId]
  );

  const fraseTemplates = useMemo(
    () =>
      filterAndSortTemplates((template) =>
        template.template_author.includes("Frase")
      ),
    [templates, bookmarkedTemplates, searchQuery]
  );
  const communityTemplates = useMemo(
    () =>
      filterAndSortTemplates(
        (template) => template.template_author !== "Frase Templates"
      ),
    [templates, bookmarkedTemplates, searchQuery]
  );
  const bookmarkedTools = useMemo(
    () =>
      filterAndSortTemplates((template) =>
        bookmarkedTemplates.some((t) => t.id === template.id)
      ),
    [templates, bookmarkedTemplates, searchQuery]
  );

  const getCommunityAuthors = (templates: Template[]) => {
    const map: { [key: string]: any } = {};
    templates.forEach((template) => {
      if (!map[template.template_author]) {
        map[template.template_author] = {
          ...template,
          template_count: 0,
          hit_count: 0,
          bookmark_count: 0,
          upvote_count: 0,
          user_hash: template.template_author_hash,
          author_name: template.template_author,
        };
      }
      map[template.template_author].hit_count += template.hit_count;
      map[template.template_author].bookmark_count += template.bookmark_count;
      map[template.template_author].upvote_count += template.upvote_count;
      map[template.template_author].template_count += 1;
    });

    const list = Object.values(map).filter(
      (author) => author.template_author !== "Frase Templates"
    );
    return list.sort((a, b) => b.upvote_count - a.upvote_count).slice(0, 5);
  };

  const communityAuthors = useMemo(
    () => getCommunityAuthors(communityTemplates),
    [communityTemplates]
  );

  if (!templates) {
    return (
      <div className="grid grid-cols-4 sm:grid-cols-4 px-4 pt-2 gap-4 overflow-y-scroll max-h-screen pb-40">
        {Array.from({ length: 20 }, (_, index) => (
          <Skeleton key={index} className="h-40 w-full" />
        ))}
      </div>
    );
  }

  const renderSection = (
    title: string,
    templates: Template[],
    sectionKey: string,
    isLoading: boolean,
    isLastSection: boolean = false
  ) => {
    const displayTemplates =
      isLastSection || showAll[sectionKey] ? templates : templates.slice(0, 8);

    const showViewAllButton = !isLastSection && templates.length > 8;

    return (
      <div className="w-full first:pt-6">
        {displayTemplates.length > 0 && (
          <div className="flex items-center pl-5 space-x-2">
            <h2 className="text-sm font-medium dark:text-white">{title}</h2>
          </div>
        )}
        <div
          className={cn(
            "grid grid-cols-4 lg:grid-cols-2 xl:grid-cols-4 px-4 gap-x-4 gap-y-2 bg-zinc-50 dark:bg-zinc-900",
            isLastSection ? "pb-40" : ""
          )}
        >
          {isLoading
            ? Array.from({ length: 8 }, (_, index) => (
                <Skeleton key={index} className="h-40 w-full" />
              ))
            : displayTemplates.length > 0 &&
              displayTemplates.map((template) => {
                const isBookmarked = bookmarkedTemplates.some(
                  (t) => t.id === template.id
                );
                const isValid = isValidTemplate(template);

                return (
                  <Card
                    key={template.hash}
                    onClick={() => {
                      if (!isValid) {
                        navigate(`/app/ai-tools/new/${template.id}`);
                      } else {
                        navigate(`/app/ai-tools/${template.hash}`, {
                          state: { isBookmarked },
                        });
                      }
                    }}
                    className={cn(
                      "flex flex-col w-full h-36 min-h-36 cursor-pointer relative group"
                    )}
                  >
                    <div className="flex justify-between items-start pl-2 pr-1 pt-1">
                      <h3 className="text-xs font-medium flex-1 line-clamp-2">
                        {template.text[0].title || ""}
                        {!isValid && <DraftBadge />}
                      </h3>
                      <Button
                        variant="buttonNodeIcon"
                        size="sm"
                        className={cn(
                          "py-1 px-1 z-10 invisible group-hover:visible",
                          isBookmarked
                            ? "text-emerald-600 fill-emerald-600 bg-emerald-600 visible"
                            : "text-zinc-500"
                        )}
                        buttonIcon={
                          isBookmarked ? (
                            <TbBookmarkFilled className="w-3 h-3" />
                          ) : (
                            <TbBookmark className="w-3 h-3" />
                          )
                        }
                        onClick={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          e.stopPropagation();
                          handleBookmark(template);
                        }}
                      ></Button>
                    </div>
                    <div className="flex flex-col px-2 py-1 justify-between w-full h-full">
                      <div className="flex flex-col space-y-2">
                        <p className="dark:text-zinc-400 text-zinc-600 text-2xs line-clamp-2">
                          {template.metadata.template_description}
                        </p>
                      </div>

                      <div className="flex items-center flex-wrap w-full gap-y-2 gap-x-2">
                        <div className="flex items-center w-full space-x-2">
                          <div className="flex items-center space-x-2">
                            <Avatar className="w-4 h-4">
                              <AvatarImage src="" />
                              <AvatarFallback
                                className={cn(
                                  "text-white font-semibold text-4xs",
                                  usernameToColor(template.template_author)
                                )}
                              >
                                {template.template_author
                                  .split(" ")
                                  .map((name) => name[0])
                                  .slice(0, 2)
                                  .join("")}
                              </AvatarFallback>
                            </Avatar>
                            <div className="flex self-start items-center">
                              <span className="text-2xs text-zinc-600 dark:text-white truncate group-hover:text-zinc-900 dark:group-hover:text-white">
                                {template.template_author === "Frase Templates"
                                  ? "Frase"
                                  : `${
                                      template.template_author.split(" ")[0]
                                    } ${
                                      template.template_author.split(
                                        " "
                                      )[1]?.[0] || ""
                                    }.`}
                              </span>
                            </div>
                          </div>
                          {template.metadata.template_tags && (
                            <div className="flex items-center space-x-2 overflow-hidden">
                              {template.metadata.template_tags
                                .split(",")
                                .slice(0, 2)
                                .map((tag) => (
                                  <span className="flex items-center flex-shrink-0 text-zinc-600 dark:text-zinc-400 ring-1 ring-inset ring-zinc-200 dark:ring-zinc-800 rounded-md px-1.5 py-[5px] text-[11px] leading-none self-center break-words">
                                    {tag[0].toUpperCase() + tag.slice(1)}
                                  </span>
                                ))}
                              {template.metadata.template_tags.split(",")
                                .length > 2 && (
                                <span className="flex items-center flex-shrink-0 text-zinc-600 dark:text-zinc-400 ring-1 ring-inset ring-zinc-200 dark:ring-zinc-800 rounded-md px-1.5 py-[5px] text-[11px] leading-none self-center break-words">
                                  +
                                  {template.metadata.template_tags.split(",")
                                    .length - 2}
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })}
        </div>
        {showViewAllButton && (
          <div className="flex pl-4 pt-2">
            <Button
              variant="text"
              startIcon={
                showAll[sectionKey] ? <TbChevronUp /> : <TbChevronDown />
              }
              size="2xs"
              onClick={() =>
                setShowAll((prev) => ({
                  ...prev,
                  [sectionKey]: !prev[sectionKey],
                }))
              }
            >
              {showAll[sectionKey] ? "Show Less" : "View All"}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full overflow-y-scroll max-h-screen bg-zinc-50 dark:bg-zinc-900 p-4 pb-40">
      <div className="grid grid-cols-4 lg:grid-cols-2 xl:grid-cols-4 px-4 gap-x-4 gap-y-2 bg-zinc-50 dark:bg-zinc-900">
        <Input
          value={searchQuery}
          onChange={(value) => setSearchQuery(value)}
          placeholder="Filter by name..."
          startIcon={
            <TbSearch className="text-zinc-500 dark:text-zinc-400 h-3 w-3" />
          }
          type="text"
          startIconClassName="ml-2"
          className="w-full text-xs"
        />
      </div>
      <div className="flex flex-col w-full space-y-6 h-full">
        {selectedType === "featured" &&
          bookmarkedTools.length > 0 &&
          renderSection(
            "Bookmarked Tools",
            bookmarkedTools,
            "bookmarkedTools",
            isLoading,
            false
          )}
        {selectedType === "created" &&
          recentDrafts.length > 0 &&
          renderSection(
            "Recent Drafts",
            recentDrafts,
            "recentDrafts",
            isLoading,
            false
          )}
        {(selectedType === "featured" || selectedType === "created") &&
          createdTemplates.length > 0 &&
          renderSection(
            "Your Tools",
            createdTemplates,
            "createdTemplates",
            isLoading,
            selectedType === "created" ? true : false
          )}
        {selectedType === "featured" && fraseTemplates.length > 0
          ? renderSection(
              "Frase Tools",
              fraseTemplates,
              "fraseTemplates",
              isLoading,
              true
            )
          : null}
        {selectedType === "community" && !selectedAuthorHash ? (
          <div className="pt-6 w-full">
            <div className="flex items-center pl-5 space-x-2">
              <h2 className="text-sm font-medium dark:text-white">
                Top Community Authors
              </h2>
            </div>
            <div className="grid grid-cols-4 lg:grid-cols-2 xl:grid-cols-4 px-4 gap-x-4 gap-y-2 bg-zinc-50 dark:bg-zinc-900">
              {communityAuthors.map((author) => (
                <Card
                  key={author.template_author}
                  className="flex flex-col w-full cursor-pointer relative group"
                  onClick={() => {
                    setSelectedAuthorHash(author.user_hash);
                    setSelectedAuthorName(author.author_name);
                  }}
                >
                  <div className="flex justify-between items-center pl-2 pr-1 pt-1 space-x-2">
                    <TbUserFilled className="text-zinc-500" />
                    <h3 className="text-xs font-medium flex-1 line-clamp-2">
                      {author.template_author}
                    </h3>
                  </div>
                  <div className="flex flex-col px-2 py-1 justify-between w-full h-full">
                    <div className="flex flex-col space-y-2">
                      <p className="dark:text-zinc-400 text-zinc-600 text-2xs line-clamp-2">
                        Templates: {author.template_count}, Upvotes:{" "}
                        {author.upvote_count}
                      </p>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        ) : null}
        {selectedType === "community" && selectedAuthorHash ? (
          <div className="pt-6 w-full h-screen pb-40">
            <div className="flex items-center pl-5 space-x-2">
              <Button
                variant="buttonIcon"
                size="xs"
                buttonIcon={<TbArrowLeft />}
                onClick={() => {
                  setSelectedAuthorHash(null);
                  setSelectedAuthorName(null);
                }}
              ></Button>
              <h2 className="text-sm font-medium dark:text-white">
                {selectedAuthorName}'s Templates
              </h2>
            </div>
            {renderSection(
              ``,
              communityTemplates,
              "communityTemplates",
              isLoading
            )}
          </div>
        ) : null}
        {selectedType === "community" &&
          !selectedAuthorHash &&
          communityTemplates.length > 0 &&
          renderSection(
            "Community Tools",
            communityTemplates,
            "communityTemplates",
            isLoading,
            true
          )}
        {selectedType === "created" &&
          templates.length === 0 &&
          !isLoadingTemplates && (
            <div className="flex flex-col space-y-4 justify-center items-center h-full w-full">
              <div className="bg-white dark:bg-zinc-800 rounded-md p-12 shadow-glow max-w-lg border space-y-4 dark:border-zinc-700">
                <FraseBot />
                <div className="space-y-2">
                  <h1 className="text-lg font-medium dark:text-white">
                    AI Tools
                  </h1>
                  <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                    Create AI tools to automate your content creation workflow.
                    Bookmark your favorite tools to access them at any time.
                  </p>
                  <div>
                    <NewAiToolButtonAction
                      size="xs"
                      className="w-30"
                      startIcon={<TbPlus />}
                      buttonVariant="primaryBlur"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default AiToolsGrid;
