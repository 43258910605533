import { Navigate, Route, Routes } from "react-router-dom";

import { ActivateExtension } from "./ActivateExtension";

export const ExtensionRoutes = () => {
  return (
    <Routes>
      <Route path="/activate" element={<ActivateExtension />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
