"use client";

import { cn } from "@/utils/style";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";
import { TbCheck } from "react-icons/tb";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-4 w-4 shrink-0 rounded border border-zinc-300 dark:border-zinc-600 group-hover:border-zinc-400 group-hover:dark:border-zinc-500 group-hover:dark:bg-zinc-800 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current transition")}
    >
      <TbCheck
        className={cn(
          "h-3.5 w-3.5 bg-emerald-600 transition-all stroke-white rounded-sm ease-in-out",
          props.checked ? "opacity-100" : "opacity-0"
        )}
        style={{
          strokeWidth: 2.25,
        }}
      />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
