import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

import { useDocumentStore } from "@/stores/document";
import { FraseDocument } from "../types";

export const getDocumentPreview = ({
  documentHash,
}: {
  documentHash: string;
}): Promise<FraseDocument> => {
  return axios.post(`/getDocumentPreviewByHash`, {
    doc_preview_hash: documentHash,
  });
};

type QueryFnType = typeof getDocumentPreview;

type UseDocumentPreviewOptions = {
  documentHash: string;
  config?: QueryConfig<QueryFnType>;
};

export const useDocumentPreview = ({
  documentHash,
  config,
}: UseDocumentPreviewOptions) => {
  const { setDocument } = useDocumentStore();

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["documentPreview", documentHash],
    queryFn: () => getDocumentPreview({ documentHash }),
    onSuccess: (document) => {
      setDocument(document);
    },
  });
};
