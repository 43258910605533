import { useIsDarkMode } from "@/utils/isDarkMode";
import { $unwrapMarkNode } from "@lexical/mark";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createRangeSelection,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isTextNode,
  $nodesOfType,
  LexicalNode,
  RangeSelection,
  TextNode,
} from "lexical";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { FraseDocument } from "../../../../features/documents";
import { useEditorStore } from "../../../../stores/editor";
import { useTopicsStore } from "../../../../stores/topics";
import { $createMarkNode, $isMarkNode, MarkNode } from "../../nodes/MarkNode";
import { addClassNamesToElement, removeClassNamesFromElement } from "@lexical/utils";

const lightModeClassMap = {
  amber: "topic-highlight-light-amber",
  emerald: "topic-highlight-light-emerald",
  rose: "topic-highlight-light-rose",
  zinc: "topic-highlight-light-zinc",
};

const darkModeClassMap = {
  amber: "topic-highlight-dark-amber",
  emerald: "topic-highlight-dark-emerald",
  rose: "topic-highlight-dark-rose",
  zinc: "topic-highlight-dark-zinc",
};

const styles = `
  .topic-highlight-light-amber {
    background-color: #fef3c7;
    border-bottom: 2px solid #f59e0b;
    cursor: pointer;
  }
  .topic-highlight-light-emerald {
    background-color: #d1fae5;
    border-bottom: 2px solid #10b981;
    cursor: pointer;
  }
  .topic-highlight-light-rose {
    background-color: #fef2f2;
    border-bottom: 2px solid #f43f5e;
    cursor: pointer;
  }
  .topic-highlight-light-zinc {
    background-color: #f4f4f5;
    border-bottom: 2px solid #a1a1aa;
    cursor: pointer;
  }
  .topic-highlight-dark-amber {
    background-color: rgba(250, 204, 21, 0.4);
    border-bottom: 2px solid #f59e0b;
    cursor: pointer;
  }
  .topic-highlight-dark-emerald {
    background-color: rgba(6, 95, 70, 0.5);
    border-bottom: 2px solid #10b981;
    color: white;
    cursor: pointer;
  }
  .topic-highlight-dark-rose {
    background-color: rgba(159, 18, 57, 0.4);
    border-bottom: 2px solid #fb7185;
    cursor: pointer;
  }
  .topic-highlight-dark-zinc {
    background-color: #3f3f46;
    border-bottom: 2px solid #27272a;
    cursor: pointer;
  }
`;

declare global {
  interface HTMLElement {
    _cleanup?: () => void;
  }
}

const DEFAULT_DELAY_DURATION = 700;
const DEFAULT_SKIP_DELAY_DURATION = 300;

export function $wrapSelectionInMarkNode(
  selection: RangeSelection,
  isBackward: boolean,
  id: string,
  yourScore: number,
  competitorScore: number,
  createNode?: (ids: Array<string>) => MarkNode
): MarkNode | undefined {
  const nodes = selection.getNodes();
  const anchorOffset = selection.anchor.offset;
  const focusOffset = selection.focus.offset;
  const nodesLength = nodes.length;
  const startOffset = isBackward ? focusOffset : anchorOffset;
  const endOffset = isBackward ? anchorOffset : focusOffset;
  let currentNodeParent;
  let lastCreatedMarkNode;

  // We only want wrap adjacent text nodes, line break nodes
  // and inline element nodes. For decorator nodes and block
  // element nodes, we step out of their boundary and start
  // again after, if there are more nodes.
  for (let i = 0; i < nodesLength; i++) {
    const node = nodes[i];
    if (
      $isElementNode(lastCreatedMarkNode) &&
      lastCreatedMarkNode.isParentOf(node)
    ) {
      // If the current node is a child of the last created mark node, there is nothing to do here
      continue;
    }
    const isFirstNode = i === 0;
    const isLastNode = i === nodesLength - 1;
    let targetNode: LexicalNode | null = null;

    if ($isTextNode(node)) {
      // Case 1: The node is a text node and we can split it
      const textContentSize = node.getTextContentSize();
      const startTextOffset = isFirstNode ? startOffset : 0;
      const endTextOffset = isLastNode ? endOffset : textContentSize;
      if (startTextOffset === 0 && endTextOffset === 0) {
        continue;
      }
      const splitNodes = node.splitText(startTextOffset, endTextOffset);
      targetNode =
        splitNodes.length > 1 &&
        (splitNodes.length === 3 ||
          (isFirstNode && !isLastNode) ||
          endTextOffset === textContentSize)
          ? splitNodes[1]
          : splitNodes[0];
    } else if ($isMarkNode(node)) {
      // Case 2: the node is a mark node and we can ignore it as a target,
      // moving on to its children. Note that when we make a mark inside
      // another mark, it may utlimately be unnested by a call to
      // `registerNestedElementResolver<MarkNode>` somewhere else in the
      // codebase.

      continue;
    } else if ($isElementNode(node) && node.isInline()) {
      // Case 3: inline element nodes can be added in their entirety to the new
      // mark
      targetNode = node;
    }

    if (targetNode !== null) {
      // Now that we have a target node for wrapping with a mark, we can run
      // through special cases.
      if (targetNode && targetNode.is(currentNodeParent)) {
        // The current node is a child of the target node to be wrapped, there
        // is nothing to do here.
        continue;
      }
      const parentNode = targetNode.getParent();
      if (parentNode == null || !parentNode.is(currentNodeParent)) {
        // If the parent node is not the current node's parent node, we can
        // clear the last created mark node.
        lastCreatedMarkNode = undefined;
      }

      currentNodeParent = parentNode;

      if (lastCreatedMarkNode === undefined) {
        // If we don't have a created mark node, we can make one
        const createMarkNode = createNode || $createMarkNode;
        lastCreatedMarkNode = createMarkNode([id], yourScore, competitorScore);
        targetNode.insertBefore(lastCreatedMarkNode);
      }

      // Add the target node to be wrapped in the latest created mark node
      lastCreatedMarkNode.append(targetNode);
    } else {
      // If we don't have a target node to wrap we can clear our state and
      // continue on with the next node
      currentNodeParent = undefined;
      lastCreatedMarkNode = undefined;
    }
  }
  return lastCreatedMarkNode;
}

export function TopicHighlightPlugin({
  fraseDocument,
}: {
  fraseDocument: FraseDocument;
}) {
  const [editor] = useLexicalComposerContext();
  const docId = fraseDocument.id;
  const { topics: topicsStore } = useTopicsStore();
  const { editor: editorStore } = useEditorStore();
  const { topics } = topicsStore[docId] || { topics: [] };
  const { highlightTopics } = editorStore;
  const isDarkMode = useIsDarkMode();
  const [isOpenDelayed, setIsOpenDelayed] = useState(true);
  const skipDelayTimerRef = useRef<NodeJS.Timeout | null>(null);
  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const mentionsRef = useRef({ your: 0, competitor: 0 });

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const markNodes = $nodesOfType(MarkNode);
        markNodes.forEach((markNode) => {
          const element = editor.getElementByKey(markNode.getKey());
          if (!element) return;

          const textContent = markNode.getTextContent().toLowerCase();
          const topic = topics.find(
            (topic) => topic.sing_lower.toLowerCase() === textContent
          );

          if (topic?.bg_color) {
            const themeClass = `topic-highlight-${isDarkMode ? 'dark' : 'light'}-${topic.bg_color}`;
            // Remove old classes first
            Object.values(lightModeClassMap).forEach(cls => {
              element.classList.remove(cls);
            });
            Object.values(darkModeClassMap).forEach(cls => {
              element.classList.remove(cls);
            });
            // Add new class
            element.classList.add(themeClass);
          }
        });
      });
    });
  }, [editor, topics, isDarkMode]);

  const handleHighlightTopics = debounce(() => {
    if (highlightTopics) {
      editor.update(
        () => {
          const currentSelection = $getSelection();
          for (const term of topics) {
            if (term.blacklist) {
              continue;
            }

            const termLower = term.sing_lower.toLowerCase();
            const children = $getRoot().getAllTextNodes();
            for (const child of children) {
              let textContent = child.getTextContent().toLowerCase();
              let occurrences = []; // Array to store start and end indices of all occurrences

              // Step 1: Scan and Record
              let startIndex = textContent.indexOf(termLower);
              while (startIndex !== -1) {
                const endIndex = startIndex + termLower.length;
                occurrences.push({ startIndex, endIndex });

                startIndex = textContent.indexOf(termLower, endIndex);
              }

              // Step 2: Apply Highlights from last to first
              for (let i = occurrences.length - 1; i >= 0; i--) {
                if ($isMarkNode(child.getParent())) {
                  continue;
                }

                const { startIndex, endIndex } = occurrences[i];
                const selection = $createRangeSelection();

                if (child instanceof TextNode) {
                  selection.setTextNodeRange(
                    child,
                    startIndex,
                    child,
                    endIndex
                  );
                }

                const markNode = $wrapSelectionInMarkNode(
                  selection,
                  false,
                  "topic-highlight",
                  term.user_count,
                  term.frequency
                );

                const element = editor.getElementByKey(markNode?.getKey());
                if (element) {
                  element.setAttribute(
                    "data-your-mentions",
                    term.user_count.toString()
                  );
                  element.setAttribute(
                    "data-competitor-mentions",
                    term.frequency.toString()
                  );
                }
              }
            }
          }

          if (
            currentSelection &&
            $isRangeSelection(currentSelection) &&
            currentSelection.focus.node instanceof MarkNode
          ) {
            currentSelection?.focus.set(
              currentSelection.focus.node.getKey(),
              currentSelection.focus.offset,
              "text"
            );
          }
        },
        {
          tag: "history-merge",
        }
      );
    } else {
      handleUnhighlightTopics();
    }
  }, 200);

  const handleUnhighlightTopics = debounce(() => {
    editor.update(
      () => {
        const currentSelection = $getSelection();
        const markNodes = $nodesOfType(MarkNode);
        markNodes.forEach((markNode) => {
          if (markNode.getIDs().includes("topic-highlight")) {
            const element = editor.getElementByKey(markNode.getKey());
            if (element) {
              Object.values(lightModeClassMap).forEach(cls => {
                element.classList.remove(cls);
              });
              Object.values(darkModeClassMap).forEach(cls => {
                element.classList.remove(cls);
              });
            }
            $unwrapMarkNode(markNode);
          }
        });

        if (
          currentSelection &&
          $isRangeSelection(currentSelection) &&
          currentSelection.focus.node instanceof MarkNode
        ) {
          currentSelection?.focus.set(
            currentSelection.focus.node.getKey(),
            currentSelection.focus.offset,
            "text"
          );
        }
      },
      {
        tag: "history-merge",
      }
    );
  }, 200);

  function showTooltip(event: MouseEvent) {
    let markElement = event.target as HTMLElement;
    while (markElement && markElement.tagName !== "MARK") {
      markElement = markElement.parentElement!;
    }

    if (!markElement || markElement.id !== "topic-highlight") return;

    const tooltip = document.getElementById("static-tooltip");
    if (!tooltip) return;

    // Update the content first and store in ref
    const yourMentions = markElement.getAttribute("data-your-mentions") || "0";
    const competitorMentions =
      markElement.getAttribute("data-competitor-mentions") || "0";
    mentionsRef.current = {
      your: parseInt(yourMentions, 10),
      competitor: parseInt(competitorMentions, 10),
    };

    // Update position and show immediately
    const rect = markElement.getBoundingClientRect();
    tooltip.style.left = `${rect.left + window.scrollX}px`;
    tooltip.style.top = `${rect.bottom + window.scrollY + 4}px`;
    tooltip.style.display = "block";
    // Small delay before showing to ensure smooth transition
    requestAnimationFrame(() => {
      tooltip.style.opacity = "1";
    });

    // Clear skip delay timer when showing tooltip
    if (skipDelayTimerRef.current) {
      clearTimeout(skipDelayTimerRef.current);
      skipDelayTimerRef.current = null;
    }
  }

  function hideTooltip() {
    const tooltip = document.getElementById("static-tooltip");
    if (!tooltip) return;

    // Hide immediately
    tooltip.style.opacity = "0";
    tooltip.style.display = "none";

    // Reset the delay state when tooltip is hidden
    setIsOpenDelayed(true);

    // Remove the skip delay timer when hiding tooltip
    if (skipDelayTimerRef.current) {
      clearTimeout(skipDelayTimerRef.current);
      skipDelayTimerRef.current = null;
    }
  }

  useEffect(() => {
    setIsOpenDelayed(true);
    return () => {
      setIsOpenDelayed(true);
    };
  }, [fraseDocument.id]); // Reset on document/tab change

  useEffect(() => {
    const registeredElements: WeakSet<HTMLElement> = new WeakSet();

    const handleMouseEnter = (element: HTMLElement) => {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
        hideTimeoutRef.current = null;
      }

      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current);
      }

      if (isOpenDelayed) {
        showTimeoutRef.current = setTimeout(() => {
          showTooltip({ target: element } as MouseEvent);
          setIsOpenDelayed(false);
          showTimeoutRef.current = null;
        }, DEFAULT_DELAY_DURATION);
      } else {
        // Show immediately if within skip delay
        showTooltip({ target: element } as MouseEvent);
      }
    };

    const handleMouseLeave = () => {
      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current);
        showTimeoutRef.current = null;
      }

      hideTimeoutRef.current = setTimeout(() => {
        hideTooltip();
        hideTimeoutRef.current = null;
      }, 200);
    };

    const removeMutationListener = editor.registerMutationListener(
      MarkNode,
      (mutations) => {
        editor.getEditorState().read(() => {
          for (const [key, mutation] of mutations) {
            const element = editor.getElementByKey(key);
            if (
              (mutation === "created" || mutation === "updated") &&
              element !== null &&
              !registeredElements.has(element)
            ) {
              registeredElements.add(element);
              element.addEventListener("mouseenter", () =>
                handleMouseEnter(element)
              );
              element.addEventListener("mouseleave", handleMouseLeave);
            }
          }
        });
      }
    );

    // Cleanup
    return () => {
      if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
      if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current);
      if (skipDelayTimerRef.current) clearTimeout(skipDelayTimerRef.current);
      removeMutationListener();
    };
  }, [editor, isOpenDelayed]);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    const scrollPosition = window.scrollY;

    if (!document.hidden) {
      if (editorStore.optimizeViewVisible) {
        handleHighlightTopics();
      } else {
        handleUnhighlightTopics();
      }

      // Restore scroll position after the update
      requestAnimationFrame(() => {
        window.scrollTo({
          top: scrollPosition,
          behavior: "instant",
        });
      });
    }
  }, [topics, highlightTopics, isDarkMode, editorStore.optimizeViewVisible]);

  return (
    <div
      id="static-tooltip"
      className="absolute z-[57] hidden shadow-glowLg dark:shadow-lg overflow-hidden font-normal px-2 py-1 text-xs text-zinc-900 dark:text-white rounded-md backdrop-blur-lg bg-white/80 dark:bg-zinc-900/50 ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5"
    >
      Your Mentions: {mentionsRef.current.your} / Competitor Mentions:{" "}
      {mentionsRef.current.competitor}
    </div>
  );
}
