import { useNavigationStore } from "@/components/Layout/MobileNavigation";
import { cn } from "@/utils/style";
import { useRef } from "react";

export default function NavigationPlugin() {
  const { openPanel, isNavOpen } = useNavigationStore();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      openPanel();
    }, 400);
  };

  const handleMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  return (
    <div
      className={cn(
        "absolute top-0 left-0 w-3 h-full z-[100] bg-transparent print:hidden",
        isNavOpen && "hidden"
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    ></div>
  );
}
