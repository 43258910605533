import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

import { User } from "@/features/users";
import { Template } from "../types";

export const getGlobalTemplates = async (
  type: string,
  orgId: number | undefined
): Promise<Template[]> => {
  const response = await axios.post(`/getGlobalTemplates`, {
    type,
    org_id: orgId,
  });
  return response;
};

type QueryFnType = typeof getGlobalTemplates;

type UseGlobalTemplatesOptions = {
  type?: string;
  config?: QueryConfig<QueryFnType>;
  orgId?: number;
  selectedType: string;
  user?: User;
};

export const useGlobalTemplates = ({
  type,
  selectedType,
  user,
  config,
}: UseGlobalTemplatesOptions) => {
  const orgId = selectedType === "custom" ? user?.orgId : undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["globalTemplates", type, orgId],
    queryFn: () => getGlobalTemplates(type || "", orgId),
  });
};
