import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";

type RateGptArticlePayload = {
  article_id: number;
  rating: string;
  feedback: string;
};

export const rateGptArticle = (
  payload: RateGptArticlePayload
): Promise<any> => {
  return axios.post(`/rateAIArticle`, payload);
};

type UseRateGptArticleOptions = {
  config?: any;
};

export const useRateGptArticle = ({
  config,
}: UseRateGptArticleOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: (payload: RateGptArticlePayload) => rateGptArticle(payload),
  });
};
