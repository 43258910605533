import { cn } from "@/utils/style";

type StatusIconProps = {
  className?: string;
};

export function StatusIcon(status: string) {
  switch (status) {
    case "Researching":
      return <ResearchingStatusIcon className="mr-2" />;
    case "Writing":
      return <WritingStatusIcon className="mr-2" />;
    case "Optimizing":
      return <OptimizingStatusIcon className="mr-2" />;
    case "Completed":
      return <CompletedStatusIcon className="mr-2" />;
    case "Published":
      return <PublishedStatusIcon className="mr-2" />;
    default:
      return <ResearchingStatusIcon className="mr-2" />;
  }
}

export function ResearchingStatusIcon(props: StatusIconProps) {
  const { className } = props;

  return (
    <svg
      className={cn("stroke-zinc-400 dark:stroke-zinc-400", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
      <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
      <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
      <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
      <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
      <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
      <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
      <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
    </svg>
  );
}

export function WritingStatusIcon(props: StatusIconProps) {
  const { className } = props;

  return (
    <svg
      className={cn("stroke-yellow-500 dark:stroke-yellow-400", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z"></path>
      <path d="M16 5l3 3"></path>
      <path d="M9 7.07a7 7 0 0 0 1 13.93a7 7 0 0 0 6.929 -6"></path>
    </svg>
  );
}

export function OptimizingStatusIcon(props: StatusIconProps) {
  const { className } = props;

  return (
    <svg
      className={cn("stroke-indigo-600 dark:stroke-indigo-400", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
      <path d="M16.924 11.132a5 5 0 1 0 -4.056 5.792"></path>
      <path d="M3 12a9 9 0 1 0 9 -9"></path>
    </svg>
  );
}

export function CompletedStatusIcon(props: StatusIconProps) {
  const { className } = props;

  return (
    <svg
      className={cn("fill-emerald-600 dark:fill-emerald-400", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="#059669"
      fill="#059669"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
        strokeWidth="0"
        fill="#059669"
      ></path>
    </svg>
  );
}

export function PublishedStatusIcon(props: StatusIconProps) {
  const { className } = props;

  return (
    <svg
      className={cn("fill-zinc-400", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-4.98 3.66l-.163 .01l-.086 .016l-.142 .045l-.113 .054l-.07 .043l-.095 .071l-.058 .054l-4 4l-.083 .094a1 1 0 0 0 1.497 1.32l2.293 -2.293v5.586l.007 .117a1 1 0 0 0 1.993 -.117v-5.585l2.293 2.292l.094 .083a1 1 0 0 0 1.32 -1.497l-4 -4l-.082 -.073l-.089 -.064l-.113 -.062l-.081 -.034l-.113 -.034l-.112 -.02l-.098 -.006z"
        strokeWidth="0"
        fill="#a1a1aa"
      ></path>
    </svg>
  );
}
