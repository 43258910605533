import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { Template } from "../types";

type GetTemplatesForOrgInput = {
  org_id: number;
  type: string;
};

type GetTemplatesForOrgResponse = Template[];

const getTemplatesForOrg = async (
  input: GetTemplatesForOrgInput
): Promise<GetTemplatesForOrgResponse> => {
  const response = await axios.post("/getTemplatesForOrg", input);
  return response;
};

type QueryFnType = typeof getTemplatesForOrg;

type UseGetTemplatesForOrgOptions = {
  org_id: number;
  type: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetTemplatesForOrg = ({
  org_id,
  type,
  config,
}: UseGetTemplatesForOrgOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["templatesForOrg", org_id, type],
    queryFn: () => getTemplatesForOrg({ org_id, type }),
  });
};
