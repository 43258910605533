import { useMutation } from "react-query";

import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";

import { FolderIcon } from "@heroicons/react/24/solid";
import { Folder } from "../types";

export const createFolder = (data: { name: string }): Promise<Folder> => {
  return axios.post(`/createTeam`, data);
};

type UseCreateFolderOptions = {
  config?: MutationConfig<typeof createFolder>;
};

export const useCreateFolder = ({ config }: UseCreateFolderOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newFolder) => {
      await queryClient.cancelQueries("channels");

      const previousFolders = queryClient.getQueryData<Folder[]>("channels");

      queryClient.setQueryData("channels", [
        ...(previousFolders || []),
        newFolder,
      ]);

      return { previousFolders: previousFolders };
    },
    onError: (_, __, context: any) => {
      if (context?.previousFolders) {
        queryClient.setQueryData("channels", context.previousFolders);
      }
    },
    onSuccess: (folder) => {
      queryClient.refetchQueries("channels");
      addNotification({
        type: "success",
        title: "Folder created",
        message: (
          <span className="flex items-center">
            <FolderIcon className="w-3.5 h-3.5 fill-zinc-500" />
            <span className="ml-2 text-xs">{folder.team.name}</span>
          </span>
        ),
      });
    },
    ...config,
    mutationFn: createFolder,
  });
};
