import { DocumentTable } from "@/components/Elements";
import { DocumentTableSkeleton } from "@/components/Elements/Skeleton/DocumentTableSkeleton";
import { useUser } from "@/features/auth";
import { useGetUsersForOrg } from "@/features/users/api/getUsersForOrg";
import { useState } from "react";
import { useGetChannelsForUser } from "../api/channels";
import { useDocumentCount } from "../api/getDocumentCount";
import { useDocuments } from "../api/getDocuments";
import { useGetFolderInfo } from "../api/getFolder";
import { getFolderFilters } from "./FolderFilters";
import { getNameFilters } from "./NameFilters";
import { getSearchQueryFilters } from "./SearchQueryFilters";
import { getStatusFilters } from "./StatusFilters";
import { getUserFilters } from "./UserFilters";

/**
 * Extraction of filters determination to a separate component.
 * This is done for much cleaner and DRY code, also providing easy testing.
 */
const useQueriesSuccessStatus = ({
  documentsQuery,
  channelsQuery,
  usersQuery,
  folderQuery,
}) => {
  const { isLoading: isDocumentsLoading } = documentsQuery;
  const { isLoading: isChannelsLoading } = channelsQuery;
  const { isLoading: isUsersLoading } = usersQuery;
  const { isLoading: isFoldersLoading } = folderQuery;

  const isSuccess = documentsQuery.isSuccess;

  return {
    isDocumentsLoading,
    isChannelsLoading,
    isUsersLoading,
    isFoldersLoading,
    isSuccess,
  };
};

/**
 * This component gets the required filters set.
 * Again, breaking down to separate components for easy testability.
 */
const useFilterSettings = ({ folderId, channelsQuery, usersQuery }) => [
  ...getNameFilters(),
  ...getSearchQueryFilters(),
  ...(folderId ? [] : getFolderFilters(channelsQuery)),
  ...getUserFilters(usersQuery),
  ...getStatusFilters(),
];

/**
 * DocumentsList main component
 */
export const DocumentsList = ({ folderId, getArchived }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(1);
  const { data: user, isLoading: isUserLoading, isSuccess } = useUser();
  const isAdmin = user?.org_role === 1;
  const userId = user?.id;

  const documentsQuery = useDocuments({
    ...fetchDocumentsQuery(
      user,
      folderId,
      selectedFilters,
      getArchived,
      size,
      page,
      isAdmin,
      userId
    ),
    config: {
      enabled: isSuccess,
    },
  });
  const documentsCountQuery = useDocumentCount({
    config: {
      enabled: isSuccess,
    },
  });
  const channelsQuery = useGetChannelsForUser({
    config: {
      enabled: documentsQuery.isSuccess,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });
  const usersQuery = useGetUsersForOrg({
    config: {
      enabled: documentsQuery.isSuccess,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });
  const folderQuery = useGetFolderInfo({
    teamId: folderId?.toString() || "",
    config: { enabled: !!folderId },
  });

  const {
    isDocumentsLoading,
    isChannelsLoading,
    isUsersLoading,
    isFoldersLoading,
  } = useQueriesSuccessStatus({
    documentsQuery,
    channelsQuery,
    usersQuery,
    folderQuery,
  });

  if (
    isDocumentsLoading ||
    isChannelsLoading ||
    isUsersLoading ||
    isFoldersLoading ||
    isUserLoading
  )
    return renderTableSkeleton();

  const filters = useFilterSettings({ folderId, channelsQuery, usersQuery });

  return renderDocumentTable({
    documentsQuery,
    documentsCountQuery,
    channelsQuery,
    usersQuery,
    folderQuery,
    filters,
    selectedFilters,
    setSelectedFilters,
    getArchived,
    setSize,
    page,
    setPage,
    isDocumentsLoading,
  });
};

/**
 * Queries the required document.
 */
const fetchDocumentsQuery = (
  user,
  folderId,
  selectedFilters,
  getArchived,
  size,
  page,
  isAdmin,
  userId
) => ({
  org_id: user?.orgId || null,
  name: fetchSelectedValue("metadata_name", null, selectedFilters),
  team_id: fetchSelectedValue("teamId", folderId, selectedFilters),
  query: fetchSelectedValue("query", null, selectedFilters),
  user_id: folderId
    ? null
    : fetchSelectedValue(
        "doc_owner_name",
        isAdmin ? null : userId,
        selectedFilters
      ),
  doc_status: fetchSelectedValue("doc_status_desc", null, selectedFilters),
  archived: getArchived,
  size,
  page,
});

/**
 * Fetch selected value utility function
 */
const fetchSelectedValue = (parent, defaultValue, selectedFilters) =>
  selectedFilters.find((filter) => filter.parent === parent)?.value ||
  defaultValue;

/**
 * Renders the spinner component
 */
const renderTableSkeleton = () => (
  <DocumentTableSkeleton rows={50} columns={6} className="w-full h-full" />
);

/**
 * Renders the DocumentTable component
 */
const renderDocumentTable = ({
  documentsQuery,
  documentsCountQuery,
  channelsQuery,
  usersQuery,
  folderQuery,
  filters,
  selectedFilters,
  setSelectedFilters,
  getArchived,
  setSize,
  page,
  setPage,
  isDocumentsLoading,
}) => {
  return (
    <DocumentTable
      documents={documentsQuery.data?.documents || []}
      documentsCount={documentsQuery.data?.total_docs || 0}
      folders={channelsQuery.data || []}
      users={usersQuery.data || []}
      folderInfo={folderQuery.data || null}
      filters={filters}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      getArchived={getArchived}
      setSize={setSize}
      page={page}
      setPage={setPage}
      isDocumentsLoading={isDocumentsLoading}
    />
  );
};
