import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";

export type Comment = {
  comment: string;
  doc_hash: string;
  reference_text: string;
  section_id: string;
  id?: number;
  parent_comment_id?: number;
  user_id?: number;
  doc_id?: number;
  date_created?: number;
  response_count?: number;
  full_name?: string | null;
};

export const getComments = ({
  documentHash,
}: {
  documentHash: string;
}): Promise<Comment[]> => {
  return axios.post(`/retrieveCommentsForDoc`, {
    doc_hash: documentHash,
  });
};

type QueryFnType = typeof getComments;

type UseRetrieveCommentsOptions = {
  documentHash: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetComments = ({
  documentHash,
  config,
}: UseRetrieveCommentsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["comments", documentHash],
    queryFn: () => getComments({ documentHash }),
  });
};
