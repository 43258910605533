import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface UpgradePlanDialogProps {
  onClose: (close: () => void) => void;
  header: string;
  description: string;
  plans: { name: string; path: string }[];
  open: boolean;
}

export const UpgradePlanDialog = ({
  onClose,
  header,
  description,
  plans,
  open,
}: UpgradePlanDialogProps) => {
  const [hasTracked, setHasTracked] = useState(false);
  const trackEvent = useTrackEvent();
  const navigate = useNavigate();

  const close = () => {
    onClose(() => {});
  };

  const handleTrackUpgradePopup = async () => {
    if (!hasTracked) {
      trackEvent.mutate({
        event: "upgrade_popup",
        properties: JSON.stringify({}),
      });
      setHasTracked(true);
    }
  };

  if (open) {
    handleTrackUpgradePopup();
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose(close);
        }
      }}
    >
      <DialogPortal>
        <DialogContent
          className="relative z-[51] w-[600px] flex flex-col h-fit gap-4 rounded-lg border dark:border-zinc-700 bg-white dark:bg-zinc-800 shadow-2xl"
          variant="custom"
        >
          <DialogHeader className="flex items-center space-x-4 pl-6 pb-0 pt-4">
            <DialogTitle className="text-2xl text-zinc-900 dark:text-white font-semibold">
              {header}
            </DialogTitle>
          </DialogHeader>

          <DialogCloseButton
            className="absolute top-4 right-4"
            close={() => {
              onClose(close);
            }}
          />
          <DialogDescription className="px-6 pb-0">
            <p className="text-sm text-zinc-900 dark:text-white">
              {description}
            </p>
          </DialogDescription>

          <DialogFooter className="px-6 pb-4 pt-0 flex items-center rounded-bl-md rounded-br-md bg-white dark:bg-zinc-800">
            <div className="flex items-center space-x-2">
              <Button
                variant="primaryBlur"
                size="sm"
                className="bg-emerald-100"
                onClick={() => {
                  navigate("/app/settings/plans");
                  onClose(close);
                }}
              >
                Upgrade plan
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
