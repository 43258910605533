import { useSidebarNavigationStore } from "@/components/Layout/SidebarToggle";
import { cn } from "@/utils/style";
import {
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarRightExpand,
  TbX,
} from "react-icons/tb";
import { Button } from "../Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "./Dialog";

interface DialogModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  title?: string;
  containerClassName?: string;
  panelClassName?: string;
  backdropClassName?: string;
  backdropEnabled?: boolean;
  closeOnBackdropClick?: boolean;
  panelWidth?: number;
}

export const DialogCloseButton = ({
  close,
  className,
}: {
  close: () => void;
  className?: string;
}) => {
  return (
    <Button
      variant="buttonIcon"
      buttonIcon={<TbX />}
      onClick={close}
      className={className}
    />
  );
};

const DialogSidebarButton = ({ isSidebarOpen, openSidebar, closeSidebar }) => {
  return (
    <>
      {isSidebarOpen ? (
        <Button
          variant="buttonIcon"
          buttonIcon={<TbLayoutSidebarRightCollapse />}
          onClick={() => {
            closeSidebar();
          }}
          size="lg"
          tooltipContent="Close sidebar"
          className="flex-shrink-0"
        ></Button>
      ) : (
        <Button
          variant="buttonIcon"
          buttonIcon={<TbLayoutSidebarRightExpand />}
          onClick={() => {
            openSidebar();
          }}
          size="lg"
          tooltipContent="Open sidebar"
          className="flex-shrink-0"
        ></Button>
      )}
    </>
  );
};

export const DialogModal: React.FC<DialogModalProps> = ({
  open,
  setOpen,
  children,
  title,
  header,
  footer,
  trigger,
  variant = "fitContent",
  containerClassName,
  panelClassName,
  closeOnBackdropClick = true,
  fullScreenToggleEnabled = false,
  panelWidth,
  className,
  contentClassName,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  title?: string;
  header?: React.ReactNode;
  containerClassName?: string;
  panelClassName?: string;
  backdropClassName?: string;
  backdropEnabled?: boolean;
  closeOnBackdropClick?: boolean;
  panelWidth?: number;
  trigger?: React.ReactNode;
  variant?: "fitContent" | "fullScreen";
  footer?: React.ReactNode;
  fullScreenToggleEnabled?: boolean;
  className?: string;
  contentClassName?: string;
}) => {
  const { closeSidebar, openSidebar, isSidebarOpen } =
    useSidebarNavigationStore();

  return (
    <Dialog open={open} onOpenChange={setOpen} modal={false}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        containerClassName="bg-white/70 dark:bg-zinc-900/70"
        variant={variant}
        preventCloseOnEscape={true}
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        className={className}
        style={{
          marginRight: !isSidebarOpen ? `8px` : `${panelWidth + 8}px`,
        }}
      >
        {title && (
          <DialogHeader>
            <div className="flex justify-between items-center w-full">
              <div className="text-lg font-medium">{title}</div>
              <div className="flex flex-row items-center gap-x-2">
                {fullScreenToggleEnabled && (
                  <DialogSidebarButton
                    isSidebarOpen={isSidebarOpen}
                    openSidebar={openSidebar}
                    closeSidebar={closeSidebar}
                  />
                )}
                <DialogCloseButton close={() => setOpen(false)} />
              </div>
            </div>
          </DialogHeader>
        )}
        {header && <DialogHeader>{header}</DialogHeader>}
        <DialogDescription className={cn("pl-4 pr-2", contentClassName)}>
          {children}
        </DialogDescription>
        {footer && <DialogFooter className="px-4 pb-4">{footer}</DialogFooter>}
      </DialogContent>
    </Dialog>
  );
};
