import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { useSerpStore } from "@/stores/serp";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { forwardRef } from "react";
import { TbArrowDown, TbArrowUp } from "react-icons/tb";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";

const EditorItem = ({
  label,
  value,
  averageValue,
  averageValueTooltip,
}: {
  label: string;
  value: number;
  averageValue: number;
  averageValueTooltip?: string;
}) => {
  return (
    <div className="flex flex-col items-center space-y-0.5 w-full">
      <p className="text-2xs font-semibold text-zinc-400 dark:text-zinc-600">
        {label.toUpperCase()}
      </p>
      <div className="flex items-center">
        <p className="font-bold text-xl dark:text-white">{value}</p>
        {value > averageValue && (
          <TbArrowUp className="ml-1 fill-emerald-500 stroke-emerald-500" />
        )}
        {value < averageValue && (
          <TbArrowDown className="ml-1 fill-rose-500 stroke-rose-500" />
        )}
      </div>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <p className="text-2xs font-semibold text-zinc-400 dark:text-zinc-600">
              {averageValue}
            </p>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>{averageValueTooltip}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

const PromptItem = ({
  question,
  setInsightVisible,
}: {
  question: string;
  setInsightVisible: (value: boolean) => void;
}) => {
  const { setHeaderNavigation } = useHeaderNavigation();

  const handleClick = () => {
    setInsightVisible(true);
    setHeaderNavigation({
      title: question,
      onBack: () => setInsightVisible(false),
    });
  };

  return (
    <div
      onClick={handleClick}
      className="h-10 flex items-center text-2xs bg-white rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5 hover:cursor-pointer"
    >
      <p className="dark:text-zinc-50 px-4 py-2">{question}</p>
    </div>
  );
};

const Insight = ({ value }: { value: string }) => {
  return (
    <p className="mx-4 py-2.5 px-4 text-xs word border rounded-md dark:text-white dark:border-zinc-800">
      {value}
    </p>
  );
};

export const Insights = forwardRef<HTMLDivElement>(({ props }, ref) => {
  const { editor: editorStore } = useEditorStore();
  const { document } = useDocumentStore();
  const { serp } = useSerpStore();
  const {
    averageWordCount,
    averageHeaderCount,
    averageImageCount,
    averageLinkCount,
  } = serp[document.id] || {
    averageWordCount: 0,
    averageHeaderCount: 0,
    averageImageCount: 0,
    averageLinkCount: 0,
  };

  return (
    <div ref={ref}>
      <div className="flex w-full divide-x dark:divide-zinc-700">
        <EditorItem
          label="Words"
          value={editorStore.wordCount}
          averageValue={averageWordCount}
          averageValueTooltip="The average word count across the top search results"
        />
        <EditorItem
          label="Headers"
          value={editorStore.headerNodes.length}
          averageValue={averageHeaderCount}
          averageValueTooltip="The average number of headers across the top search results"
        />
        <EditorItem
          label="Links"
          value={editorStore.linkNodes.length}
          averageValue={averageLinkCount}
          averageValueTooltip="The average number of links across the top search results"
        />
        <EditorItem
          label="Images"
          value={editorStore.imageNodes.length}
          averageValue={averageImageCount}
          averageValueTooltip="The average number of images across the top search results"
        />
      </div>
    </div>
  );
});
